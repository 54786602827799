import PropTypes from 'prop-types'
import React from 'react'

export const Image = (props) => {
  const { src, alt, height } = props

  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <img src={src || ''} alt={alt || 'Logo'} height={height || 40} />
    </div>
  )
}

Image.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  alt: PropTypes.string,
}

Image.defaultProps = {
  alt: '',
  className: '',
}
