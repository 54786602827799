import { makeStyles } from '../../styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    height: `calc(100% - 60px)`,
    backgroundColor: 'theme?.palette?.secondary?.main',
  },
  loading: {
    height: `100%`,
    marginTop: '20%',
  },
  divTitle: {
    display: 'table-cell',
    height: 60,
    verticalAlign: 'middle',
  },
  pagingPanel: {
    height: 50,
    fontFamily: theme?.palette?.typography?.fontFamily,
    backgroundColor: theme?.palette?.secondary?.main,
    color: theme?.palette?.secondary?.contrastText,
    '& > div > div': {
      color: `${theme?.palette?.secondary?.contrastText} !important`,
    },
    '& > div > div > svg': {
      color: `${theme?.palette?.secondary?.contrastText} !important`,
    },
    '& > div > button': {
      color: `${theme?.palette?.secondary?.contrastText} !important`,
    },
  },
  sortButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 2,
  },
  sortButton: {
    fontSize: 12,
    color: theme?.palette?.secondary?.contrastText,
  },
  sortButtonSelected: {
    color: theme?.palette?.quaternary?.main,
    backgroundColor: theme?.palette?.primary?.main,
    '&:hover': {
      color: theme?.palette?.quaternary?.main,
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
  tableContainer: {},
}))
