import useCallAPI from './useCallAPI'
import { normalizeCustomersData } from './Normalizers'

const filterQuery = (values) => {
  return {
    ...values,
  }
}

export const useCustomersData = () => {
  const {
    reset,
    requestSuccess,
    requestData,
    requestLoading,
    fetchGET,
    fetchPOST,
    fetchPUT,
  } = useCallAPI({
    initialCall: false,
    context: `customer_suggest`,
  })

  const handleGET = (params) => {
    fetchGET({ userParams: filterQuery(params) })
  }

  const handlePOST = (params) => {
    fetchPOST({ userBody: params })
  }

  const handlePUT = (params) => {
    fetchPUT({ newContext: `customer_results`, userBody: params })
  }

  const stateCustomers = normalizeCustomersData(requestData)
  return {
    reset,
    loading: requestLoading,
    state: stateCustomers,
    success: requestSuccess,
    handleGET,
    handlePOST,
    handlePUT,
  }
}
