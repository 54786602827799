import { InputAdornment, Typography } from '../../../../core'
import { makeStyles, useTheme } from '../../../../styles'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { intl } from '../../../../translation'
import { useSnackbar } from '../../../../notifications'
import InputField from '../Atoms/InputField'

const useStyles = makeStyles((theme) => ({
  focused: {},
  alignLeft: {
    width: '47.5%',
    marginRight: '5%',
  },
  alignRight: {
    width: '47.5%',
  },
  title: {
    color: theme?.palette?.secondary?.contrastText,
    textAlign: 'left',
  },
}))

const FieldRangePercentage = ({ filter, onUpdate, version, defaultName }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()

  const [state, setState] = useState({})
  const [valueMin, setValueMin] = useState('')
  const [valueMax, setValueMax] = useState('')

  useEffect(() => {
    if (JSON.stringify(filter) !== '{}') {
      setState({ ...filter })
      setValueMin(filter?.valueMin)
      setValueMax(filter?.valueMax)
    }
  }, [version])

  useEffect(() => {
    setState({
      ...filter,
      valueMin: valueMin ? valueMin / 100 : valueMin,
    })
  }, [valueMin])

  useEffect(() => {
    setState({
      ...filter,
      valueMax: valueMax ? valueMax / 100 : valueMax,
    })
  }, [valueMax])

  useEffect(() => {
    if (JSON.stringify(state) !== '{}') {
      validateValue(state)
    }
  }, [state])

  const validateValue = (data) => {
    onUpdate(data)
  }

  const handleChangeValueMin = (e) => {
    if (Number(e?.target?.value) < 0) {
      setValueMin(0)
      enqueueSnackbar(
        intl.get('phrases.valueMinPercentageError') ||
          'phrases.valueMinPercentageError',
        {
          variant: 'warning',
        }
      )
    } else if (Number(e?.target?.value) > 100) {
      setValueMin(100)
      enqueueSnackbar(
        intl.get('phrases.valueMaxPercentageError') ||
          'phrases.valueMaxPercentageError',
        {
          variant: 'warning',
        }
      )
    } else {
      setValueMin(e?.target?.value)
    }
  }

  const handleChangeValueMax = (e) => {
    if (Number(e?.target?.value) < 0) {
      setValueMax(0)
      enqueueSnackbar(
        intl.get('phrases.valueMinPercentageError') ||
          'phrases.valueMinPercentageError',
        {
          variant: 'warning',
        }
      )
    } else if (Number(e?.target?.value) > 100) {
      setValueMax(100)
      enqueueSnackbar(
        intl.get('phrases.valueMaxPercentageError') ||
          'phrases.valueMaxPercentageError',
        {
          variant: 'warning',
        }
      )
    } else {
      setValueMax(e?.target?.value)
    }
  }

  const handleBlurValueMin = (e) => {
    if (valueMax) {
      if (Number(e?.target?.value) > Number(valueMax)) {
        setValueMin(valueMax)
        enqueueSnackbar(
          intl.get('phrases.valueMinError') || 'phrases.valueMinError',
          {
            variant: 'warning',
          }
        )
      }
    }
  }

  const handleBlurValueMax = (e) => {
    if (valueMin) {
      if (valueMax && Number(e?.target?.value) < Number(valueMin)) {
        setValueMax(valueMin)
        enqueueSnackbar(
          intl.get('phrases.valueMaxError') || 'phrases.valueMaxError',
          {
            variant: 'warning',
          }
        )
      }
    }
  }

  return (
    <div data-testid="range_percentage">
      <Typography className={classes.title}>
        {intl.get(`titles.${defaultName}`) || 'title'}
      </Typography>
      <InputField
        key={`${filter?.defaultName}Min`}
        type="number"
        placeholder={intl.get('words.inputMinTitle') || 'words.inputMinTitle'}
        min={0}
        max={100}
        inputProps={{
          style: {
            color:
              valueMin?.length > 0 && theme?.palette?.secondary?.contrastText,
          },
          endAdornment: (
            <InputAdornment
              data-testid="minAdornment"
              disableTypography
              style={{ color: 'lightGray' }}
              position="start"
            >
              %
            </InputAdornment>
          ),
          value: valueMin,
          onChange: handleChangeValueMin,
          onBlur: handleBlurValueMin,
        }}
        classNames={classes.alignLeft}
      />

      <InputField
        key={`${filter?.defaultName}Max`}
        type="number"
        placeholder={intl.get('words.inputMaxTitle') || 'words.inputMaxTitle'}
        min={0}
        max={100}
        inputProps={{
          classes: {
            root: classes.root,
          },
          style: {
            color:
              valueMax?.length > 0 && theme?.palette?.secondary?.contrastText,
          },
          disableUnderline: true,
          endAdornment: (
            <InputAdornment
              data-testid="maxAdornment"
              disableTypography
              style={{ color: 'lightGray' }}
              position="start"
            >
              %
            </InputAdornment>
          ),
          value: valueMax,
          onChange: handleChangeValueMax,
          onBlur: handleBlurValueMax,
        }}
        classNames={classes.alignRight}
      />
    </div>
  )
}

FieldRangePercentage.propTypes = {
  filter: PropTypes.any,
  onUpdate: PropTypes.any,
  version: PropTypes.any,
}

export default FieldRangePercentage
