import React from 'react'
import { TableCell, TableRow } from '../../../../core'
import { NumberCurrency } from './NumberCurrency'
import { useStyles } from './styles'
import PropTypes from 'prop-types'

export const RowHeader = (props) => {
  const { itens } = props

  return (
    <TableRow>
      {itens?.map((cell, index) => {
        return (
          <TableCell key={index?.toString()} align={cell?.align}>
            {cell?.text}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

RowHeader.propTypes = {
  itens: PropTypes.arrayOf(PropTypes.object),
}

RowHeader.default = {
  itens: [{}],
}

export const DetailsRowData = (props) => {
  const classes = useStyles()
  const { itens, currencyData } = props
  return (
    <TableRow className={classes.tableDetailsValue}>
      {itens?.map((cell, index) => {
        return (
          <TableCell
            key={index.toString()}
            align={cell?.align}
            className={classes.tableCell}
          >
            {cell?.isCurrency ? (
              <NumberCurrency value={cell?.value} currencyData={currencyData} />
            ) : (
              cell?.value
            )}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

DetailsRowData.propTypes = {
  itens: PropTypes.arrayOf(PropTypes.object),
}

DetailsRowData.default = {
  itens: [{}],
}

export const SlicesRowData = (props) => {
  const classes = useStyles()
  const { itens, currencyData } = props

  return (
    <TableRow>
      {itens?.map((cell, index) => {
        return (
          <TableCell
            key={index.toString()}
            align={cell?.align}
            className={classes.tableCell}
          >
            {cell?.isCurrency ? (
              <NumberCurrency value={cell?.value} currencyData={currencyData} />
            ) : (
              cell?.value
            )}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

SlicesRowData.propTypes = {
  itens: PropTypes.arrayOf(PropTypes.object),
}

SlicesRowData.default = {
  itens: [{}],
}
