import React from 'react'
import PropTypes from 'prop-types'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

const CustomObject = ({ options, ...props }) => (
  <DataTypeProvider
    formatterComponent={({ value }) => (value ? options[value] : '')}
    {...props}
  />
)

CustomObject.defaultProps = {
  options: {},
}

CustomObject.propTypes = {
  options: PropTypes.object.isRequired,
}

export default CustomObject
