import { useFetchAxiosWrapper } from '../useFetchAxiosWrapper'
import ENDPOINTS from './ENDPOINTS'

const filterBody = (values) => {
  const newBody = values ? { ...values } : {}
  delete newBody.page
  delete newBody.limit
  delete newBody.sorting

  return newBody
}

export const useExportData = (params) => {
  const { callOnInit = false } = params || {}
  const {
    reset,
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    fetchPOST,
  } = useFetchAxiosWrapper({
    baseEndpoint: ENDPOINTS.POST.export,
    callOnInit,
  })

  const handlePOST = (params = {}) => {
    fetchPOST({
      body: filterBody(params),
    })
  }

  return {
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    reset,
    handlePOST,
  }
}
