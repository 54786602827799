import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Slide, Paper, Icon } from '../../core'
import classNames from 'classnames'
import {
  ToolsBar,
  TableDataHeader,
  TableData,
  DynamicFilters,
  SideList,
} from '../../components'
import PropTypes from 'prop-types'
import { useStyles } from './styles'
import { FilterList as FilterListIcon } from '../../icons'
import { intl } from '../../translation'
import { createListQuery } from '../../utils'
import { useWebSocketContext } from '../../../../contexts/WebSocket'

export const TableWithFilterSkeleton = ({
  hasAsideMenu,
  tableProps,
  filterProps,
  headerProps,
  toolbarProps,
  asideMenuProps,
  inLoading,
  handleUpdateData,
  currencyData,
  screen,
  stateFiltersContext,
  dispatchFiltersContext,
  currencyListOptions,
}) => {
  const classes = useStyles()

  const [currentToolBar, setCurrentToolBar] = useState('')
  const [isToolsBarOpen, setIsToolsBarOpen] = useState(false)
  const [toolbarButtons, setToolbarButtons] = useState([])
  const [hidden, setHidden] = useState(false)
  const [filters, setFilters] = useState({})
  const [sorting, setSorting] = useState([])
  const [listQuery, setListQuery] = useState({})
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(50)

  const [prevStateFiltersContext, setPrevStateFiltersContext] = useState({})

  const handleChangeRightBar = (value) => {
    setCurrentToolBar(currentToolBar === value ? '' : value)
  }

  useEffect(() => {
    if (!_.isEmpty(filterProps?.filterPossibilities)) {
      stateFiltersContext?.isOpen &&
        currentToolBar !== 'filters' &&
        setCurrentToolBar('filters')
    } else {
    }
  }, [filterProps?.filterPossibilities, stateFiltersContext])

  /* Necessário para que, sempre que os filtros mudarem, a seleção de página mostrada volte a ser a 1,
  acompanhando o envio do parâmetro page=1 para a request */
  useEffect(() => {
    if (
      !_.isEqual(prevStateFiltersContext, stateFiltersContext?.[screen]?.values)
    ) {
      setPrevStateFiltersContext(stateFiltersContext?.[screen]?.values)
      page !== 1 && setPage(1)
    }
  }, [stateFiltersContext?.[screen]?.values])

  const DefaultToolbarButtons = [
    {
      label: intl.get('words.filters' || 'words.filters'),
      text: 'filters',
      icon: <FilterListIcon />,
      component: (
        <DynamicFilters
          {...filterProps}
          currencyData={currencyData}
          stateFiltersContext={stateFiltersContext}
          dispatchFiltersContext={dispatchFiltersContext}
          screen={screen}
        />
      ),
      isVisible:
        filterProps?.visible !== undefined && filterProps?.visible !== null
          ? filterProps?.visible
          : true,
    },
  ]

  const normalizeButtons = (buttons) => {
    return buttons?.map((btn) => ({
      ...btn,
      icon: <Icon className={classes.btnColor}>{btn?.icon}</Icon>,
    }))
  }

  useEffect(() => {
    if (page !== 1) {
      setPage(1)
    } else {
      const newParams = createListQuery({
        sorting,
        page,
        limit,
        currencyData,
      })

      if (!_.isEqual(newParams, listQuery)) {
        setListQuery(newParams)
      }
    }
  }, [sorting, limit, currencyData])

  useEffect(() => {
    const newParams = createListQuery({
      filters,
      sorting,
      page,
      limit,
      currencyData,
    })
    setListQuery(newParams)
  }, [page])

  useEffect(() => {
    if (typeof handleUpdateData === 'function') {
      handleUpdateData(listQuery)
    }
  }, [listQuery])

  useEffect(() => {
    asideMenuProps?.commissionSelected && setCurrentToolBar('')
  }, [asideMenuProps?.commissionSelected])

  useEffect(() => {
    if (currentToolBar !== '') {
      setIsToolsBarOpen(true)
    } else {
      setIsToolsBarOpen(false)
      setHidden(false)
    }
  }, [currentToolBar])

  useEffect(() => {
    let newButtonsArray = DefaultToolbarButtons

    if (toolbarProps?.buttons) {
      newButtonsArray = normalizeButtons([
        ...newButtonsArray,
        ...toolbarProps?.buttons,
      ])
    }

    setToolbarButtons(newButtonsArray)
  }, [toolbarProps, filterProps])

  useEffect(() => {
    !isToolsBarOpen && !_.isEmpty(filters) && setFilters({})
  }, [isToolsBarOpen])

  const handleHiddenCall = () => {
    setHidden(!hidden)
  }

  const returnButtonComponent = () => {
    const selected = toolbarButtons?.find(
      (button) => button.text === currentToolBar
    )
    return selected?.component
  }

  const onMonthNavigationClick = () => {
    page !== 1 && setPage(1)
  }

  return (
    <div className={classes.container}>
      <div
        className={classNames(classes.divPaper, {
          [classes.divPaperSidebarOpen]: isToolsBarOpen && !hidden,
        })}
      >
        <Paper className={classes.paper}>
          <div key="tableDataHeader">
            <TableDataHeader
              onMonthNavigationClick={onMonthNavigationClick}
              {...headerProps}
              screen={screen}
            />
          </div>
          <div
            key="tableData"
            style={{ height: tableProps?.title ? 'calc(100% - 60px)' : '100%' }}
          >
            {hasAsideMenu && (
              <aside className={classes.containerList}>
                <SideList {...asideMenuProps} currencyData={currencyData} />
              </aside>
            )}
            <TableData
              {...tableProps}
              page={page}
              limit={limit}
              sorting={sorting}
              onSorting={(newSorting) => setSorting([...newSorting])}
              onPageChange={(newPage) => setPage(newPage + 1)}
              onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
              currencyData={currencyData}
              currencyListOptions={currencyListOptions}
            />
          </div>
        </Paper>
      </div>
      <ToolsBar
        buttons={toolbarButtons}
        selected={currentToolBar}
        inLoading={inLoading}
        hiddenCall={handleHiddenCall}
        isHidden={hidden}
        hasHidden={isToolsBarOpen}
        handleSelectButton={handleChangeRightBar}
      />
      <Slide
        direction="left"
        in={isToolsBarOpen && !hidden}
        mountOnEnter={!hidden}
        unmountOnExit={!hidden}
      >
        <div
          className={classNames(classes.toolBar, {
            [classes.toolBarHidden]: hidden,
          })}
        >
          {currentToolBar && returnButtonComponent()}
        </div>
      </Slide>
    </div>
  )
}

TableWithFilterSkeleton.propTypes = {
  tableProps: PropTypes.object,
  filterProps: PropTypes.object,
  headerProps: PropTypes.object,
  toolbarProps: PropTypes.object,
  inLoading: PropTypes.bool,
  handleUpdateData: PropTypes.func,
}

TableWithFilterSkeleton.defaultProps = {
  tableProps: {},
  filterProps: {},
  headerProps: {},
  toolbarProps: {},
  inLoading: false,
  handleUpdateData: (values) =>
    console.log(values, 'values for handleUpdateData'),
}
