import React, { useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import {
  privateRoutesBR,
  privateRoutesLATAM,
} from '../../Constants/AppRoutesConfig'
import { UserContext } from '../../contexts/User'
import { verifyScreenVisible } from '../../utils/permissions'

export const usePrivateRoutes = () => {
  const [stateUserContext] = useContext(UserContext)
  const { accessRoles, defaultScreen, isLatamUser } = stateUserContext

  const [routes, setRoutes] = useState([])

  const selectedRoutesByCountry = isLatamUser
    ? privateRoutesLATAM
    : privateRoutesBR

  useEffect(() => {
    if (JSON.stringify(accessRoles) !== '{}') {
      const getRedirectUrl = (item) => {
        return item?.role === defaultScreen
      }

      const validateRouteObject = (route) => ({
        ...route,
        isVisible: route?.role?.some((role) =>
          verifyScreenVisible(role, accessRoles)
        ),
        main: verifyScreenVisible(route?.role, accessRoles)
          ? () => route?.main || <></>
          : () => (
              <Redirect
                to={selectedRoutesByCountry.find(getRedirectUrl)?.path}
              />
            ),
      })

      const validatedRoutes = selectedRoutesByCountry?.map((route) => {
        if (route?.nestedItems?.length > 0) {
          const routeValidated = validateRouteObject(route)

          const nestedItems = routeValidated?.nestedItems?.map((nested) => {
            const subRoute = validateRouteObject(nested)
            return subRoute
          })

          routeValidated.hasNestedItems = true
          routeValidated.nestedItems = nestedItems

          return routeValidated
        }

        const routeValidated = validateRouteObject(route)
        routeValidated.hasNestedItems = false
        routeValidated.nestedItems = []

        return routeValidated
      })

      setRoutes(validatedRoutes)
    }
  }, [accessRoles, defaultScreen, selectedRoutesByCountry])

  return { routes }
}
