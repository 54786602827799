import { normalizeConciliationData } from './Normalizers'
import ENDPOINTS from './ENDPOINTS'
import { useFetchAxiosWrapper } from '../useFetchAxiosWrapper'
import { useState, useEffect } from 'react'
import _ from 'lodash'

const filterQuery = (values) => {
  return {
    ...values,
  }
}

export const useConciliationGPRSPlans = (params) => {
  const { callOnInit = false } = params || {}
  const [data, setData] = useState([])
  const {
    putRequest,
    getRequest,
    deleteRequest,
    fetchPOST,
    fetchGET,
    postRequest,
    fetchPUT,
    fetchDELETE,
  } = useFetchAxiosWrapper({
    baseEndpoint: ENDPOINTS.GET.plans,
    callOnInit,
  })

  useEffect(() => {
    if (!_.isEqual(getRequest.data, data)) {
      setData(getRequest.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequest])

  const handleGETPlans = (params = {}) => {
    const newQuery = filterQuery(params)
    fetchGET({
      forceEndpoint: ENDPOINTS.GET.conciliation_GPRS_plans,
      urlParameters: { ...newQuery },
    })
  }

  const handlePOSTPlan = (data = {}) => {
    fetchPOST({
      forceEndpoint: ENDPOINTS.POST.conciliation_GPRS_plans,
      body: data,
      noRefreshData: true,
    })
  }

  const handlePUTPlan = (data = {}) => {
    fetchPUT({
      forceEndpoint: ENDPOINTS.PUT.conciliation_GPRS_plans,
      body: data,
      noRefreshData: true,
    })
  }

  const handleDELETEPlan = (data = {}) => {
    fetchDELETE({
      forceEndpoint: ENDPOINTS.DELETE.conciliation_GPRS_plans,
      body: data,
      noRefreshData: true,
    })
  }

  return {
    data: normalizeConciliationData({ data: data }),
    postRequest,
    putRequest,
    getRequest,
    deleteRequest,
    handlePOSTPlan,
    handlePUTPlan,
    handleGETPlans,
    handleDELETEPlan,
  }
}
