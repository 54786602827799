import React from 'react'
import PropTypes from 'prop-types'
import { intl } from '../../../translation'
import { makeStyles } from '../../../styles'
import { CloudDownload as CloudDownloadIcon } from '../../../icons'
import {
  Paper,
  Popper,
  Tooltip,
  Collapse,
  MenuItem,
  MenuList,
  IconButton,
  Typography,
  ClickAwayListener,
} from '../../../core'
import ProgressWithPosition from '../../ProgressWithPosition'

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.tertiary?.main,
  },
  popper: {
    zIndex: 999,
  },
  paper: {
    maxWidth: 300,
    minWidth: 250,
    maxHeight: 500,
    overflowY: `auto`,
    overflowX: `hidden`,
  },
}))

const Download = ({
  downloadDirectLink,
  downloadList,
  handleButtonClick,
  isLoading,
  processCounter,
  handleReset,
  defaultTexts,
}) => {
  const classes = useStyles()
  const anchorRef = React.useRef(null)
  const [open, setOpen] = React.useState(false)

  const handleClick = (item) => {
    handleButtonClick('Download', item)
    setOpen(false)
  }

  const handleDownloadButton = () => {
    if (!downloadDirectLink) {
      setOpen(!open)
    } else {
      handleButtonClick('Download')
    }
  }

  return (
    <>
      {isLoading ? (
        <ProgressWithPosition
          defaultTexts={defaultTexts}
          processCounter={processCounter}
          handleReset={handleReset}
        />
      ) : (
        <>
          <Tooltip
            data-testid="tooltip"
            title={intl.get(`words.download`) || `words.download`}
          >
            <IconButton
              role="button"
              ref={anchorRef}
              aria-haspopup="true"
              className={classes.icon}
              onClick={handleDownloadButton}
              aria-label={intl.get(`words.download`) || `words.download`}
              aria-controls={open ? 'menu-list-grow' : undefined}
            >
              <CloudDownloadIcon data-testid="downloadIcon" />
            </IconButton>
          </Tooltip>
          {!downloadDirectLink && (
            <Popper
              transition
              open={open}
              placement="bottom-end"
              className={classes.popper}
              anchorEl={anchorRef?.current}
            >
              {({ TransitionProps, placement }) => (
                <Collapse {...TransitionProps}>
                  <Paper className={classes.paper} elevation={3}>
                    <ClickAwayListener onClickAway={() => setOpen(false)}>
                      <MenuList
                        role="list"
                        autoFocusItem={open}
                        id="download-list"
                      >
                        {downloadList?.map((item) => (
                          <MenuItem
                            role="listitem"
                            key={item?.label}
                            onClick={() => handleClick(item)}
                          >
                            <Tooltip title={item?.label}>
                              <Typography variant="inherit" noWrap>
                                {item?.label}
                              </Typography>
                            </Tooltip>
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Collapse>
              )}
            </Popper>
          )}
        </>
      )}
    </>
  )
}

Download.defaultProps = {
  downloadList: [],
  handleButtonClick: () => {},
}

Download.propTypes = {
  downloadList: PropTypes.array.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
}

export default Download
