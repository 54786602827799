import moment from 'moment'

export const secondsToTime = (value) => {
  const newDuration = moment.duration(value, 'seconds')

  const hours = Math.floor(newDuration.asHours())

  const minutes = Math.floor(newDuration.asMinutes()) - hours * 60

  let seconds =
    Math.floor(newDuration.asSeconds()) - hours * 60 * 60 - minutes * 60

  seconds = seconds < 9 ? '0' + seconds : seconds

  let response = ''
  if (value >= 3600) {
    response = `${hours
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}h ${minutes}m ${seconds}s`
  } else if (value < 3600 && value >= 60) {
    response = `${minutes}m ${seconds}s`
  } else {
    response = `${seconds}s`
  }
  return response
}

export const formatNumber = (number) => {
  if (!number) return ''
  const n = number.toString()
  let r = ''
  let x = 0

  for (let i = n.length; i > 0; i--) {
    r += n.substr(i - 1, 1) + (x === 2 && i !== 1 ? '.' : '')
    x = x === 2 ? 0 : x + 1
  }

  return r.split('').reverse().join('')
}
