import axios from 'axios'
import axiosRetry from 'axios-retry'
import qs from 'qs'
import validateError from '../../../utils/auth'
import { loadItem } from '../../../utils/utilsAPI'
import _ from 'lodash'

const client = axios.create()

axiosRetry(client, {
  retries: 3,
  retryCondition(error) {
    switch (error.response.status) {
      case 502:
        return true
      default:
        return false
    }
  },
  retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 3000),
})

export const APICall = ({
  body,
  urlParameters,
  endpoint,
  headers,
  id,
  responseType,
  verb,
  isFormData,
  disableCompanyId,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = null
      let valueId = ''
      let query = ''
      let bodyValues = {}
      const companyId = loadItem('companyId')

      if (
        id &&
        (typeof id === 'string' ||
          typeof id === 'number' ||
          typeof id === 'boolean')
      ) {
        valueId = `/${id.toString()}`
      }
      if (!!!disableCompanyId) {
        if (_.isObjectLike(urlParameters)) {
          query = qs.stringify(
            {
              ...urlParameters,
              companyId: urlParameters?.companyId
                ? urlParameters?.companyId
                : companyId,
            },
            {
              addQueryPrefix: true,
            }
          )
        }

        if (typeof body === 'object') {
          if (isFormData) {
            body.append('companyId', companyId)
          } else {
            body = {
              ...body,
              companyId: body?.companyId ? body?.companyId : companyId,
            }
          }
        }
      }
      bodyValues = body
      const url = `${endpoint}${valueId}${query}`

      switch (verb) {
        case 'GET':
          result = client.get(url, {
            headers,
            responseType: responseType || '',
          })
          break
        case 'PUT':
          result = client.put(url, bodyValues, {
            headers,
            responseType: responseType || '',
          })
          break
        case 'POST':
          result = client.post(url, bodyValues, {
            headers,
            responseType: responseType || '',
          })
          break
        case 'DELETE':
          result = client.delete(url, {
            headers,
            data: {
              ...bodyValues,
            },
            responseType: responseType || '',
          })
          break
        case 'PATCH':
          result = client.patch(url, bodyValues, {
            headers,
            responseType: responseType || '',
          })
          break
        default:
          break
      }

      result
        .then((data) => resolve(data || bodyValues))
        .catch((err) => reject(err))
    } catch (err) {
      const errMessage = await validateError(err)

      reject(errMessage)
    }
  })
}
