import { WrapperLocales as LibraryLocales } from '../src/components/Library/wrappers'
import React, { useContext } from 'react'
import { locales } from './utils/i18n'
import { UserContext } from './contexts/User'
import { hasItem, saveItem, loadItem } from './utils/localStorage'

const WrapperLocales = ({ children }) => {
  const [, dispatchUserContext] = useContext(UserContext)

  const browserLanguage = () => {
    if (!hasItem('userLanguage')) {
      let defaultLanguage = 'PT_BR'
      const regexFR = new RegExp('fr')
      const regexES = new RegExp('es')

      if (regexES.test(navigator.language)) {
        defaultLanguage = 'ES_ES'
      }
      if (regexFR.test(navigator.language)) {
        defaultLanguage = 'FR_FR'
      }
      dispatchUserContext({ type: 'setLanguage', defaultLanguage })
      saveItem(defaultLanguage, 'userLanguage')
      return defaultLanguage
    }
    return loadItem('userLanguage')
  }

  return (
    <LibraryLocales locales={locales} currentLanguage={browserLanguage()}>
      {children}
    </LibraryLocales>
  )
}

export default WrapperLocales
