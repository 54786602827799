import { APICall } from '../../components/Library/api'
import { createHeaders } from '../../utils/utilsAPI'

export const useLibrarySuggestion = () => {
  const handleGET = ({ suggestionUrl, defaultName, value, params }) => {
    return new Promise(async (resolve, reject) => {
      const headers = await createHeaders()
      try {
        const result = await APICall({
          endpoint: `${process.env.REACT_APP_API_URL}/suggestion/${suggestionUrl}`,
          headers,
          verb: 'GET',
          urlParameters: { field: defaultName, value, ...params },
        })

        resolve(result?.data)
      } catch (err) {
        reject({ err, data: [] })
      }
    })
  }
  return {
    handleGET,
  }
}
