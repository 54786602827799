import { normalizeEmailsData } from './Normalizers'
import ENDPOINTS from './ENDPOINTS'
import { useFetchAxiosWrapper } from '../useFetchAxiosWrapper'
import { useState, useEffect } from 'react'
import _ from 'lodash'
import axios from 'axios'
import { createHeaders } from '../../utils/utilsAPI'

const filterBody = ({
  cycle,
  cycleStart,
  cycleEnd,
  order,
  orderBy,
  dateFormat,
  companyId,
  productId,
  customerId,
  customerIdList,
  file,
  data,
}) => ({
  cycle,
  cycleStart,
  cycleEnd,
  order,
  orderBy,
  dateFormat,
  companyId,
  productId,
  customerId,
  customerIdList,
  file,
  data,
})

export const useEmailUploadFiles = (params) => {
  const [data, setData] = useState([])
  const { callOnInit = false } = params || {}
  const { patchRequest, postRequest } = useFetchAxiosWrapper({
    baseEndpoint: ENDPOINTS.GET.billing_email_results,
    callOnInit,
  })
  const [isLoading, setIsLoading] = useState(postRequest.loading)
  const [isSuccess, setIsSuccess] = useState(postRequest.success)

  useEffect(() => {
    if (!_.isEqual(patchRequest.data, data)) {
      setData(patchRequest.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchRequest])

  useEffect(() => {
    setIsLoading(postRequest.loading)
    setIsSuccess(postRequest.success)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postRequest.loading, postRequest.success])

  const handlePOSTUploadFiles = async (params, isFormData = false) => {
    const url = `${process.env.REACT_APP_API_URL}${ENDPOINTS.POST.upload_billing_email}`
    const data = isFormData ? params : filterBody(params)

    const response = await axios.post(url, data, {
      headers: {
        ...createHeaders(),
        'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
      },
    })
    return response
  }

  return {
    data: normalizeEmailsData({ data: data }),
    patchRequest,
    loading: isLoading,
    success: isSuccess,
    postRequest,
    handlePOSTUploadFiles,
  }
}
