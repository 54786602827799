import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import Stomp from 'stompjs'
import { createHeaders } from '../utils/utilsAPI'
import _ from 'lodash'

const WebSocketContext = createContext()

export const useWebSocketContext = () => useContext(WebSocketContext)

export const WebSocketProvider = ({ children }) => {
  const wsDomain = process.env.REACT_APP_WS_DOMAIN
  const protocolPrefix = wsDomain.startsWith('https') ? 'wss://' : 'ws://'
  const webSocketUrl = `${protocolPrefix}${wsDomain.replace(
    /^https?:\/\//,
    ''
  )}/notifws`

  const deletedMessageKeyRef = useRef('')
  const currentScreenRef = useRef('')

  const [webSocketNotifications, setWebSocketNotifications] = useState([])
  const [processCounters, setProcessCounters] = useState({})
  const [deletedMessageKey, setDeletedMessageKey] = useState('')
  const [currentScreen, setCurrentScreen] = useState('')

  useEffect(() => {
    deletedMessageKeyRef.current = deletedMessageKey
    currentScreenRef.current = currentScreen
  }, [deletedMessageKey, currentScreen])

  const processNotification = (notificationList, notification) => {
    const existingNotificationIndex = notificationList.findIndex(
      (n) => n?.key === notification?.key
    )
    if (existingNotificationIndex !== -1) {
      // Update or Remove existing notification
      const updatedNotifications = [...notificationList]
      if (notification.removedAt == null) {
        updatedNotifications[existingNotificationIndex] = notification
      } else {
        delete updatedNotifications[existingNotificationIndex]
      }
      return updatedNotifications
    }

    if (
      !currentScreenRef.current ||
      notification.screen === currentScreenRef.current
    ) {
      // Add new notification to current screen
      return [...notificationList, notification]
    }

    // No change
    return notificationList
  }

  useEffect(() => {
    let stompClient = null
    let reconnectAttempts = 0
    let intervalId
    const reconnectInterval = 5000
    const maxReconnectAttempts = 3

    const connectWebSocket = () => {
      stompClient = Stomp.client(webSocketUrl)
      stompClient.debug = null
      stompClient.heartbeat.outgoing = 20000
      stompClient.heartbeat.incoming = 20000
      stompClient.connect(
        { ...createHeaders() },
        () => {
          if (stompClient && stompClient.connected) {
            reconnectAttempts = 0
            stompClient.subscribe(
              '/user/topic/private',
              (message) => {
                const messageData = JSON.parse(message.body)
                const messageDataAsArray = Array.isArray(messageData)
                  ? messageData
                  : [messageData]

                setWebSocketNotifications((prevNotifications) => {
                  let notificationList = [...prevNotifications]
                  for (let i in messageDataAsArray) {
                    notificationList = processNotification(
                      notificationList,
                      messageDataAsArray[i]
                    )
                  }
                  return notificationList
                })
              },

              (error) => {
                console.log('Erro durante a subscrição', error)
                if (error.headers) {
                  console.log('Detalhes do erro', error.headers)
                }
                if (error.body) {
                  console.log('Corpo do erro', error.body)
                }
              }
            )

            stompClient.subscribe(
              '/user/topic/private/process_counter',
              (message) => {
                const progressData = JSON.parse(message.body)
                if (progressData?.screenName) {
                  setProcessCounters((prevProcessCounters) => {
                    const newObj = { ...prevProcessCounters }
                    if (progressData?.totalItems >= 0) {
                      if (progressData?.action) {
                        newObj[progressData?.screenName] = {
                          [progressData?.action]: {
                            errorItems: progressData?.errorItems,
                            successItems: progressData?.successItems,
                            totalItems: progressData?.totalItems,
                            progress: progressData?.progress,
                            screenName: progressData?.screenName,
                            position: progressData?.position,
                            status: progressData?.status,
                            action: progressData?.action,
                          },
                        }
                      } else {
                        newObj[progressData?.screenName] = {
                          errorItems: progressData?.errorItems,
                          successItems: progressData?.successItems,
                          totalItems: progressData?.totalItems,
                          progress: progressData?.progress,
                          screenName: progressData?.screenName,
                          position: progressData?.position,
                          status: progressData?.status,
                        }
                      }
                    } else {
                      delete newObj[progressData?.screenName]
                    }
                    return newObj
                  })
                }
              },

              (error) => {
                console.log('Erro durante a subscrição', error)
                if (error.headers) {
                  console.log('Detalhes do erro', error.headers)
                }
                if (error.body) {
                  console.log('Corpo do erro', error.body)
                }
              }
            )
          }
        },
        (error) => {
          console.log('Erro durante a conexão STOMP', error)
          if (reconnectAttempts < maxReconnectAttempts) {
            reconnectAttempts++
            setTimeout(connectWebSocket, reconnectInterval)
          } else {
            console.log('Número máximo de tentativas de reconexão atingido.')
          }
        }
      )
    }

    const reconnect = () => {
      if (!stompClient || !stompClient.connected) {
        console.log('Tentando reconectar...', reconnectAttempts + 1)
        if (reconnectAttempts < maxReconnectAttempts) {
          reconnectAttempts++
          connectWebSocket()
        } else {
          console.log('Número máximo de tentativas de reconexão atingido.')
          clearInterval(intervalId)
        }
      } else {
        reconnectAttempts = 0
        clearInterval(intervalId)
      }
    }

    connectWebSocket()

    intervalId = setInterval(reconnect, reconnectInterval)

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        if (!stompClient || !stompClient.connected) {
          reconnectAttempts = 0
          connectWebSocket()
        }
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      console.log('Componente está sendo desmontado')
      clearInterval(intervalId)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      if (stompClient) {
        console.log('Desconectando o websocket')
        stompClient.disconnect()
      }
    }
  }, [])

  const contextValue = {
    webSocketNotifications,
    deletedMessageKey: deletedMessageKeyRef.current,
    setDeletedMessageKey: (key) => {
      deletedMessageKeyRef.current = key
    },
    currentScreenRef,
    currentScreen: currentScreenRef.current,
    setCurrentScreen: (screen) => {
      currentScreenRef.current = screen
    },
    processCounters,
    clearProcessCounter: () => setProcessCounters({}),
  }

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  )
}
