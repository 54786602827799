import React, { Fragment, useEffect, useState } from 'react'
import { intl } from '../translation'

export const WrapperLocales = ({ children, locales, currentLanguage }) => {
  const [initDone, setInitDone] = useState(false)

  useEffect(() => {
    let newLocales = { PT_BR: {} }

    if (locales) {
      newLocales = locales
    }

    intl
      .init({
        locales: newLocales,
        fallbackLocale: 'PT_BR',
        currentLocale: currentLanguage,
      })
      .then(() => {
        setInitDone(true)
      })
  }, [currentLanguage])

  return <Fragment>{initDone && children}</Fragment>
}
