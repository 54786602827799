import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
} from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'
const useStyles = makeStyles((theme) => ({
  itemFilter: {
    backgroundColor: theme.palette.secondary.light,
    marginRight: 10,
    marginBottom: 5,
    borderRadius: 5,
    fontFamily: theme.typography.fontFamily,
    fontSize: '0.9em',
    minHeight: 50,
    color: theme.palette.text.secondary,
  },
  itemText: {
    color: theme.palette.text.secondary,
  },
  itemTextLocked: {
    color: theme.palette.text.secondary,
    maxWidth: '80%',
  },
  emptyData: {
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.secondary,
    color: theme.palette.text.secondary,
  },
}))

const FiltersSaved = ({
  savedFilters,
  loadingTitle,
  noInformationTitle,
  deleteCall,
  deleteTitle,
  loadFilterSavedCall,
}) => {
  const classes = useStyles()

  return (
    <>
      {savedFilters && Array.isArray(savedFilters) ? (
        savedFilters.length > 0 ? (
          <List>
            {savedFilters.map((filter) => (
              <ListItem
                button
                onClick={() => loadFilterSavedCall(filter)}
                key={filter.value}
                className={classes.itemFilter}
              >
                <ListItemText
                  primary={
                    !filter.isLocked ? (
                      <p className={classes.itemTextLocked}>{filter.label}</p>
                    ) : (
                      <p className={classes.itemText}>{filter.label}</p>
                    )
                  }
                />
                {!filter.isLocked && (
                  <ListItemSecondaryAction>
                    <Tooltip title={deleteTitle || 'deleteTitle'}>
                      <DeleteIcon
                        className={classes.button}
                        onClick={() => deleteCall(filter)}
                      />
                    </Tooltip>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
        ) : (
          <label className={classes.emptyData}>
            {noInformationTitle || 'noInformationTitle'}
          </label>
        )
      ) : (
        <label className={classes.emptyData}>
          {loadingTitle || 'loadingTitle'}
        </label>
      )}
    </>
  )
}

FiltersSaved.propTypes = {
  savedFilters: PropTypes.array,
  loadingTitle: PropTypes.string,
  noInformationTitle: PropTypes.string,
  deleteCall: PropTypes.func,
  deleteTitle: PropTypes.string,
  loadFilterSavedCall: PropTypes.func,
}

FiltersSaved.defaultProps = {
  savedFilters: [],
  loadingTitle: '',
  noInformationTitle: '',
  deleteCall: () => {},
  deleteTitle: '',
  loadFilterSavedCall: () => {},
}

export default FiltersSaved
