import _ from 'lodash'
import { useFetchAxiosWrapper } from '../useFetchAxiosWrapper'
import ENDPOINTS from './ENDPOINTS'

export const useLibraryBillingReprocess = (params) => {
  const {
    putRequest,

    fetchPUT,
  } = useFetchAxiosWrapper({
    baseEndpoint: ENDPOINTS.PUT.billing_reprocess,
    callOnInit: false,
  })

  const handlePUT = (params) => {
    fetchPUT({
      body: params,
    })
  }

  return {
    putRequest,
    handlePUT,
  }
}
