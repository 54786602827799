import { DataTypeProvider } from '@devexpress/dx-react-grid'
import React from 'react'
import NumberFormat from 'react-number-format'

const Currency = (props) => {
  const { currencyListOptions } = props

  return (
    <DataTypeProvider
      formatterComponent={({ value, column, row }) => {
        const code = row?.currencyCode || row?.currency?.code
        const currencySelected = currencyListOptions?.find(
          (item) => item?.code === code
        )

        const decimalScale = code === 'COP' ? null : column?.decimalScale ?? 2

        return (
          <NumberFormat
            displayType="text"
            prefix={currencySelected?.symbol}
            thousandSeparator={currencySelected?.thousandSeparator}
            decimalSeparator={currencySelected?.decimalSeparator}
            allowNegative
            isNumericString
            decimalScale={decimalScale}
            fixedDecimalScale
            allowLeadingZeros
            value={value || 0}
          />
        )
      }}
      {...props}
    />
  )
}

export default Currency
