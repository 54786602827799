import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  Typography,
} from '../../core'
import { Close as CloseIcon } from '../../icons'
import { makeStyles } from '../../styles'
import { Loading } from '../Loading'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.tertiary?.main,
  },
  container: {
    display: 'flex',
    maxWidth: '100%',
    height: 'calc(100vh - 49px)',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme?.palette?.tertiary?.main,
  },
  dialogContent: {
    minHeight: 200,
    backgroundColor: theme?.palette?.secondary?.main,
    padding: theme.spacing(2),
    overflow: 'visible',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  dialogActions: {
    minHeight: 50,
    backgroundColor: theme?.palette?.secondary?.main,
    padding: theme.spacing(2),
    overflow: 'none',
    justifyContent: (props) =>
      props?.actionsPosition ? props.actionsPosition : 'flex-end',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '0.9em',
    fontFamily: theme?.typography?.fontFamily,
  },
}))

export const DialogModel = (props) => {
  const { open, children, content, title, onClose, size, isLoading, actions } =
    props
  const classes = useStyles(props)

  return (
    <Dialog
      onClose={onClose}
      open={open || false}
      fullWidth
      maxWidth={size || 'xs'}
      PaperProps={{ style: { overflowY: 'visible' } }}
    >
      <DialogTitle disableTypography className={classes.root}>
        <Typography
          variant="subtitle2"
          className={classes.title}
          role="heading"
        >
          {title}
        </Typography>
        <IconButton
          role="button"
          size="small"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className={classes.dialogContent}
        data-testid="dialogContent"
        style={{ overflowY: 'visible' }}
      >
        {isLoading ? <Loading /> : content || children || ''}
      </DialogContent>
      {actions && (
        <DialogActions className={classes.dialogActions}>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  )
}
