import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import _ from 'lodash'
import { IconButton, Tooltip, Popover, Typography } from '../../core'
import { makeStyles } from '../../styles'
import { MoreVert as MoreVertIcon } from '../../icons'
import { intl } from '../../translation'
import { convertHexToRGBA } from '../../utils'

const useStyles = makeStyles((theme) => ({
  div: {
    width: '100%',
    textAlign: 'center',
  },
  actionsButtons: {
    color: theme?.palette?.secondary?.contrastText,
    cursor: 'pointer',
    margin: theme?.spacing(1),
    '&:only-child': {
      margin: 0,
    },
  },
  actionsButtonsDiv: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginRight: '15px',
  },
  iconButtonDiv: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: '10px',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: convertHexToRGBA(theme?.palette?.primary?.main, 0.3),
      fontWeight: 'bold',
    },
  },
  buttonTypography: {
    margin: theme?.spacing(1),
    color: theme?.palette?.secondary?.contrastText,
    paddingLeft: 10,
    paddingRight: 10,
    '&:hover': {
      fontWeight: 'bold',
    },
  },
}))

export const Actions = ({ row, actions, iconPosition = 'left' }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [redirect, setRedirect] = useState({ redirect: false, path: null })
  const classes = useStyles()

  const open = Boolean(anchorEl)
  const id = open ? 'actions-popover' : undefined

  const handlePopoverOpening = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    const redirectAction = actions?.filter((action) => {
      if (action?.redirect) {
        return action
      }
    })
    if (!_.isEmpty(redirectAction)) {
      setRedirect({ isRedirect: true, path: redirectAction[0].redirect })
    }
  }, [actions])

  if (redirect.isRedirect) {
    return (
      <Redirect
        to={{
          pathname: redirect.path,
        }}
      />
    )
  }

  return (
    <div className={classes.div}>
      <Tooltip title={intl.get(`words.options` || 'words.options')}>
        <IconButton
          aria-label={intl.get(`words.options`) || 'words.options'}
          aria-describedby={id}
          onClick={handlePopoverOpening}
          size="small"
          role="button"
        >
          <MoreVertIcon className={classes.actionsButtons} />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        role="menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={classes.actionsButtonsDiv}>
          {!_.isEmpty(actions) &&
            actions.map((action, index) => {
              if (action?.isVisible) {
                return (
                  <IconButton
                    key={`${action?.label}_${index}`}
                    className={classes.iconButtonDiv}
                    role="menuItem"
                    aria-label={action.label}
                    onClick={() => action?.onChoose(row)}
                    size="small"
                  >
                    {action?.icon && iconPosition === 'left' && (
                      <span data-testid="leftIcon"> {action?.icon}</span>
                    )}
                    <Typography
                      className={classes.buttonTypography}
                      data-testid="label"
                    >
                      {action?.label}
                    </Typography>
                    {action?.icon && iconPosition === 'right' && (
                      <span data-testid="rightIcon"> {action?.icon}</span>
                    )}
                  </IconButton>
                )
              } else {
              }
            })}
        </div>
      </Popover>
    </div>
  )
}

Actions.propTypes = {
  row: PropTypes.object,
  actions: PropTypes.array,
}

Actions.defaultProps = {
  row: {},
  actions: [],
}

Actions.displayName = 'Actions'
