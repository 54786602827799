import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Checkbox, FormControlLabel } from '../../../../core'
import { makeStyles } from '../../../../styles'
import { intl } from '../../../../translation'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  label: {
    fontFamily: theme?.typography?.fontFamily,
    color: theme?.palette?.secondary?.contrastText,
    textAlign: 'center',
  },
  radio: {
    color: theme?.palette?.secondary?.contrastText,
    '&$checked': {
      color: theme?.palette?.secondary?.contrastText,
      backgroundColor: theme?.palette?.secondary?.contrastText,
    },
  },
}))

const FieldCheckbox = ({ filter, onUpdate, defaultName }) => {
  const classes = useStyles()
  const [data, setData] = useState({ ...filter })

  const handleChange = (value) => {
    setData({ ...data, value: !filter?.value })
  }

  useEffect(() => {
    onUpdate(data)
  }, [data])

  return (
    <div data-testid="checkbox" style={{ textAlign: 'center' }}>
      <FormControlLabel
        classes={{
          label: classes.label,
        }}
        control={
          <Checkbox
            inputProps={{
              role: 'checkbox',
            }}
            color="primary"
            checked={filter?.value}
            onChange={handleChange}
          />
        }
        label={intl.get(`titles.${defaultName}`) || 'title'}
        labelPlacement="end"
      />
      <p />
    </div>
  )
}

FieldCheckbox.propTypes = {
  filter: PropTypes.any,
  onUpdate: PropTypes.any,
}

export default FieldCheckbox
