import { DataTypeProvider } from '@devexpress/dx-react-grid'
import React from 'react'
import { intl } from '../../../../translation'

const returnTranslatedValue = (value) => {
  switch (value) {
    case '0':
      return intl.get(`maps.minimumValue`)
    case '1':
      return intl.get(`maps.transactions`)
    case '2':
      return intl.get(`maps.store`)
    case '3':
      return intl.get(`maps.provider`)
    case '4':
      return intl.get(`maps.storeSetup`)
    default:
      return value
  }
}

const TranslatorPayzenCharge = (props) => (
  <DataTypeProvider
    formatterComponent={({ value, ...props }) => {
      const translation =
        value || Number(value) === 0
          ? returnTranslatedValue(value?.toString())
          : '-'

      return translation
    }}
    {...props}
  />
)

export default TranslatorPayzenCharge
