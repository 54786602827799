import {
  AccountBalance as AccountBalanceIcon,
  AccountBox as AccountBoxIcon,
  AccountTree as AccountTreeIcon,
  AttachMoney as AttachMoneyIcon,
  Business as BusinessIcon,
  Dashboard as DashboardIcon,
  Description as DescriptionIcon,
  ErrorOutline as ErrorOutlineIcon,
  GroupWork as GroupWorkIcon,
  HowToReg as HowToRegIcon,
  LocalOffer as LocalOfferIcon,
  People as PeopleIcon,
  Receipt as ReceiptIcon,
  Settings as SettingsIcon,
  ShowChart as ShowChartIcon,
  Star as StarIcon,
  TouchApp as TouchAppIcon,
  TrendingUp as TrendingUpIcon,
  PieChart as PieChartIcon,
  Email as EmailIcon,
  LibraryAddCheck as LibraryAddCheckIcon,
  Face as FaceIcon,
  FormatListNumbered as FormatListNumberedIcon,
  BarChart as BarChartIcon,
} from '../../../src/components/Library/icons'

const screenIconsMap = {
  dashboardScreen: DashboardIcon,
  billingScreen: TrendingUpIcon,
  billingErrorScreen: ErrorOutlineIcon,
  invoiceScreen: DescriptionIcon,
  salesOrderScreen: LocalOfferIcon,
  billingEmailScreen: EmailIcon,
  reports: PieChartIcon,
  products: AccountTreeIcon,
  commercialScreen: FaceIcon,
  accountScreen: AccountBoxIcon,
  opportunityScreen: ShowChartIcon,
  commissionScreen: HowToRegIcon,
  extractScreen: ReceiptIcon,
  conciliationGprsScreen: LibraryAddCheckIcon,
  conciliationGprsPlanScreen: FormatListNumberedIcon,
  conciliationGprsReportScreen: BarChartIcon,
  tariffScreen: AttachMoneyIcon,
  userScreen: PeopleIcon,
  groupScreen: GroupWorkIcon,
  billingProcessScreen: TouchAppIcon,
  commercialBonusScreen: StarIcon,
}
export default screenIconsMap
