import React, { createContext, useContext, useState } from 'react'

const NotificationContext = createContext()

export const useNotification = () => useContext(NotificationContext)

export const NotificationProvider = ({ children }) => {
  const [openModal, setOpenModal] = useState(false)
  const [redirectedFromSnackbar, setRedirectedFromSnackbar] = useState(false)

  return (
    <NotificationContext.Provider
      value={{
        openModal,
        setOpenModal,
        redirectedFromSnackbar,
        setRedirectedFromSnackbar,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}
