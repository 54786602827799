import React from 'react'
import PropTypes from 'prop-types'
import { Loading } from '../Loading'

const LoadingWrapper = ({ className, colSpan, limit = 10 }) => (
  <td
    className={className}
    colSpan={colSpan}
    align="center"
    style={{
      height: 48 * limit,
    }}
  >
    <div style={{ marginTop: 250 }}>
      <Loading />
    </div>
  </td>
)

LoadingWrapper.propTypes = {
  className: PropTypes.any,
  colSpan: PropTypes.any,
  limit: PropTypes.number,
}

export default LoadingWrapper
