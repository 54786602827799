import { intl } from '../../components/Library/translation'
import { normalizeMapData } from './Normalizers'
import useCallAPI from './useCallAPI'

const translateValuesData = (data) => {
  let newData = []
  if (data?.length > 0) {
    newData = data.map((item) => {
      return { ...item, label: intl.get(`maps.${item?.label}`) }
    })
  }
  return newData
}

export const useMapData = ({
  context,
  urlParameters = {},
  initialCall = true,
  translate = false,
}) => {
  const { reset, requestSuccess, requestData, requestLoading, fetchGET } =
    useCallAPI({
      initialCall,
      context: `${context}_map`,
      urlParameters,
    })

  let stateMapData = normalizeMapData(requestData)

  stateMapData = translate
    ? translateValuesData(stateMapData) || []
    : stateMapData

  const handleGET = (params) => {
    fetchGET({ userParams: params })
  }

  return {
    reset,
    success: requestSuccess,
    loading: requestLoading,
    state: stateMapData,
    handleGET,
  }
}
