import PropTypes from 'prop-types'
import React from 'react'
import {
  FieldBytes,
  FieldCheckbox,
  FieldDateFullYear,
  FieldDateMonth,
  FieldDateMonthPeriod,
  FieldDateShort,
  FieldDateShortPeriod,
  FieldDateTime,
  FieldGroupRadio,
  FieldRange,
  FieldRangeCurrency,
  FieldRangePercentage,
  FieldSelectCreatableMulti,
  FieldSelectSuggest,
  FieldSelectSuggestMulti,
  FieldSuggest,
  FieldSuggestId,
} from './components/Fields'

// Criar os componentes de acordo com o objeto do backend filterPossibilities
const createInputComponent = (props) => {
  const {
    filter,
    version,
    updateState,
    state,
    verifyValue,
    preDefinedListOptions,
    validateDateShortcut,
    callAPISuggestion,
    currencyData,
  } = props

  if (state[filter?.defaultName]) {
    let listOptions = []
    switch (filter?.type) {
      case 'text':
        return (
          <>
            <FieldSuggest
              version={version}
              key={filter?.defaultName}
              behavior={filter?.behavior}
              columnIdName={filter?.columnIdName}
              defaultName={filter?.defaultName}
              hasSuggested={filter?.hasSuggested}
              isSuggested={filter?.isSuggested}
              minToMakeRequest={filter?.minToMakeRequest}
              suggestionUrl={filter?.suggestionUrl}
              onUpdate={updateState}
              type={filter?.type}
              filter={state[filter?.defaultName]}
              condition={state[filter?.defaultName].condition}
              filterParameters={state}
              verifyValue={(value) => verifyValue(state, value)}
              enableNotEquals={
                filter?.enableNotEquals ? filter?.enableNotEquals : false
              }
              suggestionConfig={props.suggestionConfig}
              callAPISuggestion={callAPISuggestion}
            />
            <br />
          </>
        )
      case 'id':
        return (
          <>
            <FieldSuggestId
              version={version}
              key={filter?.defaultName}
              behavior={filter?.behavior}
              columnIdName={filter?.columnIdName}
              defaultName={filter?.defaultName}
              hasSuggested={filter?.hasSuggested}
              isSuggested={filter?.isSuggested}
              minToMakeRequest={filter?.minToMakeRequest}
              suggestionUrl={filter?.suggestionUrl}
              onUpdate={updateState}
              type={filter?.type}
              filter={state[filter?.defaultName]}
              filterParameters={state}
              verifyValue={(value) => verifyValue(state, value)}
              enableNotEquals={
                filter?.enableNotEquals ? filter?.enableNotEquals : false
              }
              suggestionConfig={props.suggestionConfig}
              callAPISuggestion={callAPISuggestion}
            />
            <br />
          </>
        )
      case 'number':
        return (
          <>
            <FieldSuggest
              version={version}
              key={filter?.defaultName}
              behavior={filter?.behavior}
              columnIdName={filter?.columnIdName}
              defaultName={filter?.defaultName}
              hasSuggested={filter?.hasSuggested}
              isSuggested={filter?.isSuggested}
              minToMakeRequest={filter?.minToMakeRequest}
              suggestionUrl={filter?.suggestionUrl}
              onUpdate={updateState}
              type={filter?.type}
              filter={state[filter?.defaultName]}
              filterParameters={state}
              verifyValue={(value) => verifyValue(state, value)}
              enableNotEquals={
                filter?.enableNotEquals ? filter?.enableNotEquals : false
              }
              suggestionConfig={props.suggestionConfig}
              callAPISuggestion={callAPISuggestion}
            />
            <br />
          </>
        )
      case 'date':
        return (
          <>
            <FieldDateTime
              version={version}
              key={filter?.defaultName}
              hasAutoFillButton={
                filter?.hasAutoFillButton ? filter?.hasAutoFillButton : false
              }
              filter={state[filter?.defaultName]}
              onUpdate={updateState}
              preDefinedListOptions={preDefinedListOptions}
              validateDateShortcut={validateDateShortcut}
              defaultName={filter?.defaultName}
            />
            <br />
          </>
        )
      case 'monthPeriod':
        return (
          <>
            <FieldDateMonthPeriod
              version={version}
              key={filter?.defaultName}
              filter={state[filter?.defaultName]}
              onUpdate={updateState}
              preDefinedListOptions={preDefinedListOptions}
              validateDateShortcut={validateDateShortcut}
              defaultName={filter?.defaultName}
            />
            <br />
          </>
        )
      case 'month':
        return (
          <>
            <FieldDateMonth
              version={version}
              key={filter?.defaultName}
              filter={state[filter?.defaultName]}
              onUpdate={updateState}
              defaultName={filter?.defaultName}
            />
            <br />
            <br />
          </>
        )
      case 'shortDatePeriod':
        return (
          <>
            <FieldDateShortPeriod
              version={version}
              key={filter?.defaultName}
              filter={state[filter?.defaultName]}
              onUpdate={updateState}
              preDefinedListOptions={preDefinedListOptions}
              validateDateShortcut={validateDateShortcut}
              defaultName={filter?.defaultName}
              hasMonthSelect={filter?.hasMonthSelect}
            />
            <br />
          </>
        )
      case 'shortDate':
        return (
          <>
            <FieldDateShort
              version={version}
              key={filter?.defaultName}
              filter={state[filter?.defaultName]}
              onUpdate={updateState}
              defaultName={filter?.defaultName}
            />
            <br />
          </>
        )
      case 'fullYear':
        return (
          <>
            <FieldDateFullYear
              version={version}
              key={filter?.defaultName}
              filter={state[filter?.defaultName]}
              onUpdate={updateState}
              defaultName={filter?.defaultName}
            />
            <br />
          </>
        )
      case 'range':
        if (filter?.behavior === 'number') {
          return (
            <>
              <FieldRange
                version={version}
                key={filter?.defaultName}
                filter={state[filter?.defaultName]}
                onUpdate={updateState}
                defaultName={filter?.defaultName}
              />
              <br />
            </>
          )
        }
        if (filter?.behavior === 'percentage') {
          return (
            <>
              <FieldRangePercentage
                version={version}
                key={filter?.defaultName}
                filter={state[filter?.defaultName]}
                onUpdate={updateState}
                defaultName={filter?.defaultName}
              />
              <br />
            </>
          )
        }
        if (filter?.behavior === 'bytes') {
          return (
            <>
              <FieldBytes
                version={version}
                key={filter?.defaultName}
                filter={state[filter?.defaultName]}
                onUpdate={updateState}
                defaultName={filter?.defaultName}
              />
              <br />
            </>
          )
        }
        if (filter?.behavior === 'currency') {
          return (
            <>
              <FieldRangeCurrency
                version={version}
                key={filter?.defaultName}
                filter={state[filter?.defaultName]}
                onUpdate={updateState}
                defaultName={filter?.defaultName}
                currencyData={currencyData}
              />
              <br />
            </>
          )
        }
        break
      case 'select':
        listOptions = props[filter?.behavior] ? props[filter?.behavior] : []

        return (
          <>
            <FieldSelectSuggest
              version={version}
              key={filter?.defaultName}
              filter={state[filter?.defaultName]}
              onUpdate={updateState}
              listOptions={listOptions}
              enableNotEquals={
                filter?.enableNotEquals ? filter?.enableNotEquals : false
              }
              defaultName={filter?.defaultName}
              requestNewOptions={props.requestNewOptions}
            />
            <br />
          </>
        )
      case 'radio':
        listOptions = props[filter?.behavior] ? props[filter?.behavior] : []
        return (
          <>
            <FieldGroupRadio
              version={version}
              key={filter?.defaultName}
              behavior={filter?.behavior}
              defaultName={filter?.defaultName}
              onUpdate={updateState}
              type={filter?.type}
              filter={state[filter?.defaultName]}
              radioOptions={listOptions}
            />
            <br />
          </>
        )
      case 'multi-select':
        listOptions = props[filter?.behavior] ? props[filter?.behavior] : []
        return (
          <>
            <FieldSelectSuggestMulti
              data-testid="multi-select"
              version={version}
              key={filter?.defaultName}
              behavior={filter?.behavior}
              defaultName={filter?.defaultName}
              filter={state[filter?.defaultName]}
              onUpdate={updateState}
              listOptions={listOptions}
              requestNewOptions={
                filter?.behavior === 'account'
                  ? props.requestNewOptionsAccount
                  : props.requestNewOptions
              }
              enableNotEquals={
                filter?.enableNotEquals ? filter?.enableNotEquals : false
              }
            />
            <br />
          </>
        )
      case 'multi-creatable-select':
        return (
          <>
            <FieldSelectCreatableMulti
              version={version}
              key={filter?.defaultName}
              behavior={filter?.behavior}
              defaultName={filter?.defaultName}
              filter={state[filter?.defaultName]}
              onUpdate={updateState}
              enableNotEquals={
                filter?.enableNotEquals ? filter?.enableNotEquals : false
              }
            />
          </>
        )
      case 'checkbox':
        return (
          <>
            <FieldCheckbox
              version={version}
              key={filter?.defaultName}
              behavior={filter?.behavior}
              defaultName={filter?.defaultName}
              onUpdate={updateState}
              type={filter?.type}
              filter={state[filter?.defaultName]}
            />
            <br />
          </>
        )

      default:
        return console.log(
          `Property type: ${filter?.type} in ${filter?.defaultName} is not defined`
        )
    }
  }
}

createInputComponent.propTypes = {
  filter: PropTypes.object,
  version: PropTypes.number,
  updateState: PropTypes.func,
  state: PropTypes.object,
  verifyValue: PropTypes.func,
  preDefinedListOptions: PropTypes.array,
  objectTitles: PropTypes.object,
  props: PropTypes.object,
}

createInputComponent.defaultProps = {
  filter: {},
  version: 0,
  updateState: () => {},
  state: {},
  verifyValue: () => {},
  preDefinedListOptions: [],
  objectTitles: {},
  props: {},
}

export default createInputComponent
