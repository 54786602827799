import ENDPOINTS from './ENDPOINTS'
import { useFetchAxiosWrapper } from '../useFetchAxiosWrapper'
import { useState, useEffect } from 'react'
import _ from 'lodash'

export const useReportTotal = () => {
  const [data, setData] = useState([])

  const { patchRequest, fetchPATCH } = useFetchAxiosWrapper({
    baseEndpoint: ENDPOINTS.PATCH.billing_reports_total,
    callOnInit: false,
  })

  useEffect(() => {
    if (!_.isEqual(patchRequest.data, data)) {
      setData(patchRequest.data?.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchRequest])

  const handlePATCHTotal = (body = {}) => {
    fetchPATCH({ body, disableCompanyId: true })
  }

  const clearData = () => {
    setData([])
  }

  const handlePATCHTotalMonth = (body = {}) => {
    fetchPATCH({ body })
  }

  return {
    loading: patchRequest.loading,
    handlePATCHTotal,
    handlePATCHTotalMonth,
    clearData,
    data,
  }
}
