import PropTypes from 'prop-types'
import React from 'react'
import { useCommercialData } from '.../../../src/API/Hooks'

export const FiltersCommercialContext = React.createContext()

const Provider = ({ children }) => {
  const commercialData = useCommercialData({})
  const commercials = commercialData.data
  const cleanFilters = commercialData.cleanFilters
  const applyFilters = commercialData.applyFilters
  const updateFilters = commercialData.updateFilters
  const filters = commercialData.filters
  const filteredColumns = commercialData.filteredColumns

  return (
    <FiltersCommercialContext.Provider
      value={{
        commercials,
        cleanFilters,
        applyFilters,
        updateFilters,
        filters,
        filteredColumns,
      }}
    >
      {children}
    </FiltersCommercialContext.Provider>
  )
}

Provider.defaultProps = {
  children: <></>,
}

Provider.propTypes = {
  children: PropTypes.node,
}

export default Provider
