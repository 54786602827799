import React from 'react'
import _ from 'lodash'
import NumberFormat from 'react-number-format'

export const NumberCurrency = ({ value, currencyData }) => {
  currencyData =
    !currencyData || _.isEmpty(currencyData)
      ? {
          symbol: 'R$ ',
          thousandSeparator: '.',
          decimalSeparator: ',',
        }
      : currencyData

  return (
    <NumberFormat
      displayType="text"
      prefix={currencyData?.symbol}
      thousandSeparator={currencyData?.thousandSeparator}
      decimalSeparator={currencyData?.decimalSeparator}
      allowNegative={false}
      isNumericString
      decimalScale={7}
      value={value}
      fixedDecimalScale
    />
  )
}

NumberCurrency.propTypes = {}
