import React, { createRef, useEffect, useState } from 'react'
import _ from 'lodash'
import NumberFormat from 'react-number-format'
import {
  FormControl,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TextFieldCurrency,
  Typography,
  Tooltip,
} from '../../../core'
import {
  Check as CheckIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Error as ErrorIcon,
} from '../../../icons'
import { intl } from '../../../translation'
import { useStyles } from './styles'

const initialState = {
  monthlyValue: parseFloat(0).toFixed(7),
  minimumValue: parseFloat(0).toFixed(7),
}

const initialRow = {
  status: true,
  minLimit: 1,
  maxLimit: '',
  value: parseFloat(0).toFixed(7),
}
const VeripagDetails = ({
  changeDetails,
  billingVeripagType,
  currencyData,
}) => {
  currencyData =
    !currencyData || _.isEmpty(currencyData)
      ? {
          symbol: 'R$ ',
          thousandSeparator: '.',
          decimalSeparator: ',',
        }
      : currencyData

  const classes = useStyles()
  const [stateVeripag, setState] = useState(initialState)
  const [newRow, setNewRow] = useState({ ...initialRow })
  const [values, setValues] = useState([])
  const [statusForm, setStatusForm] = useState(false)
  const [tariffName, setTariffName] = useState('')

  const inputRef = createRef()

  useEffect(() => {
    if (validateFormByBillingVeripagTypeRules()) {
      setStatusForm(true)
    } else {
      setStatusForm(false)
    }
  }, [values, newRow, tariffName, billingVeripagType, stateVeripag])

  useEffect(() => {
    if (typeof changeDetails === 'function') {
      if (statusForm) {
        const payload = createObjectByBillingVeripagTypeRules()
        changeDetails(payload)
      } else {
        changeDetails({})
      }
    }
  }, [statusForm, tariffName, stateVeripag])

  useEffect(() => {
    inputRef && inputRef.current && inputRef.current.focus()
  }, [values])

  const createObjectByBillingVeripagTypeRules = () => {
    switch (billingVeripagType) {
      case 'MONTHLY_VALUE':
        return {
          billingType: billingVeripagType,
          monthlyValue: stateVeripag.monthlyValue,
          values,
        }
      case 'MINIMUM_VALUE':
        return {
          billingType: billingVeripagType,
          minimumValue: stateVeripag.minimumValue,
          values,
        }
      case 'UNIQUE_VALUE':
        return {
          billingType: billingVeripagType,
          values,
        }

      default:
        return null
    }
  }

  const validateFormByBillingVeripagTypeRules = () => {
    const newRowStatusOK = newRow.status === false
    const valueNameOK = tariffName && tariffName.trim() !== ''
    const billingVeripagTypeOK = billingVeripagType !== ''
    const stateMinimumValueOK = stateVeripag.minimumValue !== ''
    const stateMonthlyValueOK = stateVeripag.monthlyValue !== ''

    const valuesOK = values.length > 0
    switch (billingVeripagType) {
      case 'MONTHLY_VALUE':
        if (
          newRowStatusOK &&
          valueNameOK &&
          billingVeripagTypeOK &&
          stateMonthlyValueOK &&
          valuesOK
        ) {
          return true
        }
        return false

      case 'MINIMUM_VALUE':
        if (
          newRowStatusOK &&
          valueNameOK &&
          billingVeripagTypeOK &&
          stateMinimumValueOK &&
          valuesOK
        ) {
          return true
        }
        return false

      case 'UNIQUE_VALUE':
        if (newRowStatusOK && valueNameOK && billingVeripagTypeOK && valuesOK) {
          return true
        }
        return false

      default:
        return false
    }
  }

  const handleChangeMinimumValue = (e) => {
    e.target.value
      ? setState({
          ...stateVeripag,
          minimumValue: parseFloat(e.target.value).toFixed(7),
        })
      : setState({ ...stateVeripag, minimumValue: parseFloat(0).toFixed(7) })
  }

  const handleChangeMonthlyValue = (e) => {
    e.target.value
      ? setState({
          ...stateVeripag,
          monthlyValue: parseFloat(e.target.value).toFixed(7),
        })
      : setState({ ...stateVeripag, monthlyValue: parseFloat(0).toFixed(7) })
  }

  const handleChangeNewRowLimit = (input, value) => {
    value
      ? setNewRow({ ...newRow, [input]: Number(value) })
      : setNewRow({ ...newRow, [input]: '' })
  }

  const handleChangeNewRow = (input, value) => {
    value
      ? setNewRow({ ...newRow, [input]: parseFloat(value).toFixed(7) })
      : setNewRow({ ...newRow, [input]: parseFloat(0).toFixed(7) })
  }

  const handleNewRow = () => {
    const actualNewRow = newRow
    delete actualNewRow.status

    const maxLimit = newRow.maxLimit !== '' ? newRow.maxLimit : -1

    if (
      (actualNewRow.maxLimit === '' ||
        actualNewRow.maxLimit >= actualNewRow.minLimit) &&
      actualNewRow.value !== ''
    ) {
      setValues([
        ...values,
        {
          ...actualNewRow,
          maxLimit,
        },
      ])
      if (actualNewRow.maxLimit !== '') {
        setNewRow({
          ...initialRow,
          minLimit: actualNewRow.maxLimit + 1,
        })
      } else {
        setNewRow({ ...initialRow, status: false })
      }
    }
  }

  const handleDeleteLastRow = () => {
    const newValues = values
    const actualNewRow = values.pop()
    setValues(newValues)
    setNewRow({ ...actualNewRow, maxLimit: '', status: true })
  }

  const handleEditLastRow = () => {
    const newValues = values
    const actualNewRow = values[values.length - 1]
    newValues.pop()
    if (values.length > 1) {
      setValues(newValues)
    }
    setNewRow({ ...actualNewRow, maxLimit: '', status: true })
  }

  return (
    <>
      <Typography
        variant="subtitle1"
        display="block"
        style={{ margin: '0px auto', fontWeight: 'bold' }}
      >
        {intl.get('words.description') || 'words.description'}
      </Typography>
      <div className={classes.bodySecondDetails}>
        <div className={classes.bodySecondDurationName}>
          <FormControl
            variant="outlined"
            className={classes.bodyFirstNameControl}
          >
            <TextField
              size="small"
              variant="outlined"
              margin="dense"
              label={intl.get('words.description') || 'words.description'}
              placeholder={
                intl.get('phrases.tariffName') || 'phrases.tariffName'
              }
              fullWidth
              InputProps={{
                classes: {
                  root: classes.textFieldTest,
                  input: classes.inputTextfield,
                  notchedOutline: classes.notchedOutline,
                },
                disableUnderline: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setTariffName(e.target.value)}
              value={tariffName}
            />
          </FormControl>
        </div>
      </div>
      <div className={classes.bodySecondDetails}>
        {billingVeripagType === 'MONTHLY_VALUE' && (
          <div className={classes.bodySecondDuration}>
            <FormControl
              variant="outlined"
              className={classes.bodyFirstNameControl}
            >
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                margin="dense"
                placeholder="0,0000000"
                label={`${intl.get('words.value')} Mensal`}
                InputProps={{
                  classes: {
                    root: classes.textFieldTest,
                    input: classes.currency,
                    notchedOutline: classes.notchedOutline,
                  },
                  disableUnderline: true,
                  inputComponent: TextFieldCurrency,
                  startAdornment: (
                    <InputAdornment
                      disableTypography
                      style={{ color: 'lightGray' }}
                      position="start"
                    >
                      {currencyData?.symbol}
                    </InputAdornment>
                  ),
                }}
                onChange={handleChangeMonthlyValue}
                value={stateVeripag.monthlyValue}
              />
            </FormControl>
          </div>
        )}
        {billingVeripagType === 'MINIMUM_VALUE' && (
          <div className={classes.bodySecondDuration}>
            <FormControl
              variant="outlined"
              className={classes.bodyFirstNameControl}
            >
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                margin="dense"
                placeholder="0,0000000"
                label={`Mín. ${intl.get('words.value')}`}
                InputProps={{
                  classes: {
                    root: classes.textFieldTest,
                    input: classes.currency,
                    notchedOutline: classes.notchedOutline,
                  },
                  disableUnderline: true,
                  inputComponent: TextFieldCurrency,
                  startAdornment: (
                    <InputAdornment
                      disableTypography
                      style={{ color: 'lightGray' }}
                      position="start"
                    >
                      {currencyData?.symbol}
                    </InputAdornment>
                  ),
                }}
                onChange={handleChangeMinimumValue}
                value={stateVeripag.minimumValue}
              />
            </FormControl>
          </div>
        )}
      </div>

      <>
        <Typography
          variant="subtitle1"
          display="block"
          style={{
            margin: '0px auto',
            fontWeight: 'bold',
            borderTop: '1px solid #001064',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {intl.get('words.slices') || 'words.slices'}
        </Typography>
        <div id="dial-slices" className={classes.bodySecondTable}>
          <Table
            style={{
              width: '90%',
              margin: '0px auto',
            }}
          >
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell align="center">
                  {intl.get('words.start') || 'words.start'}
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  {intl.get('words.end') || 'words.end'}
                </TableCell>
                <TableCell align="right" className={classes.tableCell}>
                  {intl.get('words.value') || 'words.value'}
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  {intl.get('words.actions') || 'words.actions'}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.map((row, index) => (
                <TableRow key={row.minLimit} className={classes.tableRow}>
                  <TableCell
                    align="center"
                    className={classes.tableCell}
                    component="th"
                    scope="row"
                  >
                    {row.minLimit}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {row.maxLimit > 0
                      ? row.maxLimit
                      : intl.get('phrases.noLimit') || 'phrases.noLimit'}
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    <NumberFormat
                      displayType="text"
                      prefix={currencyData?.symbol}
                      thousandSeparator={currencyData?.thousandSeparator}
                      decimalSeparator={currencyData?.decimalSeparator}
                      allowNegative={false}
                      isNumericString
                      decimalScale={7}
                      value={row.value}
                    />
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {index === values.length - 1 && !newRow.status && (
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={handleEditLastRow}
                        className={classes.iconButton}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {newRow.status && (
                <TableRow key="newRow" className={classes.tableRow}>
                  <TableCell
                    align="center"
                    className={classes.tableCell}
                    component="th"
                    scope="row"
                  >
                    {newRow.minLimit}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <TextField
                      autoFocus
                      inputRef={inputRef}
                      fullWidth
                      size="small"
                      variant="outlined"
                      margin="dense"
                      placeholder={
                        intl.get('phrases.noLimit') || 'phrases.noLimit'
                      }
                      InputProps={{
                        classes: {
                          root: `${
                            newRow.minLimit !== undefined &&
                            newRow.maxLimit !== undefined &&
                            (newRow.maxLimit === '' ||
                              newRow.maxLimit >= newRow.minLimit) &&
                            newRow.value !== ''
                              ? ''
                              : classes.error
                          }`,
                          input: classes.inputTextfield,
                          notchedOutline: classes.notchedOutline,
                        },
                        disableUnderline: true,
                      }}
                      onChange={(e) =>
                        handleChangeNewRowLimit('maxLimit', e.target.value)
                      }
                      value={newRow.maxLimit}
                      type="number"
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      margin="dense"
                      placeholder="0,0000000"
                      InputProps={{
                        classes: {
                          root: classes.textFieldTest,
                          input: classes.currency,
                          notchedOutline: classes.notchedOutline,
                        },
                        disableUnderline: true,
                        inputComponent: TextFieldCurrency,
                        startAdornment: (
                          <InputAdornment
                            disableTypography
                            style={{ color: 'lightGray' }}
                            position="start"
                          >
                            {currencyData?.symbol}
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) =>
                        handleChangeNewRow('value', e.target.value)
                      }
                      value={newRow.value}
                    />
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {newRow.minLimit !== undefined &&
                    newRow.maxLimit !== undefined &&
                    (newRow.maxLimit === '' ||
                      newRow.maxLimit >= newRow.minLimit) &&
                    newRow.value !== '' ? (
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={handleNewRow}
                        className={classes.iconButton}
                        size="small"
                      >
                        <CheckIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        color="primary"
                        component="span"
                        className={classes.iconButton}
                        size="small"
                      >
                        {newRow.minLimit > newRow.maxLimit ? (
                          <Tooltip title={intl.get(`phrases.minLimitError`)}>
                            <ErrorIcon style={{ color: '#d32f2f' }} />
                          </Tooltip>
                        ) : (
                          <Tooltip title={intl.get(`phrases.maxLimitError`)}>
                            <ErrorIcon style={{ color: '#d32f2f' }} />
                          </Tooltip>
                        )}
                      </IconButton>
                    )}
                    {values.length > 0 && (
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={handleDeleteLastRow}
                        className={classes.iconButton}
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </>
    </>
  )
}
export default VeripagDetails
