import React from 'react'
import PropTypes from 'prop-types'
import {
  Tooltip,
  Checkbox,
  MenuItem,
  FormGroup,
  FormControlLabel,
} from '../../../../core'

const Option = ({ onClick, checked, label }) => (
  <MenuItem role="listitem" onClick={onClick}>
    <Tooltip title={label}>
      <FormGroup>
        <FormControlLabel
          onClick={(e) => e.preventDefault()}
          control={
            <Checkbox
              inputProps={{ role: 'checkbox' }}
              color="primary"
              checked={checked}
            />
          }
          label={label}
        />
      </FormGroup>
    </Tooltip>
  </MenuItem>
)

Option.defaultProps = {
  label: '',
  checked: false,
  onClick: () => {},
}

Option.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Option
