export const convertHexToRGBA = (hexCode, opacity) => {
  let hex = hexCode.replace('#', '')

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r},${g},${b},${opacity})`
}

export const defaultColors = {
  sales: {
    light: '#2b9b25',
    dark: '#2b9b25',
  },
  purchase: {
    light: '#ffcd07',
    dark: '#ffcd07',
  },
  margin: {
    light: '#4d4daf',
    dark: '#4d4daf',
  },
}
