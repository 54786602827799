export const deepMergeObjects = (target, source) => {
  if (
    (!target || JSON.stringify(target) === '{}') &&
    (!source || JSON.stringify(source) === '{}')
  ) {
    return {}
  }
  if (!target || JSON.stringify(target) === '{}') {
    return source
  }

  if (!source || JSON.stringify(source) === '{}') {
    return target
  }

  const newTarget = { ...target }
  const newSource = { ...source }

  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (const key of Object.keys(newSource)) {
    if (newSource[key] instanceof Object)
      Object.assign(
        newSource[key],
        deepMergeObjects(newTarget[key], newSource[key])
      )
  }

  // Join `newTarget` and modified `newSource`
  Object.assign(newTarget, newSource)

  return newTarget
}
