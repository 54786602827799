import { TableCell, TableRow } from '../../../core'
import { makeStyles } from '../../../styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.tertiary,
    color: theme.palette.text.tertiary,
    width: '100%',
    margin: '0px auto',
    height: '100%',
  },
  divButtons: {
    display: 'block',
    textAlign: 'right',
  },
  buttonEdit: {
    backgroundColor: theme.palette.primary,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    margin: 10,
  },
  buttonOk: {
    backgroundColor: theme.palette.primary,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    margin: 10,
  },
  buttonCancel: {
    backgroundColor: theme.palette.tertiary,
    color: theme.palette.text.tertiary,
    fontFamily: theme.typography.fontFamily,
    margin: 10,
  },
  buttonClose: {
    backgroundColor: theme.palette.tertiary,
    color: theme.palette.text.tertiary,
    fontFamily: theme.typography.fontFamily,
    margin: 10,
  },
  checkbox: {
    margin: 0,
    color: theme.palette.text.tertiary,
    height: 2,
  },
  cell: {
    padding: 0,
    paddingRight: 16,
    paddingLeft: 16,
    textAlign: 'center',
  },
  headerCell: {
    textAlign: 'center',
    paddingRight: 16,
    paddingLeft: 16,

    color: theme.palette.text.tertiary,
    fontWeight: 'bolder',
  },
  screenCell: {
    padding: 0,
    margin: 0,
    color: theme.palette.text.tertiary,
    fontWeight: 'bolder',
  },
  itemCell: {
    padding: 0,
    margin: 0,
    color: theme.palette.text.tertiary,
  },
  divTable: {
    width: '100%',
    display: 'inline-block',
    padding: 0,
  },
  table: {
    width: '100%',
  },
  rowHeader: {
    height: '30px',
  },
  row: {
    height: '7px',
    fontFamily: theme.typography.fontFamily,
  },
  formControl: {
    width: '100%',
  },
  modeHeader: {
    width: '100%',
    display: 'block',
    textAlign: 'center',
    margin: 0,
    padding: 0,
  },
  selectFolders: {
    height: '82%',
  },
  checked: {
    '&, & + $label': {
      color: theme.palette.text.tertiary,
    },
  },
  label: { color: theme.palette.text.tertiary },
  helperText: {
    marginBottom: 10,
    color: theme.palette.text.tertiary,
  },
  labelButton: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.tertiary,
    fontSize: '0.7em',
    display: 'inline-block',
    textTransform: 'uppercase',
  },
  divLoading: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    paddingTop: '15%',
  },
  loading: {
    width: '80px',
    height: '80px',
  },
  subHeader: {
    fontWeight: 'bolder',
  },
}))

export const RowTitle = (props) => {
  const classes = useStyles()
  const { editStatus, titleRow } = props
  return (
    <TableRow
      key="active"
      className={classes.row}
      style={{
        opacity: !editStatus ? '0.5' : '1.0',
      }}
    >
      <TableCell align="left" colSpan={3} className={classes.subHeader}>
        {titleRow}
      </TableCell>
    </TableRow>
  )
}
