import React from 'react'
import { intl } from '../../../../translation'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

import { formatBytes } from '../../utils/dataProcessing'

const QueryString = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) => (
      <ul>
        {value.map((obj) => (
          <li key={obj.columnName}>{mountQueryString(obj)}</li>
        ))}
      </ul>
    )}
    {...props}
  />
)

const mountQueryString = (obj) => {
  const val =
    obj.type === 'percentage'
      ? `${obj.value}%`
      : obj.type === 'bytes'
      ? formatBytes(obj.value)
      : obj.value

  switch (obj.operator) {
    case 'all':
      return intl.get('phrases.queryStringAllDatabase')
    case 'biggerOrEqual':
      return intl.get('phrases.queryStringBiggerOrEqual', {
        columnName: intl.get(`titles.${obj.columnName}`),
        value: val,
      })
    case 'equal':
      return intl.get('phrases.queryStringEqual', {
        columnName: intl.get(`titles.${obj.columnName}`),
        value: val,
      })
    case 'nameReport':
      return intl.get('phrases.queryStringReportByFilter', {
        columnName: intl.get(`titles.${obj.columnName}`),
        value: val,
      })
    case 'nameAlert':
      return intl.get('phrases.queryStringAlert', {
        columnName: intl.get(`titles.${obj.columnName}`),
        value: val,
      })
    default:
      return intl.get('words.custom')
  }
}

export default QueryString
