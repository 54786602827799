import React, { Fragment } from 'react'
import { Chip, Avatar } from '../../../../core'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { makeStyles } from '../../../../styles'

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: '5px 0',
  },
  avatar: {
    color: theme?.palette?.tertiary?.main,
    backgroundColor: theme?.palette?.tertiary?.contrastText,
  },
}))

const CommercialAcronym = (props) => {
  const classes = useStyles()

  return (
    <DataTypeProvider
      formatterComponent={({ value }) =>
        value.map((commercial) => (
          <Fragment key={commercial?.email}>
            <Chip
              className={classes.chip}
              label={commercial?.firstName}
              avatar={
                <Avatar className={classes.avatar}>
                  {commercial?.commercialAcronym}
                </Avatar>
              }
            />
            <br />
          </Fragment>
        ))
      }
      {...props}
    />
  )
}

export default CommercialAcronym
