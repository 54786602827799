import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '../../../styles'
import Row from './Row'
import { intl } from '../../../translation'
import { convertHexToRGBA } from '../../../utils'

const useStyles = makeStyles((theme) => ({
  rowTotal: {
    height: 35,
    fontSize: 16,
    fontWeight: 'bold',
    backgroundColor: convertHexToRGBA(theme?.palette?.primary?.main, 0.3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: (props) => {
      const width = props?.rowWidth ? props?.rowWidth : '100vw'
      return width
    },
  },
}))

const EmptyRowTotal = (props) => {
  const { className, ...restProps } = props

  const classes = useStyles(props)

  return (
    <Row
      {...restProps}
      height={15}
      className={classNames(className, classes.rowTotal)}
    >
      <div>
        {intl.get('phrases.emptyTotalMessage') || 'phrases.emptyTotalMessage'}
      </div>
    </Row>
  )
}

EmptyRowTotal.defaultProps = {
  className: '',
}

EmptyRowTotal.propTypes = {
  className: PropTypes.string.isRequired,
}

export default EmptyRowTotal
