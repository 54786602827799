import { Typography } from '../../../../core'
import { makeStyles, useTheme } from '../../../../styles'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { intl } from '../../../../translation'
import InputField from '../Atoms/InputField'

const useStyles = makeStyles((theme) => ({
  focused: {},
  alignLeft: {
    width: '47.5%',
    marginRight: '5%',
  },
  alignRight: {
    width: '47.5%',
  },
  title: {
    color: theme?.palette?.secondary?.contrastText,
    textAlign: 'left',
  },
}))

const FieldRange = ({ filter, onUpdate, version, defaultName }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [state, setState] = useState({})
  const [valueMin, setValueMin] = useState('')
  const [valueMax, setValueMax] = useState('')

  useEffect(() => {
    if (JSON.stringify(filter) !== '{}') {
      setState({ ...filter })
      setValueMin(filter?.valueMin)
      setValueMax(filter?.valueMax)
    }
  }, [version])

  useEffect(() => {
    setState({
      ...filter,
      valueMin,
    })
  }, [valueMin])

  useEffect(() => {
    setState({
      ...filter,
      valueMax,
    })
  }, [valueMax])

  useEffect(() => {
    if (JSON.stringify(state) !== '{}') {
      validateValue(state)
    }
  }, [state])

  const validateValue = (data) => {
    onUpdate(data)
  }

  const handleSetValueMin = (event) => {
    if (valueMax) {
      Number(valueMin) > Number(valueMax) && setValueMin(valueMax)
    }
  }

  const handleSetValueMax = (event) => {
    if (valueMin) {
      Number(valueMax) < Number(valueMin) && setValueMax(valueMin)
    }
  }

  return (
    <div data-testid="range_number">
      <Typography className={classes.title}>
        {intl.get(`titles.${defaultName}`) || 'title'}
      </Typography>
      <InputField
        key={`${filter?.defaultName}Min`}
        type="number"
        placeholder={intl.get('words.inputMinTitle') || 'words.inputMinTitle'}
        inputProps={{
          style: {
            color:
              valueMin?.length > 0 && theme?.palette?.secondary?.contrastText,
          },
          value: valueMin,
          onChange: (event) =>
            setValueMin(event?.target?.value ? event?.target?.value : ''),
          onBlur: handleSetValueMin,
        }}
        classNames={classes.alignLeft}
      />

      <InputField
        key={`${filter?.defaultName}Max`}
        type="number"
        placeholder={intl.get('words.inputMaxTitle') || 'words.inputMaxTitle'}
        inputProps={{
          classes: {
            root: classes.root,
          },
          style: {
            color:
              valueMax?.length > 0 && theme?.palette?.secondary?.contrastText,
          },
          disableUnderline: true,
          value: valueMax,
          onChange: (event) =>
            setValueMax(event?.target?.value ? event?.target?.value : ''),
          onBlur: handleSetValueMax,
        }}
        classNames={classes.alignRight}
      />
    </div>
  )
}

FieldRange.propTypes = {
  filter: PropTypes.any,
  onUpdate: PropTypes.any,
  version: PropTypes.any,
}

export default FieldRange
