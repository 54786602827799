import { createTheme } from '../src/components/Library/styles'
import { WrapperTheme as LibraryTheme } from '../src/components/Library/wrappers'
import React, { useContext, useEffect } from 'react'
import { ThemeContext } from './contexts/Theme'
import { convertHexToRGBA } from './components/Library/utils'

const WrapperTheme = ({ children }) => {
  const [stateThemeContext] = useContext(ThemeContext)

  const isDevMode = process.env.REACT_APP_DEVELOPER_MODE === 'true'

  const billingTheme = createTheme({
    typography: {
      useNextVariants: true,
      fontFamily: "'Titillium Web', sans-serif",
    },
    palette: {
      primary: {
        main:
          stateThemeContext === 'light'
            ? '#001064'
            : /*convertHexToRGBA('#001064', 0.5), */ '#3B3A39',
        contrastText: stateThemeContext === 'light' ? '#ffffff' : '#3B3A39',
        hover: '#000B44',
        hoverContrast: '#F0F1F7',
      },
      secondary: {
        main: stateThemeContext === 'light' ? '#ffffff' : '#201F1E',
        contrastText: stateThemeContext === 'light' ? '#3B3A39' : '#ffffff',
        hover: '#F0F0F0',
        hoverContrast: '#9e9e9e',
      },
      tertiary: {
        main: stateThemeContext === 'light' ? '#D4A977' : '#ffffff',
        contrastText: stateThemeContext === 'light' ? '#ffffff' : '#201F1E',
        hover: '#C48D4E',
        hoverContrast: '#F5F2EF',
      },
      quaternary: {
        main: stateThemeContext === 'light' ? '#F5F5F5' : '#ffffff',
        contrastText: '#000000',
      },
      defaultBackground: {
        contentBGColor: isDevMode
          ? '#393939'
          : convertHexToRGBA('#001064', 0.5),
        sideBarBackground: isDevMode ? '#676767' : '#001064',
        topbarBackground: isDevMode ? '#676767' : '#fff',
      },
    },
  })

  return <LibraryTheme theme={billingTheme}>{children}</LibraryTheme>
}

export default WrapperTheme
