import { makeStyles } from '@library/styles'

export const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: theme.typography.fontFamily,
    color: theme?.palette?.tertiary?.main,
    fontSize: 14,
    textTransform: 'uppercase',
    textAlign: 'left',
    width: '100%',
    float: 'left',
    marginTop: 0,
  },

  modalContainer: {
    position: 'relative',
    paddingTop: '60px',
    width: '650px',
    maxHeight: '95vh',
  },
  modalSmall: {
    width: '300px',
  },
  modalLarge: {
    width: '900px',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerModal: {
    display: 'flex',
    alignItems: 'center',
    padding: '14px',
    width: '100%',
    height: '60px',
    backgroundColor: theme?.palette?.primary?.main,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#0003',
    zIndex: 10,
  },
  closeIconBtn: {
    color: theme?.palette?.tertiary.main,
    '& :hover': {
      color: theme?.palette?.tertiary.hover,
    },
  },
}))
