export const loadItem = (item) => {
  try {
    const serializedItem = localStorage.getItem(item)

    if (serializedItem === null) {
      return undefined
    }

    return JSON.parse(serializedItem)
  } catch (err) {
    return undefined
  }
}

export const createHeaders = () => {
  const token = loadItem('token')

  const headers = {
    Authorization: `Bearer ${token}`,
  }
  return headers
}
