import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '../../../../core'
import { makeStyles } from '../../../../styles'
import { intl } from '../../../../translation'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    width: '100%',
  },
  radioGroup: {
    display: 'block',
    textAlign: 'center',
  },
  label: {
    fontFamily: theme?.typography?.fontFamily,
    color: theme?.palette?.secondary?.contrastText,
    textAlign: 'center',
  },
  radio: {
    color: theme?.palette?.secondary?.contrastText,
    '&$checked': {
      color: theme?.palette?.secondary?.contrastText,
      backgroundColor: theme?.palette?.secondary?.contrastText,
    },
  },
  title: {
    fontFamily: theme?.typography?.fontFamily,
    color: theme?.palette?.secondary?.contrastText,
    textAlign: 'left',
    marginBottom: 0,
  },
}))

const FieldGroupRadio = ({ filter, onUpdate, radioOptions, defaultName }) => {
  const classes = useStyles()
  const state = {
    behavior: filter?.behavior,
    defaultName: filter?.defaultName,
    type: filter?.type,
    value: filter?.value,
  }

  const [data, setData] = useState(state)

  const handleChange = (event) => {
    if (event?.target?.value) {
      const newValue = event?.target?.value
      if (newValue?.toString() === data?.value?.toString()) {
        setData({ ...data, value: '' })
      } else {
        setData({ ...data, value: newValue.toString() })
      }
    }
  }

  useEffect(() => {
    onUpdate(data)
  }, [data])

  useEffect(() => {
    if (filter?.value === '' && filter?.value !== data.value) {
      setData({ ...filter })
    }
  }, [filter])

  return (
    <div data-testid="radio">
      <FormControl component="fieldset" className={classes.formControl}>
        <Typography className={classes.title}>
          {intl.get(`titles.${defaultName}`) || 'title'}
        </Typography>
        <RadioGroup
          role="radiogroup"
          className={classes.radioGroup}
          aria-label="position"
          name="position"
          value={data?.value?.toString()}
          onChange={handleChange}
          row
        >
          {radioOptions &&
            Array.isArray(radioOptions) &&
            radioOptions.map((option) => {
              return (
                <FormControlLabel
                  key={`FormControl-${option.value}`}
                  classes={{
                    label: classes.label,
                  }}
                  value={option?.value?.toString()}
                  control={
                    <Radio
                      key={option.value}
                      className={classes.radio}
                      inputProps={{
                        role: 'radio',
                      }}
                      onClick={handleChange}
                      value={option?.value?.toString()}
                      color="primary"
                    />
                  }
                  label={option?.label || 'label'}
                  labelPlacement="end"
                />
              )
            })}
        </RadioGroup>
      </FormControl>
      <p />
    </div>
  )
}

FieldGroupRadio.propTypes = {
  filter: PropTypes.any,
  onUpdate: PropTypes.any,
}

export default FieldGroupRadio
