import { DataTypeProvider } from '@devexpress/dx-react-grid'
import PropTypes from 'prop-types'
import React from 'react'

const formatRevenueData = (value) => {
  if (value && Array.isArray(value)) {
    return value.map((item, index) => {
      return <li key={index.toString()}>{item?.product?.name}</li>
    })
  } else {
    return value
  }
}

const ArrayRevenue = (props) => {
  return (
    <DataTypeProvider
      formatterComponent={({ value }) =>
        value ? (
          <ul style={{ listStyleType: 'square' }}>
            {formatRevenueData(value)}
          </ul>
        ) : (
          ''
        )
      }
      {...props}
    />
  )
}

ArrayRevenue.defaultProps = {
  attribute: '',
}

ArrayRevenue.propTypes = {
  attribute: PropTypes.string.isRequired,
}

export default ArrayRevenue
