import useCallAPI from './useCallAPI'
import { normalizeTableSettingsData } from './Normalizers'

const filterQuery = (values) => {
  return {
    groupBy: values?.groupBy,
    order: values?.order,
    orderBy: values?.orderBy,
    productDetails: values?.productDetails,
    detailed: values?.detailed,
    productId: values?.productId,
    cards: values?.cards,
    paymentMonth: values?.paymentMonth,
    year: values?.year,
    componentDetails: values?.componentDetails,
  }
}

export const useTableSettings = ({ componentName, screen }) => {
  const contextParams = {
    screen,
  }
  const {
    reset,
    requestSuccess,
    requestLoading,
    requestData,
    fetchGET,
    fetchPUT,
  } = useCallAPI({
    initialCall: true,
    context: 'tableColumns',
    urlParameters: {
      ...contextParams,
    },
  })

  const stateTableSettings = normalizeTableSettingsData(requestData)

  const handleGET = (params = {}) => {
    const newQuery = filterQuery(params)
    fetchGET({
      userParams: { ...contextParams, ...newQuery },
    })
  }

  const handlePUT = ({ newSettings, params, noRefreshData }) => {
    const newQuery = filterQuery(params)
    fetchPUT({
      userParams: { ...contextParams, ...newQuery },
      userBody: { ...newSettings },
      noRefreshData,
    })
  }

  return {
    reset,
    success: requestSuccess,
    loading: requestLoading,
    state: stateTableSettings,
    handleGET,
    handlePUT,
  }
}
