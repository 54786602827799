import {
  TextField,
  Tooltip,
  Typography,
  InputAdornment,
} from '../../../../core'
import { makeStyles, useTheme } from '../../../../styles'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { intl } from '../../../../translation'
import { useSnackbar } from '../../../../notifications'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { convertHexToRGBA } from '../../../../utils'

const useStyles = makeStyles((theme) => ({
  input: {
    color: theme?.palette?.secondary?.contrastText,
  },
  div: {
    marginTop: 5,
    marginBottom: 5,
  },
  equal: {
    color: theme?.palette?.secondary?.contrastText,
    fontFamily: theme?.typography?.fontFamily,
  },
  notEqual: {
    color: 'red',
    fontFamily: theme?.typography?.fontFamily,
  },
  title: {
    color: theme?.palette?.secondary?.contrastText,
    textAlign: 'left',
    marginBottom: 0,
  },
  scrollbar: {
    cursor: 'default',
  },
}))

const usePrevious = (value) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

const FieldSelectSuggest = (props) => {
  const {
    listOptions,
    filter,
    enableNotEquals,
    onUpdate,
    defaultName,
    selectMenuPosition,
    requestNewOptions,
  } = props
  const theme = useTheme()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const dot = (data) => {
    if (data && data?.hasDot && data?.color) {
      return {
        display: 'flex',
        ':before': {
          backgroundColor: data?.color,
          borderRadius: 15,
          content: '" "',
          display: 'block',
          marginRight: 8,
          height: 15,
          width: 15,
          borderColor: '#fff',
          borderWidth: 1,
          borderStyle: 'solid',
        },
      }
    } else {
      return null
    }
  }

  const icon = (data) => {
    if (data && data?.hasIcon && data?.imgSource) {
      return {
        display: 'flex',
        ':before': {
          backgroundImage: data?.imgSource,
          borderRadius: 15,
          content: '" "',
          display: 'block',
          marginRight: 8,
          height: 15,
          width: 15,
          borderColor: '#fff',
          borderWidth: 1,
          borderStyle: 'solid',
        },
      }
    } else {
      return null
    }
  }

  const customStyles = {
    container: (provided, providedState) => ({
      ...provided,
      width: '100%',
    }),
    option: (provided, providedState) => ({
      ...provided,
      cursor: 'pointer',
      textAlign: 'left',
      fontWeight: providedState?.isSelected ? 'bold' : '',
      backgroundColor: theme?.palette?.secondary?.main,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
      ...dot(providedState?.data),
      ...icon(providedState?.data),
      '&:hover': {
        backgroundColor: convertHexToRGBA(theme?.palette?.primary?.main, 0.3),
        fontWeight: 'bold',
      },
    }),
    control: (provided, providedState) => ({
      ...provided,
      cursor: 'text',
      border: !providedState?.selectProps?.value
        ? `1px solid ${theme?.palette?.secondary?.contrastText}`
        : `2px solid ${theme?.palette?.secondary?.contrastText}`,
      '&:hover': {
        border: !providedState?.selectProps?.value
          ? `1px solid ${theme?.palette?.secondary?.contrastText}`
          : `2px solid ${theme?.palette?.secondary?.contrastText}`,
        '&:active': {
          border: !providedState?.selectProps?.value
            ? `1px solid ${theme?.palette?.secondary?.contrastText}`
            : `2px solid ${theme?.palette?.secondary?.contrastText}`,
        },
      },
      backgroundColor: theme?.palette?.secondary,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
    }),
    placeholder: (provided, providedState) => ({
      ...provided,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
      opacity: '0.4',
      fontSize: '0.8em',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
      cursor: 'pointer',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
      cursor: 'pointer',
    }),
    singleValue: (provided, providedState) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
      marginLeft: 0,
      ...dot(providedState?.selectProps?.value),
    }),
    valueContainer: (provided, providedState) => ({
      ...provided,
      paddingLeft: 7,
    }),
    input: (provided) => ({
      ...provided,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
    }),
    menu: (provided) => ({
      ...provided,
      overflowY: 'auto',
      maxHeight: '400px',
      backgroundColor: theme?.palette?.secondary?.main,
      border: `1px solid ${theme?.palette?.secondary?.contrastText}`,
      borderRadius: '5px',
      fontSize: '1em',
      zIndex: 9999,
      position: selectMenuPosition || 'absolute',
      paddingLeft: 5,
    }),
  }

  const filterState = {
    behavior: filter?.behavior,
    defaultName: filter?.defaultName,
    hasSuggested: filter?.hasSuggested,
    isSuggested: filter?.isSuggested,
    minToMakeRequest: filter?.minToMakeRequest,
    type: filter?.type,
    value: filter?.value,
    condition: filter?.condition,
    enableNotEquals: filter?.enableNotEquals,
  }
  const prevFilter = usePrevious(filter)
  const [data, setData] = useState(filterState)
  const firstLoad = useRef(true)

  useEffect(() => {
    if (firstLoad.current === true) {
      firstLoad.current = false
    } else {
      onUpdate(data)
    }
  }, [data])

  useEffect(() => {
    if (!_.isEqual(prevFilter, filter)) {
      setData(filter)
    }
  }, [prevFilter, filter])

  const handleChange = (value) => {
    const chosenValue = value
    if (chosenValue === null) {
      setData({
        ...data,
        value: {
          value: null,
          label: null,
        },
      })
    } else {
      setData({
        ...data,
        value: {
          value: chosenValue.value,
          label: chosenValue.label,
        },
        isSuggested: true,
      })
    }
  }

  const handleInputChange = (e, callbackFunction) => {
    if (typeof requestNewOptions !== 'function') {
      return
    }
    if (!e || e.length < 3) {
      return []
    } else {
      requestNewOptions(e)
        .then((data) => {
          callbackFunction(data?.data)
        })
        .catch((err) => {
          console.log(err)
          enqueueSnackbar(intl.get('phrases.loadOptionsError'), {
            variant: 'error',
          })
        })
    }
  }

  const handleClickCondition = () => {
    setData((thisData) => ({ ...thisData, condition: !thisData.condition }))
  }

  const TextMaskCustom = (props) => {
    if (!listOptions || _.isEmpty(listOptions)) {
      return (
        <AsyncSelect
          inputId="asyncSelectInput"
          isClearable
          cacheOptions
          placeholder={intl.get('words.inputSearchTitle')}
          value={
            data?.value === '' || _.isEmpty(data?.value) || !data?.value?.label
              ? ''
              : data?.value
          }
          loadOptions={handleInputChange}
          onChange={handleChange}
          styles={customStyles}
        />
      )
    } else {
      return (
        <Select
          inputId="selectInput"
          placeholder={
            intl.get('words.inputSearchTitle') || 'words.inputSearchTitle'
          }
          value={
            data?.value === '' || _.isEmpty(data?.value) || !data?.value?.label
              ? ''
              : data?.value
          }
          isSearchable
          options={listOptions}
          onChange={handleChange}
          styles={customStyles}
          backspaceRemoves
          isClearable
        />
      )
    }
  }

  return (
    <div data-testid="select">
      {defaultName && (
        <Typography className={classes.title}>
          {intl.get(`titles.${defaultName}`) || 'title'}
        </Typography>
      )}
      <TextField
        fullWidth
        backgroundcolor="secondary"
        textcolor="transparent"
        inputProps={{
          role: 'textbox',
        }}
        InputProps={{
          classes: { root: classes.scrollbar },
          disableUnderline: true,
          inputComponent: () => TextMaskCustom(),
          startAdornment: enableNotEquals && (
            <InputAdornment position="start">
              {!data?.condition ? (
                <Tooltip title={intl.get('words.inputNotEqual')}>
                  <Typography
                    className={classes.notEqual}
                    component="h2"
                    variant="subheading"
                    onClick={handleClickCondition}
                  >
                    &#8800;
                  </Typography>
                </Tooltip>
              ) : (
                <Tooltip title={intl.get('words.inputEqual')}>
                  <Typography
                    className={classes.equal}
                    component="h2"
                    variant="subheading"
                    onClick={handleClickCondition}
                  >
                    &#61;
                  </Typography>
                </Tooltip>
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  )
}

FieldSelectSuggest.propTypes = {
  enableNotEquals: PropTypes.any,
  filter: PropTypes.any,
  listOptions: PropTypes.any,
  onUpdate: PropTypes.any,
}

export default FieldSelectSuggest
