import useCallAPI from './useCallAPI'

const filterBody = ({
  commercialId,
  customerId,
  companyId,
  email,
  language,
  group,
  name,
  emailOrigin,
  productIdList,
  billingADApprovalType,
  billingADApprovalLimitDateStart,
  billingADApprovalLimitDateEnd,
  companies,
  companyIdList,
  products,
}) => ({
  commercialId,
  customerId,
  companyId,
  email,
  language,
  group,
  name,
  emailOrigin,
  productIdList,
  billingADApprovalType,
  billingADApprovalLimitDateStart,
  billingADApprovalLimitDateEnd,
  companies,
  companyIdList,
  products,
})

export const useUsersData = () => {
  const { requestLoading, requestSuccess, fetchPOST, fetchPUT, reset } =
    useCallAPI({
      initialCall: false,
      context: `user`,
    })

  const handlePOST = (params) => {
    fetchPOST({
      userBody: filterBody(params),
    })
  }

  const handleClonePOST = (params) => {
    fetchPOST({
      newContext: `user_clone`,
      userBody: filterBody(params),
    })
  }

  const handlePUTCompany = (params) => {
    fetchPUT({
      newContext: `user_company`,
      userBody: filterBody(params),
    })
  }

  const handlePUTLanguage = (params) => {
    fetchPUT({
      newContext: `user_language`,
      userBody: filterBody(params),
    })
  }
  const handlePUT = (params) => {
    fetchPUT({
      userBody: filterBody(params),
    })
  }

  return {
    loading: requestLoading,
    success: requestSuccess,
    handlePOST,
    handleClonePOST,
    handlePUTCompany,
    handlePUTLanguage,
    handlePUT,
    reset,
  }
}
