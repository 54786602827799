import { IconButton, Tooltip } from '../../../core'
import { makeStyles } from '../../../styles'
import { Edit as EditIcon } from '../../../icons'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.tertiary?.main,
  },
}))

const Edit = ({ handleButtonClick, editText }) => {
  const classes = useStyles()

  const text = editText || 'editText'

  return (
    <Tooltip data-testid="tooltip" title={text}>
      <IconButton
        role="button"
        onClick={() => handleButtonClick('Edit')}
        className={classes.icon}
        aria-label={text}
      >
        <EditIcon data-testid="editIcon" />
      </IconButton>
    </Tooltip>
  )
}

Edit.defaultProps = {
  handleButtonClick: () => {},
  editText: '',
}

Edit.propTypes = {
  handleButtonClick: PropTypes.func.isRequired,
  editText: PropTypes.string,
}

export default Edit
