import useCallAPI from './useCallAPI'

const filterQuery = () => {
  return {}
}

export const useAccountsSync = () => {
  const { reset, requestSuccess, requestLoading, fetchGET, fetchPOST } =
    useCallAPI({
      initialCall: false,
      context: `sync_accounts`,
    })

  const handlePOST = (data) => {
    fetchPOST({ userBody: data })
  }

  const handleGET = (params) => {
    fetchGET({ userParams: filterQuery(params) })
  }

  return {
    reset,
    loading: requestLoading,
    success: requestSuccess,
    handleGET,
    handlePOST,
  }
}
