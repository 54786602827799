import PropTypes from 'prop-types'
import React from 'react'
import { IconButton, Tooltip } from '../../../core'
import { Publish as PublishIcon } from '../../../icons'
import { makeStyles } from '../../../styles'
import { intl } from '../../../translation'

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.tertiary?.main,
  },
}))

const Publish = ({ handleButtonClick }) => {
  const classes = useStyles()

  return (
    <Tooltip
      data-testid="tooltip"
      title={intl.get(`phrases.importFileAD`) || `phrases.importFileAD`}
    >
      <IconButton
        role="button"
        aria-label={intl.get(`phrases.importFileAD`) || `phrases.importFileAD`}
        onClick={() => handleButtonClick('ADFileImport')}
        className={classes.icon}
      >
        <PublishIcon data-testid="publishIcon" />
      </IconButton>
    </Tooltip>
  )
}

Publish.defaultProps = {
  handleButtonClick: () => {},
}

Publish.propTypes = {
  handleButtonClick: PropTypes.func.isRequired,
}

export default Publish
