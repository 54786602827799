import PropTypes from 'prop-types'
import React from 'react'
import {
  ClickAwayListener,
  Collapse,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from '../../../core'
import { Folder as FolderIcon } from '../../../icons'
import { makeStyles } from '../../../styles'
import { intl } from '../../../translation'

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.tertiary?.main,
  },
  popper: {
    zIndex: 999,
  },
  paper: {
    maxWidth: 300,
    minWidth: 250,
    maxHeight: 500,
    overflowY: `auto`,
    overflowX: `hidden`,
  },
}))

const MoveToFolder = ({ moveToFolderList, handleButtonClick }) => {
  const classes = useStyles()
  const anchorRef = React.useRef(null)
  const [open, setOpen] = React.useState(false)

  const handleClick = (item) => {
    handleButtonClick('MoveToFolder', item)
    setOpen(false)
  }

  return (
    <>
      <Tooltip
        data-testid="tooltip"
        title={intl.get(`phrases.moveToFolder`) || 'phrases.moveToFolder'}
      >
        <IconButton
          role="button"
          ref={anchorRef}
          onClick={() => setOpen(!open)}
          className={classes.icon}
        >
          <FolderIcon data-testid="folderIcon" />
        </IconButton>
      </Tooltip>
      <Popper
        transition
        open={open}
        placement="bottom-end"
        className={classes.popper}
        anchorEl={anchorRef?.current}
      >
        {({ TransitionProps, placement }) => (
          <Collapse
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'top' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={classes.paper} elevation={3}>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList
                  role="list"
                  autoFocusItem={open}
                  id="move-to-folder-list"
                >
                  {moveToFolderList?.map((item) => (
                    <MenuItem
                      role="listitem"
                      key={item.id}
                      onClick={() => handleClick(item)}
                    >
                      <Tooltip title={item.name}>
                        <Typography variant="inherit" noWrap>
                          {item.name}
                        </Typography>
                      </Tooltip>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Collapse>
        )}
      </Popper>
    </>
  )
}

MoveToFolder.defaultProps = {
  moveToFolderList: [],
  handleButtonClick: () => {},
}

MoveToFolder.propTypes = {
  moveToFolderList: PropTypes.array.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
}

export default MoveToFolder
