import PropTypes from 'prop-types'
import React from 'react'
import { Item } from './Item'
import { ItemWithNested } from './ItemWithNested'

export const MenuButton = ({
  hasNestedItems,
  icon,
  isNested,
  isOpen,
  isVisible,
  nestedItems,
  notifications,
  onClose,
  path,
  role,
  text,
}) => {
  if (!isVisible) {
    return <div />
  }

  if (hasNestedItems) {
    return (
      <ItemWithNested
        icon={icon}
        isNested={isNested}
        isOpen={isOpen}
        nestedItems={nestedItems}
        notifications={notifications}
        onClose={onClose}
        path={path}
        role={role}
        text={text}
      />
    )
  }

  return (
    <Item
      isOpen={isOpen}
      isVisible={isVisible}
      icon={icon}
      text={text}
      path={path}
      role={role}
      notifications={notifications}
    />
  )
}

MenuButton.propTypes = {
  icon: PropTypes.any,
  isNested: PropTypes.bool,
  isVisible: PropTypes.bool,
  text: PropTypes.string,
  path: PropTypes.string,
  itens: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  notifications: PropTypes.object,
}

MenuButton.defaultProps = {
  isNested: false,
  isVisible: false,
  text: '',
  path: '',
  itens: [],
  onClose: () => {},
  isOpen: false,
  notifications: {},
}
