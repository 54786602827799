import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { makeStyles } from '../../../styles'
import _ from 'lodash'
import DateFnsUtils from '@date-io/date-fns'
import { IconButton } from '../../index'
import { Clear as ClearIcon } from '../../../icons'
import ptLocale from 'date-fns/locale/pt-BR'

const useStyles = makeStyles((theme) => ({
  field: {
    width: (param) => {
      if (param.width) {
        return param.width
      }
    },
    marginTop: (param) => param.margin || 0,
    marginBottom: (param) => param.margin || 0,
  },
  adornment: {
    color: theme?.palette?.secondary?.contrastText,
    padding: '5px',
  },
  inputBorder: {
    border: (param) => {
      const fieldBorder = !param.value
        ? `1px solid ${theme?.palette?.secondary?.contrastText}`
        : `2px solid ${theme?.palette?.secondary?.contrastText}`
      return fieldBorder
    },
  },
}))

export const TextFieldDate = ({
  format,
  views,
  placeholder,
  onChangeDate,
  selectedDate,
  initialDate,
  margin,
  width,
  className,
  hideCloseButton,
  ...restProps
}) => {
  const [isOpenStart, setIsOpenStart] = useState(false)
  const [value, setValue] = useState(selectedDate || null)

  const classes = useStyles({ margin: margin, value: value, width: width })

  useEffect(() => {
    ;((selectedDate && selectedDate !== value) ||
      (value !== null && selectedDate === null)) &&
      setValue(selectedDate)
  }, [selectedDate])

  useEffect(() => {
    if (typeof onChangeDate === 'function' && !_.isEqual(value, selectedDate)) {
      onChangeDate(value)
    }
  }, [value])

  const handleData = (date) => setValue(date)

  return (
    <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...restProps}
        classes={{
          root: classnames(className, classes.field),
        }}
        fullWidth={!width}
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        format={format}
        margin="dense"
        id="date-picker-inline"
        value={value}
        onChange={handleData}
        views={views}
        autoOk
        invalidDateMessage=""
        initialFocusedDate={initialDate}
        onOpen={() => setIsOpenStart(true)}
        onClose={() => setIsOpenStart(false)}
        open={isOpenStart}
        placeholder={placeholder}
        InputAdornmentProps={{ position: 'start', variant: 'standard' }}
        KeyboardButtonProps={{
          classes: { root: classes.adornment },
        }}
        InputProps={{
          'data-testid': 'textFieldDate',
          classes: {
            adornedEnd: classes.adornment,
            adornedStart: classes.adornment,
            notchedOutline: classes.inputBorder,
          },
          endAdornment: value && !hideCloseButton && (
            <IconButton
              data-testid="monthClearButton"
              onClick={() => setValue(null)}
              classes={{ root: classes.adornment }}
            >
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
    </MuiPickersUtilsProvider>
  )
}

TextFieldDate.propTypes = {}
