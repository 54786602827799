import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

const Percentage = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) =>
      value ? (
        `${(value * 100).toFixed(2)}%`
      ) : (
        <span style={{ color: 'lightGray' }}>0.00%</span>
      )
    }
    {...props}
  />
)

export default Percentage
