import {
  CompanyDetails,
  UserDetails,
  SelectLanguage,
} from '../src/components/Library/components'
import { Badge, IconButton, Tooltip } from '../src/components/Library/core'
import {
  Notifications as NotificationsIcon,
  NotificationsNone as NotificationsNoneIcon,
} from '../src/components/Library/icons'
import { makeStyles } from '../src/components/Library/styles'
import { PrivateAppSkeleton } from '../src/components/Library/templates'
import intl from 'react-intl-universal'
import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import {
  useAlertData,
  useLogout,
  usePrivateRoutes,
  useSettingsUser,
  useUsersData,
} from './API/Hooks'
import { ThemeContext } from './contexts/Theme'
import { UserContext } from './contexts/User'
import { useWebSocketContext } from './contexts/WebSocket'
import { suportedLocales, languagesList } from './utils/i18n'
import { Loading } from './components/Library/components/Loading'
import AlertsNotificationModal from './components/Notifications'
import { useSnackbar } from 'notistack'
import _ from 'lodash'
import { Button } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  buttonHeader: {
    margin: '0px 10px',
  },
  loadingContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10000,
    backgroundColor: '#0003',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: 0,
    top: 0,
  },
  snackbarButtonError: {
    marginLeft: theme.spacing(2),
    width: '100%',
    border: `1px solid`,
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette?.error?.light,
    color: theme.palette.background.paper,
    '&:hover': {
      color: theme?.palette?.primary?.main,
      border: `1px solid ${theme.palette.primary?.main}`,
    },
  },
  snackbarButtonSuccess: {
    marginLeft: theme.spacing(2),
    width: '100%',
    border: `1px solid`,
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette?.success?.light,
    color: theme.palette.background.paper,
    '&:hover': {
      color: theme?.palette?.primary?.main,
      border: `1px solid ${theme.palette.primary?.main}`,
    },
  },
}))

const PrivateApp = withRouter(() => {
  const classes = useStyles()
  const { handlePUTCompany, handlePUTLanguage, success, loading } =
    useUsersData()
  const { fetchLogout } = useLogout()
  const [stateUserContext] = useContext(UserContext)
  const {
    userName,
    company,
    userId,
    userGroup,
    userLanguage,
    accessRoles,
    userXCompanyList,
    userEmail,
  } = stateUserContext
  const enqueueSnackbar = useSnackbar()?.enqueueSnackbar
  const [stateThemeContext] = useContext(ThemeContext)
  const { webSocketNotifications } = useWebSocketContext() ?? {}
  const { fetchSettingsUser, requestLoading } = useSettingsUser()
  const { routes } = usePrivateRoutes()
  const alertData = useAlertData()
  const [newNotification, setNewNotification] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [processedNotificationKeys, setProcessedNotificationKeys] = useState(
    new Set()
  )

  const suportedLocalesList = suportedLocales.map((locale) => ({
    value: locale,
    label: locale,
  }))

  useEffect(() => {
    const alerts = {}

    if (webSocketNotifications && webSocketNotifications.map) {
      webSocketNotifications.map((notification) => {
        if (notification?.removedAt != null) return
        alerts[notification?.screen] = {
          ...alerts[notification?.screen],
          [notification?.key]: notification,
        }
      })
    }

    setNewNotification({ alerts: alerts, processCounters: {} })
  }, [accessRoles, webSocketNotifications])

  const handleMarkAsReceived = alertData.useMarkAsReceivedNotification()

  useEffect(() => {
    if (
      Array.isArray(webSocketNotifications) &&
      webSocketNotifications.length > 0
    ) {
      const notificationKeysListReceived = []

      webSocketNotifications.forEach((notification) => {
        const messageValidation =
          notification?.labelAlert === 'billingNfZipFile' ||
          notification?.labelAlert === 'exportDataCompleted' ||
          notification?.labelAlert === 'billingInvoiceGenerationCompleted' ||
          notification?.labelAlert === 'downloadInvoice' ||
          notification?.labelAlert === 'exportDataError'

        if (
          !notification?.removedAt &&
          !messageValidation &&
          !notification?.receivedAt &&
          !processedNotificationKeys.has(notification?.key)
        ) {
          const message = intl.get(`phrases.${notification?.labelAlert}`)
          const action = (key) => (
            <Button
              className={
                notification?.type === 'error'
                  ? classes.snackbarButtonError
                  : classes.snackbarButtonSuccess
              }
              onClick={() => handleOpenNotifications(notification?.screen)}
            >
              Mostrar Detalhes
            </Button>
          )

          if (notification?.type === 'error') {
            enqueueSnackbar(message, {
              variant: 'error',
              autoHideDuration: 7000,
              action,
            })
          }

          if (!notification?.receivedAt && !notification?.readAt) {
            notificationKeysListReceived.push(notification?.key)
          }
        }

        if (
          notificationKeysListReceived.length > 0 &&
          !notification?.receivedAt &&
          !notification?.readAt
        ) {
          handleMarkAsReceived(notificationKeysListReceived)
          setProcessedNotificationKeys((prevKeys) => {
            const newKeys = new Set(prevKeys)
            notificationKeysListReceived.forEach((key) => newKeys.add(key))
            return newKeys
          })
        }
      })
    }
  }, [webSocketNotifications, enqueueSnackbar, classes, handleMarkAsReceived])

  const handleOpenNotifications = () => {
    setOpenModal(true)
  }

  const filteredNotifications = webSocketNotifications.filter(
    (notification) => {
      const messageValidation =
        notification?.labelAlert === 'billingNfZipFile' ||
        notification?.labelAlert === 'exportDataCompleted' ||
        notification?.labelAlert === 'billingInvoiceGenerationCompleted' ||
        notification?.labelAlert === 'downloadInvoice' ||
        notification?.labelAlert === 'exportDataError'

      if (
        notification &&
        !notification?.readAt &&
        !messageValidation &&
        notification.body
      ) {
        const parsedBody = JSON.parse(notification.body)
        return !parsedBody.link
      }
      return false
    }
  )

  const hasNotifications = filteredNotifications.length > 0

  const handleChangeCompanyDefault = (companyId) => {
    handlePUTCompany({ email: userEmail, companyId })
  }
  const handleChangeLanguage = (language) => {
    handlePUTLanguage({ email: userEmail, language })
  }

  useEffect(() => {
    if (success?.PUT) {
      fetchSettingsUser()
    }
  }, [success?.PUT])

  return (
    <>
      <PrivateAppSkeleton
        routes={routes}
        handleLogout={fetchLogout}
        notifications={newNotification}
        theme={stateThemeContext}
        headerEndContent={
          <>
            <SelectLanguage
              currentLanguage={userLanguage}
              languagesList={languagesList}
              handleChangeLanguage={handleChangeLanguage}
            />

            <CompanyDetails
              name={company?.name}
              companiesList={userXCompanyList}
              email={userEmail}
              changeCompany={handleChangeCompanyDefault}
            />
            <Tooltip title={intl.get(`phrases.notifications`)}>
              <IconButton onClick={() => setOpenModal(true)} className="icon">
                <Badge
                  variant="dot"
                  color="error"
                  invisible={!hasNotifications}
                >
                  {hasNotifications ? (
                    <NotificationsIcon />
                  ) : (
                    <NotificationsNoneIcon />
                  )}
                </Badge>
              </IconButton>
            </Tooltip>
            <UserDetails
              userName={userName}
              userId={userId?.toString()}
              userGroup={userGroup}
              userLanguage={userLanguage}
              developerMode={process.env.REACT_APP_DEVELOPER_MODE === 'true'}
              suportedLocales={suportedLocalesList}
              isDisabled={true}
            />
          </>
        }
      />

      {openModal && (
        <AlertsNotificationModal
          open={openModal}
          onCancel={() => setOpenModal(false)}
        />
      )}

      {(loading?.PUT || requestLoading) && (
        <div className={classes.loadingContainer}>
          <Loading />
        </div>
      )}
    </>
  )
})

export default PrivateApp
