import { normalizeReportsData } from './Normalizers'
import { useState, useEffect } from 'react'
import _ from 'lodash'
import useCallAPI from './useCallAPI'

const filterQuery = (values) => {
  return {
    ...values,
  }
}

export const useReportSize = (params) => {
  const [data, setData] = useState([])
  const { reset, requestData, fetchPATCH, requestLoading } = useCallAPI({
    initialCall: false,
    context: 'billing_reports_size',
  })

  useEffect(() => {
    if (!_.isEqual(requestData.data, data)) {
      setData(requestData.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestData])

  const handlePATCHSize = (params) => {
    fetchPATCH({
      userBody: { ...params },
      disableCompanyId: true,
    })
  }

  return {
    data: normalizeReportsData({ data }),
    loading: requestLoading.PATCH,
    requestData,
    reset,
    handlePATCHSize,
  }
}
