import { normalizeConciliationData } from './Normalizers'
import ENDPOINTS from './ENDPOINTS'
import { useFetchAxiosWrapper } from '../useFetchAxiosWrapper'
import { useState, useEffect } from 'react'
import _ from 'lodash'

const filterQuery = (values) => {
  return {
    ...values,
  }
}

export const useConciliationGPRSSize = (params) => {
  const [data, setData] = useState([])
  const { callOnInit = false } = params || {}
  const {
    reset,
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    fetchGET,
    fetchPATCH,
  } = useFetchAxiosWrapper({
    baseEndpoint: ENDPOINTS.PATCH.conciliation_GPRS_size,
    callOnInit,
  })

  useEffect(() => {
    if (!_.isEqual(getRequest.data, data)) {
      setData(getRequest.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequest])

  useEffect(() => {
    if (!_.isEqual(patchRequest.data, data)) {
      setData(patchRequest.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchRequest])

  const handleGETSize = (params = {}) => {
    const newQuery = filterQuery(params)
    fetchGET({
      urlParameters: { ...newQuery },
    })
  }

  const handlePATCHSize = (params = {}) => {
    fetchPATCH({
      body: { ...params },
    })
  }

  return {
    data: normalizeConciliationData({ data }),
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    reset,
    handleGETSize,
    handlePATCHSize,
  }
}
