import { normalizeEmailsData } from './Normalizers'
import ENDPOINTS from './ENDPOINTS'
import { useFetchAxiosWrapper } from '../useFetchAxiosWrapper'
import { useState, useEffect } from 'react'
import _ from 'lodash'
import axios from 'axios'
import { createHeaders } from '../../utils/utilsAPI'

const filterBody = ({
  cycle,
  cycleStart,
  cycleEnd,
  order,
  orderBy,
  dateFormat,
  companyId,
  productId,
  screen,
  customerId,
  customerIdList,
  file,
  data,
}) => ({
  cycle,
  cycleStart,
  cycleEnd,
  order,
  orderBy,
  dateFormat,
  screen,
  companyId,
  productId,
  customerId,
  customerIdList,
  file,
  data,
})

export const useEmailData = (params) => {
  const [data, setData] = useState([])
  const { callOnInit = false } = params || {}
  const { patchRequest, postRequest, fetchPOST, fetchPATCH, fetchDELETE } =
    useFetchAxiosWrapper({
      baseEndpoint: ENDPOINTS.GET.billing_email_results,
      callOnInit,
    })
  const [isLoading, setIsLoading] = useState(postRequest.loading)
  const [isSuccess, setIsSuccess] = useState(postRequest.success)

  useEffect(() => {
    if (!_.isEqual(patchRequest.data, data)) {
      setData(patchRequest?.data?.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchRequest])

  useEffect(() => {
    setIsLoading(postRequest.loading)
    setIsSuccess(postRequest.success)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postRequest.loading, postRequest.success])

  const applyFilters = (sageIdSelected) => {
    const body = {
      sageIdList: sageIdSelected,
    }

    fetchPATCH({ body: body })
  }

  const handlePOST = (data = {}) => {
    fetchPOST({
      forceEndpoint: ENDPOINTS.POST.billing_email,
      body: data,
      noRefreshData: true,
    })
  }

  const handlePOSTNFeFiles = ({ params, isFormData = false }) => {
    fetchPOST({
      forceEndpoint: ENDPOINTS.POST.nfe_processor,
      body: isFormData ? params : filterBody(params),
      isFormData: isFormData,
    })
  }

  const handleGETDownload = async (formData) => {
    const url = `${process.env.REACT_APP_API_URL}${ENDPOINTS.GET.invoice_pdf}`

    try {
      await axios.patch(url, formData, {
        responseType: 'blob',
        headers: {
          ...createHeaders(),
          'Content-Disposition': 'attachment',
          'Content-Type': 'application/zip',
        },
      })

      // const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
      // return downloadUrl
    } catch (error) {
      console.error('Erro ao fazer o download', error)
      throw error
    }
  }

  const handleDELETEFile = (data = {}) => {
    fetchDELETE({
      forceEndpoint: ENDPOINTS.DELETE.billing_email_results,
      body: data,
      noRefreshData: true,
    })
  }

  return {
    data: data,
    patchRequest,
    loading: isLoading,
    success: isSuccess,
    postRequest,
    applyFilters,
    handlePOST,
    handleGETDownload,
    handlePOSTNFeFiles,
    handleDELETEFile,
  }
}
