import { makeStyles } from '../../styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'stretch',
    minHeight: 400,
    height: '100%',
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
  },
  stepper: {
    width: '45%',
    minHeight: '100%',
    borderRadius: 5,
    paddingLeft: 5,
  },
  details: {
    width: '65%',
    minHeight: '100%',
    borderRadius: 5,
    border: `1px solid ${theme?.palette?.primary?.main}`,
  },
  error: {
    width: '100%',
    textAlign: 'center',
    color: 'red',
  },
}))
