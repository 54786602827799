import { Button, IconButton, Tooltip } from '../../../core'
import { makeStyles } from '../../../styles'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.tertiary?.main,
    borderColor: theme?.palette?.tertiary?.main,
    padding: 5,
  },
}))

const Multifunctional = ({
  handleButtonClick,
  multifunctionalIcon,
  multifunctionalText,
  multifunctionalIsIconButton,
}) => {
  const classes = useStyles()

  const text = multifunctionalText || 'multifunctionalText'

  return (
    <Tooltip data-testid="tooltip" title={text}>
      {multifunctionalIsIconButton && multifunctionalIcon ? (
        <IconButton
          role="button"
          onClick={() => handleButtonClick('Multifunctional', {})}
          className={classes.icon}
          aria-label={text}
        >
          {multifunctionalIcon || 'multifunctionalIcon'}
        </IconButton>
      ) : (
        <Button
          role="button"
          variant="contained"
          layout="tertiary"
          onClick={() => handleButtonClick('Multifunctional', {})}
          startIcon={multifunctionalIcon}
        >
          {text}
        </Button>
      )}
    </Tooltip>
  )
}

Multifunctional.defaultProps = {
  handleButtonClick: () => {},
}

Multifunctional.propTypes = {
  handleButtonClick: PropTypes.func.isRequired,
}

export default Multifunctional
