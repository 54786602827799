import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useFetchAxiosWrapper } from '../useFetchAxiosWrapper'
import ENDPOINTS from './ENDPOINTS'
import { normalizeBillingProcessData } from './Normalizers'

const filterQuery = (values) => {
  return {
    groupBy: values?.groupBy,
    order: values?.order,
    orderBy: values?.orderBy,
    productDetails: values?.productDetails,
    detailed: values?.detailed,
    cycleStart: values?.cycleStart,
    cycleEnd: values?.cycleEnd,
    companyId: values?.companyId,
    productId: values?.productId,
    customerId: values?.customerId,
    categoryId: values?.categoryId,
    commercialId: values?.commercialId,
    cards: values?.cards,
    paymentMonth: values?.paymentMonth,
    year: values?.year,
    componentDetails: values?.componentDetails,
    limit: values?.limit,
    page: values?.page,
    cycle: values?.cycle,
    sageId: values?.sageId,
    sageIdList: values?.sageIdList,
  }
}

const filterBody = ({
  cycle,
  cycleStart,
  cycleEnd,
  order,
  orderBy,
  dateFormat,
  companyId,
  productId,
  customerId,
  customerIdList,
  file,
  data,
  allCustomers,
  allProducts,
  sageId,
  sageIdList,
}) => ({
  cycle,
  cycleStart,
  cycleEnd,
  order,
  orderBy,
  dateFormat,
  companyId,
  productId,
  customerId,
  customerIdList,
  file,
  data,
  allCustomers,
  allProducts,
  sageId,
  sageIdList,
})

export const useLibraryBillingProcess = (params) => {
  const [data, setData] = useState([])
  const { callOnInit = true } = params || {}
  const {
    reset,
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    fetchGET,
    fetchPATCH,
    fetchPOST,
    fetchPUT,
  } = useFetchAxiosWrapper({
    baseEndpoint: ENDPOINTS.GET.billingProcess_results,
    callOnInit,
  })

  useEffect(() => {
    if (!_.isEqual(getRequest.data, data)) {
      setData(getRequest.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequest])

  useEffect(() => {
    if (!_.isEqual(patchRequest.data, data)) {
      setData(patchRequest.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchRequest])

  const handleGET = ({ urlParameters }) => {
    const newQuery = filterQuery(urlParameters)
    fetchGET({
      urlParameters: { ...newQuery },
    })
  }

  const handlePATCH = (params = {}) => {
    fetchPATCH({
      body: { ...params },
    })
  }

  const handlePOST = ({ params, isFormData = false, processType }) => {
    fetchPOST({
      forceEndpoint:
        processType === 'commissions'
          ? ENDPOINTS.POST.billing_process_commissions
          : ENDPOINTS.POST.billing_process,
      body: isFormData ? params : filterBody(params),
      isFormData: isFormData,
    })
  }

  const handlePUT = (params) => {
    fetchPUT({
      forceEndpoint: ENDPOINTS.POST.billing_process,
      body: params,
      isFormData: true,
    })
  }
  const handleReprocessPUT = (params) => {
    fetchPUT({
      forceEndpoint: ENDPOINTS.PUT.billing_reprocess,
      body: params,
      isFormData: true,
    })
  }

  return {
    data: normalizeBillingProcessData({ data: data }),
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    handleReprocessPUT,
    reset,
    handleGET,
    handlePATCH,
    handlePOST,
    handlePUT,
  }
}
