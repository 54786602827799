import PropTypes from 'prop-types'
import React from 'react'
import { NavLink as Option } from 'react-router-dom'
import {
  AlertBadge,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useMediaQuery,
} from '../../../core'
import { makeStyles } from '../../../styles'
import { intl } from '../../../translation'
import { convertHexToRGBA } from '../../../utils'

const useStyles = makeStyles((theme) => ({
  drawerIcon: {
    color: theme?.palette?.tertiary?.main,
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: '0.3s',
    }),
    [theme?.breakpoints.down('xs')]: {
      maxWidth: 33,
      minWidth: 33,
    },
  },
  drawerText: {
    color: theme?.palette?.tertiary?.main,
    [theme?.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  nestedOpen: {
    paddingLeft: 30,
    paddingTop: 10,
    paddingBottom: 8,
    '&:hover': {
      backgroundColor: `${convertHexToRGBA(theme.palette.secondary.main, 0.3)}`,
    },
  },
  nestedClosed: {
    paddingTop: 10,
    paddingBottom: 8,
    '&:hover': {
      backgroundColor: `${convertHexToRGBA(theme.palette.secondary.main, 0.3)}`,
    },
  },
  simple: {
    marginTop: -1,
    paddingTop: 8,
    paddingBottom: 8,
    '&:hover': {
      backgroundColor: `${convertHexToRGBA(theme.palette.secondary.main, 0.3)}`,
    },
  },
  active: {},
}))

export const Item = ({
  icon,
  text,
  path,
  isNested,
  isVisible,
  isOpen,
  role,
  notifications,
}) => {
  const classes = useStyles()

  const minSize = useMediaQuery('(min-width:600px)')

  if (!isVisible) {
    return <div />
  }

  return (
    <Option
      to={path}
      style={{
        textDecoration: 'none',
      }}
    >
      <ListItem
        role="menuitem"
        button
        className={
          isNested
            ? isOpen
              ? classes.nestedOpen
              : classes.nestedClosed
            : classes.simple
        }
        classes={{
          selected: classes.selected,
        }}
      >
        {(minSize || !isOpen) && (
          <Tooltip
            role="tooltip"
            title={intl.get(`pages.${text}`) || `pages.${text}`}
          >
            <ListItemIcon className={classes.drawerIcon}>
              <AlertBadge
                icon={icon}
                screen={role}
                notifications={notifications}
              />
            </ListItemIcon>
          </Tooltip>
        )}
        <ListItemText
          data-testid="menuItemText"
          primaryTypographyProps={{ className: classes.drawerText }}
          primary={intl.get(`pages.${text}`) || `pages.${text}`}
        />
      </ListItem>
    </Option>
  )
}

Item.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.string,
  path: PropTypes.string,
  isNested: PropTypes.bool,
  isVisible: PropTypes.bool,
  isOpen: PropTypes.bool,
  notifications: PropTypes.object,
}

Item.defaultProps = {
  text: '',
  path: '',
  isNested: false,
  isVisible: false,
  isOpen: false,
  notifications: {},
}
