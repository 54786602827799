import React, { useEffect, useState } from 'react'
import { Button, Grid, Typography } from '../../../core'
import { DialogModel } from '../../DialogModel'
import { makeStyles } from '../../../styles'
import { intl } from '../../../translation'
import { ScreenDetails } from './ScreenDetails'
import { ScreenList } from './ScreenList'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  divButtons: {
    display: 'block',
    textAlign: 'right',
    borderTop: `1px solid ${theme?.palette?.primary?.main}`,
  },
  buttonEdit: {
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.primary?.contrastText,
    fontFamily: theme.typography.fontFamily,
    margin: 10,
  },
  buttonOk: {
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.primary?.contrastText,
    fontFamily: theme.typography.fontFamily,
    margin: 10,
  },
  buttonCancel: {
    backgroundColor: theme?.palette?.secondary?.main,
    color: theme?.palette?.secondary?.contrastText,
    fontFamily: theme.typography.fontFamily,
    margin: 10,
  },
  buttonClose: {
    backgroundColor: theme?.palette?.secondary?.main,
    color: theme?.palette?.secondary?.contrastText,
    fontFamily: theme.typography.fontFamily,
    margin: 10,
  },
  displayContent: {
    display: 'block',
    width: '100%',
  },
  divTable: {
    width: '100%',
    display: 'inline-block',
    padding: 0,
    height: 500,
  },
  row: {
    height: '7px',
  },
  label: { color: theme?.palette?.secondary?.contrastText },
  divLoading: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    paddingTop: '15%',
  },
  loading: {
    width: '80px',
    height: '80px',
    color: theme?.palette?.primary?.main,
  },
  grid: {
    overflow: 'hidden',
    display: 'flex',
  },
  gridList: {
    borderRight: `1px solid ${theme?.palette?.primary?.main}`,
    borderTop: `1px solid ${theme?.palette?.primary?.main}`,
    height: '475px',
    overflow: 'auto',
  },
  gridDetails: {
    borderTop: `1px solid ${theme?.palette?.primary?.main}`,
    height: '400px',
  },
  selectScreen: {
    color: theme?.palette?.secondary?.contrastText,
    width: '100%',
    textAlign: 'center',
    marginTop: '25%',
  },
}))

export const DialogUserPermissionsByScreen = ({
  open,
  isLoading,
  accessRolesUser,
  accessRolesGroup,
  row,
  defaultScreenUser,
  defaultScreenGroup,
  allowedEditPermission,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles()

  const [editState, setEditState] = useState(false)
  const [selectedScreen, setSelectedScreen] = useState('')
  const [userRoles, setUserRoles] = useState({})
  const [userScreen, setUserScreen] = useState('')
  const [restoredToGroup, setRestoredToGroup] = useState(false)

  useEffect(() => {
    if (JSON.stringify(accessRolesUser) !== '{}') {
      setUserRoles({ ...accessRolesUser })
    }
  }, [accessRolesUser])

  useEffect(() => {
    if (defaultScreenUser !== '') {
      setUserScreen(defaultScreenUser)
    }
  }, [defaultScreenUser])

  useEffect(() => {
    if (restoredToGroup) {
      handleUpdateData()
    }
  }, [restoredToGroup])

  const handleSelectedScreen = (screen) => {
    selectedScreen !== screen
      ? setSelectedScreen(screen)
      : setSelectedScreen('')
  }

  const handleChangeStatusScreen = (newStatus) => {
    const newScreenUserRoles = { ...userRoles[selectedScreen] }
    newScreenUserRoles.visible = newStatus
    setUserRoles({ ...userRoles, [selectedScreen]: newScreenUserRoles })
  }

  const handleChangeDefaultScreen = (screen) => setUserScreen(screen)

  const handleChangeStatusAction = (action, newStatus) => {
    const newScreenUserRoles = { ...userRoles[selectedScreen] }
    newScreenUserRoles.actions[action] = newStatus

    setUserRoles({ ...userRoles, [selectedScreen]: newScreenUserRoles })
  }

  const handleChangeStatusField = (field, newStatus) => {
    const newScreenUserRoles = { ...userRoles[selectedScreen] }
    newScreenUserRoles.fields[field] = newStatus
    setUserRoles({ ...userRoles, [selectedScreen]: newScreenUserRoles })
  }

  const handleCancelDataUpdates = () => {
    setUserRoles({ ...accessRolesUser })
    setUserScreen(defaultScreenUser)
    setEditState(false)
  }

  const handleUpdateData = () => {
    if (!userScreen) {
      return console.log(
        'No defaultScreen defined. Update data is not permitted'
      )
    }

    if (!row?.email) {
      return console.log('No user email defined. Update data is not permitted')
    }

    if (typeof onSubmit !== 'function') {
      return console.log(
        'No onSubmit function defined. Update data is not permitted'
      )
    }

    const newAccessRoles = {
      ...userRoles,
    }

    const defaultScreen = userScreen

    const newDataUser = {
      accessTo: row?.email,
      defaultScreen,
      permissions: {
        ...newAccessRoles,
      },
    }

    onSubmit(newDataUser)
  }

  const handleResetDataToGroup = () => {
    const originalAccessRolesGroup = _.cloneDeep(accessRolesGroup)
    setUserRoles(originalAccessRolesGroup)
    setUserScreen(defaultScreenGroup)
    setEditState(false)
    setRestoredToGroup(true)
  }

  return (
    <DialogModel
      open={open}
      title={intl.get(`phrases.userPermissions`) || `phrases.userPermissions`}
      size="lg"
      onClose={onClose}
      isLoading={isLoading}
    >
      <div className={classes.displayContent}>
        <div className={classes.divTable}>
          <Typography>{`${intl.get(`words.user`) || 'words.user'}:${
            row?.email || ''
          }`}</Typography>
          <Grid container direction="row">
            <Grid item xs={4} className={classes.gridList}>
              <ScreenList
                screens={accessRolesUser}
                handleSelectedScreen={handleSelectedScreen}
                selectedScreen={selectedScreen}
              />
            </Grid>
            <Grid item xs={8} className={classes.gridDetails}>
              {!selectedScreen ? (
                <Typography variant="h5" className={classes.selectScreen}>
                  {intl.get(`phrases.selectAScreen`) || `phrases.selectAScreen`}
                </Typography>
              ) : (
                <ScreenDetails
                  editState={editState}
                  selectedScreen={selectedScreen}
                  row={row}
                  selectedScreenDataUser={userRoles?.[selectedScreen]}
                  selectedScreenDataGroup={accessRolesGroup?.[selectedScreen]}
                  defaultScreenUser={userScreen}
                  defaultScreenGroup={defaultScreenGroup}
                  handleChangeStatusScreen={handleChangeStatusScreen}
                  handleChangeDefaultScreen={handleChangeDefaultScreen}
                  handleChangeStatusAction={handleChangeStatusAction}
                  handleChangeStatusField={handleChangeStatusField}
                />
              )}
            </Grid>
          </Grid>
        </div>
        {!editState && (
          <div className={classes.divButtons}>
            {allowedEditPermission && (
              <Button
                variant="contained"
                className={classes.buttonEdit}
                onClick={() => setEditState(true)}
              >
                {intl.get(`buttons.edit`) || 'buttons.edit'}
              </Button>
            )}

            <Button
              variant="contained"
              onClick={onClose}
              className={classes.buttonClose}
            >
              {intl.get(`buttons.close`) || 'buttons.close'}
            </Button>
          </div>
        )}

        {editState && (
          <div className={classes.divButtons}>
            <Button
              variant="contained"
              className={classes.buttonCancel}
              onClick={handleCancelDataUpdates}
            >
              {intl.get(`buttons.cancel`) || 'buttons.cancel'}
            </Button>
            {row?.group && (
              <Button
                variant="contained"
                className={classes.buttonOk}
                onClick={handleResetDataToGroup}
                disabled={!allowedEditPermission}
              >
                {intl.get(`buttons.resetToGroup`) || 'buttons.resetToGroup'}
              </Button>
            )}

            <Button
              variant="contained"
              className={classes.buttonOk}
              onClick={handleUpdateData}
              disabled={!allowedEditPermission}
            >
              {intl.get(`buttons.update`) || 'buttons.update'}
            </Button>
          </div>
        )}
      </div>
    </DialogModel>
  )
}

DialogUserPermissionsByScreen.propTypes = {}
