import React from 'react'
import { Button } from '../../core'
import { intl } from '../../translation'

const Actions = ({ disabled, onClose, sendData }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
      }}
    >
      <Button variant="outlined" layout="secondary" onClick={onClose}>
        {intl.get(`buttons.cancel`) || 'buttons.cancel'}
      </Button>
      <Button
        variant="contained"
        layout="primary"
        disabled={disabled}
        onClick={sendData}
      >
        {intl.get(`buttons.create`) || 'buttons.create'}
      </Button>
    </div>
  )
}

export default Actions
