import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { TextField } from '@material-ui/core'
import { useTheme } from '../../../styles'
import { intl } from '../../../translation'
import _ from 'lodash'

export const SearchSelectMulti = (props) => {
  const {
    listOptions,
    onSelectOption,
    selectedOption,
    placeholder,
    InputProps,
    isDisabled,
    margin,
    displayClearIcon,
    className,
  } = props
  const theme = useTheme()

  const [selectedData, setSelectedData] = useState([])
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const customStyles = {
    container: (provided, providedState) => ({
      ...provided,
      width: '100%',
      marginTop: margin || 0,
      marginBottom: margin || 0,
    }),
    option: (provided, providedState) => ({
      ...provided,
      textAlign: 'left',
      fontWeight: providedState?.isSelected ? 'bold' : '',
      backgroundColor: theme?.palette?.secondary?.main,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
    }),
    control: (provided, providedState) => ({
      ...provided,
      border: !providedState?.selectProps?.value?.length
        ? `1px solid ${theme?.palette?.secondary?.contrastText}`
        : `2px solid ${theme?.palette?.secondary?.contrastText}`,
      '&:hover': {
        border: !providedState?.selectProps?.value?.length
          ? `1px solid ${theme?.palette?.secondary?.contrastText}`
          : `2px solid ${theme?.palette?.secondary?.contrastText}`,
        '&:active': {
          border: !providedState?.selectProps?.value?.length
            ? `1px solid ${theme?.palette?.secondary?.contrastText}`
            : `2px solid ${theme?.palette?.secondary?.contrastText}`,
        },
      },
      backgroundColor: theme?.palette?.secondary,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
    }),
    placeholder: (provided, providedState) => ({
      ...provided,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
      opacity: '0.4',
      fontSize: '0.8em',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
      cursor: 'pointer',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: displayClearIcon || 'inline',
      color: theme?.palette?.secondary?.contrastText,
      cursor: 'pointer',
    }),
    singleValue: (provided, providedState) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
      marginLeft: 0,
    }),
    multiValue: (provided) => ({
      ...provided,
      color: theme?.palette?.quaternary?.contrastText,
    }),
    valueContainer: (provided, providedState) => ({
      ...provided,
      paddingLeft: 7,
    }),
    input: (provided) => ({
      ...provided,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
    }),
    menu: (provided) => ({
      ...provided,
      overflowY: 'auto',
      maxHeight: '400px',
      backgroundColor: theme?.palette?.secondary?.main,
      border: `1px solid ${theme?.palette?.secondary?.contrastText}`,
      borderRadius: '5px',
      fontSize: '1em',
      zIndex: 9999,
      paddingLeft: 5,
    }),
  }

  useEffect(() => {
    if (selectedOption && selectedOption !== selectedData) {
      setSelectedData(selectedOption)
    } else if (_.isEmpty(selectedOption)) {
      setSelectedData([])
    }
  }, [selectedOption])

  const handleSelectCustomer = (item) => {
    setSelectedData(item || {})
    onSelectOption(item || {})
  }

  const SearchSelectComponent = ({ isDisabled }) => (
    <Select
      isMulti
      placeholder={
        placeholder ||
        intl.get('words.inputSearchTitle') ||
        'words.inputSearchTitle'
      }
      isSearchable
      options={listOptions}
      onChange={handleSelectCustomer}
      styles={customStyles}
      backspaceRemoves
      isClearable
      value={!selectedData?.length ? [] : selectedData}
      isDisabled={isDisabled}
      closeMenuOnSelect={false}
      menuIsOpen={menuIsOpen}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
    />
  )

  return (
    <TextField
      className={className}
      fullWidth
      disabled={isDisabled}
      InputProps={{
        ...InputProps,
        disableUnderline: true,
        inputComponent: () => SearchSelectComponent({ isDisabled }),
      }}
    />
  )
}
