import { makeStyles } from '../../styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'stretch',
    minHeight: 400,
    height: '100%',
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
  },
  details: {
    width: '100%',
    minHeight: '200px',
    overflow: 'none',
  },
  error: {
    width: '100%',
    textAlign: 'center',
    color: 'red',
  },
}))
