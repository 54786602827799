import { Slide, Paper, Icon } from '../../core'
import classNames from 'classnames'
import { ToolsBar, TableDataHeader, DynamicFilters } from '../../components'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useStyles } from './styles'
import { FilterList as FilterListIcon } from '../../icons'
import { intl } from '../../translation'
import { createListQuery } from '../../utils'
import _ from 'lodash'

export const PaperWithFilterSkeleton = ({
  paperContent,
  filterProps,
  headerProps,
  toolbarProps,
  inLoading,
  handleUpdateData,
}) => {
  const classes = useStyles()

  const [currentToolBar, setCurrentToolBar] = useState('')
  const [isToolsBarOpen, setIsToolsBarOpen] = useState(false)
  const [toolbarButtons, setToolbarButtons] = useState([])
  const [hidden, setHidden] = useState(false)
  const [filters, setFilters] = useState({})
  const [listQuery, setListQuery] = useState({})

  const handleChangeRightBar = (value) => {
    setCurrentToolBar(currentToolBar === value ? '' : value)
  }

  const DefaultToolbarButtons = [
    {
      label: intl.get('words.filters' || 'words.filters'),
      text: 'filters',
      icon: <FilterListIcon />,
      component: (
        <DynamicFilters
          {...filterProps}
          onFilter={(values) => setFilters(values)}
        />
      ),
      isVisible: filterProps?.visible ? filterProps?.visible : true,
    },
  ]

  const normalizeButtons = (buttons) => {
    return buttons?.map((btn) => ({
      ...btn,
      icon: <Icon className={classes.btnColor}>{btn?.icon}</Icon>,
    }))
  }

  useEffect(() => {
    const newParams = createListQuery({ filters })
    if (!_.isEqual(newParams, listQuery)) {
      setListQuery(newParams)
    }
  }, [filters])

  useEffect(() => {
    if (typeof handleUpdateData === 'function') {
      handleUpdateData(listQuery)
    }
  }, [listQuery])

  useEffect(() => {
    if (currentToolBar !== '') {
      setIsToolsBarOpen(true)
    } else {
      setIsToolsBarOpen(false)
      setHidden(false)
    }
  }, [currentToolBar])

  useEffect(() => {
    let newButtonsArray = DefaultToolbarButtons

    if (toolbarProps?.buttons) {
      newButtonsArray = normalizeButtons([
        ...newButtonsArray,
        ...toolbarProps?.buttons,
      ])
    }

    setToolbarButtons(newButtonsArray)
  }, [toolbarProps, filterProps])

  const handleHiddenCall = () => {
    setHidden(!hidden)
  }

  const returnButtonComponent = () => {
    const selected = toolbarButtons?.find(
      (button) => button.text === currentToolBar
    )
    return selected?.component
  }

  return (
    <div className={classes.container}>
      <div
        className={classNames(classes.divPaper, {
          [classes.divPaperSidebarOpen]: isToolsBarOpen && !hidden,
        })}
      >
        <Paper className={classes.paper}>
          <div key="tableDataHeader">
            <TableDataHeader {...headerProps} />
          </div>
          <div key="paperContent" style={{ height: '100%' }}>
            {paperContent}
          </div>
        </Paper>
      </div>
      <ToolsBar
        buttons={toolbarButtons}
        selected={currentToolBar}
        inLoading={inLoading}
        hiddenCall={handleHiddenCall}
        isHidden={hidden}
        hasHidden={isToolsBarOpen}
        handleSelectButton={handleChangeRightBar}
      />
      <Slide
        direction="left"
        in={isToolsBarOpen && !hidden}
        mountOnEnter={!hidden}
        unmountOnExit={!hidden}
      >
        <div
          className={classNames(classes.toolBar, {
            [classes.toolBarHidden]: hidden,
          })}
        >
          {currentToolBar && returnButtonComponent()}
        </div>
      </Slide>
    </div>
  )
}

PaperWithFilterSkeleton.propTypes = {
  paperContent: PropTypes.node,
  filterProps: PropTypes.object,
  headerProps: PropTypes.object,
  toolbarProps: PropTypes.object,
  inLoading: PropTypes.bool,
  handleUpdateData: PropTypes.func,
}

PaperWithFilterSkeleton.defaultProps = {
  paperContent: <></>,
  filterProps: {},
  headerProps: {},
  toolbarProps: {},
  inLoading: false,
  handleUpdateData: (values) =>
    console.log(values, 'values for handleUpdateData'),
}
