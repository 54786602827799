// clusters
import m1 from '@library/images/markers/clusters/m1.png'
import m2 from '@library/images/markers/clusters/m2.png'
import m3 from '@library/images/markers/clusters/m3.png'
import m4 from '@library/images/markers/clusters/m4.png'
import m5 from '@library/images/markers/clusters/m5.png'
// icons
import blue from '@library/images/markers/icons/blue.png'
import red from '@library/images/markers/icons/red.png'
import ruler from '@library/images/markers/icons/ruler.png'

const clusters = { m1, m2, m3, m4, m5 }

const icons = { blue, red, ruler }

const markers = { clusters, icons }
export { markers }
