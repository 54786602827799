/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import { makeStyles } from '@material-ui/core/styles'
import React, { useContext } from 'react'
import Loading from '../components/Loading'
import { ThemeContext } from '../contexts/Theme'

const useStyles = makeStyles((theme) => ({
  loadContainer: {
    width: '100%',
    textAlign: 'center',
    height: '100vh',
    fontFamily: theme?.palettePers?.typography,
    padding: 30,
  },
  loading: {
    margin: '0px auto',
    width: '20%',
    marginTop: '10%',
    opacity: '0.2',
  },
}))

const LoadingComponent = ({ isLoading, error, pastDelay, timedOut }) => {
  const classes = useStyles()
  const [stateThemeContext] = useContext(ThemeContext)

  if (isLoading && pastDelay) {
    return (
      <div className={classes.loading}>
        <Loading theme={stateThemeContext === 'light' ? 'dark' : 'light'} />
      </div>
    )
  }
  if (timedOut) {
    return <div className={classes.loadContainer} />
  }
  if (error) {
    return <div className={classes.loadContainer} />
  }
  return null
}

export default LoadingComponent
