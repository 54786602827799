import useCallAPI from './useCallAPI'

const filterQuery = (values) => {
  return {
    order: values.order,
    orderBy: values.orderBy,
    productDetails: values.productDetails,
    detailed: values.detailed,
    productId: values.productId,
    cards: values.cards,
    paymentMonth: values.paymentMonth,
    year: values.year,
    componentDetails: values?.componentDetails,
  }
}

export const useFilterSettings = ({ componentName, screen }) => {
  const contextParams = {
    screen,
  }

  const { reset, requestSuccess, requestData, requestLoading, fetchGET } =
    useCallAPI({
      initialCall: true,
      context: 'filters',
      urlParameters: {
        ...contextParams,
      },
    })

  const stateFilterSettings = requestData?.data?.data || {
    filterPossibilities: [],
    groupingPossibilities: [],
    groupsPossibilities: [],
  }

  const handleGET = (params = {}) => {
    const newQuery = filterQuery(params)
    fetchGET({
      userParams: { ...contextParams, ...newQuery },
    })
  }

  return {
    reset,
    loading: requestLoading,
    state: stateFilterSettings,
    success: requestSuccess,
    handleGET,
  }
}

export const useFilterAdvancedSettings = ({ componentName, screen }) => {
  const contextParams = {
    screen,
  }

  const {
    reset,
    requestSuccess,
    requestData,
    requestLoading,
    requestData: stateFilterAdvancedSettings,
    fetchGET,
  } = useCallAPI({
    initialCall: false,
    context: 'filters',
    urlParameters: {
      ...contextParams,
    },
  })

  const handleGET = (params = {}) => {
    const newQuery = filterQuery(params)

    fetchGET({
      userParams: { ...contextParams, ...newQuery },
    })
  }

  return {
    reset,
    requestSuccess,
    requestData,
    requestLoading,
    state: stateFilterAdvancedSettings,
    handleGET,
  }
}
