import { DataTypeProvider } from '@devexpress/dx-react-grid'
import React from 'react'
import { intl } from '../../../../translation'

const returnTranslatedValue = (value) => {
  switch (value) {
    case '0':
      return intl.get(`maps.default`)
    case '1':
      return intl.get(`maps.whiteLabel`)
    case '2':
      return intl.get(`maps.providerStore`)
    case '3':
      return intl.get(`maps.provider`)
    case '4':
      return intl.get(`maps.whatsappOld`)
    case '5':
      return intl.get(`maps.providerWhatsapp`)
    case '6':
      return intl.get(`maps.defaultStoreOld`)
    case '7':
      return intl.get(`maps.defaultStore`)
    case '8':
      return intl.get(`maps.providerOld`)
    case '9':
      return intl.get(`maps.whatsapp`)
    default:
      return value
  }
}
const TranslatorPayzenBilling = (props) => (
  <DataTypeProvider
    formatterComponent={({ value, ...props }) => {
      const translatedValue =
        value || Number(value) === 0
          ? returnTranslatedValue(value?.toString())
          : '-'

      return translatedValue
    }}
    {...props}
  />
)

export default TranslatorPayzenBilling
