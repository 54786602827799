import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import Autosuggest from 'react-autosuggest'
import { MenuItem, Paper, Tooltip, Typography } from '../../../../core'
import { makeStyles, useTheme } from '../../../../styles'
import { intl } from '../../../../translation'
import InputField from '../Atoms/InputField'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '0.8em',
    fontFamily: theme?.typography?.fontFamily,
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    paddingRight: 0,
    border: `1px solid ${theme?.palette?.secondary?.contrastText}`,
    overflow: 'hidden',
    borderRadius: 4,
    color: theme?.palette?.secondary?.contrastText,
    marginBottom: 5,
    marginTop: 5,
    width: '100%',
  },
  container: {
    position: 'relative',
    margin: 0,
  },
  suggestionsContainerOpen: {
    zIndex: 99999,
    marginTop: 5,
    maxHeight: 400,
    width: '100%',
    borderRadius: 5,
    overflowY: 'auto',
    position: 'absolute',
    border: `1px solid ${theme?.palette?.secondary?.contrastText}`,
    backgroundColor: theme?.palette?.secondary?.main,
    color: theme?.palette?.secondary?.contrastText,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  menuItem: {
    color: theme?.palette?.secondary?.contrastText,
  },
  equal: {
    color: theme?.palette?.secondary?.contrastText,
    fontFamily: theme?.typography?.fontFamily,
    fontWeight: 'bold',
  },
  notEqual: {
    color: 'red',
    fontFamily: theme?.typography?.fontFamily,
  },
  title: {
    color: theme?.palette?.secondary?.contrastText,
    textAlign: 'left',
    marginBottom: 0,
  },
}))

const defaultRequest = {
  suggestionsTotal: [],
  isFetching: false,
  error: false,
  errorMessage: '',
}

const FieldSuggestId = ({
  defaultName,
  defaultValue,
  hasSuggested,
  minToMakeRequest,
  suggestionUrl,
  onUpdate,
  filter,
  filterParameters,
  verifyValue,
  enableNotEquals,
  version,
  suggestionConfig,
  callAPISuggestion,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const firstLoad = useRef(true)

  const [state, setState] = useState({})
  const [request, setRequest] = useState(defaultRequest)
  const [value, setValue] = useState('')
  const [suggest, setSuggest] = useState([])

  useEffect(() => {
    if (defaultValue && JSON.stringify(defaultValue) !== '{}') {
      loadSuggestions(defaultValue.label)
    }
  }, [defaultValue])

  useEffect(() => {
    if (filter && JSON.stringify(filter) !== '{}') {
      setState({ ...filter })
      setValue(filter?.value)
    }
  }, [version])

  useEffect(() => {
    if (JSON.stringify(state) !== '{}') {
      validateValue(state)
    }
  }, [state])

  useEffect(() => {
    setState({
      ...filter,
      value,
    })
  }, [value])

  useEffect(() => {
    if (firstLoad.current === true) {
      firstLoad.current = false
      return
    }
    validateValue(state)
  }, [suggest])

  const validateValue = (data) => {
    const stateExport = {
      ...data,
    }

    const foundElement = suggest.filter(
      (suggest) =>
        suggest.label?.toString()?.toLowerCase() ===
        stateExport.value?.toString()?.toLowerCase()
    )

    if (foundElement && foundElement.length === 1) {
      stateExport.isSuggested = true
      stateExport.id = foundElement[0].id
    } else {
      stateExport.isSuggested = false
    }
    onUpdate(stateExport)
  }

  useEffect(() => {
    if (firstLoad.current === true) {
      firstLoad.current = false
      return
    }
    setSuggest(getMatchingLanguages(value))
  }, [request])

  const getMatchingLanguages = (value) => {
    const escapedValue =
      value && escapeRegexCharacters(value?.toString()?.toLowerCase().trim())

    if (escapedValue === '') {
      return []
    }

    const regex = new RegExp(`^${escapedValue}`, 'i')

    return request.suggestionsTotal.filter((suggest) =>
      regex.test(suggest.label)
    )
  }

  const escapeRegexCharacters = (str) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  const getSuggestionValue = (suggestion) => {
    validateValue(state)
    return suggestion.label?.toString()
  }

  const renderSuggestion = (suggestion, { isHighlighted }) => {
    return (
      <MenuItem
        selected={isHighlighted}
        component="div"
        classes={{
          root: classes.menuItem,
        }}
      >
        <span>{suggestion.label}</span>
      </MenuItem>
    )
  }

  const loadSuggestions = (value) => {
    if (typeof callAPISuggestion !== 'function') {
      console.log(
        `value: ${value}`,
        'Function callAPISuggestion is not defined'
      )
      return
    }
    const newState = filterParameters
    if (newState[defaultName]) {
      delete newState[defaultName]
    }

    const filtersSelected = []
    Object.keys(newState).map((item) => {
      return filtersSelected.push(verifyValue(item))
    })

    const filtersCleaned = filtersSelected.filter((item) => {
      return item !== null
    })

    const filtersReady = {}
    filtersCleaned.map((item) => {
      if (Array.isArray(item)) {
        item.map((sub) => {
          filtersReady[sub.defaultColumn] = sub.value
          return filtersReady[sub.defaultColumn]
        })
      } else {
        filtersReady[item.defaultColumn] = item.value
        return filtersReady[item.defaultColumn]
      }
      return null
    })

    callAPISuggestion({
      defaultName,
      value,
      suggestionUrl,
      filtersReady,
      suggestionConfig,
    })
      .then((res) => {
        setRequest({
          ...request,
          suggestionsTotal: res.data,
        })
      })
      .catch((err) => {
        if (err !== `cancelled`) {
          setRequest({
            ...request,
            error: true,
            errMessage: err,
          })
        }
      })
  }

  const handleChange = (event, { newValue }) => {
    setValue(newValue)
  }

  const handleClickCondition = () => {
    setState(() => ({ ...state, condition: !state.condition }))
  }

  const renderInputComponent = (inputProps) => {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {enableNotEquals && (
          <div
            style={{
              display: 'flex',
              width: 22,
              paddingTop: 2,
            }}
          >
            {!state.condition ? (
              <Tooltip title={intl.get('words.inputNotEqual')}>
                <Typography
                  className={classes.notEqual}
                  component="h2"
                  variant="subheading"
                  onClick={handleClickCondition}
                  style={{
                    color: 'red',
                    fontFamily: theme?.typography?.fontFamily,
                    paddingTop: 5,
                  }}
                >
                  &#8800;
                </Typography>
              </Tooltip>
            ) : (
              <Tooltip title={intl.get('words.inputEqual')}>
                <Typography
                  className={classes.equal}
                  component="h2"
                  variant="subheading"
                  onClick={handleClickCondition}
                  style={{
                    color: theme?.palette?.secondary?.contrastText,
                    fontFamily: theme?.typography?.fontFamily,
                    paddingTop: 5,
                  }}
                >
                  &#61;
                </Typography>
              </Tooltip>
            )}
          </div>
        )}
        <div style={{ display: 'flex', flexGrow: 1 }}>
          <InputField
            inputProps={{
              inputRef: (node) => {
                ref(node)
                inputRef(node)
              },
              value,

              style: {
                border:
                  value.length > 0 &&
                  `2px solid ${theme?.palette?.secondary?.contrastText}`,
              },
            }}
            {...other}
          />
        </div>
      </div>
    )
  }

  const handleSuggestionsFetchRequested = ({ value }) => {
    if (
      hasSuggested === true &&
      value.length > minToMakeRequest &&
      request.isFetching === false
    ) {
      loadSuggestions(value)
    } else if (
      hasSuggested === true &&
      request.isFetching === false &&
      request.suggestionsTotal.length > 0
    ) {
      loadSuggestions(value)
    }
  }

  const handleSuggestionsClearRequested = () => {}

  const autosuggestProps = {
    renderInputComponent,
    suggestions: suggest,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  }

  return (
    <div data-testid="id" className={classes.div}>
      <Typography className={classes.title}>
        {intl.get(`titles.${defaultName}`) || 'title'}
      </Typography>
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          classes: {
            root: classes.root,
          },
          placeholder: intl.get('words.inputSearchTitle'),
          value,
          onChange: handleChange,
          column: defaultName,
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={(options) => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
    </div>
  )
}

FieldSuggestId.propTypes = {
  behavior: PropTypes.any,
  columnIdName: PropTypes.any,
  condition: PropTypes.any,
  defaultName: PropTypes.any,
  enableNotEquals: PropTypes.any,
  filter: PropTypes.any,
  filterParameters: PropTypes.any,
  hasSuggested: PropTypes.any,
  isSuggested: PropTypes.any,
  minToMakeRequest: PropTypes.any,
  onUpdate: PropTypes.any,
  row: PropTypes.any,
  suggestionUrl: PropTypes.any,
  type: PropTypes.any,
  verifyValue: PropTypes.any,
  version: PropTypes.any,
}

export default FieldSuggestId
