import React, { useEffect, useState } from 'react'
import Badge from '@material-ui/core/Badge'
import _ from 'lodash'
import { makeStyles } from '../../../styles'

const useStyles = makeStyles((theme) => ({
  badge: {
    backgroundColor: 'red',
    color: '#fff',
  },
  icon: {
    [theme?.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
}))

export const AlertBadge = ({ screen, icon, nestedItems, notifications }) => {
  const Icon = icon || ''
  const classes = useStyles()
  const [notificationsTotal, setNotificationsTotal] = useState(0)

  const returnTotalItems = ({ alertItems }) => {
    if (!alertItems) {
      return 0
    }
    const alertsNotViewed = Object.keys(alertItems).filter((item) => {
      return !alertItems?.[item]?.readAt
    })

    return alertsNotViewed?.length || 0
  }

  useEffect(() => {
    if (!notifications || _.isEmpty(notifications)) {
      setNotificationsTotal(0)
      return
    }

    const { alerts } = notifications
    if (alerts && nestedItems?.map) {
      let totalRoles = []
      nestedItems.map((item) => {
        if (item?.isVisible) {
          totalRoles = [...totalRoles, ...item?.role]

          return item?.role
        }
      })

      let subTotal = 0

      totalRoles.filter((nested) => {
        if (!alerts || !nested) {
          return
        }

        const alertItems = alerts?.[nested]

        subTotal = subTotal + returnTotalItems({ alertItems })
      })

      setNotificationsTotal(subTotal)
    } else {
      if (!alerts) {
        if (!_.isEmpty(notifications)) {
          let total = 0
          for (const key in notifications) {
            if (!notifications[key].readAt) {
              total += 1
            }
          }
          setNotificationsTotal(total)
        }
        return
      }
      const alertItems = alerts?.[screen]
      if (alertItems) {
        const total = returnTotalItems({ alertItems })

        setNotificationsTotal(total)
      } else {
        setNotificationsTotal(0)
      }
    }
  }, [notifications])

  return (
    <>
      {icon && (
        <span role="icon">
          <Icon className={classes.icon} />
        </span>
      )}
    </>
  )
}
