import DateFnsUtils from '@date-io/date-fns'
import { IconButton, Typography } from '../../../../core'
import { makeStyles, useTheme } from '../../../../styles'
import { Clear as ClearIcon } from '../../../../icons'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import ptLocale from 'date-fns/locale/pt'
import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/pt-br'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { intl } from '../../../../translation'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '0.8em',
    fontFamily: theme?.typography?.fontFamily,
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    border: `1px solid ${theme?.palette?.secondary?.contrastText}`,
    overflow: 'hidden',
    borderRadius: 4,
    color: theme?.palette?.secondary?.contrastText,
  },
  adornment: {
    color: theme?.palette?.secondary?.contrastText,
    padding: '5px',
  },
  alignLeft: {
    width: '100%',
    paddingRight: 0,
    margin: 0,
  },
  title: {
    color: theme?.palette?.secondary?.contrastText,
    textAlign: 'left',
    marginBottom: 0,
  },
}))

const usePrevious = (value) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

const FieldDateMonth = (props) => {
  const { filter, onUpdate, language, languages, defaultName } = props
  const classes = useStyles()
  const theme = useTheme()
  const firstLoad = useRef(true)
  const prevFilter = usePrevious(filter)
  const [isOpenStart, setIsOpenStart] = useState(false)
  const [data, setData] = useState({
    ...filter,
    date: null,
  })

  useEffect(() => {
    if (!_.isEqual(prevFilter, filter)) {
      setData(filter)
    }
  }, [prevFilter, filter])

  useEffect(() => {
    if (filter && filter?.date === '') {
      setData({ ...filter, date: null })
    }
  }, [filter])

  useEffect(() => {
    if (firstLoad.current === true) {
      firstLoad.current = false
      return
    }

    if (moment(data.date).isValid()) {
      onUpdate(data)
    } else {
      onUpdate({ ...data, date: null })
    }
  }, [data])

  const handleData = (date) => {
    if (date !== null) {
      const newData = {
        ...data,
        date: moment(date),
      }
      setData(newData)
    } else {
      const newData = {
        ...data,
        date: null,
      }
      setData(newData)
    }
  }

  return (
    <div data-testid="month">
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={
          JSON.stringify(languages) !== '{}' && language
            ? languages[language]
            : ptLocale
        }
      >
        <Typography className={classes.title}>
          {intl.get(`titles.${defaultName}`) || 'title'}
        </Typography>
        <KeyboardDatePicker
          id={`date-picker-inline-${filter?.defaultName}`}
          disableToolbar
          format="MM/yyyy"
          margin="normal"
          onChange={handleData}
          variant="inline"
          value={data.date}
          InputAdornmentProps={{ position: 'start', variant: 'standard' }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
            classes: { root: classes.adornment },
          }}
          views={['month', 'year']}
          autoOk
          invalidDateMessage=""
          onOpen={() => setIsOpenStart(true)}
          onClose={() => setIsOpenStart(false)}
          open={isOpenStart}
          key={filter ? `${filter?.defaultName}Date` : `KeyDate`}
          placeholder={
            intl.get('words.inputMonthTitle') || 'words.inputMonthTitle'
          }
          InputProps={{
            classes: {
              root: classes.root,
              adornedEnd: classes.adornment,
              adornedStart: classes.adornment,
            },
            style: {
              border:
                data.date &&
                moment(data.date).isValid() &&
                `2px solid ${theme?.palette?.secondary?.contrastText}`,
            },
            endAdornment: data?.date && (
              <IconButton
                data-testid="clearButton"
                onClick={() => setData((prev) => ({ ...prev, date: null }))}
                classes={{ root: classes.adornment }}
              >
                <ClearIcon />
              </IconButton>
            ),
            disableUnderline: true,
          }}
          className={classes.alignLeft}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}

FieldDateMonth.propTypes = {
  filter: PropTypes.object,
  onUpdate: PropTypes.func,
  language: PropTypes.string,
  languages: PropTypes.object,
}

export default FieldDateMonth
