import React from 'react'
import _ from 'lodash'
import NumberFormat from 'react-number-format'

const NumberFormatCurrency = ({
  inputRef,
  onChange,
  currencyData,
  ...other
}) => {
  currencyData =
    !currencyData || _.isEmpty(currencyData)
      ? {
          symbol: 'R$ ',
          thousandSeparator: '.',
          decimalSeparator: ',',
        }
      : currencyData

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
      thousandSeparator={currencyData?.thousandSeparator}
      decimalSeparator={currencyData?.decimalSeparator}
      allowNegative={false}
      isNumericString
      decimalScale={2}
    />
  )
}

export default NumberFormatCurrency
