import useCallAPI from './useCallAPI'

const filterBody = ({ cycle, ids, rejectMessage }) => ({
  cycle,
  ids,
  rejectMessage: rejectMessage ? rejectMessage : undefined,
})

export const useBillingAD = (action) => {
  const {
    reset,
    requestSuccess,
    requestLoading,
    requestError,
    fetchPUT,
    fetchDELETE,
  } = useCallAPI({
    initialCall: false,
    context: `billing_ad_${action}`,
  })

  const handlePUT = (params) => {
    fetchPUT({
      userBody: filterBody(params),
    })
  }

  const handleDELETE = (params) => {
    fetchDELETE({
      userBody: filterBody(params),
    })
  }

  return {
    reset,
    loading: requestLoading,
    success: requestSuccess,
    error: requestError,
    handlePUT,
    handleDELETE,
  }
}
