import _ from 'lodash'

export const createListQuery = ({
  page,
  limit,
  filters,
  sorting,
  currencyData,
}) => {
  let newQueryObj = {}
  if (page) {
    newQueryObj.page = page
  }

  if (limit) {
    newQueryObj.limit = limit
  }

  if (!_.isEmpty(currencyData) && currencyData?.code) {
    newQueryObj.currencyCode = currencyData?.code
  }

  if (sorting) {
    newQueryObj.sorting = sorting
    if (sorting.length > 0) {
      newQueryObj.order = sorting[0]?.direction
      newQueryObj.orderBy = sorting[0]?.columnName
    }
  }

  if (!_.isEmpty(filters?.values)) {
    newQueryObj = { ...newQueryObj, filters }
  }

  return newQueryObj
}
