import { DataTypeProvider } from '@devexpress/dx-react-grid'
import React from 'react'
import { intl } from '../../../../translation'

const returnTranslatedValue = (value) => {
  switch (value) {
    case 'C':
      return intl.get(`words.purchase`)
    case 'V':
      return intl.get(`words.sale`)
    default:
      return value
  }
}

const TranslatorTariffType = (props) => (
  <DataTypeProvider
    formatterComponent={({ value, ...props }) => {
      const translatedValue = value
        ? returnTranslatedValue(value?.toString())
        : '-'

      return translatedValue
    }}
    {...props}
  />
)

export default TranslatorTariffType
