import React, { useEffect, useState } from 'react'
import { List, Table, TableBody, TableHead, Typography } from '../../../core'
import { intl } from '../../../translation'
import { TableDetails } from './Components/TableDetails'
import {
  DetailsRowData,
  RowHeader,
  SlicesRowData,
} from './Components/RowComponents'
import { useStyles } from './styles'
import PropTypes from 'prop-types'
import { formatNumber } from '../../../utils'

export const GPRSSaleDetails = ({ tariffSelected, currencyData }) => {
  const classes = useStyles()
  const [selectedValue, setSelectedValue] = useState()

  const { activationValue, waterfall } = tariffSelected?.details || {}

  useEffect(() => {
    tariffSelected?.id && setSelectedValue(tariffSelected.id)
  }, [tariffSelected])

  const DetailsHeader = [
    {
      align: 'center',
      text:
        intl.get('phrases.tariffDetailsActivationValue') ||
        'phrases.tariffDetailsActivationValue',
    },
    {
      align: 'center',
      text: intl.get('phrases.cumulative') || 'phrases.cumulative',
    },
  ]

  const DetailsData = [
    {
      align: 'center',
      isCurrency: true,
      value: activationValue,
    },
    {
      align: 'center',
      value: waterfall ? intl.get('words.yes') : intl.get('words.no'),
    },
  ]

  const SlicesHeader = [
    {
      align: 'center',
      text: intl.get('words.start') || 'words.start',
    },
    {
      align: 'center',
      text: intl.get('words.end') || 'words.end',
    },
    {
      align: 'right',
      text: intl.get('words.value') || 'words.value',
    },
    {
      align: 'right',
      text: intl.get('phrases.longDistance') || 'phrases.longDistance',
    },
  ]

  const SlicesData = (row) => {
    return [
      {
        align: 'center',
        value: row?.minLimit > 0 ? formatNumber(row?.minLimit) : '0',
      },
      {
        align: 'center',
        value:
          row?.maxLimit > 0
            ? formatNumber(row?.maxLimit)
            : intl.get('phrases.noLimit'),
      },
      {
        align: 'right',
        isCurrency: true,
        value: row?.value || 0,
      },
      {
        align: 'right',
        isCurrency: true,
        value: row?.excessValue || 0,
      },
    ]
  }

  return (
    <List dense className={classes.container}>
      {JSON.stringify(tariffSelected) !== '{}' && (
        <>
          <Typography variant="body1" className={classes.titleDetails}>
            {intl.get('words.details') || 'words.details'}
          </Typography>
          <div className={classes.listItem}>
            <TableDetails tariffSelected={tariffSelected} />
            {tariffSelected?.details && (
              <Table size="small">
                <TableHead>
                  <RowHeader itens={DetailsHeader} />
                </TableHead>
                <TableBody>
                  <DetailsRowData
                    itens={DetailsData}
                    currencyData={currencyData}
                  />
                </TableBody>
              </Table>
            )}
            {tariffSelected?.details?.values && (
              <>
                <Typography variant="body1" className={classes.titleSlices}>
                  {intl.get('words.slices') || 'words.slices'}
                </Typography>
                {tariffSelected && selectedValue === tariffSelected?.id && (
                  <div className={classes.listItemStatus}>
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <RowHeader itens={SlicesHeader} />
                      </TableHead>
                      <TableBody>
                        {tariffSelected?.details?.values?.map((row, index) => (
                          <SlicesRowData
                            key={row?.minLimit || index.toString()}
                            itens={SlicesData(row)}
                            currencyData={currencyData}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </List>
  )
}

GPRSSaleDetails.propTypes = { tariffSelected: PropTypes.object }

GPRSSaleDetails.default = {
  tariffSelected: {},
}
