import React from 'react'
import { Table, TableBody, TableCell, TableRow } from '../../../../core'
import { intl } from '../../../../translation'
import { makeStyles } from '../../../../styles'
import moment from 'moment'

export const useStyles = makeStyles((theme) => ({
  tableNotRow: {
    border: 'none',
    width: '80px',
  },
  tableNotRowValue: {
    border: 'none',
    fontWeight: 'bolder',
    width: '100%',
    whiteSpace: 'wrap',
  },
}))

export const TableDetails = (props) => {
  const { tariffSelected } = props
  const classes = useStyles()
  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell
            align="left"
            component="th"
            scope="row"
            className={classes.tableNotRow}
          >
            {intl.get('phrases.tariffDetailsName') ||
              'phrases.tariffDetailsName'}
          </TableCell>
          <TableCell align="right" className={classes.tableNotRowValue}>
            {`${tariffSelected?.description || 'tariffSelected.description'}`}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            align="left"
            component="th"
            scope="row"
            className={classes.tableNotRow}
          >
            {intl.get('phrases.tariffDetailsType') ||
              'phrases.tariffDetailsType'}
          </TableCell>
          <TableCell align="right" className={classes.tableNotRowValue}>
            {`${tariffSelected?.type || 'tariffSelected.type'}`}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            align="left"
            component="th"
            scope="row"
            className={classes.tableNotRow}
          >
            {intl.get('phrases.tariffDetailsProduct') ||
              'phrases.tariffDetailsProduct'}
          </TableCell>
          <TableCell align="right" className={classes.tableNotRowValue}>
            {`${tariffSelected?.productName || 'tariffSelected.productName'}`}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            align="left"
            component="th"
            scope="row"
            className={classes.tableNotRow}
          >
            {intl.get('phrases.tariffDetailsOperator') ||
              'phrases.tariffDetailsOperator'}
          </TableCell>
          <TableCell align="right" className={classes.tableNotRowValue}>
            {`${tariffSelected?.operatorName || 'tariffSelected.operatorName'}`}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            align="left"
            component="th"
            scope="row"
            className={classes.tableNotRow}
          >
            {intl.get('phrases.tariffDetailsCreatedBy') ||
              'phrases.tariffDetailsCreatedBy'}
          </TableCell>
          <TableCell align="right" className={classes.tableNotRowValue}>
            {`${tariffSelected?.createdBy || 'tariffSelected.createdBy '}`}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            align="left"
            component="th"
            scope="row"
            className={classes.tableNotRow}
          >
            {intl.get('phrases.tariffDetailsCreatedAt') ||
              'phrases.tariffDetailsCreatedAt'}
          </TableCell>
          <TableCell align="right" className={classes.tableNotRowValue}>
            {`${
              tariffSelected?.createdAt
                ? moment(tariffSelected.createdAt).format(
                    intl.get('default.date.short')
                  )
                : 'tariffSelected.createdAt'
            }`}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

TableDetails.propTypes = {}
