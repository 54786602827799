import { Slide, Paper } from '../../core'
import classNames from 'classnames'
import { ToolsBar } from '../../components'
import propTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useStyles } from './styles'

export const SkeletonScreen = (props) => {
  const {
    childrenPaper,
    buttonsToolsBar,
    selectedButtonToolbar,
    childrenSlider,
    sliderWidth,
    inLoading,
  } = props
  const classes = useStyles()

  const [isToolsBarOpen, setIsToolsBarOpen] = useState(false)
  const [hidden, setHidden] = useState(false)

  useEffect(() => {
    if (selectedButtonToolbar !== '') {
      setIsToolsBarOpen(true)
    } else {
      setIsToolsBarOpen(false)
      setHidden(false)
    }
  }, [selectedButtonToolbar])

  const handleHiddenCall = () => {
    setHidden(!hidden)
  }

  return (
    <div className={classes.container}>
      <div
        className={classNames(classes.divPaper, {
          [classes.divPaperSidebarOpen]: isToolsBarOpen && !hidden,
        })}
        style={
          sliderWidth > 0 && isToolsBarOpen && !hidden
            ? { width: `calc(100% - ${sliderWidth}px)` }
            : {}
        }
      >
        <Paper className={classes.paper}>{childrenPaper}</Paper>
      </div>
      <ToolsBar
        buttons={buttonsToolsBar}
        selected={selectedButtonToolbar}
        inLoading={inLoading}
        hiddenCall={handleHiddenCall}
        isHidden={hidden}
        hasHidden={isToolsBarOpen}
      />
      <Slide
        direction="left"
        in={isToolsBarOpen && !hidden}
        mountOnEnter={!hidden}
        unmountOnExit={!hidden}
      >
        <div
          className={classNames(classes.toolBar, {
            [classes.toolBarHidden]: hidden,
          })}
          style={
            sliderWidth && isToolsBarOpen && !hidden
              ? {
                  width: sliderWidth,
                }
              : {}
          }
        >
          {childrenSlider}
        </div>
      </Slide>
    </div>
  )
}

SkeletonScreen.propTypes = {
  childrenPaper: propTypes.element,
  buttonsToolsBar: propTypes.arrayOf(propTypes.array),
  selectedButtonToolbar: propTypes.string,
  childrenSlider: propTypes.element,
}

SkeletonScreen.defaultProps = {
  childrenPaper: <></>,
  buttonsToolsBar: [],
  selectedButtonToolbar: '',
  childrenSlider: <></>,
}
