import React, { useState, useEffect } from 'react'
import Autosuggest from 'react-autosuggest'
import { MenuItem, Paper } from '../../../core'
import { makeStyles } from '../../../styles'
import { intl } from '../../../translation'
import InputField from '../InputField'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '0.8em',
    fontFamily: theme?.typography?.fontFamily,
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    paddingRight: 0,
    border: `1px solid ${theme?.palette?.secondary?.contrastText}`,
    overflow: 'hidden',
    borderRadius: 4,
    color: theme?.palette?.secondary?.contrastText,
    marginBottom: 5,
    marginTop: 0,
    width: '100%',
  },
  container: {
    position: 'relative',
    margin: 0,
  },
  suggestionsContainerOpen: {
    zIndex: 99999,
    marginTop: 5,
    maxHeight: 400,
    width: '100%',
    borderRadius: 5,
    overflowY: 'auto',
    position: 'absolute',
    border: `1px solid ${theme?.palette?.secondary?.contrastText}`,
    backgroundColor: theme?.palette?.secondary?.main,
    color: theme?.palette?.secondary?.contrastText,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  menuItem: {
    color: theme?.palette?.secondary?.contrastText,
  },
  equal: {
    color: theme?.palette?.secondary?.contrastText,
    fontFamily: theme?.typography?.fontFamily,
    fontWeight: 'bold',
  },
  notEqual: {
    color: 'red',
    fontFamily: theme?.typography?.fontFamily,
  },
  title: {
    color: theme?.palette?.secondary?.contrastText,
    textAlign: 'left',
    marginBottom: 0,
  },
}))

export const TextFieldSuggest = ({
  defaultName,
  minToMakeRequest,
  suggestionApiMethod,
  selectedValue,
  placeholder,
  defaultValue,
}) => {
  const classes = useStyles()

  const [value, setValue] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [loading, setLoading] = useState(false)
  const [, setSelectedOption] = useState({})

  const handleSelectOption = (option) => {
    setSelectedOption(option)
    if (typeof selectedValue === 'function') {
      selectedValue(option)
    }
  }

  const getMatchingLanguages = (value) => {
    const escapedValue =
      value && escapeRegexCharacters(value?.toString()?.toLowerCase().trim())

    if (escapedValue === '') {
      return []
    }

    const regex = new RegExp(`^${escapedValue}`, 'i')

    let returnValues = suggestions?.filter((suggest) =>
      regex.test(suggest?.label)
    )
    if (returnValues.length === 0) {
      returnValues = suggestions?.filter((suggest) =>
        regex.test(suggest?.value)
      )
    }

    return returnValues
  }

  const escapeRegexCharacters = (str) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  const getSuggestionValue = (suggestion) => {
    return suggestion?.label?.toString()
  }

  const renderSuggestion = (suggestion, { isHighlighted }) => {
    return (
      <MenuItem
        selected={isHighlighted}
        component="div"
        classes={{
          root: classes.menuItem,
        }}
        onClick={() => handleSelectOption(suggestion)}
      >
        <span>{suggestion?.label}</span>
      </MenuItem>
    )
  }

  const loadSuggestions = (value) => {
    if (typeof suggestionApiMethod !== 'function') {
      console.log(
        `value: ${value}`,
        'Function suggestionApiMethod is not defined'
      )
      return
    }

    if (!loading) {
      setLoading(true)
      suggestionApiMethod({
        value,
      })
        .then(({ data }) => {
          setSuggestions(data)
          setLoading(false)
        })
        .catch(() => {
          setSuggestions([])
          setLoading(false)
        })
    }
  }

  const handleChange = (_, { newValue }) => setValue(newValue)

  const renderInputComponent = (inputProps) => {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps
    return (
      <InputField
        inputProps={{
          inputRef: (node) => {
            ref(node)
            inputRef(node)
          },
          value,
        }}
        {...other}
      />
    )
  }

  const handleSuggestionsFetchRequested = ({ value }) => {
    getMatchingLanguages(value)
    if (value.length > minToMakeRequest) {
      loadSuggestions(value)
    }
  }

  const handleSuggestionsClearRequested = () => {}

  const autosuggestProps = {
    renderInputComponent,
    suggestions: suggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  }

  useEffect(() => {
    if (defaultValue?.value && defaultValue?.label) {
      setValue(defaultValue?.label)
      setSelectedOption(defaultValue)
    } else {
      setValue('')
      setSelectedOption({})
    }
  }, [defaultValue])

  return (
    <Autosuggest
      {...autosuggestProps}
      inputProps={{
        classes: {
          root: classes.root,
        },
        placeholder:
          placeholder ||
          intl.get('words.inputSearchTitle') ||
          'words.inputSearchTitle',
        value,
        onChange: handleChange,
        column: defaultName,
      }}
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
      }}
      renderSuggestionsContainer={(options) => (
        <Paper {...options.containerProps} square>
          {options.children}
        </Paper>
      )}
    />
  )
}

TextFieldSuggest.propTypes = {}
