import { useFetchAxiosWrapper } from '../useFetchAxiosWrapper'
import ENDPOINTS from './ENDPOINTS'
import { normalizeSizeData } from './Normalizers'
import { useState, useEffect } from 'react'
import _ from 'lodash'

const filterQuery = (values) => {
  return {
    groupBy: values?.groupBy,
    order: values?.order,
    orderBy: values?.orderBy,
    productDetails: values?.productDetails,
    detailed: values?.detailed,
    cycleStart: values?.cycleStart,
    cycleEnd: values?.cycleEnd,
    productId: values?.productId,
    customerId: values?.customerId,
    categoryId: values?.categoryId,
    commercialId: values?.commercialId,
    cards: values?.cards,
    paymentMonth: values?.paymentMonth,
    year: values?.year,
    componentDetails: values?.componentDetails,
    limit: values?.limit,
    page: values?.page,
    cycle: values?.cycle,
  }
}

export const useLibrarySize = (params) => {
  const [data, setData] = useState([])
  const { callOnInit = true, componentName } = params || {}
  const {
    reset,
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    fetchGET,
    fetchPATCH,
  } = useFetchAxiosWrapper({
    baseEndpoint: ENDPOINTS.GET[`${componentName}_size`],
    callOnInit,
  })

  useEffect(() => {
    if (!_.isEqual(getRequest.data, data)) {
      setData(getRequest.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequest])

  useEffect(() => {
    if (!_.isEqual(patchRequest.data, data)) {
      setData(patchRequest.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchRequest])

  const handleGET = ({ urlParameters }) => {
    const newQuery = filterQuery(urlParameters)
    fetchGET({
      urlParameters: { ...newQuery },
    })
  }

  const handlePATCH = (params = {}) => {
    fetchPATCH({
      body: { ...params },
    })
  }

  return {
    getRequest,
    data: normalizeSizeData({ data: data }),
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    reset,
    handleGET,
    handlePATCH,
  }
}
