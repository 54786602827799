import { DataTypeProvider } from '@devexpress/dx-react-grid'
import PropTypes from 'prop-types'
import React from 'react'
import { carriers as carriersObj } from '../../../../images'

const Carriers = ({ carriers, ...otherProps }) => {
  return (
    <DataTypeProvider
      formatterComponent={({ value }) => {
        if (!value) {
          return ''
        }

        const carrierLogo = carriersObj.getLogoById(value || 1)

        if (!carrierLogo) {
          return value
        }

        return <img src={carrierLogo} alt="carrierLogo" height="18" />
      }}
      {...otherProps}
    />
  )
}

Carriers.defaultProps = {
  carriers: [],
}

Carriers.propTypes = {
  carriers: PropTypes.array.isRequired,
}

export default Carriers
