import React from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'

import CardAdapter from './CardAdapter'

const ResponsiveGridLayout = WidthProvider(Responsive)

const Dashboard = ({
  cardsList,
  children,
  showGrid,
  isResizable,
  gridColor,
  isDraggable,
  backgroundColor,
  onLayoutChange,
  onBreakpointChange,
}) => {
  const dashboardRef = React.useRef()
  const [style, setStyle] = React.useState({})
  const [colWidth, setColWidth] = React.useState(100)
  const [currentBreakPoint, setCurrentBreackPoint] = React.useState('lg')

  const Cards = () => {
    const newChildren = []
    let tempChildren = children

    if (children.type === React.Fragment) {
      tempChildren = children.props.children
    }

    React.Children.forEach(tempChildren, (child) => {
      if (!cardsList[currentBreakPoint]) {
        return
      }

      const foundCard = cardsList[currentBreakPoint].findIndex(
        (card) => card.i === child.props.id
      )
      if (
        child.type &&
        child.type.displayName === 'CardAdapter' &&
        foundCard > -1
      ) {
        newChildren.push(child)
      }
    })

    return newChildren
  }

  const mountGrid = (containerWidth, margin, cols, containerPadding) => {
    const calcGridColWidth = (positionParams) => {
      const { margin, containerPadding, containerWidth, cols } = positionParams
      return (
        (containerWidth - margin[0] * (cols - 1) - containerPadding[0] * 2) /
        cols
      )
    }

    let newColWidth = calcGridColWidth({
      margin,
      containerPadding,
      containerWidth,
      cols,
    })

    newColWidth = Math.round(newColWidth)
    const tileMargin = margin[0]
    const tileSize = newColWidth + tileMargin

    setColWidth(newColWidth)
    setStyle({
      backgroundRepeat: 'repeat',
      color: gridColor || 'rgb(74 74 74 / 16%)',
      backgroundSize: `${tileSize}px ${tileSize}px`,
      backgroundPosition: `0 ${tileMargin}px, ${newColWidth - tileMargin}px ${
        newColWidth + 10
      }px`,
      backgroundImage: `linear-gradient(to left, ${backgroundColor} ${tileMargin}px, transparent 0%, transparent 0%, transparent 0%, transparent), linear-gradient(to top, transparent ${tileMargin}px, transparent 0%, transparent 0%, currentcolor 0%, currentcolor)`,
    })
  }

  const handleWidthChange = (
    containerWidth,
    margin,
    cols,
    containerPadding
  ) => {
    mountGrid(containerWidth, margin, cols, containerPadding)
  }

  const handleBreakpointChange = (breakpoint) => {
    setCurrentBreackPoint(breakpoint)
    onBreakpointChange(breakpoint)
  }

  return (
    <div ref={dashboardRef}>
      <ResponsiveGridLayout
        onLayoutChange={onLayoutChange}
        rowHeight={colWidth}
        compactType="horizontal"
        layouts={cardsList}
        containerPadding={[0, 0]}
        onWidthChange={handleWidthChange}
        isDraggable={isDraggable}
        isResizable={isResizable}
        resizeHandles={isResizable ? ['se'] : []}
        style={showGrid ? style : undefined}
        onBreakpointChange={handleBreakpointChange}
      >
        {Cards()}
      </ResponsiveGridLayout>
    </div>
  )
}

Dashboard.CardAdapter = CardAdapter

export default Dashboard
