import PropTypes from 'prop-types'
import React from 'react'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'

export const MainRouter = withRouter((props) => {
  const { routes } = props

  return (
    <Switch>
      {routes?.map((route, index) => {
        if (route?.hasNestedItems) {
          const { nestedItems } = route
          return nestedItems?.map((nestedRoute, nestedIndex) => (
            <Route
              key={`${nestedIndex}`}
              path={nestedRoute?.path}
              exact
              render={() => <nestedRoute.main {...props} />}
              {...props}
            />
          ))
        }
        return (
          <Route
            key={`${index}`}
            path={route?.path}
            exact
            render={() => <route.main {...props} />}
            {...props}
          />
        )
      })}
      {routes.length >= 1 && (
        <Route path="*">
          <Redirect to="/" />
        </Route>
      )}
    </Switch>
  )
})

MainRouter.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  restProps: PropTypes.object,
}
