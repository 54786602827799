import { MenuItem, Select, Typography } from '../../../../core'
import { makeStyles, useTheme } from '../../../../styles'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { intl } from '../../../../translation'
import InputField from '../Atoms/InputField'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '0.8em',
    fontFamily: theme?.typography?.fontFamily,
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    border: `1px solid ${theme?.palette?.secondary?.contrastText}`,
    overflow: 'hidden',
    borderRadius: 4,
    color: theme?.palette?.secondary?.contrastText,
    marginBottom: 5,
    marginTop: 5,
  },
  selected: {
    fontSize: '0.8em',
    fontFamily: theme?.typography?.fontFamily,
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    border: `2px solid ${theme?.palette?.secondary?.contrastText}`,
    overflow: 'hidden',
    borderRadius: 4,
    color: theme?.palette?.secondary?.contrastText,
    marginBottom: 5,
    marginTop: 5,
  },
  focused: {},
  alignLeft: {
    width: '32%',
    marginRight: '5%',
  },
  alignRight: {
    width: '32%',
    marginRight: '5%',
  },
  alignSelect: {
    width: '26%',
  },
  title: {
    color: theme?.palette?.secondary?.contrastText,
    textAlign: 'left',
    marginBottom: 0,
  },
  rootSelect: {
    fontSize: '0.8em',
    fontFamily: theme?.typography?.fontFamily,
    height: 28,
    paddingTop: 10,
    paddingBottom: 0,
    paddingLeft: 10,
    border: `1px solid ${theme?.palette?.secondary?.contrastText}`,
    overflow: 'hidden',
    borderRadius: 4,
    color: theme?.palette?.secondary?.contrastText,
    marginBottom: 5,
    marginTop: 5,
  },
  selectedSelect: {
    fontSize: '0.8em',
    fontFamily: theme?.typography?.fontFamily,
    height: 26,
    paddingTop: 10,
    paddingBottom: 0,
    paddingLeft: 10,
    border: `2px solid ${theme?.palette?.secondary?.contrastText}`,
    overflow: 'hidden',
    borderRadius: 4,
    color: theme?.palette?.secondary?.contrastText,
    marginBottom: 5,
    marginTop: 5,
  },
  menuItem: {
    fontSize: '0.8em',
    fontFamily: theme?.typography?.fontFamily,
    height: 20,
    paddingTop: 10,
  },
}))

const FieldBytes = ({ filter, onUpdate, version, defaultName }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [state, setState] = useState({})
  const [valueMin, setValueMin] = useState('')
  const [valueMax, setValueMax] = useState('')
  const [valueDensity, setDensity] = useState('B')

  useEffect(() => {
    if (JSON.stringify(filter) !== '{}') {
      setState({ ...filter })
      setValueMin(filter?.valueMin)
      setValueMax(filter?.valueMax)
      setDensity(filter?.density)
    }
  }, [version])

  useEffect(() => {
    setState({
      ...filter,
      valueMin,
    })
  }, [valueMin])

  useEffect(() => {
    setState({
      ...filter,
      valueMax,
    })
  }, [valueMax])

  useEffect(() => {
    setState({
      ...filter,
      density: valueDensity,
    })
  }, [valueDensity])

  useEffect(() => {
    if (JSON.stringify(state) !== '{}') {
      validateValue(state)
    }
  }, [state])

  const validateValue = (data) => {
    onUpdate(data)
  }

  return (
    <div data-testid="range_bytes">
      <Typography className={classes.title}>
        {intl.get(`titles.${defaultName}`) || 'title'}
      </Typography>
      <InputField
        placeholder={intl.get('words.inputMinTitle') || 'words.inputMinTitle'}
        type="number"
        inputProps={{
          classes: {
            root: classes.root,
          },
          style: {
            border:
              valueMin?.length > 0 &&
              `2px solid ${theme?.palette?.secondary?.contrastText}`,
            color: theme?.palette?.secondary?.contrastText,
          },
          disableUnderline: true,
          value: valueMin,
          onChange: (event) => setValueMin(event?.target?.value || ''),
        }}
        className={classes.alignLeft}
      />
      <InputField
        placeholder={intl.get('words.inputMaxTitle') || 'words.inputMaxTitle'}
        type="number"
        inputProps={{
          classes: {
            root: classes.root,
          },
          style: {
            border:
              valueMax?.length > 0 &&
              `2px solid ${theme?.palette?.secondary?.contrastText}`,
            color: theme?.palette?.secondary?.contrastText,
          },
          disableUnderline: true,
          value: valueMax,
          onChange: (event) => setValueMax(event?.target?.value || ''),
        }}
        className={classes.alignRight}
      />
      <div data-testid="selectDiv" title={valueDensity}>
        <Select
          inputId={`${filter?.defaultName}Density`}
          className={classes.alignSelect}
          disableUnderline
          value={valueDensity}
          onChange={(event) => setDensity(event?.target?.value || 'B')}
          inputProps={{
            name: 'Density',
            id: `${filter?.defaultName}Density`,
            classes: {
              root:
                valueMax || valueMin
                  ? classes.selectedSelect
                  : classes.rootSelect,
            },
          }}
        >
          <MenuItem role="menuitem" value="B">
            B
          </MenuItem>
          <MenuItem role="menuitem" value="KB">
            KB
          </MenuItem>
          <MenuItem role="menuitem" value="MB">
            MB
          </MenuItem>
        </Select>
      </div>
    </div>
  )
}

FieldBytes.propTypes = {
  filter: PropTypes.any,
  onUpdate: PropTypes.any,
  version: PropTypes.any,
}

export default FieldBytes
