import React from 'react'
import PropTypes from 'prop-types'

const CardAdapter = React.forwardRef(
  (
    { id, style, children, editMode, className, showBackground, ...props },
    ref
  ) => {
    return (
      <div
        ref={ref}
        id={id}
        role="presentation"
        className={className}
        style={{
          ...style,
          color: 'black',
          backgroundColor: '#8080807a',
        }}
        {...props}
      >
        {children}
      </div>
    )
  }
)

CardAdapter.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  // editMode: PropTypes.bool.isRequired
}

CardAdapter.displayName = 'CardAdapter'

export default CardAdapter
