import { DataTypeProvider } from '@devexpress/dx-react-grid'
import moment from 'moment'
import React from 'react'

const Date = (props) => (
  <DataTypeProvider
    formatterComponent={({ value, ...props }) =>
      value
        ? moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')
        : ''
    }
    {...props}
  />
)

export default Date
