import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button, Divider, SearchSelect, Tooltip, Typography } from '../../core'
import { DialogModel } from '../index'
import {
  Close as CloseIcon,
  Fingerprint as FingerprintIcon,
  Group as GroupIcon,
  Person as PersonIcon,
  Translate as TranslateIcon,
} from '../../icons'
import { makeStyles } from '../../styles'
import { intl } from '../../translation'

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme?.spacing(1),
  },
  button: {
    minWidth: 200,
    textDecoration: 'none',
    color: theme?.palette?.tertiary?.main,
  },
  dialogText: {
    marginLeft: theme?.spacing(2),
    color: theme?.palette?.primary?.main,
  },
  dialogIcon: {
    color: theme?.palette?.primary?.main,
    fontSize: '32px',
  },
  dialogItem: {
    display: 'flex',
    margin: theme?.spacing(1),
    alignItems: 'center',
  },
  divider: {
    width: '100%',
    backgroundColor: theme?.palette?.tertiary?.main,
  },
  languageSelect: {
    marginLeft: theme?.spacing(2),
  },
}))

export const UserDetails = ({
  userName,
  userId,
  developerMode,
  userGroup,
  userLanguage,
  suportedLocales,
  handleLanguageUpdate,
  isDisabled,
}) => {
  const classes = useStyles()

  const [isOpen, setIsOpen] = useState(false)
  const [language, setLanguage] = useState({
    value: userLanguage,
    label: userLanguage,
  })

  const openProfileModal = () => {
    setIsOpen(true)
  }

  const closeProfileModal = () => {
    setIsOpen(false)
  }

  const handleLanguageChange = (value) => {
    setLanguage(value)
    typeof handleLanguageUpdate === 'function' &&
      handleLanguageUpdate(value?.value)
  }

  return (
    <>
      <Tooltip title={intl.get(`titles.profile`) || `titles.profile`}>
        <Button
          className={classes.button}
          variant="text"
          onClick={openProfileModal}
        >
          <PersonIcon
            data-testid="personIcon"
            color="inherit"
            className={classes.icon}
          />
          {`${userName}`}
          {developerMode && ` - Id:${userId}`}
        </Button>
      </Tooltip>
      <DialogModel
        role="dialog"
        open={isOpen}
        onClose={closeProfileModal}
        size="xs"
        title={intl.get(`words.profile`).toUpperCase() || `words.profile`}
        content={
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <div data-testid="userNameDiv" className={classes.dialogItem}>
              <PersonIcon className={classes.dialogIcon} />
              <Typography variant="body1" className={classes.dialogText}>
                {intl.get(`titles.user`) || `titles.user`}:
              </Typography>
              <Typography variant="body1" className={classes.dialogText}>
                {userName}
              </Typography>
            </div>
            {userId && (
              <>
                <Divider classes={{ root: classes.divider }} />
                <div data-testid="userIdDiv" className={classes.dialogItem}>
                  <FingerprintIcon className={classes.dialogIcon} />
                  <Typography variant="body1" className={classes.dialogText}>
                    {intl.get(`titles.id`) || `titles.id`}:
                  </Typography>
                  <Typography variant="body1" className={classes.dialogText}>
                    {userId}
                  </Typography>
                </div>
              </>
            )}
            {userGroup && (
              <>
                <Divider classes={{ root: classes.divider }} />
                <div data-testid="userGroupDiv" className={classes.dialogItem}>
                  <GroupIcon className={classes.dialogIcon} />
                  <Typography variant="body1" className={classes.dialogText}>
                    {intl.get(`titles.groupName`) || `titles.groupName`}:
                  </Typography>
                  <Typography variant="body1" className={classes.dialogText}>
                    {userGroup}
                  </Typography>
                </div>
              </>
            )}
            {userLanguage && (
              <>
                <Divider classes={{ root: classes.divider }} />
                <div
                  data-testid="userLanguageDiv"
                  className={classes.dialogItem}
                >
                  <TranslateIcon className={classes.dialogIcon} />
                  <Typography variant="body1" className={classes.dialogText}>
                    {intl.get(`words.language`) || `words.language`}:
                  </Typography>
                  <SearchSelect
                    className={classes.languageSelect}
                    listOptions={suportedLocales}
                    onSelectOption={handleLanguageChange}
                    selectedOption={language}
                    isDisabled={isDisabled}
                    fullWidth={false}
                    width={120}
                    displayClearIcon="none"
                  />
                </div>
              </>
            )}
          </div>
        }
        actions={
          <Button
            layout="tertiary"
            variant="contained"
            size="small"
            weight="bold"
            onClick={closeProfileModal}
          >
            <CloseIcon />
            {intl.get(`buttons.close`) || 'buttons.close'}
          </Button>
        }
        actionsPosition="center"
      />
    </>
  )
}

UserDetails.propTypes = {
  userName: PropTypes.string,
  userId: PropTypes.string,
  developerMode: PropTypes.bool,
  userGroup: PropTypes.string,
  userLanguage: PropTypes.string,
  suportedLocales: PropTypes.array,
  handleLanguageUpdate: PropTypes.func,
}

UserDetails.defaultProps = {
  userName: '',
  userId: '',
  developerMode: false,
  userLanguage: '',
  suportedLocales: [],
}
