import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '../../../styles'
import Select from 'react-select'
import { IconButton, FormControl } from '../../../core'
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '../../../icons'

const useStyles = makeStyles((theme) => ({
  containerBar: {
    width: '100%',
    border: `1px solid ${theme?.palette?.tertiary?.main}`,
    borderRadius: 5,
  },
  chart: {
    fontFamily: theme?.typography?.fontFamily,
  },
  label: {
    width: '95%',
    textAlign: 'right',
    paddingTop: 10,
    display: 'block',
    fontFamily: theme?.typography?.fontFamily,
    color: theme?.palette?.tertiary?.main,
    fontSize: '0.8em',
    textTransform: 'uppercase',
  },
  header: {
    height: 80,
    border: `1px solid ${theme?.palette?.tertiary?.main}`,
    marginBottom: 10,
  },
  margin: {
    marginTop: 7,
  },
  select: {
    height: 40,
    width: 200,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 5,
    marginTop: 2,
    backgroundColor: theme?.palette?.primary?.main,
  },
  monthSelect: {
    minWidth: 200,
  },
  iconButton: {
    margin: 5,
    display: 'block',
    color: theme?.palette?.tertiary?.main,
  },
  titleHeader: {
    color: theme?.palette?.tertiary?.main,
    margin: '0px auto',
  },
  display: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
}))

const SelectNavigation = ({
  handleButtonClick,
  selectNavigationIsLoading,
  selectNavigationListOptions,
  selectNavigationPlaceholder,
  selectNavigationDefaultValue,
}) => {
  const classes = useStyles()
  const firstUpdate = useRef(true)
  const [selectedValue, setSelectedValue] = useState(null)

  useEffect(() => {
    const selected = selectedValue?.value || ''
    if (
      selectNavigationDefaultValue &&
      selectNavigationListOptions &&
      (!selectedValue || selectNavigationDefaultValue !== selected)
    ) {
      const newSelected = selectNavigationListOptions.find((item) => {
        return item?.value === selectNavigationDefaultValue
      })
      setSelectedValue(newSelected)
    } else if (!selectNavigationDefaultValue) {
      setSelectedValue(null)
    }
  }, [])

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    if (typeof handleButtonClick === 'function') {
      handleButtonClick('SelectNavigation', selectedValue)
    }
  }, [selectedValue])

  const theme = useTheme()

  const customStyles = {
    option: (provided, providedState) => ({
      ...provided,
      fontWeight: providedState?.isSelected ? 'bold' : '',
      backgroundColor: theme?.palette?.secondary?.main,
      color: theme?.palette?.secondary?.contrastText,
    }),
    control: (provided, providedState) => ({
      ...provided,
      backgroundColor: theme?.palette?.secondary?.main,
      border: !providedState?.selectProps?.value?.value
        ? `1px solid ${theme?.palette?.secondary?.contrastText}`
        : `2px solid ${theme?.palette?.secondary?.contrastText}`,
      '&:hover': {
        border: !providedState?.selectProps?.value?.value
          ? `1px solid ${theme?.palette?.secondary?.contrastText}`
          : `2px solid ${theme?.palette?.secondary?.contrastText}`,
        '&:active': {
          border: !providedState?.selectProps?.value?.value
            ? `1px solid ${theme?.palette?.secondary?.contrastText}`
            : `2px solid ${theme?.palette?.secondary?.contrastText}`,
        },
      },
      color: theme?.palette?.secondary?.contrastText,
    }),
    placeholder: (provided, providedState) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
      opacity: !providedState?.selectProps?.value?.value ? '0.4' : 1,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
    }),
    singleValue: (provided) => ({
      ...provided,
      backgroundColor: theme?.palette?.secondary?.main,
      color: theme?.palette?.secondary?.contrastText,
    }),
    input: (provided) => ({
      ...provided,
      fontFamily: theme?.typography?.fontFamily,
      backgroundColor: theme?.palette?.secondary?.main,
      color: theme?.palette?.secondary?.contrastText,
    }),
    menu: (provided) => ({
      ...provided,
      overflowY: 'auto',
      maxHeight: '400px',
      backgroundColor: theme?.palette?.secondary?.main,
      border: `1px solid ${theme?.palette?.secondary?.contrastText}`,
      borderRadius: '5px',
      fontSize: '1em',
      opacity: '1.0',
      zIndex: 9999,
    }),
  }

  const handleReduce = () => {
    if (
      selectNavigationListOptions &&
      Array.isArray(selectNavigationListOptions) &&
      selectedValue &&
      selectNavigationListOptions.indexOf(selectedValue) > 0
    ) {
      setSelectedValue(
        selectNavigationListOptions[
          selectNavigationListOptions.indexOf(selectedValue) - 1
        ]
      )
    }
  }

  const handleIncrease = () => {
    if (
      selectNavigationListOptions &&
      Array.isArray(selectNavigationListOptions) &&
      selectNavigationListOptions.indexOf(selectedValue) <
        selectNavigationListOptions.length - 1
    ) {
      setSelectedValue(
        selectNavigationListOptions[
          selectNavigationListOptions.indexOf(selectedValue) + 1
        ]
      )
    }
  }

  const handleChangeValue = (selectedItem) => {
    if (selectedItem) {
      setSelectedValue(selectedItem)
    } else {
      setSelectedValue(null)
    }
  }

  return (
    <div className={classes.display}>
      <IconButton
        role="button"
        component="span"
        onClick={handleReduce}
        className={classes.iconButton}
        disabled={
          !selectedValue ||
          (selectNavigationListOptions &&
            selectNavigationListOptions.indexOf(selectedValue) === 0) ||
          selectNavigationIsLoading
        }
      >
        <KeyboardArrowLeftIcon
          data-testid="leftIcon"
          disabled={
            !selectedValue ||
            (selectNavigationListOptions &&
              selectNavigationListOptions.indexOf(selectedValue) === 0) ||
            selectNavigationIsLoading
          }
        />
      </IconButton>
      <FormControl data-testid="form" className={classes.margin}>
        <div data-testid="select" title={selectedValue?.label}>
          <Select
            inputId="option"
            isClearable
            value={selectedValue}
            onChange={handleChangeValue}
            classNamePrefix={selectNavigationPlaceholder}
            placeholder={selectNavigationPlaceholder}
            options={selectNavigationListOptions}
            className={classes.select}
            styles={customStyles}
            isDisabled={selectNavigationIsLoading}
          />
        </div>
      </FormControl>
      <IconButton
        role="button"
        component="span"
        className={classes.iconButton}
        onClick={handleIncrease}
        disabled={
          (selectNavigationListOptions &&
            selectNavigationListOptions.indexOf(selectedValue) ===
              selectNavigationListOptions.length - 1) ||
          selectNavigationIsLoading
        }
      >
        <KeyboardArrowRightIcon
          data-testid="rightIcon"
          disabled={
            (selectNavigationListOptions &&
              selectNavigationListOptions.indexOf(selectedValue) ===
                selectNavigationListOptions.length - 1) ||
            selectNavigationIsLoading
          }
        />
      </IconButton>
    </div>
  )
}

SelectNavigation.defaultProps = {
  handleButtonClick: () => {},
}

SelectNavigation.propTypes = {
  handleButtonClick: PropTypes.func.isRequired,
}

export default SelectNavigation
