import { normalizeReportsData } from './Normalizers'
import ENDPOINTS from './ENDPOINTS'
import { useFetchAxiosWrapper } from '../useFetchAxiosWrapper'
import { useState, useEffect } from 'react'
import _ from 'lodash'
import { useReportSize } from './useReportSize'
import { useReportTotal } from './useReportTotal'
import moment from 'moment'

const initialState = {
  company: {
    id: 'companyIdList',
    filterBy: [{ label: 'Lyra Network', value: 1 }],
  },
  dataType: {
    id: 'dataType',
    filterBy: 'saleValue',
  },
  year: {
    id: 'year',
    filterBy: moment().year(),
  },
  cumulative: false,
  category: {
    id: 'categoryId',
    filterBy: undefined,
  },
  product: {
    id: 'productId',
    filterBy: undefined,
  },
  // commercial: {
  //   id: 'commercialId',
  //   filterBy: undefined,
  // },
  customer: {
    id: 'customerId',
    filterBy: undefined,
  },

  groupBy: ['productId'],
  order: undefined,
  orderBy: undefined,
  page: 0,
  rowsPerPage: 50,
}

const columns = [
  'companyId',
  'customerId',
  'categoryId',
  // 'commercialId',
  'productId',
  'dataType',
  'yearTotal',
  'data1',
  'data2',
  'data3',
  'data4',
  'data5',
  'data6',
  'data7',
  'data8',
  'data9',
  'data10',
  'data11',
  'data12',
]

export const possibleFilters = [
  'companyId',
  'customerId',
  'categoryId',
  // 'commercialId',
  'productId',
]

export const useReportData = () => {
  const [data, setData] = useState([])
  const reportSize = useReportSize({})
  const reportTotal = useReportTotal({})

  const { patchRequest, fetchPATCH } = useFetchAxiosWrapper({
    baseEndpoint: ENDPOINTS.PATCH.billing_reports,
    callOnInit: false,
  })

  const [filters, setFilters] = useState(initialState)
  const [filteredColumns, setFilteredColumns] = useState(columns)
  const [body, setBody] = useState({})

  const filterColumn = () => {
    const items = columns.filter((item) => {
      const validId = possibleFilters.includes(item)
      if (validId) {
        const validGroup = filters.groupBy?.includes(item)
        return validGroup
      }
      return true
    })
    setFilteredColumns(items)
  }

  useEffect(() => {
    filterColumn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!_.isEqual(patchRequest.data, data)) {
      setData(patchRequest.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchRequest])

  const updateDataBody = () => {
    return {
      year: filters.year?.filterBy,
      productId: filters.product?.filterBy?.value,
      dataType: filters.dataType?.filterBy,
      categoryId: filters.category?.filterBy?.value,
      companyIdList: filters.company?.filterBy?.map((company) => company.value),
      commercialIdList: filters.commercial?.filterBy?.map(
        (commercial) => commercial.value
      ),
      customerIdList: filters.customer?.filterBy?.map(
        (customer) => customer.value
      ),
      groupBy: filters.groupBy.length >= 1 ? filters.groupBy : null,
      orderBy: filters.orderBy,
      order: filters.order,
      page: filters.page + 1,
      limit: filters.rowsPerPage,
      cumulative: filters.cumulative,
    }
  }

  const applyFilters = (_) => {
    // aplica os filtros passando os parameters no body

    setBody(updateDataBody)
  }

  const reportRequests = () => {
    reportTotal.handlePATCHTotal(body)
    reportSize.handlePATCHSize(body)
    fetchPATCH({ body, disableCompanyId: true })
    // chamada da função das colunas
    filterColumn()
  }

  useEffect(() => {
    if (!_.isEmpty(body)) {
      reportRequests()
    }
  }, [body])

  const cleanFilters = () => {
    setFilters(initialState)
  }

  const updateCumulative = (value) => {
    setFilters({
      ...filters,
      cumulative: value,
    })
  }

  const updateFilters = (newFilter, toUpdate) => {
    let tableToBeUpdated = JSON.parse(JSON.stringify(filters))
    tableToBeUpdated[toUpdate].filterBy = newFilter

    setFilters({
      ...filters,
      [toUpdate]: {
        ...filters[toUpdate],
        filterBy: _.isEmpty(newFilter) ? undefined : newFilter,
      },
    })
  }

  const handleRemoveFilter = (newFilter, toUpdate) => {
    let tableToBeUpdated = JSON.parse(JSON.stringify(filters))
    tableToBeUpdated[toUpdate].filterBy = newFilter
    const newFiltersList = {
      ...filters,
      [toUpdate]: {
        ...filters[toUpdate],
        filterBy: newFilter,
      },
    }
    setFilters(newFiltersList)
    setBody(updateDataBody)
  }

  const updateGrouping = (newAgroup, isChecked) => {
    let tableToBeAgruped = JSON.parse(JSON.stringify(filters))
    tableToBeAgruped.groupBy = isChecked
      ? [...tableToBeAgruped.groupBy, newAgroup]
      : tableToBeAgruped.groupBy.filter((item) => item !== newAgroup)
    setFilters(tableToBeAgruped)
  }

  const updateSorting = (property) => {
    const isAsc = filters.orderBy === property && filters.order === 'desc'
    setFilters({
      ...filters,
      orderBy: property,
      order: isAsc ? 'asc' : 'desc',
      page: initialState.page,
    })
  }

  const updatePage = (p) => {
    setFilters({
      ...filters,
      page: p,
    })
  }

  const updateRowsPerPage = (value) => {
    setFilters({
      ...filters,
      rowsPerPage: value,
      page: initialState.page,
    })
  }

  return {
    loading: patchRequest.loading || reportTotal.loading || reportSize.loading,
    data: normalizeReportsData({ data: data }),
    updateFilters,
    updateCumulative,
    updateGrouping,
    updateSorting,
    updatePage,
    updateRowsPerPage,
    body,
    filters,
    cleanFilters,
    applyFilters,
    size: reportSize.data,
    total: reportTotal.data,
    filteredColumns,
    handleRemoveFilter,
  }
}
