import { makeStyles } from '../../../styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    paddingTop: 0,
    paddingBottom: 10,
    borderRadius: 5,
  },
  listItem: {
    backgroundColor: theme?.palette?.secondary?.main,
    borderRadius: 5,
  },
  listItemOpacity: {
    margin: 10,
    border: `1px solid ${theme?.palette?.secondary?.contrastText}`,
    opacity: '0.3',
    borderRadius: 5,
  },
  listItemStatus: {
    width: '100%',
    maxHeight: 150,
    overflow: 'auto',
  },
  formControl: {
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
  },
  iconButton: {
    color: theme?.palette?.secondary?.contrastText,
  },
  titleGroup: {
    color: theme?.palette?.secondary?.contrastText,
    fontWeight: 'bold',
  },
  cardHeader: {
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRight: `1px solid ${theme?.palette?.secondary?.contrastText}`,
  },
  cardListGroup: {
    height: '100%',
    backgroundColor: theme?.palette?.secondary?.main,
  },
  title: {
    marginTop: 5,
    display: 'flex',
  },
  subTitleCompany: {
    width: '100%',
    color: theme?.palette?.secondary?.contrastText,
    fontWeight: 'bold',
  },
  subTitle: {
    color: theme?.palette?.secondary?.contrastText,
  },
  subTitleValue: {
    fontWeight: 'bold',
    color: theme?.palette?.secondary?.contrastText,
  },
  tableRow: {
    fontSize: 14,
    borderBottom: `1px solid ${theme?.palette?.secondary?.contrastText}`,
  },
  tableCell: {
    fontSize: 14,
  },
  tableNotRow: {
    border: 'none',
    width: '80px',
  },
  tableNotRowValue: {
    border: 'none',
    fontWeight: 'bolder',
    width: '100%',
    whiteSpace: 'wrap',
  },
  tableDetailsValue: {
    border: 'none',
    fontWeight: 'bolder',
    maxWidth: 50,
    whiteSpace: 'wrap',
  },
  titleDetails: {
    textAlign: 'center',
    width: '100%',
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.tertiary?.main,
  },
  titleSlices: {
    textAlign: 'center',
    width: '100%',
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.tertiary?.main,
    marginTop: 10,
    marginBottom: 10,
  },
  stepper: {
    padding: '0 20px',
    width: '100%',
  },
  listClients: {
    margin: '10px auto',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  tabDetails: {
    color: theme?.palette?.tertiary?.main,
    fontWeight: 'bolder',
    height: 20,
    margin: '0px 10px',
  },
}))
