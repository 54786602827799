import useCallAPI from './useCallAPI'

const filterBody = ({ name }) => ({
  name,
})

export const useSubproductsData = () => {
  const {
    reset,
    requestSuccess,
    requestLoading,
    requestData,
    fetchGET,
    fetchPOST,
  } = useCallAPI({
    initialCall: false,
    context: 'subproduct_results',
  })

  const handleGET = (params) => {
    fetchGET({ userParams: { ...params } })
  }

  const handlePOST = (params) => {
    fetchPOST({
      userBody: filterBody(params),
    })
  }

  return {
    reset,
    state: requestData,
    success: requestSuccess,
    loading: requestLoading,
    handleGET,
    handlePOST,
  }
}
