import { normalizeConciliationData } from './Normalizers'
import ENDPOINTS from './ENDPOINTS'
import { useFetchAxiosWrapper } from '../useFetchAxiosWrapper'
import { useState, useEffect } from 'react'
import _ from 'lodash'
import { useConciliationGPRSSize } from './useConciliationGPRSSize'
import axios from 'axios'
import { createHeaders } from '../../utils/utilsAPI'

const initialState = {
  page: 0,
  rowsPerPage: 50,
}

export const useConciliationGPRSData = (params) => {
  const { callOnInit = false } = params || {}
  const conciliationSize = useConciliationGPRSSize({})
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState(initialState)
  const { patchRequest, deleteRequest, fetchPATCH, fetchDELETE, postRequest } =
    useFetchAxiosWrapper({
      baseEndpoint: ENDPOINTS.GET.conciliation_GPRS_resumes,
      callOnInit,
    })
  const [isLoading, setIsLoading] = useState(postRequest.loading)
  const [isSuccess, setIsSuccess] = useState(postRequest.success)

  useEffect(() => {
    if (!_.isEqual(patchRequest.data, data)) {
      setData(patchRequest.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchRequest])

  useEffect(() => {
    setIsLoading(postRequest.loading)
    setIsSuccess(postRequest.success)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postRequest.loading, postRequest.success])

  const getResumes = (_) => {
    const body = {
      page: pagination.page + 1,
      limit: pagination.rowsPerPage,
    }

    fetchPATCH({ body: body })
    conciliationSize.handlePATCHSize(body)
  }

  const updatePage = (p) => {
    setPagination({
      ...pagination,
      page: p,
    })
  }

  const updateRowsPerPage = (value) => {
    setPagination({
      ...pagination,
      rowsPerPage: value,
      page: initialState.page,
    })
  }

  /** @function handlePOSTUploadFiles */
  //Upload de arquivos
  const handlePOSTUploadFiles = async (formData) => {
    const url = `${process.env.REACT_APP_API_URL}${ENDPOINTS.POST.conciliation_GPRS_readInvoices}`

    const response = await axios.post(url, formData, {
      headers: {
        ...createHeaders(),
        'Content-Type': 'multipart/form-data',
      },
    })
    return response
  }

  /** @function handlePOSTProcessCounter */
  //Envia o número total do contador do upload de arquivos para iniciar o processo no firebase
  const handlePOSTProcessCounter = async (body) => {
    const url = `${process.env.REACT_APP_API_URL}${ENDPOINTS.POST.process_counter}`

    const response = await axios.post(url, body, {
      headers: {
        ...createHeaders(),
        'Content-Type': 'application/json',
      },
    })
    return response
  }

  const handleDELETEClaroInvoice = (data = {}) => {
    fetchDELETE({
      forceEndpoint: ENDPOINTS.DELETE.conciliation_delete_claro_invoice,
      body: data,
      noRefreshData: true,
    })
  }

  const handleDELETEFilesUploaded = (data = {}) => {
    fetchDELETE({
      forceEndpoint: ENDPOINTS.DELETE.conciliation_delete_claro_invoice,
      body: data,
      noRefreshData: true,
    })
  }

  return {
    data: normalizeConciliationData({ data: data }),
    patchRequest,
    postRequest,
    pagination,
    deleteRequest,
    loading: isLoading,
    success: isSuccess,
    size: conciliationSize.data,
    getResumes,
    updatePage,
    updateRowsPerPage,
    handlePOSTUploadFiles,
    handleDELETEClaroInvoice,
    handlePOSTProcessCounter,
    handleDELETEFilesUploaded,
  }
}
