import React, { useEffect, useState } from 'react'
import moment from 'moment'
import _ from 'lodash'
import NumberFormat from 'react-number-format'
import {
  CircularProgress,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '../../core'
import {
  AccountBalance as AccountBalanceIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Warning as WarningIcon,
} from '../../icons'
import { makeStyles } from '../../styles'
import { convertHexToRGBA } from '../../utils'
import { intl } from '../../translation'

const useStyles = makeStyles((theme) => ({
  rootNotHaveAccess: {
    width: (props) => props.sbWidth || '100%',
    height: (props) => (props.sbWidth ? '100vh' : 'calc(100vh - 160px)'),
    paddingTop: 0,
    paddingBottom: 10,
    paddingRight: 5,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    overflowY: 'auto',
    backgroundColor: convertHexToRGBA(
      theme?.palette?.secondary?.hoverContrast,
      0.2
    ),
    borderTop: `2px solid ${theme?.palette?.quaternary?.contrastText}`,
  },
  listItem: {
    margin: 5,
    border: `1px solid ${theme?.palette?.secondary?.contrastText}`,
    backgroundColor: theme?.palette?.secondary?.main,
    borderRadius: 5,
  },
  listItemOpacity: {
    margin: 5,
    border: `1px solid ${theme?.palette?.secondary?.contrastText}`,
    opacity: '0.3',
    borderRadius: 5,
  },
  listItemStatus: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    paddingLeft: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
  warning: {
    display: 'block',
    color: 'red',
  },
  iconButton: {
    color: theme?.palette?.secondary?.contrastText,
    cursor: 'pointer',
  },
  subTitle: {
    width: '100%',
    color: theme?.palette?.secondary?.contrastText,
  },
  divLoading: {
    height: 480,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingTop: '250px',
  },
  emptyTitle: {
    width: '100%',
    textAlign: 'center',
    marginTop: '40%',
  },
}))

export const SideList = (props) => {
  const {
    onCommercialCardClick,
    handlePaymentClick,
    list,
    commissionSelected,
    hasPaymentAction,
    isLoading,
  } = props

  let { currencyData } = props

  currencyData =
    !currencyData || _.isEmpty(currencyData)
      ? {
          symbol: 'R$ ',
          thousandSeparator: '.',
          decimalSeparator: ',',
        }
      : currencyData

  const classes = useStyles(props)
  const [selectedValue, setSelectedValue] = useState({})

  useEffect(() => {
    if (commissionSelected) {
      setSelectedValue(commissionSelected)
    }
  }, [commissionSelected])

  const handleListItemClick = (item) => {
    if (
      typeof onCommercialCardClick === 'function' &&
      JSON.stringify(selectedValue) !== JSON.stringify(item)
    ) {
      setSelectedValue(item)
      onCommercialCardClick(item)
    } else {
      setSelectedValue(null)
      typeof onCommercialCardClick === 'function' && onCommercialCardClick({})
    }
  }

  const handlePayment = () => {
    if (typeof handlePaymentClick === 'function') {
      handlePaymentClick()
    } else {
    }
  }

  return (
    <>
      <List
        role="list"
        dense
        className={classes.rootNotHaveAccess}
        id="panelMessages"
      >
        {list && !isLoading ? (
          Array.isArray(list) && !_.isEmpty(list) ? (
            list.map((value, index) => {
              const labelId = `list-secondary-label-${value.expectedDueDate}_${value?.commercial?.id}`
              return (
                <div
                  key={`key_${labelId}`}
                  className={
                    selectedValue &&
                    !_.isEmpty(selectedValue) &&
                    !_.isEqual(selectedValue, value)
                      ? classes.listItemOpacity
                      : classes.listItem
                  }
                >
                  <ListItem
                    data-testId="listItem"
                    primaryTypographyProps={{
                      role: 'listitem',
                    }}
                    button
                    selected={_.isEqual(selectedValue, value)}
                    onClick={() => handleListItemClick(value)}
                    style={{ margin: 0 }}
                  >
                    <ListItemText
                      id={labelId}
                      primary={
                        <>
                          {!value.paid &&
                            moment(value.expectedDueDate) < moment() &&
                            hasPaymentAction && (
                              <Typography
                                variant="label"
                                className={classes.description}
                              >
                                <div className={classes.warning}>
                                  <Tooltip
                                    title={
                                      intl.get('phrases.latePayment') ||
                                      'phrases.latePayment'
                                    }
                                  >
                                    <WarningIcon data-testid="warningIcon" />
                                  </Tooltip>
                                </div>
                              </Typography>
                            )}

                          <Typography
                            variant="label"
                            className={classes.description}
                          >
                            {`${intl.get('words.payment') || 'words.payment'} `}
                            <span style={{ fontWeight: 'bold' }}>
                              {moment(value.expectedDueDate)
                                .format('MMMM/YYYY')
                                .toUpperCase()}
                            </span>
                          </Typography>
                        </>
                      }
                      secondary={
                        <span>
                          {value?.commercialName && (
                            <>
                              <Typography
                                variant="label"
                                className={classes.subTitle}
                              >
                                {`${
                                  intl.get('words.commercial') ||
                                  'words.commercial'
                                }: `}
                                <span style={{ fontWeight: 'bold' }}>
                                  {value?.commercialName}
                                </span>
                              </Typography>
                              <br />
                            </>
                          )}
                          {value?.commercial?.id && (
                            <>
                              <Typography
                                variant="label"
                                className={classes.subTitle}
                              >
                                {`${
                                  intl.get('words.initials') || 'words.initials'
                                }: `}
                                <span style={{ fontWeight: 'bold' }}>
                                  {value?.commercial?.id}
                                </span>
                              </Typography>
                              <br />
                            </>
                          )}
                          <Typography
                            variant="label"
                            className={classes.subTitle}
                          >
                            {`${intl.get('words.value') || 'words.value'}: `}
                          </Typography>
                          <br />
                          {value?.values &&
                            !_.isEmpty(value?.values) &&
                            value?.values?.map((currencyValue) => {
                              return (
                                <>
                                  <Typography
                                    style={{ marginLeft: 10 }}
                                    variant="body2"
                                  >
                                    {`${currencyValue?.currencyCode}: `}
                                    <NumberFormat
                                      displayType="text"
                                      prefix={currencyValue?.currency?.symbol}
                                      thousandSeparator={
                                        currencyData?.thousandSeparator
                                      }
                                      decimalSeparator={
                                        currencyData?.decimalSeparator
                                      }
                                      allowNegative={false}
                                      isNumericString
                                      decimalScale={2}
                                      fixedDecimalScale
                                      allowLeadingZeros
                                      value={currencyValue?.value}
                                      style={{
                                        color:
                                          currencyValue?.value >= 0
                                            ? 'green'
                                            : 'red',
                                      }}
                                    />
                                  </Typography>
                                </>
                              )
                            })}
                          <Typography
                            variant="label"
                            className={classes.subTitle}
                          >
                            <span style={{ width: '40%' }}>{`${
                              intl.get('words.situation') || 'words.situation'
                            }: `}</span>
                            <span style={{ width: '10%', textAlign: 'right' }}>
                              <div
                                style={{
                                  backgroundColor: value?.paid
                                    ? 'green'
                                    : 'blue',
                                  width: 10,
                                  height: 10,
                                  borderRadius: 10,
                                  display: 'inline-block',
                                  marginLeft: 10,
                                  marginRight: 5,
                                }}
                              />
                            </span>
                            <span
                              data-testid="paymentSituation"
                              style={{
                                width: '50%',
                              }}
                            >{`${
                              value?.paid
                                ? intl.get('words.paid') || 'words.paid'
                                : intl.get('words.foreseen') || 'words.foreseen'
                            }`}</span>
                          </Typography>
                        </span>
                      }
                    />
                    <ListItemSecondaryAction
                      onClick={() => handleListItemClick(value)}
                    >
                      {_.isEqual(selectedValue, value) && (
                        <KeyboardArrowRightIcon
                          data-testid="arrowIcon"
                          className={classes.iconButton}
                        />
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>

                  <div className={classes.listItemStatus}>
                    {_.isEqual(selectedValue, value) &&
                      !value.paid &&
                      hasPaymentAction && (
                        <FormControlLabel
                          control={
                            <Tooltip
                              title={
                                intl.get('phrases.informPayment') ||
                                'phrases.informPayment'
                              }
                            >
                              <IconButton
                                data-testid="informPaymentIcon"
                                aria-label="add"
                                size="small"
                                style={{ marginLeft: 10 }}
                                onClick={() => handlePayment(value)}
                              >
                                <AccountBalanceIcon
                                  className={classes.iconButton}
                                />
                              </IconButton>
                            </Tooltip>
                          }
                        />
                      )}
                  </div>
                </div>
              )
            })
          ) : (
            <Typography variant="h6" className={classes.emptyTitle}>
              {intl.get('phrases.noInformation') || 'phrases.noInformation'}
            </Typography>
          )
        ) : (
          <td className={classes.divLoading} align="center">
            <CircularProgress role="progressbar" />
          </td>
        )}
      </List>
    </>
  )
}
