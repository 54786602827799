import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '../../../styles'
import { convertHexToRGBA } from '../../../utils'
import Row from './Row'

const useStyles = makeStyles((theme) => ({
  rowTotal: {
    height: 20,
    backgroundColor: convertHexToRGBA(theme?.palette?.primary?.main, 0.3),
  },
}))

const RowTotal = ({ className, ...restProps }) => {
  const classes = useStyles()

  return (
    <Row
      {...restProps}
      height={15}
      className={classNames(className, classes.rowTotal)}
    />
  )
}

RowTotal.defaultProps = {
  className: '',
}

RowTotal.propTypes = {
  className: PropTypes.string.isRequired,
}

export default RowTotal
