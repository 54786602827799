import React from 'react'
import PropTypes from 'prop-types'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

const BasicObject = ({ attribute, ...props }) => (
  <DataTypeProvider
    formatterComponent={({ value }) => (value ? value[attribute] : '')}
    {...props}
  />
)

BasicObject.defaultProps = {
  attribute: '',
}

BasicObject.propTypes = {
  attribute: PropTypes.string.isRequired,
}

export default BasicObject
