import { TableCell, TableRow } from '../../../core'
import React from 'react'
import { makeStyles } from '../../../styles'

const useStyles = makeStyles((theme) => ({
  headerCell: {
    textAlign: 'left',
    paddingRight: 16,
    paddingLeft: 16,
    color: theme?.palette?.primary?.main,
    backgroundColor: theme?.palette?.secondary?.main,
    fontWeight: 'bolder',
  },
  row: {
    height: 30,
    fontFamily: theme.typography.fontFamily,
    color: theme?.palette?.primary?.main,
  },
  userCell: {
    padding: 0,
    paddingRight: 16,
    paddingLeft: 16,
    textAlign: 'center',
    minWidth: 150,
    maxWidth: 150,
    backgroundColor: theme?.palette?.secondary?.main,
    color: theme?.palette?.primary?.main,
    fontWeight: 'bolder',
  },
}))

export const RowTitleHead = (props) => {
  const classes = useStyles()
  const { titleRow, userTitleRow, groupTitleRow } = props
  return (
    <TableRow key="active" className={classes.row}>
      <TableCell className={classes.headerCell}>{titleRow}</TableCell>
      <TableCell className={classes.userCell}>{userTitleRow}</TableCell>
      {groupTitleRow && (
        <TableCell className={classes.userCell}>{groupTitleRow}</TableCell>
      )}
    </TableRow>
  )
}
