import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

const Number = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) =>
      value ? (
        `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
      ) : (
        <span style={{ color: 'lightGray' }}>0</span>
      )
    }
    {...props}
  />
)

export default Number
