import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '../../../styles'
import { Typography } from '../../../core'

const useStyles = makeStyles((theme) => ({
  containerBar: {
    width: '100%',
    border: `1px solid ${theme?.palette?.tertiary?.main}`,
    borderRadius: 5,
  },
  chart: {
    fontFamily: theme.typography.fontFamily,
  },
  label: {
    width: '95%',
    textAlign: 'right',
    paddingTop: 10,
    display: 'block',
    fontFamily: theme.typography.fontFamily,
    color: theme?.palette?.tertiary?.main,
    fontSize: '0.8em',
    textTransform: 'uppercase',
  },
  header: {
    height: 80,
    border: `1px solid ${theme?.palette?.tertiary?.main}`,
    marginBottom: 10,
  },
  margin: {
    marginTop: 7,
  },
  select: {
    height: 40,
    width: 250,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 5,
    marginTop: 2,
    backgroundColor: theme?.palette?.primary?.main,
  },
  monthSelect: {
    minWidth: 200,
  },
  iconButton: {
    margin: 5,
    display: 'block',
    color: theme?.palette?.tertiary?.main,
  },
  titleHeader: {
    color: theme?.palette?.tertiary?.main,
    margin: '0px auto',
  },
  display: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
}))

const TitleDetails = ({ titleDetailsText }) => {
  const classes = useStyles()
  return (
    <div className={classes.display}>
      <Typography
        variant="subtitle1"
        display="block"
        className={classes.titleHeader}
      >
        {titleDetailsText || 'titleDetailsText'}
      </Typography>
    </div>
  )
}

TitleDetails.defaultProps = {
  titleDetailsText: '',
}

TitleDetails.propTypes = {
  titleDetailsText: PropTypes.string,
}

export default TitleDetails
