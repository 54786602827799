import { Typography, Button, Collapse, Paper } from '../../core'
import { makeStyles } from '../../styles'
import {
  Business as BusinessIcon,
  ArrowDropDown,
  ArrowDropUp,
} from '../../icons'
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    color: theme?.palette?.secondary?.contrastText,
  },
  iconStyle: {
    marginRight: '8px',
  },
  button: {
    minWidth: 200,
    textDecoration: 'none',
    color: theme?.palette?.primary?.main,
    position: 'relative',
    padding: '10px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
  },
  paper: {
    position: 'absolute',
    left: 0,
    top: '100%',
    minWidth: '150px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
  },
  companyOption: {
    width: '100%',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    cursor: 'pointer',
    margin: '0',
    '&:hover': {
      fontWeight: 'bold',
    },
  },
}))

export const CompanyDetails = ({ name, companiesList, changeCompany }) => {
  const [showCompanies, setShowCompanies] = useState(false)
  const classes = useStyles()

  return (
    <>
      <div
        data-testid="companyTrigger"
        className={classes.button}
        onMouseEnter={() => setShowCompanies(true)}
        onMouseLeave={() => setShowCompanies(false)}
      >
        <BusinessIcon className={classes.iconStyle} />
        <Typography variant="body1" noWrap className={classes.pageTitle}>
          {name}
        </Typography>
        {companiesList?.length >= 2 && (
          <>
            {!showCompanies ? <ArrowDropDown /> : <ArrowDropUp />}

            <Paper elevation={4} className={classes.paper}>
              <Collapse in={showCompanies}>
                {companiesList.map((company) => {
                  if (company.company.name !== name) {
                    return (
                      <Button
                        key={company.companyId}
                        className={classes.companyOption}
                        onClick={() => changeCompany(company.companyId)}
                      >
                        {`[${company?.company.country}] ${company?.company.name}`}
                      </Button>
                    )
                  }
                })}
              </Collapse>
            </Paper>
          </>
        )}
      </div>
    </>
  )
}
