import React from 'react'
import PropTypes from 'prop-types'
import { intl } from '../../translation'

const NoData = ({ className, colSpan }) => (
  <td className={className} colSpan={colSpan} align="center">
    {intl.get(`phrases.noData`)}
  </td>
)

NoData.propTypes = {
  className: PropTypes.any,
  colSpan: PropTypes.any,
}

export default NoData
