import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Image,
  Slide,
} from '../../core'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '../../styles'
import { convertHexToRGBA } from '../../utils'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  dialog: {
    marginBottom: '15vh',
  },
  dialogTitleMin: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    paddingTop: 40,
    margin: 0,
    height: 45,
    width: '100%',
  },
  dialogTitleMax: {
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
    paddingTop: 40,
    width: '100%',
    margin: 0,
    height: 70,
    transition: 'width 1s, height 1s, opacity 1s 1s',
  },
  dialogContent: {
    overflowY: 'hidden',
    transition: 'width 1s, height 1s, opacity 1s 1s',
    paddingTop: 0,
  },
  dialogActions: {
    padding: '0px 25px',
    marginBottom: 30,
    height: 30,
    textAlign: 'center',
    transition: 'width 1s, height 1s, opacity 1s 1s',
  },
  root: {
    flexGrow: 1,
    fontFamily: theme.typography.fontFamily,
    overflow: 'none',
    transition: 'width 1s, height 1s, opacity 1s 1s',
  },
  backdrop: {
    backgroundColor: convertHexToRGBA(theme.palette.primary.main, 0.5),
  },
  invisible: {
    opacity: 0,
    width: 0,
    height: 0,
    margin: 0,
    padding: 0,
  },
  paperPropsMax: {
    borderLeftWidth: 6,
    borderLeftColor: convertHexToRGBA(theme.palette.primary.main, 1),
    borderLeftStyle: 'solid',
    borderRightWidth: 6,
    borderRightColor: convertHexToRGBA(theme.palette.primary.main, 1),
    borderRightStyle: 'solid',
    overflow: 'hidden',
    width: 438,
    height: 390,
    transition: 'width 1s, height 1s, border-width 0.6s linear',
    backgroundColor: theme.palette.secondary.main,
  },
  paperPropsMin: {
    borderLeftWidth: 6,
    borderLeftColor: convertHexToRGBA(theme.palette.primary.main, 1),
    borderLeftStyle: 'solid',
    borderRightWidth: 6,
    borderRightColor: convertHexToRGBA(theme.palette.primary.main, 1),
    borderRightStyle: 'solid',
    overflow: 'hidden',
    width: 110,
    height: 120,
    backgroundColor: theme.palette.secondary.main,
  },
  borderDetail: {
    backgroundColor: convertHexToRGBA(theme.palette.primary.main, 1),
    width: '100%',
    height: 6,
    display: 'inline-block',
    margin: 0,
    padding: 0,
  },
  whiteMark: {
    width: '8%',
    backgroundColor: theme.palette.secondary.main,
    height: 6,
  },
  blueMark: {
    width: '50%',
    backgroundColor: convertHexToRGBA(theme.palette.primary.main, 0.7),
    height: 6,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

export const AnimatedDialog = (props) => {
  const { content, contentTitle, contentActions } = props
  const classes = useStyles()

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const delay = setTimeout(() => {
      setVisible(true)
    }, 1000)
    return () => {
      clearTimeout(delay)
    }
  }, [])

  return (
    <Dialog
      className={classes.dialog}
      open
      TransitionComponent={Transition}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{
        classes: {
          root: visible ? classes.paperPropsMax : classes.paperPropsMin,
        },
      }}
    >
      <div className={classes.borderDetail}>
        <div
          className={classes.whiteMark}
          style={{ marginLeft: '10%', width: '10%', marginRight: '80%' }}
        >
          <div
            className={classes.blueMark}
            style={{ marginLeft: '60%', width: '40%' }}
          />
        </div>
      </div>
      <DialogTitle
        data-testid="dialog-title-login"
        id="dialog-title-login"
        className={visible ? classes.dialogTitleMax : classes.dialogTitleMin}
      >
        {contentTitle}
      </DialogTitle>
      <DialogContent
        data-testid="dialog-content-login"
        id="dialog-content-login"
        className={visible ? classes.dialogContent : classes.invisible}
      >
        {content}
      </DialogContent>
      <DialogActions
        data-testid="dialog-actions-login"
        id="dialog-actions-login"
        className={visible ? classes.dialogActions : classes.invisible}
      >
        {contentActions}
      </DialogActions>
      <div className={classes.borderDetail}>
        <div
          className={classes.whiteMark}
          style={{ marginLeft: '80%', width: '10%', marginRight: '10%' }}
        >
          <div
            className={classes.blueMark}
            style={{ marginLeft: 0, width: '40%' }}
          />
        </div>
      </div>
    </Dialog>
  )
}

AnimatedDialog.propTypes = {
  content: PropTypes.any,
  contentTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.instanceOf(Image),
  ]),
  contentActions: PropTypes.any,
}

AnimatedDialog.defaultProps = {
  content: <></>,
  contentTitle: <></>,
  contentActions: <></>,
}
