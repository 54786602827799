import classNames from 'classnames'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  IconButton,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '../../core'
import {
  Delete as DeleteIcon,
  Done as DoneIcon,
  ExpandMore as ExpandMoreIcon,
  Publish as PublishIcon,
  Save as SaveIcon,
} from '../../icons'
import { makeStyles, withStyles } from '../../styles'
import { intl } from '../../translation'
import { convertHexToRGBA } from '../../utils'
import { FieldSelectSuggest } from './components/Fields'
import FiltersSaved from './components/FiltersSaved'
import CreateGroup from './CreateGroup'
import { createState } from './CreateState'
import { processFilters, verifyValue } from './ProcessFilters'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: 10,
    marginTop: 5,
    color: theme?.palette?.tertiary?.main,
  },
  title: {
    marginBottom: 0,
    padding: 0,
    marginTop: '3%',
    fontFamily: theme.typography.fontFamily,
    fontSize: '0.9em',
  },
  expansionPanel: {
    borderRadius: 5,
    marginTop: 5,
    backgroundColor: theme?.palette?.secondary?.main,
  },
  expansionPanelSummary: {
    paddingLeft: '10px',
    color: theme?.palette?.secondary?.contrastText,
    fontWeight: 'bold',
  },
  expansionPanelDetails: {
    display: 'block',
    paddingRight: '3%',
    paddingLeft: '3%',
    paddingTop: '0px',
    paddingBottom: '10px',
  },
  tabs: {
    backgroundColor: theme?.palette?.secondary?.main,
    color: theme?.palette?.secondary?.contrastText,
  },
  tabLabel: {
    color: theme?.palette?.secondary?.contrastText,
  },
  indicator: {
    backgroundColor: theme?.palette?.secondary?.contrastText,
  },
  newContainer: {
    width: (props) => (!props.sbWidth ? '100%' : props.sbWidth),
    margin: '0px auto',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'none',
  },
  newHeader: {
    height: 30,
    backgroundColor: theme?.palette?.secondary.main,
    color: theme?.palette?.secondary?.contrastText,
    textAlign: 'center',
  },
  newHeaderAdvanced: {
    height: 60,
    backgroundColor: theme?.palette?.secondary.main,
    color: theme?.palette?.secondary?.contrastText,
    textAlign: 'center',
  },
  newTabs: {
    height: 50,
    backgroundColor: theme?.palette?.secondary.main,
    color: theme?.palette?.secondary?.contrastText,
    textAlign: 'center',
  },
  newList: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    color: theme?.palette?.secondary?.contrastText,
    padding: 10,
    textAlign: 'center',
    overflowY: 'auto',
    alignItems: 'stretch',
    alignContent: 'stretch',
    height: 'calc(100vh - 180px)',
  },
  newListAdvanced: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    color: theme?.palette?.secondary?.contrastText,
    padding: 10,
    textAlign: 'center',
    overflowY: 'auto',
    alignItems: 'stretch',
    alignContent: 'stretch',
    height: 'calc(100vh - 210px)',
  },
  newMenuBottom: {
    height: 50,
    paddingTop: 7,
    backgroundColor: theme?.palette?.secondary?.main,
    color: theme?.palette?.tertiary?.main,
    textAlign: 'center',
  },
}))

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    marginTop: 7,
  },
  switchBase: {
    padding: 2,
    color: convertHexToRGBA(theme?.palette?.primary?.main, 0.5),
    '&$checked': {
      transform: 'translateX(12px)',
      color: convertHexToRGBA(theme?.palette?.primary?.main, 0.5),
      '& + $track': {
        backgroundColor: theme?.palette?.secondary?.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    height: '100%',
    border: `1px solid ${convertHexToRGBA(theme?.palette?.primary?.main, 0.5)}`,
    borderRadius: 8,
    opacity: 1,
    backgroundColor: theme?.palette?.secondary?.main,
  },
  checked: {},
}))(Switch)

const defaultAgroup = {
  defaultName: 'agroupTitle',
  value: '',
}

export const DynamicFilters = (props) => {
  const {
    agroupCall, // Função chamada quando o agrupamento simples é alterado
    agroupAdvancedCall, // Função chamada quando o agrupamento avançado é alterado
    fixedFilterAdvancedCall, // Função chamada quando o filtro fixo é alterado
    groupingPossibilities, // agroupPossibilitiesDetails, // Opções para o agrupamento simples
    groupingAdvancedPossibilities, // agroupAdvancedPossibilitiesDetails, // Opções para o agrupamento avançado
    deleteCall, // Função chamada para o botão de limpar
    exportCall, // Função chamada para o botão de exportar
    saveCall, // Função chamada para o botão de salvar
    filterPossibilities, // Opções de filtros simples
    filterAdvancedPossibilities, // Opções de filtros avançados
    fixedFilterAdvancedPossibilities, // Opções de filtros fixos
    groupsPossibilities, // Opções de grupos para os filtros simples
    groupsAdvancedPossibilities, // Opções de grupos para os filtros avançados
    hasAdvanced, // Boolean para habilitar filtros avançados
    hasAgroup, // Boolean para habilitar agrupamento
    hasExport, // Boolean para habilitar exportação
    hasSave, // Boolean para habilitar salvar filtros
    hasFixedFilterAdvanced, // Boolean para habilitar filtros fixos,
    isFilterCleaning, // Função chamada para avisar se o set de filtros é uma limpeza do filtro
    loadFilterSavedCall, // Função para carregar informações do filtro armazenado
    savedFilters, // Função para carregar filtros armazenados
    fixedFilterAdvancedTitle, // Título para os filtros fixos
    screen, // Screen onde o filtro está montado,
    stateFiltersContext, // State Context dos filtros
    dispatchFiltersContext, // Dispatch Context dos filtros
    callAPISuggestion, // API function para obter os dados de sugestão dos inputs,
    resetFilters, // Reseta as colunas e conteúdo da tabela para a tabela resumida (filtros básicos)
    currencyData, // Informação da moeda selecionada no filtro pelo usuário
  } = props
  const firstAdvancedRender = useRef(true)
  const firstAgroupRender = useRef(true)
  const classes = useStyles(props)
  const [tab, setTab] = useState(0)
  const [state, setState] = useState({})
  const [version, setVersion] = useState(0)
  const [advanced, setAdvanced] = useState(false)
  const [agroup, setAgroup] = useState({ ...defaultAgroup })
  const [fixedFiltersAdvancedData, setFixedFiltersAdvancedData] = useState({})

  const [groupingOptions, setGroupingOptions] = useState([])
  const [groupingAdvancedOptions, setGroupingAdvancedOptions] = useState([])
  const [fixedFilterAdvancedOptions, setFixedFilterAdvancedOptions] = useState(
    []
  )

  const [antSwitchValue, setAntSwitchValue] = useState(0)

  const preDefinedListOptions = [
    {
      value: 'actualMonth',
      label: intl.get('phrases.actualMonth') || 'phrases.actualMonth',
    },
    {
      value: 'lastMonth',
      label: intl.get('phrases.lastMonth') || 'phrases.lastMonth',
    },
    {
      value: 'last30Days',
      label: intl.get('phrases.last30Days') || 'phrases.last30Days',
    },
    {
      value: 'lastThreeMonths',
      label: intl.get('phrases.lastThreeMonths') || 'phrases.lastThreeMonths',
    },
    {
      value: 'last90Days',
      label: intl.get('phrases.last90Days') || 'phrases.last90Days',
    },
    {
      value: 'lastSixMonths',
      label: intl.get('phrases.lastSixMonths') || 'phrases.lastSixMonths',
    },
    {
      value: 'last180Days',
      label: intl.get('phrases.last180Days') || 'phrases.last180Days',
    },
    {
      value: 'lastTwelveMonths',
      label: intl.get('phrases.lastTwelveMonths') || 'phrases.lastTwelveMonths',
    },
    {
      value: 'actualYear',
      label: intl.get('phrases.actualYear') || 'phrases.actualYear',
    },
  ]

  const validateDateShortcut = (value) => {
    switch (value) {
      case 'actualMonth':
        return { dateStart: moment().startOf('month'), dateEnd: moment() }
      case 'lastMonth':
        return {
          dateStart: moment().startOf('month').subtract(1, 'M'),
          dateEnd: moment().subtract(1, 'M'),
        }
      case 'last30Days':
        return { dateStart: moment().subtract(1, 'M'), dateEnd: moment() }
      case 'lastThreeMonths':
        return {
          dateStart: moment().startOf('month').subtract(2, 'M'),
          dateEnd: moment(),
        }
      case 'last90Days':
        return { dateStart: moment().subtract(3, 'M'), dateEnd: moment() }
      case 'lastSixMonths':
        return {
          dateStart: moment().startOf('month').subtract(5, 'M'),
          dateEnd: moment(),
        }
      case 'last180Days':
        return { dateStart: moment().subtract(6, 'M'), dateEnd: moment() }
      case 'actualYear':
        return {
          dateStart: moment().startOf('month').month('January'),
          dateEnd: moment(),
        }
      case 'lastTwelveMonths':
        return {
          dateStart: moment().subtract(12, 'M'),
          dateEnd: moment(),
        }
      default:
        return null
    }
  }

  useEffect(() => {
    if (advanced) {
      if (
        groupingAdvancedPossibilities?.length > 0 &&
        JSON.stringify(fixedFiltersAdvancedData) !== '{}'
      ) {
        const tempValues = groupingAdvancedPossibilities?.map((item) => {
          return {
            ...item,
            label: intl.get(`titles.${item?.label}`) || item?.label,
          }
        })
        return setGroupingAdvancedOptions(tempValues)
      }

      setGroupingAdvancedOptions([])
    } else if (groupingPossibilities?.length > 0) {
      const tempValues = groupingPossibilities?.map((item) => {
        return {
          ...item,
          label: intl.get(`titles.${item?.label}`) || item?.label,
        }
      })
      return setGroupingOptions(tempValues)
    }
  }, [groupingPossibilities, groupingAdvancedPossibilities, advanced])

  useEffect(() => {
    if (fixedFilterAdvancedPossibilities?.length > 0) {
      const tempValues = fixedFilterAdvancedPossibilities?.map((item) => {
        return {
          ...item,
          label: intl.get(`titles.${item?.label}`) || item?.label,
        }
      })
      return setFixedFilterAdvancedOptions(tempValues)
    } else {
      setFixedFilterAdvancedOptions([])
    }
  }, [fixedFilterAdvancedPossibilities])

  useEffect(() => {
    if (advanced) {
      mountState(filterAdvancedPossibilities)
      hasAgroup && setAgroup(defaultAgroup)
    } else {
      mountState(filterPossibilities)
      hasAgroup && setAgroup(defaultAgroup)
    }
  }, [advanced, filterAdvancedPossibilities])

  useEffect(() => {
    if (firstAdvancedRender?.current) {
      firstAdvancedRender.current = false
      return
    }
    dispatchFiltersContext({
      type: 'removeFilter',
      screen,
    })
  }, [advanced])

  /* Não apagar esse useEffect! É ele quem sincroniza o mês que está no monthNavigation com o valor
  mostrado pelos filtros de período mensal */
  useEffect(() => {
    if (stateFiltersContext?.[screen]?.state) {
      const mixedState = _.cloneDeep(state)
      const globalFilterValues = stateFiltersContext?.[screen]?.state
      Object.keys(globalFilterValues).forEach((key) => {
        if (mixedState[key]) {
          mixedState[key] = { ...mixedState[key], ...globalFilterValues[key] }
        }
      })
      !_.isEqual(mixedState, state) && setState({ ...mixedState })
    }
  }, [stateFiltersContext])

  useEffect(() => {
    if (firstAgroupRender?.current) {
      firstAgroupRender.current = false
      return
    }
    if (advanced) {
      if (
        agroup &&
        agroupAdvancedCall &&
        typeof agroupAdvancedCall === 'function' &&
        agroup !== defaultAgroup
      ) {
        agroupAdvancedCall(agroup)
      }
      return
    }

    if (
      agroup &&
      agroupCall &&
      typeof agroupCall === 'function' &&
      agroup !== defaultAgroup
    ) {
      agroupCall(agroup)
    }
  }, [agroup])

  useEffect(() => {
    if (typeof fixedFilterAdvancedCall === 'function') {
      if (JSON.stringify(fixedFiltersAdvancedData) !== '{}' && advanced) {
        fixedFilterAdvancedCall(fixedFiltersAdvancedData)
      } else {
        fixedFilterAdvancedCall({})
      }
    }
  }, [fixedFiltersAdvancedData])

  useEffect(() => {
    !_.isEmpty(filterAdvancedPossibilities) &&
      typeof resetFilters === 'function' &&
      resetFilters()
  }, [antSwitchValue])

  // Atualiza os valores da propriedade do state
  const updateState = (element) => {
    setState({
      ...state,
      [element?.defaultName]: {
        ...element,
      },
    })
  }

  // Envia os filtros para execução
  const mountFilter = (data = {}) => {
    let filtersReady = {}
    let filtersValidated = {}

    if (hasAdvanced && advanced) {
      filtersValidated = processFilters(filterAdvancedPossibilities, data)
    } else {
      filtersValidated = processFilters(filterPossibilities, data)
    }

    if (
      (agroup.value?.value || agroup.value?.value === 0) &&
      !_.isEmpty(data)
    ) {
      filtersValidated.groupBy = agroup.value?.value
    }

    filtersReady = {
      advanced,
      state: data,
      values: filtersValidated,
    }

    if (
      screen &&
      filtersReady?.values &&
      !_.isEmpty(filtersReady?.values) &&
      typeof dispatchFiltersContext === 'function'
    ) {
      dispatchFiltersContext({
        type: 'setFilter',
        screen,
        data: filtersReady,
      })
    }
  }

  // Solicita a limpeza dos filtros
  const cleanFilters = () => {
    typeof isFilterCleaning === 'function' && isFilterCleaning(true)
    stateFiltersContext?.isOpen === true &&
      typeof dispatchFiltersContext === 'function' &&
      dispatchFiltersContext({ type: 'clear' })
    emptyState()
    mountFilter({})
    if (screen && typeof dispatchFiltersContext === 'function') {
      dispatchFiltersContext({ type: 'removeFilter', screen })
    }
  }

  // Realiza a limpeza do state
  const emptyState = () => {
    if (
      advanced &&
      filterAdvancedPossibilities &&
      Array.isArray(filterAdvancedPossibilities)
    ) {
      const newState = {}

      filterAdvancedPossibilities.map((filter) => {
        if (!newState[filter?.defaultName]) {
          newState[filter?.defaultName] = createState(filter)
          return newState[filter?.defaultName]
        }
        return null
      })
      setState({ ...newState })
      setAgroup({ ...defaultAgroup, value: '', label: '' })
      setVersion(Math.random())
      return
    }
    if (
      !advanced &&
      filterPossibilities &&
      Array.isArray(filterPossibilities)
    ) {
      const newState = {}

      filterPossibilities.map((filter) => {
        if (!newState[filter?.defaultName]) {
          newState[filter?.defaultName] = createState(filter)
          return newState[filter?.defaultName]
        }
        return null
      })
      setState({ ...newState })
      setAgroup({ ...defaultAgroup, value: '', label: '' })
      setVersion(Math.random())
    }
  }

  // Monta o state do componente
  const mountState = (values) => {
    if (values && Array.isArray(values)) {
      const newState = {}

      values.map((filter) => {
        newState[filter?.defaultName] = createState(filter)
      })

      if (stateFiltersContext?.[screen]?.state) {
        const globalFilterValues = stateFiltersContext?.[screen]?.state
        const mixedState = { ...newState }
        Object.keys(globalFilterValues).forEach((key) => {
          if (mixedState[key]) {
            mixedState[key] = { ...mixedState[key], ...globalFilterValues[key] }
          }
        })
        setState({ ...mixedState })
        mountFilter(mixedState)
      } else {
        setState({ ...newState })
      }
    }
  }

  const handleChangeTypeFilter = (e) => {
    if (typeof dispatchFiltersContext === 'function') {
      dispatchFiltersContext({ type: 'removeFilter', screen })
    }
    hasFixedFilterAdvanced
      ? setFixedFiltersAdvancedData({})
      : setFixedFiltersAdvancedData('')
    setAdvanced(!advanced)
    antSwitchValue === 0 ? setAntSwitchValue(1) : setAntSwitchValue(0)
  }

  const handleLoadFilterSavedCall = (values) => {
    loadFilterSavedCall(values)
    setTab(0)
  }

  const handleAgroup = (values) => {
    setAgroup(values)
  }

  const handleFixed = (values) => {
    setFixedFiltersAdvancedData(values)
  }

  const handleSaveCall = () => {
    const newData = {}
    newData.state = state
    if (agroup) {
      newData.agroup = agroup
    }
    saveCall(newData)
  }

  const handleExportCall = () => {
    const newData = {}
    newData.state = state
    if (agroup) {
      newData.agroup = agroup
    }
    exportCall(newData)
  }

  const handleMountFilter = () => {
    stateFiltersContext?.isOpen === true &&
      typeof dispatchFiltersContext === 'function' &&
      dispatchFiltersContext({ type: 'clear' })
    mountFilter(state)
  }

  return (
    <div data-testid="dynamicFiltersContainer" className={classes.newContainer}>
      <div
        className={classNames(classes.newHeader, {
          [classes.newHeaderAdvanced]: hasAdvanced,
        })}
      >
        <Typography component="h3" variant="h6">
          {intl.get('words.filtersTitle') || 'words.filtersTitle'}
        </Typography>
        {hasAdvanced && (
          <Typography variant="subtitle1">
            <Grid container justify="center">
              <Grid item role="gridcell">
                {intl.get('words.basicTitle') || 'words.basicTitle'}
              </Grid>
              <Grid item style={{ marginLeft: 3, marginRight: 3 }}>
                <AntSwitch
                  inputProps={{
                    role: 'button',
                  }}
                  role="switch"
                  disabled={
                    fixedFilterAdvancedOptions &&
                    _.isEmpty(fixedFilterAdvancedOptions)
                  }
                  value={antSwitchValue}
                  checked={advanced}
                  onChange={handleChangeTypeFilter}
                />
              </Grid>
              <Grid item role="gridcell">
                {intl.get('words.advancedTitle') || 'words.advancedTitle'}
              </Grid>
            </Grid>
          </Typography>
        )}
      </div>
      <div className={classes.newTabs}>
        <Tabs
          role="tablist"
          centered
          value={tab}
          onChange={(e, value) => {
            setTab(value)
          }}
          className={classes.tabs}
          classes={{ indicator: classes.indicator }}
        >
          <Tab
            role="tab"
            label={intl.get('words.tabFilterTitle') || 'words.tabFilterTitle'}
            className={classes.tabLabel}
          />
          {hasSave && (
            <Tab
              role="tab"
              label={intl.get('words.tabStoredTitle') || 'words.tabStoredTitle'}
              className={classes.tabLabel}
            />
          )}
        </Tabs>
      </div>
      <div
        className={classNames(classes.newList, {
          [classes.newListAdvanced]: hasAdvanced,
        })}
      >
        {tab === 0 && (
          <>
            {advanced &&
              hasFixedFilterAdvanced &&
              fixedFilterAdvancedOptions &&
              Array.isArray(fixedFilterAdvancedOptions) &&
              fixedFilterAdvancedOptions.length > 0 && (
                <ExpansionPanel
                  className={classes.expansionPanel}
                  defaultExpanded
                  key="fixedFiltersAdvancedData"
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.expansionPanelSummary}
                  >
                    <span className={classes.title}>
                      {fixedFilterAdvancedTitle}
                    </span>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                    className={classes.expansionPanelDetails}
                  >
                    <div className={classes.listFilters}>
                      <FieldSelectSuggest
                        version={version}
                        key="fixedTitle"
                        filter={fixedFiltersAdvancedData}
                        onUpdate={handleFixed}
                        listOptions={fixedFilterAdvancedOptions}
                      />
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )}
            {hasAgroup && (
              <>
                {hasAdvanced && advanced
                  ? groupingAdvancedOptions &&
                    Array.isArray(groupingAdvancedOptions) &&
                    groupingAdvancedOptions.length > 0 && (
                      <ExpansionPanel
                        className={classes.expansionPanel}
                        defaultExpanded
                        key="group"
                      >
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          className={classes.expansionPanelSummary}
                        >
                          <span className={classes.title}>
                            {intl.get('words.agroupTitle') ||
                              'words.agroupTitle'}
                          </span>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          className={classes.expansionPanelDetails}
                        >
                          <div className={classes.listFilters}>
                            <FieldSelectSuggest
                              version={version}
                              key="agroupTitle"
                              filter={agroup}
                              onUpdate={handleAgroup}
                              listOptions={groupingAdvancedOptions}
                            />
                          </div>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    )
                  : groupingOptions &&
                    Array.isArray(groupingOptions) &&
                    groupingOptions.length > 0 &&
                    Object.keys(state).length > 0 && (
                      <ExpansionPanel
                        className={classes.expansionPanel}
                        defaultExpanded
                        key="group"
                      >
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          className={classes.expansionPanelSummary}
                        >
                          <span className={classes.title}>
                            {intl.get('words.agroupTitle') ||
                              'words.agroupTitle'}
                          </span>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          className={classes.expansionPanelDetails}
                        >
                          <div className={classes.listFilters}>
                            <FieldSelectSuggest
                              version={version}
                              key="agroupTitle"
                              filter={agroup}
                              onUpdate={handleAgroup}
                              listOptions={groupingOptions}
                            />
                          </div>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    )}
              </>
            )}
            {hasAdvanced && advanced
              ? groupsAdvancedPossibilities?.length > 0 &&
                Object.keys(state).length > 0 &&
                groupsAdvancedPossibilities?.map((group) => {
                  return (
                    <CreateGroup
                      key={group.name}
                      group={group.name}
                      groupTitle={group.name}
                      possibilities={filterAdvancedPossibilities}
                      version={version}
                      updateState={updateState}
                      state={state}
                      verifyValue={verifyValue}
                      preDefinedListOptions={preDefinedListOptions}
                      validateDateShortcut={validateDateShortcut}
                      callAPISuggestion={callAPISuggestion}
                      currencyData={currencyData}
                      {...props}
                    />
                  )
                })
              : groupsPossibilities?.length > 0 &&
                Object.keys(state).length > 0 &&
                groupsPossibilities?.map((group) => {
                  return (
                    <CreateGroup
                      {...props}
                      key={group.name}
                      group={group.name}
                      groupTitle={group.name}
                      possibilities={filterPossibilities}
                      version={version}
                      updateState={updateState}
                      state={state}
                      verifyValue={verifyValue}
                      preDefinedListOptions={preDefinedListOptions}
                      validateDateShortcut={validateDateShortcut}
                      callAPISuggestion={callAPISuggestion}
                      currencyData={currencyData}
                    />
                  )
                })}
          </>
        )}

        {tab === 1 && hasSave && (
          <FiltersSaved
            savedFilters={savedFilters}
            loadFilterSavedCall={handleLoadFilterSavedCall}
            deleteCall={deleteCall}
            loadingTitle={
              intl.get('words.loadingTitle') || 'words.loadingTitle'
            }
            noInformationTitle={
              intl.get('words.noInformationTitle') || 'words.noInformationTitle'
            }
            deleteTitle={intl.get('words.deleteTitle') || 'words.deleteTitle'}
          />
        )}
      </div>
      <div className={classes.newMenuBottom}>
        {tab === 0 && (
          <>
            <Tooltip title={intl.get('words.applyTitle') || 'words.applyTitle'}>
              <IconButton
                data-testid="applyButton"
                role="button"
                className={classes.button}
                color="default"
                onClick={handleMountFilter}
                size="small"
              >
                <DoneIcon data-testid="doneIconButton" />
              </IconButton>
            </Tooltip>
            <Tooltip title={intl.get('words.cleanTitle') || 'words.cleanTitle'}>
              <IconButton
                data-testid="deleteButton"
                role="button"
                className={classes.button}
                color="default"
                onClick={cleanFilters}
                size="small"
              >
                <DeleteIcon data-testid="deleteIconButton" />
              </IconButton>
            </Tooltip>
            {hasSave && saveCall && (
              <Tooltip title={intl.get('words.saveTitle') || 'words.saveTitle'}>
                <IconButton
                  className={classes.button}
                  color="default"
                  onClick={handleSaveCall}
                  size="small"
                >
                  <SaveIcon />
                </IconButton>
              </Tooltip>
            )}

            {hasExport && exportCall && (
              <Tooltip
                title={intl.get('words.exportTitle') || 'words.exportTitle'}
              >
                <IconButton
                  className={classes.button}
                  color="default"
                  onClick={handleExportCall}
                  size="small"
                >
                  <PublishIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </div>
    </div>
  )
}

DynamicFilters.propTypes = {
  agroupCall: PropTypes.func,
  agroupAdvancedCall: PropTypes.func,
  groupingAdvancedPossibilities: PropTypes.array,
  agroupAdvancedPossibilitiesDetails: PropTypes.array,
  groupingPossibilities: PropTypes.array,
  agroupPossibilitiesDetails: PropTypes.array,
  deleteCall: PropTypes.func,

  exportCall: PropTypes.func,

  filterAdvancedPossibilities: PropTypes.array,
  fixedFilterAdvancedPossibilities: PropTypes.array,
  filterPossibilities: PropTypes.array,
  groupsAdvancedPossibilities: PropTypes.array,
  groupsPossibilities: PropTypes.array,
  hasAdvanced: PropTypes.bool,
  hasAgroup: PropTypes.bool,
  hasExport: PropTypes.bool,
  hasSave: PropTypes.bool,
  loadFilterSavedCall: PropTypes.func,
  saveCall: PropTypes.func,
  savedFilters: PropTypes.array,
}

DynamicFilters.defaultProps = {
  agroupCall: () => {},
  agroupAdvancedCall: () => {},
  groupingAdvancedPossibilities: [],
  agroupAdvancedPossibilitiesDetails: [],
  groupingPossibilities: [],
  agroupPossibilitiesDetails: [],
  deleteCall: () => {
    console.log('delete call not found')
  },
  exportCall: () => {
    console.log('export call not found')
  },
  filterAdvancedPossibilities: [],
  fixedFilterAdvancedPossibilities: [],
  filterPossibilities: [],
  groupsAdvancedPossibilities: [],
  groupsPossibilities: [],
  hasAdvanced: false,
  hasAgroup: false,
  hasExport: false,
  hasSave: false,
  loadFilterSavedCall: () => {
    console.log('load filter saved call not found')
  },
  saveCall: () => {
    console.log('save call not found')
  },
  savedFilters: [],
}
