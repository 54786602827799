import React from 'react'
import PropTypes from 'prop-types'
import ReactImage from '../Shared/ReactImage'

const Loading = ({ theme, height }) => {
  return (
    <ReactImage
      src={
        theme && theme === 'dark'
          ? '/img/loadingLyraDark.svg'
          : '/img/loadingLyra.svg'
      }
      alt="LoadingLyra"
      height={height}
    />
  )
}

Loading.propTypes = {
  theme: PropTypes.string.isRequired,
  height: PropTypes.number,
}

Loading.defaultProps = {
  height: 500,
}

export default Loading
