import { DataTypeProvider } from '@devexpress/dx-react-grid'
import React from 'react'
import { IconButton } from '../../../../core'
import { Description as DescriptionIcon } from '../../../../icons'

export const Invoice = (props) => {
  const { onInvoiceDownload } = props

  const handleInvoiceBillet = (value) => {
    if (onInvoiceDownload && typeof onInvoiceDownload === 'function') {
      onInvoiceDownload(value)
    } else {
      console.log(value, 'onInvoiceDownload is not defined or not a function')
    }
  }

  return (
    <DataTypeProvider
      formatterComponent={({ value }) => (
        <IconButton
          disabled={!value}
          color="primary"
          size="small"
          onClick={() => handleInvoiceBillet(value)}
        >
          <DescriptionIcon />
        </IconButton>
      )}
      {...props}
    />
  )
}

export default Invoice
