import { useFetchAxiosWrapper } from '../useFetchAxiosWrapper'
import ENDPOINTS from './ENDPOINTS'
import { normalizeFilterSettingsData } from './Normalizers'

const filterQuery = (values) => {
  return {
    groupBy: values?.groupBy,
    order: values?.order,
    orderBy: values?.orderBy,
    productDetails: values?.productDetails,
    detailed: values?.detailed,
    productId: values?.productId,
    cards: values?.cards,
    paymentMonth: values?.paymentMonth,
    year: values?.year,
    componentDetails: values?.componentDetails,
  }
}

export const useLibraryFilterSettings = ({ componentName, screen }) => {
  const contextParams = {
    type: 'component',
    screen,
  }
  const {
    reset,
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    fetchGET,
    fetchPUT,
  } = useFetchAxiosWrapper({
    baseEndpoint: `${ENDPOINTS.GET.preferences}/filters`,
    callOnInit: true,
    urlParameters: {
      ...contextParams,
    },
  })

  const handleGET = (params = {}) => {
    const newQuery = filterQuery(params)
    fetchGET({
      urlParameters: { ...contextParams, ...newQuery },
    })
  }

  const handlePUT = ({ newSettings, params, noRefreshData }) => {
    const newQuery = filterQuery(params)
    fetchPUT({
      urlParameters: { ...contextParams, ...newQuery },
      body: { ...newSettings },
      noRefreshData,
    })
  }

  return {
    getRequest: {
      ...getRequest,
      data: normalizeFilterSettingsData(getRequest),
    },
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    reset,
    handleGET,
    handlePUT,
  }
}
