import { makeStyles } from '../../styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    maxWidth: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    border: 'none',
  },
  containerList: {
    width: 270,
    height: 'calc(100vh - 50px)',
    borderRadius: 5,
    paddingLeft: 5,
    float: 'left',
    display: 'inline',
  },
  divPaper: {
    width: 'calc(100% - 50px)',
    padding: 15,
    textAlign: '-webkit-center',
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    border: 'none',
  },
  divPaperSidebarOpen: {
    width: 'calc(100% - 400px)',
  },
  toolBar: {
    order: 3,
    flexGrow: 2,
    width: 350,
    height: 'calc(100% - 40px)',
  },
  toolBarHidden: {
    order: 3,
    flexGrow: 2,
    width: 0,
    height: 'calc(100% - 40px)',
    display: 'none',
  },
  paper: {
    height: '100%',
    borderRadius: 5,
  },
}))
