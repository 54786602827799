import React from 'react'
import moment from 'moment'
import { intl } from '../../../../translation'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

/* Atenção: atualmente, esta lógica de cálculo conforme a timezone do usuário está sendo feita assim
porque o backend está enviando o horário de Greenwich */

const today = new Date()
const timezoneMinutes = today.getTimezoneOffset()
const timezoneHours = timezoneMinutes / 60

const Month = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) => {
      let formattedValue
      if (value) {
        formattedValue =
          timezoneMinutes > 0
            ? moment(value)
                .add(timezoneHours, 'hours')
                .format(intl.get(`default.date.monthYear`))
            : moment(value)
                .subtract(timezoneHours, 'hours')
                .format(intl.get(`default.date.monthYear`))
      } else {
        formattedValue = ''
      }
      return formattedValue
    }}
    {...props}
  />
)

export default Month
