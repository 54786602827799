import { DataTypeProvider } from '@devexpress/dx-react-grid'
import React from 'react'
import { intl } from '../../../../translation'

const BillingTypeVeripag = ({ ...props }) => {
  const translateText = (value) => {
    switch (value?.toString()) {
      case '0':
        return intl.get(`phrases.monthlyValue`)
      case '1':
        return intl.get(`phrases.minimumValue`)
      case '2':
        return intl.get(`phrases.uniqueValue`)
      default:
        return intl.get(`phrases.notRecognized`)
    }
  }

  return (
    <DataTypeProvider
      formatterComponent={({ value, ...props }) => translateText(value || '0')}
      {...props}
    />
  )
}

export default BillingTypeVeripag
