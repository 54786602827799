import { DataTypeProvider } from '@devexpress/dx-react-grid'
import moment from 'moment'
import React from 'react'

const DateShort = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) =>
      value ? moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
    }
    {...props}
  />
)

export default DateShort
