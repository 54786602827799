import React, { useState, useEffect } from 'react'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '../../../../core'
import { ArrowRight as ArrowRightIcon } from '../../../../icons'
import { intl } from '../../../../translation'
import { makeStyles } from '../../../../styles'
import classNames from 'classnames'
import { convertHexToRGBA } from '../../../../utils'

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderBottom: `1px solid ${theme?.palette?.quaternary?.main} !important`,
    backgroundColor: theme?.palette?.secondary?.main,
    color: theme?.palette?.secondary?.contrastText,
  },
  listItemSelected: {
    backgroundColor: `${convertHexToRGBA(
      theme?.palette?.primary?.main,
      0.3
    )} !important`,
  },
  listHeader: {
    color: theme?.palette?.primary?.main,
    fontWeight: 'bolder',
    height: 30,
    textAlign: 'left',
    paddingTop: 5,
  },
}))

export const ScreenList = ({
  screens,
  handleSelectedScreen,
  selectedScreen,
}) => {
  const classes = useStyles()

  const [sortedScreens, setSortedScreens] = useState([])

  useEffect(() => {
    const screensList = Object.keys(screens)
    const translatedScreensList = screensList.map((screenName) => ({
      name: screenName,
      translatedName: intl.get(`pages.${screenName}`) || screenName,
    }))
    const sortedScreensList = translatedScreensList.sort((a, b) => {
      if (a.translatedName < b.translatedName) {
        return -1
      } else {
        return 1
      }
    })
    setSortedScreens(sortedScreensList)
  }, [screens])
  return (
    <>
      <Typography className={classes.listHeader}>
        {intl.get(`words.screens`) || `words.screens`}
      </Typography>
      <List>
        {sortedScreens.map((screen) => {
          return (
            <ListItem
              role="listitem"
              key={screen.name}
              dense
              button
              onClick={() => handleSelectedScreen(screen.name)}
              selected={screen.name === selectedScreen}
              className={classNames(classes.listItem, {
                [classes.listItemSelected]: screen.name === selectedScreen,
              })}
            >
              <ListItemText
                id={`ListItemText${screen.name}`}
                primary={<Typography>{screen.translatedName}</Typography>}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  size="small"
                  onClick={() => handleSelectedScreen(screen.name)}
                >
                  <ArrowRightIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </List>
    </>
  )
}
