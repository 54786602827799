import PropTypes from 'prop-types'
import React from 'react'
import { IconButton, Tooltip } from '../../core'
import {
  SkipNext as SkipNextIcon,
  SkipPrevious as SkipPreviousIcon,
} from '../../icons'
import { makeStyles } from '../../styles'
import { intl } from '../../translation'

const useStyles = makeStyles((theme) => ({
  toolsBar: {
    paddingTop: 5,
    width: 50,
    order: 2,
    backgroundColor: theme?.palette?.secondary?.main,
    minHeight: `100%`,
  },
  fab: {
    margin: 5,
    marginTop: 10,
    padding: 5,
    backgroundColor: theme?.palette?.secondary?.main,
    color: theme?.palette?.tertiary?.main,
  },
  fabSelected: {
    margin: 5,
    marginTop: 10,
    padding: 5,
    backgroundColor: theme?.palette?.secondary?.main,
    color: theme?.palette?.tertiary?.main,
    border: `1px solid ${theme?.palette?.tertiary?.main}`,
  },
}))

export const ToolsBar = ({
  buttons,
  selected,
  inLoading,
  hiddenCall,
  isHidden,
  hasHidden,
  handleSelectButton,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.toolsBar}>
      <div style={{ height: 'calc(100% - 60px)' }}>
        {buttons?.map(
          (element) =>
            element?.isVisible && (
              <Tooltip
                data-testid="tooltip"
                key={element?.label}
                title={element?.label}
              >
                <IconButton
                  role="button"
                  size="small"
                  className={
                    selected &&
                    element?.text?.toLowerCase() === selected?.toLowerCase()
                      ? classes.fabSelected
                      : classes.fab
                  }
                  onClick={() => handleSelectButton(element?.text)}
                  aria-label={element?.label}
                  disabled={inLoading}
                >
                  {element?.icon}
                </IconButton>
              </Tooltip>
            )
        )}
      </div>
      <div style={{ height: '70px', paddingTop: '20%' }}>
        {hasHidden && (
          <Tooltip
            key="hidden"
            title={
              isHidden
                ? intl.get('words.display') || 'words.display'
                : intl.get('words.hide') || 'words.hide'
            }
          >
            <IconButton
              data-testid="bottomButton"
              size="small"
              className={isHidden ? classes.fabSelected : classes.fab}
              onClick={hiddenCall}
              disabled={inLoading}
            >
              {isHidden ? (
                <SkipPreviousIcon data-testid="previousButton" />
              ) : (
                <SkipNextIcon data-testid="nextButton" />
              )}
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  )
}

ToolsBar.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.object,
      isSelected: PropTypes.bool,
      label: PropTypes.string,
      onClick: PropTypes.func,
      text: PropTypes.string,
    })
  ),
  selected: PropTypes.string,
}

ToolsBar.defaultProps = {
  buttons: [],
  selected: '',
}
