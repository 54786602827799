import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '../../../styles'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    fontWeight: 'bold',
    width: '100%',
    textTransform: 'uppercase',
    whiteSpace: 'normal',
    color: theme?.palette?.secondary?.contrastText,
  },
}))

const HeaderTitle = ({ className, ...restProps }) => {
  const classes = useStyles()
  const newTitle = restProps.children ? restProps.children : ''
  return (
    <span {...restProps} className={classes.root}>
      {newTitle}
    </span>
  )
}

HeaderTitle.defaultProps = {
  className: '',
}

HeaderTitle.propTypes = {
  className: PropTypes.string.isRequired,
}

export default HeaderTitle
