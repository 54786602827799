import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  Image,
  InputAdornment,
  TextField,
  Typography,
} from '../../core'
import { Email, Lock } from '../../icons'
import { logos } from '../../images'
import { makeStyles } from '../../styles'
import { intl } from '../../translation'
import { validateEmail } from '../../utils'
import { AnimatedDialog } from '../AnimatedDialog'

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 160,
    maxWidth: 300,
    padding: 0,
    margin: '0px auto',
  },
  adornment: {
    opacity: '0.3',
  },
  helperText: {
    width: '100%',
    textAlign: 'right',
    height: '8px',
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    marginBottom: 5,
    marginTop: 5,
  },
  checkbox: {
    margin: 0,
    padding: 0,
  },
  typographyForgot: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    textAlign: 'justify',
    marginTop: 10,
    marginBottom: 0,
  },
  label: {
    display: 'block',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    width: '100%',
    marginRight: 2,
  },
}))

const defaultState = { username: '', password: '' }

export const LoginForm = (props) => {
  const {
    logo,
    hasResetPassword,
    requestLogin,
    requestResetPassword,
    userNameErrorMessage = '',
    passwordErrorMessage = '',
  } = props
  const classes = useStyles()
  const [state, setState] = useState({ ...defaultState })
  const [loading, setLoading] = useState(false)
  const [statusPassword, setStatusPassword] = useState(false)
  const [resetPasswordFormActive, setResetPasswordFormActive] = useState(false)
  const [userNameInternalErrorMessage, setUserNameInternalErrorMessage] =
    useState('')

  const { username, password } = state

  let companyLogo = logos?.textLogoBlueTransparentPNG

  if (logo) {
    companyLogo = logo
  }

  useEffect(() => {
    validateUsernameValue(username)
  }, [username])

  const viewPassword = (e) => {
    if (e.key) {
      if (e.key === 'Enter') {
        setStatusPassword(!statusPassword)
      }
    } else {
      setStatusPassword(!statusPassword)
    }
  }

  const handleResetPasswordForm = () =>
    setResetPasswordFormActive(!resetPasswordFormActive)

  const keyPress = (e) => {
    if (e.key === 'Enter') {
      if (resetPasswordFormActive) {
        return handleRequestResetPassword()
      }

      return handleRequestLogin()
    }
  }

  const handleChange = (item, value) =>
    !loading && setState((prev) => ({ ...prev, [item]: value }))

  const handleRequestLogin = () => {
    if (!username || !password || userNameInternalErrorMessage || loading) {
    } else {
      setLoading(true)
      const response = requestLogin(state)
      Promise.resolve(response).finally(() => setLoading(false))
    }
  }

  const handleRequestResetPassword = () => {
    if (!username || userNameInternalErrorMessage || loading) {
    } else {
      setLoading(true)
      const response = requestResetPassword({ username })
      Promise.resolve(response)
        .then(() => setResetPasswordFormActive(false))
        .finally(() => setLoading(false))
    }
  }

  const validateUsernameValue = (value) => {
    if (!value) {
      setUserNameInternalErrorMessage('')
      return
    }
    if (!validateEmail(value)) {
      setUserNameInternalErrorMessage(
        intl.get(`phrases.emailFormatError`) || `phrases.emailFormatError`
      )
      return
    }
    setUserNameInternalErrorMessage('')
  }

  const userNameHasError = () =>
    (userNameInternalErrorMessage || userNameErrorMessage) !== ''

  const passwordHasError = () => passwordErrorMessage !== ''

  return (
    <AnimatedDialog
      contentTitle={<Image src={companyLogo} height={40} />}
      content={
        <Fragment>
          {!resetPasswordFormActive && (
            <form onSubmit={(event) => event.preventDefault()}>
              <TextField
                autoFocus
                disabled={loading}
                layout="primary"
                reverse
                type="text"
                fullWidth
                error={userNameHasError()}
                id="username-id"
                inputProps={{ 'data-testid': 'username-input' }}
                label={intl.get('words.username') || 'words.username'}
                margin="dense"
                name="username-name"
                onChange={(e) => handleChange('username', e.target.value)}
                onKeyPress={keyPress}
                value={username}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email className={classes.adornment} />
                    </InputAdornment>
                  ),
                }}
                helperText={
                  userNameInternalErrorMessage || userNameErrorMessage || ' '
                }
                FormHelperTextProps={{ 'data-testid': 'helper-text' }}
              />
              <TextField
                disabled={loading}
                layout="primary"
                reverse
                fullWidth
                type={statusPassword ? 'text' : 'password'}
                error={passwordHasError()}
                id="password-id"
                inputProps={{ 'data-testid': 'password-input' }}
                label={intl.get('words.password') || 'words.password'}
                margin="dense"
                name="password-name"
                onChange={(e) => handleChange('password', e.target.value)}
                onKeyPress={keyPress}
                value={password}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock className={classes.adornment} />
                    </InputAdornment>
                  ),
                }}
                helperText={passwordErrorMessage || ''}
              />
              {password.length > 0 && (
                <Typography
                  component="p"
                  variant="subtitle1"
                  className={classes.helperText}
                >
                  <Checkbox
                    checked={statusPassword}
                    onChange={viewPassword}
                    onKeyPress={viewPassword}
                    color="primary"
                    className={classes.checkbox}
                  />
                  {intl.get('phrases.viewPassword') || 'phrases.viewPassword'}
                </Typography>
              )}
            </form>
          )}
          {hasResetPassword && resetPasswordFormActive && (
            <form onSubmit={(event) => event.preventDefault()}>
              <TextField
                autoFocus
                disabled={loading}
                fullWidth
                layout="primary"
                reverse
                type="text"
                error={userNameHasError()}
                inputProps={{ 'data-testid': 'username-input-reset' }}
                label={intl.get('words.username') || 'words.username'}
                margin="dense"
                onChange={(e) => handleChange('username', e.target.value)}
                value={username}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email className={classes.adornment} />
                    </InputAdornment>
                  ),
                }}
                onKeyPress={keyPress}
                helperText={
                  userNameInternalErrorMessage || userNameErrorMessage || ' '
                }
              />
              <Typography
                data-testid="instruction"
                component="p"
                variant="subtitle1"
                className={classes.typographyForgot}
              >
                {intl.get('phrases.recoveryPasswordTitle') ||
                  'phrases.recoveryPasswordTitle'}
              </Typography>
            </form>
          )}
        </Fragment>
      }
      contentActions={
        <Fragment>
          {hasResetPassword && (
            <Button
              name="forgotten-password"
              className={classes.button}
              width="200px"
              layout="primary"
              onClick={handleResetPasswordForm}
              type="button"
              variant={resetPasswordFormActive ? 'outlined' : 'text'}
            >
              {resetPasswordFormActive
                ? intl.get('words.cancel') || 'words.cancel'
                : intl.get('phrases.forgetPassword') ||
                  'phrases.forgetPassword'}
            </Button>
          )}
          <Button
            className={classes.button}
            layout="primary"
            disabled={
              resetPasswordFormActive
                ? !username.length || loading || userNameHasError()
                : !username.length ||
                  loading ||
                  userNameHasError() ||
                  !password.length
            }
            fullWidth={!hasResetPassword}
            onClick={
              resetPasswordFormActive
                ? handleRequestResetPassword
                : handleRequestLogin
            }
            type="button"
            variant="contained"
          >
            {resetPasswordFormActive
              ? intl.get('words.send') || 'words.send'
              : intl.get('words.login') || 'words.login'}
          </Button>
        </Fragment>
      }
    />
  )
}

LoginForm.propTypes = {
  logo: PropTypes.string,
  hasResetPassword: PropTypes.bool,
  requestLogin: PropTypes.func.isRequired,
  requestResetPassword: PropTypes.func,
  userNameErrorMessage: PropTypes.string,
  passwordErrorMessage: PropTypes.string,
}

LoginForm.defaultProps = {
  logo: '',
  hasResetPassword: false,
  requestLogin: () =>
    Promise.resolve(console.log('requestLogin function undefined')),
  requestResetPassword: () =>
    Promise.resolve(console.log('requestResetPassword function undefined')),
  userNameErrorMessage: '',
  passwordErrorMessage: '',
}
