import { normalizeReportsData } from './Normalizers'
import ENDPOINTS from './ENDPOINTS'
import { useFetchAxiosWrapper } from '../useFetchAxiosWrapper'
import { useState, useEffect } from 'react'
import _ from 'lodash'
import { useSnackbar } from '../../components/Library/notifications'

export const useReportBudget = () => {
  const [dataBudget, setDataBudget] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  const { patchRequest, fetchPATCH } = useFetchAxiosWrapper({
    baseEndpoint: ENDPOINTS.PATCH.billing_reports_budget,
    callOnInit: false,
  })

  useEffect(() => {
    if (!_.isEqual(patchRequest.data, dataBudget)) {
      setDataBudget(patchRequest.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchRequest])

  const patchBudgetContent = (body) => {
    fetchPATCH({ body, disableCompanyId: true })
  }

  return {
    loading: patchRequest.loading,
    dataBudget: normalizeReportsData({ data: dataBudget }),

    patchBudgetContent,
  }
}
