import PropTypes from 'prop-types'
import React from 'react'
import { IconButton, Tooltip } from '../../../core'
import { Refresh as RefreshIcon } from '../../../icons'
import { makeStyles } from '../../../styles'
import { intl } from '../../../translation'

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.tertiary?.main,
  },
}))

const Refresh = ({ handleButtonClick }) => {
  const classes = useStyles()

  return (
    <Tooltip
      data-testid="tooltip"
      title={intl.get(`phrases.updateData`) || `phrases.updateData`}
    >
      <IconButton
        role="button"
        aria-label={intl.get(`phrases.updateData`) || `phrases.updateData`}
        onClick={() => handleButtonClick('Refresh')}
        className={classes.icon}
      >
        <RefreshIcon data-testid="refreshIcon" />
      </IconButton>
    </Tooltip>
  )
}

Refresh.defaultProps = {
  handleButtonClick: () => {},
}

Refresh.propTypes = {
  handleButtonClick: PropTypes.func.isRequired,
}

export default Refresh
