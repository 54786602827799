import React from 'react'
import PropTypes from 'prop-types'
import { intl } from '../../../translation'
import { makeStyles } from '../../../styles'
import { Tooltip, IconButton } from '../../../core'

import ExportTableIcon from '../../../icons/ExportTableIcon'
import ProgressWithPosition from '../../ProgressWithPosition'

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.tertiary?.main,
  },
}))

const Excel = ({
  handleButtonClick,
  isLoading,
  processCounter,
  handleReset,
  defaultTexts,
}) => {
  const classes = useStyles()

  return (
    <>
      {isLoading ? (
        <ProgressWithPosition
          defaultTexts={defaultTexts}
          processCounter={processCounter}
          handleReset={handleReset}
        />
      ) : (
        <Tooltip
          data-testid="tooltip-exporting"
          title={intl.get(`phrases.exportToTable`)}
        >
          <IconButton
            role="button"
            onClick={() => handleButtonClick('Excel')}
            className={classes.icon}
          >
            <ExportTableIcon data-testid="listIcon" />
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}

Excel.defaultProps = {
  handleButtonClick: () => {},
}

Excel.propTypes = {
  handleButtonClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  processCounter: PropTypes.object.isRequired,
  handleReset: PropTypes.func.isRequired,
}

export default Excel
