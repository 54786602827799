import { DataTypeProvider } from '@devexpress/dx-react-grid'
import React from 'react'
import { LinearProgress } from '../../../../core'
import { makeStyles } from '../../../../styles'

const useStyles = makeStyles((theme) => ({
  progressBar: {
    zIndex: 1,
    height: 20,
    marginTop: -20,
    backgroundColor: theme?.palette?.tertiary?.main,
    color: theme?.palette?.tertiary?.main,
    border: `1px solid ${theme?.palette?.tertiary?.main}`,
    opacity: '0.7',
  },
  progressBarDiv: {
    textAlign: 'center',
  },
  progressBarText: {
    zIndex: 2,
    position: 'relative',
    color: 'white',
  },
}))

export const ProgressBar = (props) => {
  const classes = useStyles()

  return (
    <DataTypeProvider
      formatterComponent={({ value }) => (
        <div className={classes.progressBarDiv}>
          <span className={classes.progressBarText}>{value}%</span>

          <LinearProgress
            variant="determinate"
            color="primary"
            value={value > 100 ? 100 : value}
            classes={{
              root: classes.progressBar,
              bar1Determinate: classes.bar1Determinate,
            }}
          />
        </div>
      )}
      {...props}
    />
  )
}

export default ProgressBar
