const hasMinLengthRegex = new RegExp('^(?=.{6,})')
const hasSpecialCharacterRegex = new RegExp('^(?=.*[!@#$%*])')
const hasUpperCaseLetterRegex = new RegExp('^(?=.*[A-Z])')
const hasLowerCaseLetterRegex = new RegExp('^(?=.*[a-z])')
const hasNumberRegex = new RegExp('^(?=.*[0-9])')
const hasEmailRegex = new RegExp(
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)"
)

export const lengthRule = (password) =>
  password.length !== 0 ? password.length >= 6 && password.length <= 15 : true

export const minLengthRule = (password) =>
  password.length !== 0 ? hasMinLengthRegex.test(password) : true

export const specialCharacterRule = (password) =>
  password.length !== 0 ? hasSpecialCharacterRegex.test(password) : true

export const upperCaseRule = (password) =>
  password.length !== 0 ? hasUpperCaseLetterRegex.test(password) : true

export const lowerCaseRule = (password) =>
  password.length !== 0 ? hasLowerCaseLetterRegex.test(password) : true

export const numberRule = (password) =>
  password.length !== 0 ? hasNumberRegex.test(password) : true

export const confirmPasswordRule = (password, confirmPassword) =>
  password.length !== 0 || confirmPassword.length !== 0
    ? password === confirmPassword
    : true

export const validateEmail = (value) =>
  value.length !== 0 ? hasEmailRegex.test(value) : true
