import useCallAPI from './useCallAPI'
import { normalizeOpportunitiesData } from './Normalizers'
import { useMemo, useState } from 'react'
import _ from 'lodash'
import { intl } from '../../components/Library/translation'

const filterQuery = (values) => {
  return {
    ...values,
  }
}

export const useOpportunityData = () => {
  const {
    reset,
    requestSuccess,
    requestData,
    requestLoading,
    fetchGET,
    fetchPOST,
    fetchPUT,
  } = useCallAPI({
    initialCall: false,
    context: `opportunity`,
  })

  const [errorList, setErrorList] = useState({})
  const [previewValues, setPreviewValues] = useState(null)
  const [opportunityIdCopied, setOpportunityIdCopied] = useState(null)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [opportunitiesList, setOpportunitiesList] = useState([])
  const [generalError, setGeneralError] = useState('')
  const [stateSugarId, setStateSugarId] = useState('')

  const [inputValues, setInputValues] = useState({
    opportunityName: null,
    categoryId: null,
    accountId: null,
    commercialId: null,
    commercialCoefficient: null,
    commercial2Id: null,
    commercial2Coefficient: null,
    preVendaId: null,
    sdrId: null,
  })
  const [checkboxFields, setCheckboxFields] = useState({
    commercialId: false,
    commercialCoefficient: false,
    commercial2Id: false,
    commercial2Coefficient: false,
    preVendaId: false,
    sdrId: false,
  })

  const handleInitialValues = (key, value) => {
    setInputValues((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  const singleOpportunityValues = ({ opportunitySelected }) => {
    if (opportunitySelected?.sugarId) {
      setStateSugarId(opportunitySelected.sugarId)
    }
    if (opportunitySelected?.categoryId) {
      handleInitialValues('categoryId', {
        value: opportunitySelected.categoryId,
      })
    }
    if (opportunitySelected?.accountId) {
      handleInitialValues('accountId', {
        value: opportunitySelected.accountId,
        label: opportunitySelected.accountName,
      })
    }
    if (opportunitySelected?.name) {
      handleInitialValues('opportunityName', opportunitySelected.name)
    }
    if (opportunitySelected?.commercialId) {
      handleInitialValues('commercialId', {
        value: opportunitySelected.commercialId,
      })
    }
    if (opportunitySelected?.commercialCoefficient) {
      handleInitialValues(
        'commercialCoefficient',
        opportunitySelected.commercialCoefficient * 100
      )
    }
    if (opportunitySelected?.commercial2Id) {
      handleInitialValues('commercial2Id', {
        value: opportunitySelected.commercial2Id,
      })
    }
    if (opportunitySelected?.commercial2Coefficient) {
      handleInitialValues(
        'commercial2Coefficient',
        opportunitySelected.commercial2Coefficient * 100
      )
    }
    if (opportunitySelected?.preVendaId) {
      handleInitialValues('preVendaId', {
        value: opportunitySelected.preVendaId,
      })
    }
    if (opportunitySelected?.sdrId) {
      handleInitialValues('sdrId', { value: opportunitySelected.sdrId })
    }
  }

  const handleUnlinkFields = () => {
    const emptyValues = Object.keys(inputValues).reduce(
      (values, key) => ({
        ...values,
        [key]: null,
      }),
      {}
    )
    const checkboxes = Object.keys(checkboxFields).reduce(
      (checkboxesList, key) => ({
        ...checkboxesList,
        [key]: false,
      }),
      {}
    )
    setCheckboxFields(checkboxes)
    setOpportunityIdCopied(null)
    setInputValues(emptyValues)
  }

  const handleChangeField = (field, value) => {
    const errors = { ...errorList }
    delete errors[field + 'Error']
    setErrorList(errors)
    setGeneralError('')

    setOpportunityIdCopied(null)
    setInputValues((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  const handleRemoveOpportunity = (index) => {
    const opportunities = [...opportunitiesList]
    opportunities.splice(index, 1)

    setOpportunitiesList(opportunities)
  }

  const validateValues = ({
    opportunitiesSelected,
    opportunitySelected,
    updateDataUser,
  }) => {
    const {
      opportunityName,
      categoryId,
      commercialId,
      commercialCoefficient,
      commercial2Coefficient,
      accountId,
    } = inputValues

    let errors = {}

    const isNullishValues = Object.values(inputValues).every((value) => {
      if (value === null) {
        return true
      }
      return false
    })

    if (isNullishValues && opportunitiesSelected) {
      setGeneralError(intl.get('phrases.opportunityGeneralError'))
      return
    }
    if (Number(commercialCoefficient) + Number(commercial2Coefficient) > 100) {
      setGeneralError(intl.get('phrases.coefficientCommercialInvalid'))
      return
    }

    if (opportunitySelected) {
      if (!opportunityName) {
        errors.opportunityNameError = intl.get('phrases.opportunityRequired')
      }
      if (_.isEmpty(categoryId)) {
        errors.categoryIdError = intl.get('phrases.categoryRequired')
      }
      if (!accountId) {
        errors.accountIdError = intl.get('phrases.accountRequired')
      }
      if (_.isEmpty(commercialId)) {
        errors.commercialIdError = intl.get('phrases.commercialRequired')
      }
      if (!commercialCoefficient) {
        errors.commercialCoefficientError = intl.get(
          'phrases.coefficientCommercialRequired'
        )
      }
    }
    if (opportunitiesSelected) {
      if (_.isEmpty(commercialId) && checkboxFields.commercialId) {
        errors.commercialIdError = intl.get('phrases.commercialRequired')
      }
      if (!commercialCoefficient && checkboxFields.commercialCoefficient) {
        errors.commercialCoefficientError = intl.get(
          'phrases.coefficientCommercialRequired'
        )
      }
    }

    if (!_.isEmpty(errors)) {
      setErrorList(errors)
      return
    }

    opportunitiesSelected
      ? setShowConfirmationModal(true)
      : handleSubmitEditOpportunity({
          opportunitiesSelected,
          opportunitySelected,
          updateDataUser,
        })
  }

  const convertValueToDecimal = (value) => {
    if (!value) return null

    return parseFloat(value) / 100
  }

  const handleSubmitEditOpportunity = ({
    opportunitiesSelected,
    opportunitySelected,
    updateDataUser,
  }) => {
    const {
      opportunityName,
      categoryId,
      commercialId,
      commercial2Id,
      commercialCoefficient,
      commercial2Coefficient,
      accountId,
      preVendaId,
      sdrId,
    } = inputValues

    const commercial2CheckedField = checkboxFields.commercial2Id
      ? commercial2Id?.value ?? ''
      : null
    const commercial2IdValue = opportunitiesSelected
      ? commercial2CheckedField
      : commercial2Id?.value ?? ''

    const commercialCheckedField = checkboxFields.commercialId
      ? commercialId?.value ?? ''
      : null
    const commercialIdValue = opportunitiesSelected
      ? commercialCheckedField
      : commercialId?.value ?? ''

    const preVendaIdCheckedField = checkboxFields.preVendaId
      ? preVendaId?.value ?? ''
      : null
    const preVendaIdValue = opportunitiesSelected
      ? preVendaIdCheckedField
      : preVendaId?.value ?? ''

    const sdrIdCheckedField = checkboxFields.sdrId ? sdrId?.value ?? '' : null
    const sdrIdValue = opportunitiesSelected
      ? sdrIdCheckedField
      : sdrId?.value ?? ''

    const commercialCoefficientValue = opportunitiesSelected
      ? checkboxFields.commercialCoefficient
        ? convertValueToDecimal(commercialCoefficient) ?? ''
        : null
      : convertValueToDecimal(commercialCoefficient)

    const commercial2CoefficientValue = opportunitiesSelected
      ? checkboxFields.commercial2Coefficient
        ? convertValueToDecimal(commercial2Coefficient) ?? ''
        : null
      : convertValueToDecimal(commercial2Coefficient) ?? ''

    const idListValue =
      opportunitiesList.length > 0
        ? opportunitiesList.map((opportunity) => opportunity.id)
        : null

    const idValues = opportunitiesSelected
      ? idListValue
      : opportunitySelected?.id

    const payload = {
      [opportunitiesSelected ? 'idList' : 'id']: idValues,
      sugarId: opportunitiesSelected ? null : stateSugarId,
      categoryId: categoryId?.value,
      accountId: accountId?.value,
      name: opportunityName,
      commercialId: commercialIdValue,
      commercialCoefficient: commercialCoefficientValue,
      commercial2Id: commercial2IdValue,
      commercial2Coefficient: commercial2CoefficientValue,
      preVendaId: preVendaIdValue,
      sdrId: sdrIdValue,
    }

    if (opportunitiesSelected) {
      Object.keys(payload).forEach((key) => {
        if (payload[key] === null || payload[key] === undefined) {
          delete payload[key]
        }
      })
    }
    updateDataUser(payload)
  }

  const handleGET = (params) => {
    fetchGET({ userParams: filterQuery(params) })
  }
  const handlePOST = (params) => {
    fetchPOST({ userBody: params })
  }
  const handlePUT = (params) => {
    fetchPUT({ userBody: params })
  }

  const emptyObject = (object) => _.values(object).every(_.isEmpty)

  const handleSaveAllFields = () => {
    const checkboxes = Object.keys(checkboxFields).reduce(
      (checkboxesList, key) => ({
        ...checkboxesList,
        [key]: true,
      }),
      {}
    )
    setOpportunityIdCopied(previewValues.id)
    setCheckboxFields(checkboxes)
    setInputValues(preview)
  }

  const preview = useMemo(() => {
    const commercialObj = {
      value: previewValues?.commercial?.id,
      label: previewValues?.commercial?.name,
    }

    const commercialCoefficientObj =
      previewValues?.commercialCoefficient * 100 ?? null

    const commercial2CoefficientObj =
      previewValues?.commercial2Coefficient * 100 ?? null

    const commercial2Obj = {
      value: previewValues?.commercial2?.id,
      label: previewValues?.commercial2?.name,
    }

    const preVendaObj = {
      value: previewValues?.preVenda?.id ?? null,
      label: previewValues?.preVenda?.name ?? null,
    }

    const sdrObj = {
      value: previewValues?.sdr?.id ?? null,
      label: previewValues?.sdr?.name ?? null,
    }

    const previewFiltred = {
      commercialId: emptyObject(commercialObj) ? null : commercialObj,
      commercialCoefficient: _.isNaN(commercialCoefficientObj)
        ? null
        : commercialCoefficientObj,
      commercial2Id: emptyObject(commercialObj) ? null : commercial2Obj,
      commercial2Coefficient: _.isNaN(commercial2CoefficientObj)
        ? null
        : commercial2CoefficientObj,
      preVendaId: emptyObject(commercialObj) ? null : preVendaObj,
      sdrId: emptyObject(commercialObj) ? null : sdrObj,
    }
    return previewFiltred
  }, [previewValues])

  const handleTogleCheckbox = ({ key, value }) => {
    setGeneralError('')
    setOpportunityIdCopied(null)
    setCheckboxFields((prevCheckbox) => {
      if (value) {
        return {
          ...prevCheckbox,
          [key]: value,
        }
      }
      if (prevCheckbox[key]) {
        setInputValues((prevValues) => ({
          ...prevValues,
          [key]: null,
        }))
      } else {
        setInputValues((prevValues) => ({
          ...prevValues,
          [key]: '',
        }))
      }
      return {
        ...prevCheckbox,
        [key]: !prevCheckbox[key],
      }
    })
  }

  const stateOpportunities = normalizeOpportunitiesData(requestData)
  return {
    reset,
    inputValues,
    checkboxFields,
    errorList,
    showConfirmationModal,
    setShowConfirmationModal,
    previewValues,
    setPreviewValues,
    opportunitiesList,
    setOpportunitiesList,
    opportunityIdCopied,
    handleUnlinkFields,
    generalError,
    handleRemoveOpportunity,
    handleSubmitEditOpportunity,
    singleOpportunityValues,
    handleChangeField,
    handleSaveAllFields,
    handleTogleCheckbox,
    validateValues,
    preview,
    loading: requestLoading,
    state: stateOpportunities,
    success: requestSuccess,
    handleGET,
    handlePOST,
    handlePUT,
  }
}
