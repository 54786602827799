import React from 'react'
import { useStyles } from './styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core'
import intl from 'react-intl-universal'

function ErrorBoundaryScreen() {
  const classes = useStyles()

  return (
    <Dialog
      className={classes.dialog}
      open
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{
        classes: {
          root: classes.paperPropsMax,
        },
      }}
    >
      <div className={classes.borderDetail}>
        <div
          className={classes.whiteMark}
          style={{ marginLeft: '10%', width: '10%', marginRight: '80%' }}
        >
          <div
            className={classes.blueMark}
            style={{ marginLeft: '60%', width: '40%' }}
          />
        </div>
      </div>

      <DialogContent
        data-testid="dialog-content-error"
        id="dialog-content-error"
        className={classes.dialogContent}
      >
        <Typography className={classes.title} variant="h4">
          {intl.get('phrases.somethingWrong')}
        </Typography>
        <Typography variant="h6">
          {intl.get('phrases.sorrySomeProblem')}
          <br />
          {intl.get('phrases.pleaseRefreshPage')}
        </Typography>
      </DialogContent>
      <DialogActions
        data-testid="dialog-actions-error"
        id="dialog-actions-login"
        className={classes.dialogActions}
      >
        <Button
          layout="primary"
          fullWidth
          onClick={() => window.location.reload()}
          type="button"
          variant="contained"
          color="primary"
        >
          {intl.get('buttons.refreshPage')}
        </Button>
      </DialogActions>

      <div className={classes.borderDetail}>
        <div
          className={classes.whiteMark}
          style={{ marginLeft: '80%', width: '10%', marginRight: '10%' }}
        >
          <div
            className={classes.blueMark}
            style={{ marginLeft: 0, width: '40%' }}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default ErrorBoundaryScreen
