import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { List } from '../../core'
import { makeStyles } from '../../styles'
import { MenuButton } from './MenuButton'

const useStyles = makeStyles((theme) => ({
  drawer: {
    marginTop: '50px',
    flexShrink: 0,
    border: 'none',
    whiteSpace: 'nowrap',
    backgroundColor: theme?.palette?.defaultBackground?.sideBarBackground,
    overflowX: 'hidden',
    overflowY: 'hidden',
    height: '100%',
    maxHeight: 'calc(100vh - 70px)',
    paddingTop: 5,
    paddingBottom: 0,
    width: (props) => props.storybookWidth || '100%',
    borderRight: 'none',
    marginRight: 0,
    borderTopRightRadius: 5,
  },
  drawerOpen: {
    marginTop: '50px',
    flexShrink: 0,
    border: 'none',
    whiteSpace: 'nowrap',
    backgroundColor: theme?.palette?.defaultBackground?.sideBarBackground,
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100%',
    width: (props) => props.storybookWidth * 4.2 || '100%',
    maxHeight: 'calc(100vh - 70px)',
    paddingTop: 5,
    paddingBottom: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderTopRightRadius: 5,
  },
  drawerIcon: {
    color: theme?.palette?.text.icon,
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: '0.3s',
    }),
  },
  drawerIconRotate: {
    color: theme?.palette?.text.icon,
    transform: 'rotate(90deg)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: '0.3s',
    }),
  },
  logoItem: {
    marginTop: 1,
  },
  logo: {
    width: 80,
    marginLeft: 4,
    marginTop: -45,
    marginBottom: -50,
  },
}))

export const MenuNavigation = (props) => {
  const { isOpen, onClose, routes, notifications } = props
  const classes = useStyles(props)
  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      window.dispatchEvent(new Event('resize'))
    }, 200)
  })

  return (
    <List
      className={classNames(classes.drawer, { [classes.drawerOpen]: isOpen })}
    >
      {routes &&
        routes.map((item, index) => (
          <MenuButton
            hasNestedItems={item?.hasNestedItems}
            icon={item?.icon}
            isNested={item?.isNested}
            isOpen={isOpen}
            isVisible={item?.isVisible}
            key={`${index}`}
            nestedItems={item?.nestedItems}
            notifications={notifications}
            onClose={onClose}
            path={item?.path}
            role={item?.role}
            text={item?.text}
          />
        ))}
    </List>
  )
}

MenuNavigation.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
  notifications: PropTypes.object,
}

MenuNavigation.defaultProps = {
  isOpen: false,
  onClose: () => {},
  routes: null,
  notifications: {},
}
