import { normalizePermissionsAccessData } from './Normalizers'
import useCallAPI from './useCallAPI'

const filterQuery = ({ email }) => ({
  accessTo: email,
})

const filterBody = ({ accessTo, permissions, defaultScreen }) => ({
  accessTo,
  permissions,
  defaultScreen,
})

export const usePermissionsAccessData = () => {
  const {
    reset,
    requestSuccess,
    requestData,
    requestLoading,
    requestError,
    fetchGET,
    fetchPUT,
  } = useCallAPI({
    initialCall: false,
    context: `permission_access`,
  })

  const statePermissions = normalizePermissionsAccessData(requestData)

  const handleGET = (params) => {
    fetchGET({ userParams: filterQuery(params) })
  }

  const handlePUT = (params) => {
    fetchPUT({
      userBody: filterBody(params),
    })
  }

  return {
    reset,
    state: statePermissions,
    loading: requestLoading,
    success: requestSuccess,
    error: requestError,
    handleGET,
    handlePUT,
  }
}
