import { useTheme } from '@material-ui/core'
import React from 'react'

export default function ExportTableIcon() {
  const theme = useTheme()
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="20.000000pt"
      height="20.000000pt"
      viewBox="0 0 48.000000 48.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
        fill={theme.palette.tertiary.main}
        stroke="none"
      >
        <path
          d="M92 428 c-17 -17 -17 -359 0 -376 8 -8 45 -12 102 -12 88 0 89 0 71
20 -14 16 -31 20 -82 20 l-63 0 0 160 0 160 70 0 70 0 0 -50 0 -50 50 0 50 0
0 -50 c0 -43 3 -50 20 -50 18 0 20 7 20 63 l0 63 -58 57 -58 57 -90 0 c-57 0
-94 -4 -102 -12z"
        />
        <path
          d="M190 275 c0 -4 7 -20 16 -37 15 -29 15 -33 -6 -69 -21 -39 -21 -39
-1 -39 14 0 23 9 27 25 8 32 20 32 28 0 4 -16 13 -25 26 -25 20 0 20 0 0 40
l-20 39 21 36 c21 35 21 35 1 35 -13 0 -24 -10 -30 -27 l-10 -28 -11 28 c-6
15 -17 27 -26 27 -8 0 -15 -2 -15 -5z"
        />
        <path
          d="M360 120 c0 -38 -1 -40 -32 -40 l-33 0 43 -39 42 -39 42 39 43 39
-33 0 c-31 0 -32 2 -32 40 0 33 -3 40 -20 40 -17 0 -20 -7 -20 -40z"
        />
      </g>
    </svg>
  )
}
