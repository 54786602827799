import React from 'react'
import moment from 'moment'
import 'moment/locale/pt-br'
import { intl } from '../../../../translation'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

const CompleteMonth = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) =>
      value
        ? moment(value)
            .locale('pt-BR')
            .format(intl.get(`default.date.monthYearComplete`))
            .toUpperCase()
        : ''
    }
    {...props}
  />
)

export default CompleteMonth
