import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import DateFnsUtils from '@date-io/date-fns'
import ptLocale from 'date-fns/locale/pt'
import _ from 'lodash'
import moment from 'moment'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { IconButton, TextFieldDate, Typography } from '../../../../core'
import { Clear as ClearIcon } from '../../../../icons'
import { makeStyles, useTheme } from '../../../../styles'
import { convertHexToRGBA } from '../../../../utils'
import { intl } from '../../../../translation'
import 'moment/locale/pt-br'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '0.8em',
    fontFamily: theme?.typography?.fontFamily,
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 5,
    border: `1px solid ${theme?.palette?.secondary?.contrastText}`,
    overflow: 'hidden',
    borderRadius: 4,
    color: theme?.palette?.secondary?.contrastText,
  },
  adornmentDefault: {
    color: theme?.palette?.secondary?.contrastText,
    padding: '5px',
  },
  alignLeft: {
    width: '47.5%',
    paddingRight: 0,
    marginRight: '5%',
    paddingTop: 0,
    marginTop: 0,
  },
  alignRight: {
    width: '47.5%',
    paddingRight: 0,
    paddingTop: 0,
    marginTop: 0,
  },
  title: {
    color: theme?.palette?.secondary?.contrastText,
    textAlign: 'left',
    marginBottom: 0,
  },
  group: {
    fontSize: '0.3em',
    margin: 0,
    padding: 0,
  },
  block: {
    width: '80%',
    backgroundColor: theme?.palette?.secondary?.contrastText,
    height: 1,
    margin: '10px auto',
  },
}))

const usePrevious = (value) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

const FieldDateMonthPeriod = (props) => {
  const {
    filter,
    onUpdate,
    language,
    languages,
    preDefinedListOptions,
    validateDateShortcut,
    defaultName,
  } = props
  const classes = useStyles()
  const theme = useTheme()
  const firstLoad = useRef(true)
  const prevFilter = usePrevious(filter)
  const [selectedShortCut, setSelectedShortCut] = useState('')
  const [isOpenStart, setIsOpenStart] = useState(false)
  const [isOpenEnd, setIsOpenEnd] = useState(false)
  const [data, setData] = useState({
    ...filter,
    date: null,
    dateStart: null,
    dateEnd: null,
    periodType: null,
  })

  const customStyles = {
    option: (provided, providedState) => ({
      ...provided,
      cursor: 'pointer',
      textAlign: 'left',
      fontWeight: providedState.isSelected ? 'bold' : '',
      backgroundColor: theme.palette.secondary,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
      '&:hover': {
        backgroundColor: convertHexToRGBA(theme?.palette?.primary?.main, 0.3),
        fontWeight: 'bold',
      },
    }),
    control: (provided, providedState) => ({
      ...provided,
      border: !providedState.selectProps.value
        ? `1px solid ${theme?.palette?.secondary?.contrastText}`
        : `2px solid ${theme?.palette?.secondary?.contrastText}`,
      '&:hover': {
        border: !providedState.selectProps.value
          ? `1px solid ${theme?.palette?.secondary?.contrastText}`
          : `2px solid ${theme?.palette?.secondary?.contrastText}`,
        '&:active': {
          border: !providedState.selectProps.value
            ? `1px solid ${theme?.palette?.secondary?.contrastText}`
            : `2px solid ${theme?.palette?.secondary?.contrastText}`,
        },
      },
      backgroundColor: theme.palette.secondary,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
    }),
    placeholder: (provided, providedState) => ({
      ...provided,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
      opacity: '0.4',
      fontSize: '0.8em',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
      cursor: 'pointer',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
      cursor: 'pointer',
    }),
    singleValue: (provided, providedState) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
      marginLeft: 0,
    }),
    input: (provided) => ({
      ...provided,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
    }),
    menu: (provided) => ({
      ...provided,
      overflowY: 'auto',
      maxHeight: '400px',
      backgroundColor: theme?.palette?.secondary?.main,
      border: `1px solid ${theme?.palette?.secondary?.contrastText}`,
      borderRadius: '5px',
      fontSize: '1em',
      zIndex: 9999,
      paddingLeft: 5,
    }),
  }

  useEffect(() => {
    if (selectedShortCut) {
      applyDateShorCut(selectedShortCut)
    }
  }, [selectedShortCut])

  useEffect(() => {
    if (!_.isEqual(prevFilter, filter)) {
      setData(filter)
    }
  }, [prevFilter, filter])

  useEffect(() => {
    if (filter?.dateStart === '') {
      setData({ ...filter, dateStart: null })
    }
    if (filter?.dateEnd === '') {
      setData({ ...filter, dateEnd: null })
    }
  }, [filter])

  useEffect(() => {
    if (firstLoad.current === true) {
      firstLoad.current = false
      return
    }

    const newData = _.cloneDeep(data)

    if (
      moment(data?.dateStart).isValid() &&
      moment(data?.dateEnd).isValid() &&
      moment(data?.dateStart).isAfter(data?.dateEnd)
    ) {
      newData.dateEnd = data?.dateStart
    }

    if (
      !moment(data?.date).isValid() &&
      !moment(data?.dateEnd).isValid() &&
      !moment(data?.dateStart).isValid()
    ) {
      setSelectedShortCut('')
    }

    if (!data?.date && !data?.dateStart && !data?.dateEnd) {
      newData.periodType = null
    }

    onUpdate(newData)
  }, [data])

  const applyDateShorCut = (value) => {
    setData({
      ...data,
      ...validateDateShortcut(value),
      date: null,
      periodType: 'range',
    })
  }

  const handleDataStart = (date) => {
    if (date !== null) {
      const newData = {
        ...data,
        date: null,
        dateStart: moment(date),
        periodType: 'range',
      }
      setSelectedShortCut('')
      setData(newData)
    } else {
      const newData = {
        ...data,
        dateStart: null,
        periodType: null,
      }
      setData(newData)
    }
  }

  const handleClearStart = () => {
    selectedShortCut && setSelectedShortCut('')

    setData((prev) => ({
      ...prev,
      dateStart: null,
    }))
  }

  const handleDataEnd = (date) => {
    if (date !== null) {
      const newData = {
        ...data,
        date: null,
        dateEnd: moment(date),
        periodType: 'range',
      }
      setSelectedShortCut('')
      setData(newData)
    } else {
      const newData = {
        ...data,
        dateEnd: null,
        periodType: null,
      }
      setData(newData)
    }
  }

  const handleClearEnd = () => {
    selectedShortCut && setSelectedShortCut('')

    setData((prev) => ({
      ...prev,
      dateEnd: null,
    }))
  }

  const handleMonth = (date) => {
    if (date !== null) {
      const newData = {
        ...data,
        date: moment(date),
        dateStart: null,
        dateEnd: null,
        periodType: 'month',
      }
      setData(newData)
    } else {
      const newData = {
        ...data,
        date: null,
        periodType: null,
      }
      setData(newData)
    }
  }

  const handleChange = (selectedItem) => {
    if (selectedItem) {
      setSelectedShortCut(selectedItem.value)
    } else {
      setSelectedShortCut('')
      setData({
        ...data,
        dateStart: null,
        dateEnd: null,
      })
    }
  }

  return (
    <div data-testid="monthPeriod">
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={
          JSON.stringify(languages) !== '{}' && language
            ? languages[language]
            : ptLocale
        }
      >
        <Typography className={classes.title}>
          {intl.get(`titles.${defaultName}`) || 'title'}
        </Typography>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/yyyy"
          margin="dense"
          id={`date-picker-inlineStart-${filter?.defaultName}`}
          value={data?.dateStart}
          onChange={handleDataStart}
          KeyboardButtonProps={{
            'aria-label': 'change date',
            classes: { root: classes.adornmentDefault },
          }}
          views={['month', 'year']}
          autoOk
          invalidDateMessage=""
          onOpen={() => setIsOpenStart(true)}
          onClose={() => setIsOpenStart(false)}
          open={isOpenStart}
          key={`${filter?.defaultName}DateStart`}
          placeholder={intl.get('words.inputInitialTitle')}
          InputAdornmentProps={{ position: 'start', variant: 'standard' }} /// //////////////
          InputProps={{
            classes: {
              root: classes.root,
            },
            style: {
              border:
                data?.dateStart &&
                moment(data?.dateStart).isValid() &&
                `2px solid ${theme?.palette?.secondary?.contrastText}`,
            },
            endAdornment: data?.dateStart && (
              <IconButton
                data-testid="startClearButton"
                onClick={handleClearStart}
                classes={{
                  root: classes.adornmentDefault,
                }}
              >
                <ClearIcon />
              </IconButton>
            ),
            disableUnderline: true,
          }}
          className={classes.alignLeft}
        />
        <KeyboardDatePicker
          minDate={moment(data?.dateStart).isValid() && data?.dateStart}
          minDateMessage=""
          disableToolbar
          variant="inline"
          format="MM/yyyy"
          margin="dense"
          id={`date-picker-inlineEnd-${filter?.defaultName}`}
          value={data?.dateEnd}
          onChange={handleDataEnd}
          KeyboardButtonProps={{
            'aria-label': 'change date',
            classes: { root: classes.adornmentDefault },
          }}
          views={['month', 'year']}
          autoOk
          invalidDateMessage=""
          onOpen={() => setIsOpenEnd(true)}
          onClose={() => setIsOpenEnd(false)}
          open={isOpenEnd}
          key={`${filter?.defaultName}DateEnd`}
          placeholder={intl.get('words.inputFinalTitle')}
          InputAdornmentProps={{ position: 'start', variant: 'standard' }}
          InputProps={{
            classes: {
              root: classes.root,
            },
            style: {
              border:
                data?.dateEnd &&
                moment(data?.dateEnd).isValid() &&
                `2px solid ${theme?.palette?.secondary?.contrastText}`,
            },
            endAdornment: data?.dateEnd && (
              <IconButton
                data-testid="endClearButton"
                onClick={handleClearEnd}
                classes={{
                  root: classes.adornmentDefault,
                }}
              >
                <ClearIcon />
              </IconButton>
            ),
            disableUnderline: true,
          }}
          className={classes.alignRight}
        />
      </MuiPickersUtilsProvider>

      <div>
        <TextFieldDate
          format="MM/yyyy"
          views={['month', 'year']}
          selectedDate={data?.date ? data?.date : null}
          placeholder={intl.get(`phrases.month`)}
          onChangeDate={handleMonth}
          margin={10}
        />
      </div>

      <div
        data-testid="selectDiv"
        title={selectedShortCut === '' ? '' : selectedShortCut}
        className={classes.div}
      >
        <Select
          inputId="predefinedSelect"
          placeholder={intl.get('words.inputPredefined')}
          value={
            selectedShortCut === ''
              ? ''
              : preDefinedListOptions.find((x) => x.value === selectedShortCut)
          }
          isSearchable
          name={intl.get(`titles.${defaultName}`) || 'title'}
          options={preDefinedListOptions}
          onChange={handleChange}
          backspaceRemoves
          isClearable
          inputProps={{ className: classes.input }}
          styles={customStyles}
        />
      </div>
    </div>
  )
}

FieldDateMonthPeriod.propTypes = {
  filter: PropTypes.any,
  onUpdate: PropTypes.any,
}

export default FieldDateMonthPeriod
