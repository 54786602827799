import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '../../styles'

const useStyles = makeStyles((theme) => ({
  footer: {
    width: '100%',
    borderTop: `1px solid ${theme?.palette?.primary?.contrastText}`,
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.primary?.contrastText,
    textAlign: 'center',
  },
}))
export const Footer = ({ text }) => {
  const classes = useStyles()
  return (
    <div className={classes.footer}>
      <label>{text}</label>
    </div>
  )
}

Footer.propTypes = {
  text: PropTypes.string,
}
