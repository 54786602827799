const ENDPOINTS = {
  GET: {
    account_results: '/accounts',
    account_history: '/accounts/history',
    account_size: '/accounts/size',
    billingError_results: '/billing/error',
    billingError_size: '/billing/error/size',
    billingProcess_results: '/billing/process',
    billingProcess_size: '/billing/process/size',
    billingProcess_total: '/billing/process/total',
    billing_ad_approval_status_map: '/map/billing_ad_approval_status',
    billing_ad_pending_requests: '/map/billing_ad_pending_requests',
    billing_process_service_map: '/map/billing_process_actions',
    billing_process_status_map: '/map/billing_process_status',
    billing_invoice_status_map: '/map/billing_invoice_status',
    billing_results: '/billing',
    billing_email_results: '/mail/billing',
    billing_size: '/billing/size',
    billing_total: '/billing/total',
    billing_top_customers: '/dashboard/billing/top_customers',
    billing_type_map: '/map/billing_payzen_types',
    billing_type_LATAM_map: '/payzen/billing_types',
    billing_LATAM_payzen_invoice: '/billing/latam/payzen/invoice_file',
    cancelled_chips: '/sim/simPending',
    category_map: '/map/product/category',
    commercial: '/commercial',
    commercialBonus_results: '/commercial_bonus',
    commercialBonus_size: '/commercial_bonus/size',
    commercialBonus_total: '/commercial_bonus/total',
    commercial_map: '/map/commercial',
    commercial_target_results: '/commercial_target',
    commercial_target_size: '/commercial_target/size',
    commercial_target_total: '/commercial_target/total',
    commission_results: '/commission',
    commission_size: '/commission/size',
    commission_total: '/commission/total',
    commission_summary_results: '/commission/summary',
    company_map: '/map/companies',
    components: '/preferences',
    conciliation_GPRS_plans: '/conciliation/gprs/plan',
    conciliation_GPRS_resumes: '/conciliation/gprs/resume',
    conciliation_GPRS_report: '/conciliation/gprs/report',
    conciliation_GPRS_size: '/conciliation/gprs/resume/size',
    conciliation_GPRS_report_size: '/conciliation/gprs/report/size',
    conciliation_GPRS_plan: '/suggestion/plan',
    conciliation_GPRS_report_status: '/conciliation/gprs/invoice/status',
    conciliation_GPRS_report_download: '/conciliation/gprs/report/download',
    conciliation_GPRS_report_export_status:
      '/conciliation/gprs/report/export_status',
    preferences: '/preferences',
    customer_map: '/map/customer',
    customer_results: '/customer',
    customer_size: '/customer/size',
    customer_status: '/map/customer_status',
    customer_status_map: '/map/customer_status',
    customerLatam_results: '/customer/latam',
    customerLatam_size: '/customer/latam/size',
    customer_suggest: '/suggestion/customer',
    customer_total: '/customer/total',
    dashboard_billing: `/dashboard/billing`,
    dashboard_data_type_map: '/map/dashboard_data_type',
    extract_results: '/commission/invoice',
    extract_cards_results: '/commission/invoice/cards',
    extract_status_map: '/map/extract_status',
    gprs_plan_map: '/tariff/plan/gprs',
    filters: '/preferences/filters',
    gprs_subscription_map: '/map/sim_abonnement',
    group: '/user/group',
    group_results: '/user/group',
    group_size: '/user/group/size',
    group_map: '/map/user_group',
    invoice_results: '/billing/invoice',
    invoice_size: '/billing/invoice/size',
    invoice_status_map: '/map/invoice_status',
    invoice_total: '/billing/invoice/total',
    invoice_pdf: '/billing/invoice/pdf',
    operator_map: '/map/operator',
    opportunity: '/opportunity',
    opportunity_history: '/opportunity/history',
    opportunity_results: '/opportunity',
    opportunity_size: '/opportunity/size',
    opportunity_total: '/opportunity/total',
    commercial_suggest: '/suggestion/commercial',
    payzen_billing_type_map: '/map/billing_payzen_types',
    payzen_charge_type: '/map/payzen_charge_type',
    permission_access: '/permission/access',
    product_complete_map: '/map/default_product',
    product_items_map: '/tariff/association/groupId',
    product_map: '/map/product',
    product_results: '/tariff/association',
    product_size: '/tariff/association/size',
    salesOrder_results: '/sales_order',
    salesOrder_size: '/sales_order/size',
    salesOrder_total: '/sales_order/total',
    sales_order_status_map: '/map/sales_order_status',
    sim_status_map: '/map/sim_status',
    sub_product_map: '/map/billing_ad_subproducts',
    subproduct_map: '/map/subproduct',
    subproduct_results: '/asset/subproduct',
    sync_accounts: '/customer/sync',
    report_results: '/report',
    report_size: '/size/report',
    report_total: '/total/report',
    report_map: '/map/report',
    tableColumns: '/preferences/columns',
    tariff_default: '/map/default_tariff',
    tariff_purchase_map: '/map/tariff',
    tariff_results: '/tariff',
    tariff_sale_map: '/map/tariff',
    tariff_size: '/tariff/size',
    tariff_total: '/tariff/total',
    tariff_type_map: '/map/tariff_type',
    tariff_associated_suggestion_map: '/suggestion/tariff/associated',
    user_results: '/user',
    user_size: '/user/size',
    veripag_billing_type_map: '/map/billing_veripag_types',
    tariff_map: '/tariff',
    payzenShop_results: '/payzen/shop',
    payzenShop_size: '/payzen/shop/size',
  },
  PUT: {
    account_results: '/accounts',
    account_size: '/accounts/size',
    alerts: '/notification',
    billing_ad_approve: '/billing/ad/approve',
    billing_ad_reject: '/billing/ad/reject',
    billing_process: '/billing',
    billing_reprocess: '/logprocess',
    commercial: '/commercial',
    commissions: '/commission',
    commissions_confirm: '/commission/invoice',
    components: '/preferences',
    conciliation_GPRS_plans: '/conciliation/gprs/plan',
    customer_results: '/customer',
    filters: '/preferences/filters',
    group: '/user/group',
    invoice_results: '/billing/invoice',
    permission_access: '/permission',
    sales_order: '/sales_order',
    tableColumns: '/preferences/columns',
    user: '/user',
    user_company: '/user/default_company',
    user_language: '/user/default_language',
    account: '/accounts',
    opportunity_results: '/opportunity',
    opportunity_size: '/opportunity/size',
    opportunity_total: '/opportunity/total',
    opportunity: '/opportunity',
    commercial_suggest: '/suggestion/commercial',
    payzenShop_results: '/payzen/shop',
    payzenShop_size: '/payzen/shop/size',
    tariff_association: '/tariff/association',
    tariff_association_delete: '/tariff/association/delete',
  },
  PATCH: {
    account_results: '/accounts',
    account_size: '/accounts/size',
    billingError_results: '/billing/error',
    billingError_size: '/billing/error/size',
    billingProcess_results: '/billing/process',
    billingProcess_size: '/billing/process/size',
    billingProcess_total: '/billing/process/total',
    billing_ad_pending_requests: '/map/billing_ad_pending_requests',
    billing_email: '/mail/billing/send',
    billing_email_results: '/mail/billing',
    billing_results: '/billing',
    billing_reports: '/billing/report',
    billing_reports_total: '/billing/report/total',
    billing_reports_budget: '/billing/report/budget',
    billing_reports_size: '/billing/report/size',
    billing_size: '/billing/size',
    billing_total: '/billing/total',
    billing_type_map: '/map/payzen_billing_type',
    category_map: '/map/product/category',
    commercial: '/commercial',
    commercialBonus_results: '/commercial_bonus',
    commercialBonus_size: '/commercial_bonus/size',
    commercialBonus_total: '/commercial_bonus/total',
    commercial_map: '/map/commercial',
    commercial_target_results: '/commercial_target',
    commercial_target_size: '/commercial_target/size',
    commercial_target_total: '/commercial_target/total',
    commercial_suggest: '/suggestion/commercial',
    commission_results: '/commission',
    commission_size: '/commission/size',
    commission_total: '/commission/total',
    commission_summary_results: '/commission/summary',
    company_map: '/map/companies',
    components: '/preferences',
    conciliation_GPRS_resumes: '/conciliation/gprs/resume',
    conciliation_GPRS_size: '/conciliation/gprs/resume/size',
    conciliation_GPRS_report: '/conciliation/gprs/invoice',
    conciliation_GPRS_report_size: '/conciliation/gprs/invoice/size',
    conciliation_GPRS_report_status: '/conciliation/gprs/invoice/status',
    customer_map: '/map/customer',
    customer_results: '/customer',
    customer_size: '/customer/size',
    customerLatam_results: '/customer/latam',
    customerLatam_size: '/customer/latam/size',
    customer_status: '/map/customer_status',
    customer_total: '/customer/total',
    dashboard_data_type_map: '/map/dashboard_data_type',
    extract_results: '/commission/invoice',
    extract_cards_results: '/commission/invoice/cards',
    filters: '/preferences/filters',
    gprs_plan_map: '/tariff/plan/gprs',
    gprs_subscription_map: '/map/sim_abonnement',
    group_map: '/map/user_group',
    group: '/user/group',
    group_results: '/user/group',
    group_size: '/user/group/size',
    invoice_results: '/billing/invoice',
    invoice_size: '/billing/invoice/size',
    invoice_status_map: '/map/invoice_status',
    invoice_total: '/billing/invoice/total',
    invoice_pdf: '/billing/invoice/pdf',
    operator_map: '/map/operator',
    opportunity: '/opportunity',
    opportunity_results: '/opportunity',
    opportunity_size: '/opportunity/size',
    opportunity_total: '/opportunity/total',
    payzen_billing_type_map: '/map/payzen_billing_type',
    payzen_charge_type: '/map/payzen_charge_type',
    product_complete_map: '/map/default_product',
    product_map: '/map/product',
    product_results: '/tariff/association',
    product_size: '/tariff/association/size',
    salesOrder_results: '/sales_order',
    salesOrder_size: '/sales_order/size',
    salesOrder_total: '/sales_order/total',
    sim_status_map: '/map/sim_status',
    sub_product_map: '/map/billing_ad_subproducts',
    report_results: '/report',
    report_size: '/size/report',
    report_total: '/total/report',
    report_map: '/map/report',
    tableColumns: '/preferences/columns',
    tariff_default: '/map/default_tariff',
    tariff_purchase_map: '/map/tariff',
    tariff_results: '/tariff',
    tariff_sale_map: '/map/tariff',
    tariff_size: '/tariff/size',
    tariff_total: '/tariff/total',
    tariff_type_map: '/map/tariff_type',
    user_results: '/user',
    user_size: '/user/size',
    payzenShop_results: '/payzen/shop',
    payzenShop_size: '/payzen/shop/size',
  },
  POST: {
    commercial: '/commercial',
    conciliation_GPRS_plans: '/conciliation/gprs/plan',
    conciliation_GPRS_readInvoices: '/conciliation/gprs/readInvoices',
    conciliation_GPRS_report: '/conciliation/gprs/invoice',
    billing_email: '/mail/billing/send',
    customer_suggest: '/customer',
    upload_billing_email: '/mail/billing/upload',
    nfe_processor: '/billing/nfe_processor',
    export: '/export',
    user: '/user',
    user_clone: '/user/clone',
    group: '/user/group',
    group_clone: '/user/group/clone',
    invoice_results: '/billing/invoice',
    billing_process: '/billing',
    billing_process_commissions: '/commission/schedule',
    billing_process_ad: '/billing/br/ad',
    billing_LATAM_payzen_invoice: '/billing/latam/payzen/invoice_file',
    subproduct_results: '/asset/subproduct',
    sales_order: '/sales_order',
    sales_order_send: '/sales_order/send',
    commissions: '/commission',
    commissions_new_release: '/commission/invoice',
    tariff_association: '/tariff/association',
    account: '/accounts',
    opportunity: '/opportunity',
    payzenShop_results: '/payzen/shop',
    payzenShop_size: '/payzen/shop/size',
    sync_accounts: '/accounts/sync',
    billing_reports: '/billing/report',
    process_counter: '/conciliation/gprs/process_counter',
  },
  DELETE: {
    alerts: '/notification',
    billing_ad_clean: '/billing/ad',
    payzenShop_results: '/payzen/shop',
    payzenShop_size: '/payzen/shop/size',
    tariff_association: '/tariff/association',
    billing_process_ad: '/billing/br/ad',
    billing_email_results: '/mail/billing',
    conciliation_delete_claro_invoice: '/conciliation/gprs/readInvoices',
    conciliation_delete_lyra_invoice: '/conciliation/gprs/invoice',
    conciliation_GPRS_plans: '/conciliation/gprs/plan',
    commissions: '/commission',
    commissions_new_release: '/commission/invoice',
  },
}

export default ENDPOINTS
