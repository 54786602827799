import PropTypes from 'prop-types'
import React, { useReducer } from 'react'

const initialState = {
  isOpen: false,
}

const reducer = (state, action) => {
  let newData = {}
  switch (action.type) {
    case 'setFilter':
      return {
        ...state,
        [action.screen]: action.data,
        isOpen: true,
      }
    case 'setCycleFilter':
      return {
        ...state,
        [action.screen]: action.data,
        isOpen: false,
      }
    case 'removeFilter':
      newData = { ...state }

      delete newData[action.screen]

      return {
        ...newData,
        isOpen: false,
      }
    case 'openFilter':
      return {
        ...state,
        isOpen: true,
      }
    case 'clear':
      return {
        ...initialState,
      }
    default:
      return null
  }
}

export const FiltersContext = React.createContext()

const Provider = ({ children }) => {
  const context = useReducer(reducer, initialState)
  return (
    <FiltersContext.Provider value={context}>
      {children}
    </FiltersContext.Provider>
  )
}

Provider.defaultProps = {
  children: <></>,
}

Provider.propTypes = {
  children: PropTypes.node,
}

export default Provider
