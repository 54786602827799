import {
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '../../../../core'
import { makeStyles, useTheme } from '../../../../styles'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { intl } from '../../../../translation'
import { useSnackbar } from '../../../../notifications'
import { convertHexToRGBA } from '../../../../utils'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'

const useStyles = makeStyles((theme) => ({
  input: {
    color: theme?.palette?.secondary?.contrastText,
  },
  div: {
    marginTop: 5,
    marginBottom: 5,
  },
  equal: {
    color: theme?.palette?.secondary?.contrastText,
    fontFamily: theme?.typography?.fontFamily,
    fontSize: '21px',
  },
  notEqual: {
    color: 'red',
    fontFamily: theme?.typography?.fontFamily,
    fontSize: '21px',
  },
  equalsAdornment: {
    paddingLeft: 0,
  },
  title: {
    color: theme?.palette?.secondary?.contrastText,
    textAlign: 'left',
    marginBottom: 0,
  },
  scrollbar: {
    cursor: 'default',
  },
}))

const usePrevious = (value) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

const FieldSelectSuggestMulti = (props) => {
  const {
    listOptions,
    filter,
    enableNotEquals,
    onUpdate,
    selectMenuPosition,
    requestNewOptions,
  } = props
  const theme = useTheme()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const prevFilter = usePrevious(filter)

  const dot = (data) => {
    if (data && data?.hasDot && data?.color) {
      return {
        display: 'flex',
        ':before': {
          backgroundColor: data?.color,
          borderRadius: 15,
          content: '" "',
          display: 'block',
          marginRight: 8,
          height: 15,
          width: 15,
          borderColor: '#fff',
          borderWidth: 1,
          borderStyle: 'solid',
        },
      }
    } else {
      return null
    }
  }

  const icon = (data) => {
    if (data && data?.hasIcon && data?.imgSource) {
      return {
        display: 'flex',
        ':before': {
          backgroundImage: data?.imgSource,
          borderRadius: 15,
          content: '" "',
          display: 'block',
          marginRight: 8,
          height: 15,
          width: 15,
          borderColor: '#fff',
          borderWidth: 1,
          borderStyle: 'solid',
        },
      }
    } else {
      return null
    }
  }

  const customStyles = {
    container: (provided, providedState) => ({
      ...provided,
      width: '100%',
    }),
    option: (provided, providedState) => ({
      ...provided,
      cursor: 'pointer',
      textAlign: 'left',
      fontWeight: providedState?.isSelected ? 'bold' : '',
      backgroundColor: theme?.palette?.secondary?.main,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
      '&:hover': {
        backgroundColor: convertHexToRGBA(theme?.palette?.primary?.main, 0.3),
        fontWeight: 'bold',
      },
      ...dot(providedState?.data),
      ...icon(providedState?.data),
    }),
    control: (provided, providedState) => ({
      ...provided,
      cursor: 'text',
      border: providedState?.selectProps?.value?.length
        ? `2px solid ${theme?.palette?.secondary?.contrastText}`
        : `1px solid ${theme?.palette?.secondary?.contrastText}`,
      '&:hover': {
        border: !providedState?.selectProps.value
          ? `1px solid ${theme?.palette?.secondary?.contrastText}`
          : `2px solid ${theme?.palette?.secondary?.contrastText}`,
        '&:active': {
          border: !providedState?.selectProps.value
            ? `1px solid ${theme?.palette?.secondary?.contrastText}`
            : `2px solid ${theme?.palette?.secondary?.contrastText}`,
        },
      },
      backgroundColor: theme?.palette?.secondary?.main,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
    }),
    placeholder: (provided, providedState) => ({
      ...provided,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
      opacity: '0.4',
      fontSize: '0.8em',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
      cursor: 'pointer',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
      cursor: 'pointer',
    }),
    singleValue: (provided, providedState) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
      marginLeft: 0,
      ...dot(providedState?.selectProps.value),
    }),
    multiValue: (provided) => ({
      ...provided,
      cursor: 'pointer',
      color: theme?.palette?.quaternary?.contrastText,
    }),
    valueContainer: (provided, providedState) => ({
      ...provided,
      paddingLeft: 7,
    }),
    input: (provided) => ({
      ...provided,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
    }),
    menu: (provided) => ({
      ...provided,
      overflowY: 'auto',
      maxHeight: '400px',
      backgroundColor: theme?.palette?.secondary?.main,
      border: `1px solid ${theme?.palette?.secondary?.contrastText}`,
      borderRadius: '5px',
      fontSize: '1em',
      zIndex: 9999,
      position: selectMenuPosition || 'absolute',
      paddingLeft: 5,
    }),
  }

  const filterState = {
    behavior: filter?.behavior,
    defaultName: filter?.defaultName,
    hasSuggested: filter?.hasSuggested,
    isSuggested: filter?.isSuggested,
    minToMakeRequest: filter?.minToMakeRequest,
    type: filter?.type,
    value: filter?.value,
    condition: filter?.condition,
    enableNotEquals: filter?.enableNotEquals,
  }

  const [data, setData] = useState(filterState)
  const firstLoad = useRef(true)

  useEffect(() => {
    if (firstLoad.current === true) {
      firstLoad.current = false
    } else {
      onUpdate(data)
    }
  }, [data])

  useEffect(() => {
    if (!_.isEqual(prevFilter, filter)) {
      setData(filter)
    }
  }, [prevFilter, filter])

  const handleChange = (value) => {
    if (!value) {
      setData({ ...data, value: [] })
    } else {
      setData({
        ...data,
        value,
        isSuggested: true,
      })
    }
  }

  const handleInputChange = (e, callbackFunction) => {
    if (typeof requestNewOptions !== 'function') {
      return
    }
    if (!e || e.length < filter?.minToMakeRequest) {
      return []
    } else {
      requestNewOptions(e)
        .then((data) => {
          callbackFunction(data?.data)
        })
        .catch((err) => {
          console.log(err)
          enqueueSnackbar(
            intl.get('phrases.loadOptionsError') || 'phrases.loadOptionsError',
            {
              variant: 'error',
            }
          )
        })
    }
  }

  const handleClickCondition = () => {
    setData((thisData) => ({ ...thisData, condition: !thisData.condition }))
  }

  const TextMaskCustom = (props) => {
    if (!listOptions || _.isEmpty(listOptions)) {
      return (
        <AsyncSelect
          classNamePrefix={intl.get(`titles.${filter?.defaultName}`)}
          name={intl.get(`titles.${filter?.defaultName}`)}
          isMulti
          isClearable
          cacheOptions
          closeMenuOnSelect={false}
          placeholder={
            intl.get('words.inputSearchTitle') || 'words.inputSearchTitle'
          }
          inputProps={{ className: classes.input }}
          value={
            !filter?.value || filter?.value?.length === 0 ? [] : filter?.value
          }
          loadOptions={handleInputChange}
          onChange={handleChange}
          styles={customStyles}
        />
      )
    } else {
      return (
        <Select
          inputId="selectInput"
          isMulti
          closeMenuOnSelect={false}
          classNamePrefix={intl.get(`titles.${filter?.defaultName}`)}
          placeholder={
            intl.get('words.inputSearchTitle') || 'words.inputSearchTitle'
          }
          value={!data?.value || data?.value.length === 0 ? [] : data?.value}
          isSearchable
          name={intl.get(`titles.${filter?.defaultName}`)}
          options={listOptions}
          onChange={handleChange}
          styles={customStyles}
          backspaceRemoves
          isClearable
          inputProps={{ className: classes.input }}
          noOptionsMessage={() =>
            intl.get('phrases.noOptions') || 'phrases.noOptions'
          }
        />
      )
    }
  }

  return (
    <div data-testid="multi-select">
      <Typography className={classes.title}>
        {intl.get(`titles.${filter?.filterName || filter?.defaultName}`) || ''}
      </Typography>
      <TextField
        fullWidth
        height="auto"
        backgroundcolor="secondary"
        textcolor="transparent"
        inputProps={{
          role: 'textbox',
        }}
        InputProps={{
          classes: {
            root: classes.scrollbar,
            adornedStart: classes.equalsAdornment,
          },
          disableUnderline: true,
          inputComponent: () => TextMaskCustom(),
          startAdornment: enableNotEquals && (
            <InputAdornment position="start">
              {!data?.condition ? (
                <Tooltip title={intl.get('words.inputNotEqual')}>
                  <Typography
                    className={classes.notEqual}
                    component="h2"
                    variant="subheading"
                    onClick={handleClickCondition}
                  >
                    &#8800;
                  </Typography>
                </Tooltip>
              ) : (
                <Tooltip title={intl.get('words.inputEqual')}>
                  <Typography
                    className={classes.equal}
                    component="h2"
                    variant="subheading"
                    onClick={handleClickCondition}
                  >
                    &#61;
                  </Typography>
                </Tooltip>
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  )
}

FieldSelectSuggestMulti.propTypes = {
  enableNotEquals: PropTypes.any,
  filter: PropTypes.any,
  listOptions: PropTypes.any,
  onUpdate: PropTypes.any,
}

export default FieldSelectSuggestMulti
