import React from 'react'
import { Loading } from '../../components/Loading'
import { Card, CardContent, Icon, Typography } from '../../core'
import { useStyles } from './styles'

export const CardWithIcon = ({
  title,
  subTitle,
  icon,
  loading,
  align = 'left',
}) => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className={classes.iconSpace}>
            <Icon className={classes.icon}>{icon}</Icon>
          </div>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography variant="subtitle1">
                {title?.toString() || '-'}
              </Typography>
              <Typography
                component="span"
                variant="h5"
                style={{ textAlign: align }}
              >
                {subTitle?.toString() || '-'}
              </Typography>
            </CardContent>
          </div>
        </>
      )}
    </Card>
  )
}

CardWithIcon.propTypes = {}

export const CardWithGraph = ({ title, graphContent, loading, align }) => {
  const classes = useStyles()

  return (
    <Card className={classes.rootGraph} variant="outlined">
      <div className={classes.headerTitle}>
        <div className={classes.title}>{title?.toString() || '-'}</div>
      </div>
      <div className={classes.detailsGraph}>
        {loading ? (
          <div className={classes.detailsGraphLoading}>
            <Loading align={align} />
          </div>
        ) : (
          <CardContent className={classes.contentGraph}>
            {graphContent || <h1>graphContent</h1>}
          </CardContent>
        )}
      </div>
    </Card>
  )
}

CardWithGraph.propTypes = {}

export const CardWithDoubleGraph = ({
  title,
  graphContentLeft,
  graphContentRight,
  loading,
  align,
}) => {
  const classes = useStyles()

  return (
    <Card className={classes.rootGraph} variant="outlined">
      <div className={classes.headerTitle}>
        <div className={classes.title}>{title?.toString() || '-'}</div>
      </div>
      <div className={classes.detailsGraph}>
        {loading ? (
          <Loading align={align} />
        ) : (
          <CardContent className={classes.contentGraph}>
            <div className={classes.graphDoubleContainer}>
              <div className={classes.graphDoubleLeft}>
                {graphContentLeft || <h1>graphContentLeft</h1>}
              </div>
              <div className={classes.graphDoubleRight}>
                {graphContentRight || <h1>graphContentRight</h1>}
              </div>
            </div>
          </CardContent>
        )}
      </div>
    </Card>
  )
}

CardWithDoubleGraph.propTypes = {}
