import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '../../../styles'
import { convertHexToRGBA } from '../../../utils'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    paddingLeft: 8,
    paddingRight: 8,
    borderRight: `1px solid ${convertHexToRGBA(
      theme?.palette?.secondary?.contrastText,
      0.3
    )}`,
    borderBottom: `1px solid ${convertHexToRGBA(
      theme?.palette?.secondary?.contrastText,
      0.3
    )}`,
  },
}))

const Cell = ({ row, className, column, onDoubleClick, value, ...others }) => {
  const classes = useStyles()

  return (
    <TableHeaderRow.Cell
      {...others}
      row={row}
      column={column}
      onDoubleClick={(e) => onDoubleClick(e, row, column)}
      className={classNames(className, classes.root, classes.tableCell)}
    >
      {value}
    </TableHeaderRow.Cell>
  )
}

Cell.defaultProps = {
  row: {},
  className: '',
  column: {},
  onDoubleClick: () => {},
}

Cell.propTypes = {
  row: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  column: PropTypes.object.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
}

export default Cell
