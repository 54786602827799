import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import { deepMergeObjects } from '../utils/ObjectsMerge'

export const WrapperTheme = ({ theme, children }) => {
  const defaultTheme = createTheme({
    typography: {
      useNextVariants: true,
      fontFamily: "'Titillium Web', sans-serif",
    },
    palette: {
      primary: {
        main: '#001064',
        contrastText: '#ffffff',
        hover: '#000B44',
        hoverContrast: '#F0F1F7',
      },
      secondary: {
        main: '#ffffff',
        contrastText: '#3B3A39',
        hover: '#F0F0F0',
        hoverContrast: '#9e9e9e ',
      },
      tertiary: {
        main: '#D4A977',
        contrastText: '#ffffff',
        hover: '#C48D4E',
        hoverContrast: '#F5F2EF',
      },
      quaternary: {
        main: '#F5F5F5',
        contrastText: '#000000',
      },
    },
  })

  const mixedThemes = deepMergeObjects(defaultTheme, theme)

  return <ThemeProvider theme={mixedThemes}>{children}</ThemeProvider>
}

WrapperTheme.propTypes = {}
