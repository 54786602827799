import React from 'react'
import { intl } from '../../../../translation'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

const Screen = ({ ...props }) => (
  <DataTypeProvider
    formatterComponent={({ value, ...props }) =>
      value ? intl.get(`pages.${value}`) : value
    }
    {...props}
  />
)

export default Screen
