import useCallAPI from './useCallAPI'

const filterQuery = (values) => {
  let newCodeList = ''

  for (let i = 0; i < values?.codeList?.length; i++) {
    const code = values?.codeList?.[i]
    if (i === values?.codeList?.length - 1) {
      newCodeList = newCodeList + code
    } else {
      newCodeList = newCodeList + code + ','
    }
  }

  return {
    cycle: values?.cycle,
    productId: values?.productId,
    code: values?.code,
    codeList: newCodeList ? newCodeList : undefined,
    currencyCode: values?.currencyCode,
  }
}

const filterBody = ({
  cycle,
  productId,
  customerId,
  companyId,
  id,
  paymentDate,
  screen,
  code,
  codeList,
  currencyCode,
}) => ({
  cycle,
  productId,
  customerId,
  screen,
  id,
  paymentDate,
  code,
  codeList,
  currencyCode,
  companyId,
})

export const useInvoiceData = (newContext) => {
  const {
    reset,
    requestError,
    requestSuccess,
    requestLoading,
    requestData,
    fetchGET,
    fetchPUT,
    fetchPOST,
    fetchPATCH,
  } = useCallAPI({
    initialCall: false,
    context: newContext ? `invoice_${newContext}` : 'invoice_results',
  })

  const state = requestData.data

  const handleGET = (params) => {
    fetchGET({
      userParams: filterQuery(params),
      isFileDownload: true,
    })
  }

  const handlePOST = (params) => {
    fetchPOST({
      userBody: filterBody(params),
    })
  }

  const handlePOSTInvoice = (params) => {
    fetchPOST({
      userBody: filterBody(params),
      newContext: 'billing_LATAM_payzen_invoice',
    })
  }

  const handlePUT = (params) => {
    fetchPUT({
      userBody: filterBody(params),
    })
  }

  const handlePATCH = (params) => {
    fetchPATCH({
      userBody: filterBody(params),
      isFileDownload: true,
    })
  }

  return {
    reset,
    state,
    error: requestError,
    success: requestSuccess,
    loading: requestLoading,
    handleGET,
    handlePOST,
    handlePOSTInvoice,
    handlePUT,
    handlePATCH,
  }
}
