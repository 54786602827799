import completeLogoBlueTransparentSVG from '@library/images/logos/completeLogoBlueTransparent.svg'
import completeLogoM2MBlueTransparentPNG from '@library/images/logos/completeLogoM2MBlueTransparent.png'
import textLogoBlueTransparentPNG from '@library/images/logos/textLogoBlueTransparent.png'
import textLogoM2MBlueTransparentSVG from '@library/images/logos/textLogoM2MBlueTransparent.svg'
import textLogoM2MWhiteTransparentSVG from '@library/images/logos/textLogoM2MWhiteTransparent.svg'
import textLogoWhiteTransparentPNG from '@library/images/logos/textLogoWhiteTransparent.png'

const logos = {
  completeLogoBlueTransparentSVG,
  completeLogoM2MBlueTransparentPNG,
  textLogoBlueTransparentPNG,
  textLogoM2MBlueTransparentSVG,
  textLogoM2MWhiteTransparentSVG,
  textLogoWhiteTransparentPNG,
}

export { logos }
