import PropTypes from 'prop-types'
import React from 'react'
import {
  ClickAwayListener,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popper,
  Tooltip,
} from '../../../../core'
import { Archive as ArchiveIcon } from '../../../../icons'
import { makeStyles } from '../../../../styles'
import { intl } from '../../../../translation'
import SubMenu from './SubMenu'

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.tertiary?.main,
  },
  popper: {
    zIndex: 999,
  },
  paper: {
    maxWidth: 300,
    minWidth: 250,
    maxHeight: 500,
    overflowY: `auto`,
    overflowX: `hidden`,
  },
}))

const Export = ({ exportList, handleButtonClick }) => {
  const classes = useStyles()
  const anchorRef = React.useRef(null)
  const [open, setOpen] = React.useState(false)

  const handleExport = (item) => {
    handleButtonClick('Export', item)
    setOpen(false)
  }

  const ItemType = ({ item }) => {
    if (item?.subMenuItems?.length > 0) {
      return <SubMenu item={item} onClick={handleExport} />
    } else {
      return (
        <ListItem button onClick={() => handleExport(item)}>
          <Tooltip title={item?.label}>
            <ListItemText primary={item?.label} />
          </Tooltip>
        </ListItem>
      )
    }
  }

  return (
    <>
      <Tooltip
        title={intl.get(`phrases.scheduleExport`) || 'phrases.scheduleExport'}
      >
        <IconButton
          ref={anchorRef}
          onClick={() => setOpen(!open)}
          className={classes.icon}
        >
          <ArchiveIcon />
        </IconButton>
      </Tooltip>
      <Popper
        transition
        open={open}
        placement="bottom-end"
        className={classes.popper}
        anchorEl={anchorRef?.current}
      >
        {({ TransitionProps }) => (
          <Collapse {...TransitionProps}>
            <Paper className={classes.paper} elevation={3}>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <List component="nav" className={classes.root}>
                  {exportList?.map((item) => (
                    <ItemType key={item?.label} item={item} />
                  ))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Collapse>
        )}
      </Popper>
    </>
  )
}

Export.defaultProps = {
  exportList: [],
  handleButtonClick: () => {},
}

Export.propTypes = {
  exportList: PropTypes.array.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
}

export default Export
