import React from 'react'
import { intl } from '../../../../translation'
import classNames from 'classnames'
import { makeStyles } from '../../../../styles'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

const useStyles = makeStyles((theme) => ({
  dot: {
    width: 10,
    height: 10,
    borderRadius: 10,
    marginRight: 10,
    display: 'inline-block',
  },
  blue: {
    color: 'blue',
  },
  red: {
    color: 'red',
  },
  gray: {
    color: 'gray',
  },
  green: {
    color: 'green',
  },
}))

const StatusReport = ({ className, ...props }) => {
  const classes = useStyles()

  const returnColorGreen = (value) => {
    switch (value) {
      case 'ACCEPTED':
        return true
      case 'APPROVED':
        return true
      case 'SENT':
        return true
      default:
        return false
    }
  }

  const returnColorBlue = (value) => {
    switch (value) {
      case 'Com Contrato':
        return true
      case 'PAID':
        return true
      case 'REQUESTED':
        return true
      case 'READY_TO_SEND':
        return true
      default:
        return false
    }
  }

  const returnColorRed = (value) => {
    switch (value) {
      case 'Encerrado':
        return true
      case 'NOT_PAID':
        return true
      case 'REJECTED':
        return true
      case 'REFUSED':
        return true
      default:
        return false
    }
  }

  const returnColorGray = (value) => {
    switch (value) {
      case 'Encerrado':
        return true
      case 'CANCELLED':
        return true
      case 'PENDING':
        return true
      case 'NEW':
        return true
      default:
        return false
    }
  }

  const verifyValue = (value) => {
    switch (value) {
      case 'CANCELLED':
        return intl.get(`status.CANCELLED`)
      case 'PAID':
        return intl.get(`status.PAID`)
      case 'NOT_PAID':
        return intl.get(`status.NOT_PAID`)
      case 'PENDING':
        return intl.get(`status.PENDING`)
      case 'REQUESTED':
        return intl.get(`status.REQUESTED`)
      case 'ACCEPTED':
        return intl.get(`status.ACCEPTED`)
      case 'APPROVED':
        return intl.get(`status.APPROVED`)
      case 'REFUSED':
        return intl.get(`status.REFUSED`)
      case 'REJECTED':
        return intl.get(`status.REJECTED`)
      case 'NEW':
        return intl.get(`status.NEW`)
      case 'READY_TO_SEND':
        return intl.get(`status.READY_TO_SEND`)
      case 'SENT':
        return intl.get(`status.SENT`)
      case 'NOT_NEEDED':
        return intl.get(`status.NOT_NEEDED`)
      default:
        return value
    }
  }

  return (
    <DataTypeProvider
      formatterComponent={({ value, ...props }) =>
        value !== '' ? (
          <span
            className={classNames(className, {
              [classes.blue]: returnColorBlue(value),
              [classes.red]: returnColorRed(value),
              [classes.gray]: returnColorGray(value),
              [classes.green]: returnColorGreen(value),
            })}
          >
            {verifyValue(value)}
          </span>
        ) : (
          <span>{value}</span>
        )
      }
      {...props}
    />
  )
}

export default StatusReport
