import React from 'react'
import { makeStyles } from '../../styles'
import { TableCell, TableRow } from '../../core'

const useStyles = makeStyles((theme) => ({
  headerRow: {
    fontSize: 16,
    fontWeight: 'bold',
  },
}))

export const HeaderRow = ({ header }) => {
  const classes = useStyles()

  return (
    <TableRow>
      {header &&
        header.map((cell, index) => {
          return (
            <TableCell
              className={classes.headerRow}
              key={`${index}_${cell.text}`}
              align={cell?.align}
            >
              {cell?.text}
            </TableCell>
          )
        })}
    </TableRow>
  )
}

export const BodyRow = ({ body }) => {
  return (
    <>
      {body &&
        body.map((row, index) => {
          return (
            <TableRow key={`${index}_${row[0].value}`}>
              {row &&
                row.map((cell, index) => {
                  return (
                    <TableCell
                      key={`${index}_${cell.value}`}
                      align={cell?.align}
                    >
                      {cell?.value}
                    </TableCell>
                  )
                })}
            </TableRow>
          )
        })}
    </>
  )
}
