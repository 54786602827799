import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { FormControl, IconButton, Typography } from '../../../core'
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '../../../icons'
import { makeStyles, useTheme } from '../../../styles'
import { intl } from '../../../translation'

const useStyles = makeStyles((theme) => ({
  containerBar: {
    width: '100%',
    border: `1px solid ${theme?.palette?.tertiary?.main}`,
    borderRadius: 5,
  },
  chart: {
    fontFamily: theme?.typography?.fontFamily,
  },
  label: {
    width: '95%',
    textAlign: 'right',
    paddingTop: 10,
    display: 'block',
    fontFamily: theme?.typography?.fontFamily,
    color: theme?.palette?.tertiary?.main,
    fontSize: '0.8em',
    textTransform: 'uppercase',
  },
  header: {
    height: 80,
    border: `1px solid ${theme?.palette?.tertiary?.main}`,
    marginBottom: 10,
  },
  select: {
    height: 40,
    width: 200,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 5,
    marginTop: 2,
    backgroundColor: theme?.palette?.primary?.main,
  },
  monthSelect: {
    minWidth: 200,
  },
  iconButton: {
    margin: 5,
    display: 'block',
    color: theme?.palette?.tertiary?.main,
  },
  titleHeader: {
    color: theme?.palette?.tertiary?.main,
    margin: '0px auto',
  },
  display: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
}))

const MonthNavigation = ({
  monthNavigationDefaultDate,
  monthNavigationDateStart,
  monthNavigationDateEnd,
  monthNavigationIsLoading,
  monthNavigationReferenceText,
  monthNavigationMessage,
  onMonthNavigationClick,
  handleButtonClick,
}) => {
  const classes = useStyles()
  const [cycles, setCycles] = useState([])
  const [selectedMonth, setSelectedMonth] = useState({})
  const [initialDate, setInitialDate] = useState({})

  useEffect(() => {
    const defaultDateNormalized = {}

    if (monthNavigationDefaultDate) {
      const defaultDate = moment(monthNavigationDefaultDate, 'DD/MM/YYYY')

      defaultDateNormalized.value = defaultDate

      defaultDateNormalized.valueFormatted = defaultDate.format('YYYYMM')

      defaultDateNormalized.label = defaultDate
        .locale('pt-BR')
        .format('MMMM/YYYY')
        .toUpperCase()

      setInitialDate(defaultDateNormalized)
      return
    }

    defaultDateNormalized.value = moment()
    defaultDateNormalized.valueFormatted = moment().format('YYYYMM')
    defaultDateNormalized.label = moment().format('MMMM/YYYY').toUpperCase()

    setInitialDate(defaultDateNormalized)
  }, [monthNavigationDefaultDate])

  useEffect(() => {
    if (initialDate) {
      !_.isEmpty(initialDate) && _.isEmpty(cycles) && setCycles([initialDate])
      setSelectedMonth(initialDate)
      return
    }
    const now = moment()
    setCycles([now])
    setSelectedMonth(now)
  }, [initialDate])

  const theme = useTheme()

  const customStyles = {
    option: (provided, providedState) => ({
      ...provided,
      fontWeight: providedState?.isSelected ? 'bold' : '',
      backgroundColor: theme?.palette?.secondary?.main,
      color: theme?.palette?.secondary?.contrastText,
    }),
    control: (provided, providedState) => ({
      ...provided,
      backgroundColor: theme?.palette?.secondary?.main,
      border: !providedState?.selectProps?.value?.value
        ? `1px solid ${theme?.palette?.secondary?.contrastText}`
        : `2px solid ${theme?.palette?.secondary?.contrastText}`,
      '&:hover': {
        border: !providedState?.selectProps?.value?.value
          ? `1px solid ${theme?.palette?.secondary?.contrastText}`
          : `2px solid ${theme?.palette?.secondary?.contrastText}`,
        '&:active': {
          border: !providedState?.selectProps?.value?.value
            ? `1px solid ${theme?.palette?.secondary?.contrastText}`
            : `2px solid ${theme?.palette?.secondary?.contrastText}`,
        },
      },
      color: theme?.palette?.secondary?.contrastText,
    }),
    placeholder: (provided, providedState) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
      opacity: !providedState?.selectProps?.value?.value ? '0.4' : 1,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
    }),
    singleValue: (provided) => ({
      ...provided,
      backgroundColor: theme?.palette?.secondary?.main,
      color: theme?.palette?.secondary?.contrastText,
    }),
    input: (provided) => ({
      ...provided,
      fontFamily: theme?.typography?.fontFamily,
      backgroundColor: theme?.palette?.secondary?.main,
      color: theme?.palette?.secondary?.contrastText,
    }),
    menu: (provided) => ({
      ...provided,
      overflowY: 'auto',
      maxHeight: '400px',
      backgroundColor: theme?.palette?.secondary?.main,
      border: `1px solid ${theme?.palette?.secondary?.contrastText}`,
      borderRadius: '5px',
      fontSize: '1em',
      opacity: '1.0',
      zIndex: 9999,
    }),
  }

  const handleReduceCycle = () => {
    onMonthNavigationClick()
    if (
      moment(selectedMonth?.value, 'DD/MM/YYYY').format('YYYYMM') >
      moment(initialDate?.value, 'DD/MM/YYYY').format('YYYYMM')
    ) {
      const newCycles = cycles
      newCycles.pop()
      setSelectedMonth(newCycles[newCycles?.length - 1])

      typeof handleButtonClick === 'function' &&
        !_.isEmpty(newCycles[newCycles?.length - 1]) &&
        handleButtonClick('MonthNavigation', newCycles[newCycles?.length - 1])
      setCycles(newCycles)
      return
    }

    if (
      moment(selectedMonth?.value, 'DD/MM/YYYY').format('YYYYMM') <=
      moment(initialDate?.value, 'DD/MM/YYYY').format('YYYYMM')
    ) {
      const newCycle = moment(selectedMonth?.value, 'DD/MM/YYYY').subtract(
        1,
        'M'
      )
      const newObject = {
        valueFormatted: moment(newCycle, 'DD/MM/YYYY').format('YYYYMM'),
        value: moment(newCycle, 'DD/MM/YYYY'),
        label: moment(newCycle, 'DD/MM/YYYY').format('MMMM/YYYY').toUpperCase(),
      }

      typeof handleButtonClick === 'function' &&
        !_.isEmpty(newObject) &&
        handleButtonClick('MonthNavigation', newObject)
      setSelectedMonth(newObject)
      if (cycles.findIndex((cycle) => cycle.label === newObject.label) === -1) {
        setCycles([...cycles, newObject])
      }
    }
  }

  const handleIncreaseCycle = () => {
    onMonthNavigationClick()
    if (
      moment(selectedMonth?.value, 'DD/MM/YYYY').format('YYYYMM') <
      moment(initialDate?.value, 'DD/MM/YYYY').format('YYYYMM')
    ) {
      const newCycles = cycles
      newCycles.pop()

      typeof handleButtonClick === 'function' &&
        !_.isEmpty(newCycles[newCycles?.length - 1]) &&
        handleButtonClick('MonthNavigation', newCycles[newCycles?.length - 1])
      setSelectedMonth(newCycles[newCycles?.length - 1])
      setCycles(newCycles)
    }

    if (
      moment(selectedMonth?.value, 'DD/MM/YYYY').format('YYYYMM') >=
      moment(initialDate?.value, 'DD/MM/YYYY').format('YYYYMM')
    ) {
      const newCycle = moment(selectedMonth?.value, 'DD/MM/YYYY').add(1, 'M')
      const newObject = {
        valueFormatted: moment(newCycle, 'DD/MM/YYYY').format('YYYYMM'),
        value: moment(newCycle, 'DD/MM/YYYY'),
        label: moment(newCycle, 'DD/MM/YYYY').format('MMMM/YYYY').toUpperCase(),
      }

      typeof handleButtonClick === 'function' &&
        !_.isEmpty(newObject) &&
        handleButtonClick('MonthNavigation', newObject)

      setSelectedMonth(newObject)
      if (cycles.findIndex((cycle) => cycle.label === newObject.label) === -1) {
        setCycles([...cycles, newObject])
      }
    }
  }

  useEffect(() => {
    cycles.sort((a, b) => a.valueFormatted - b.valueFormatted)
  }, [cycles])

  const handleChangeMonth = (selectedItem) => {
    onMonthNavigationClick()
    selectedItem && setSelectedMonth(selectedItem)
    typeof handleButtonClick === 'function' &&
      selectedItem &&
      handleButtonClick('MonthNavigation', selectedItem)
  }

  if (monthNavigationDateStart && !monthNavigationDateEnd) {
    return (
      <div className={classes.display}>
        <Typography
          variant="subtitle1"
          display="block"
          className={classes.titleHeader}
        >
          {`${
            intl.get(`phrases.periodAfter`, {
              date:
                monthNavigationDateStart?.length === 6
                  ? moment(monthNavigationDateStart, 'YYYYMM')
                      .locale('pt-BR')
                      .format('MMMM/YYYY')
                      .toUpperCase()
                  : moment(monthNavigationDateStart)
                      .locale('pt-BR')
                      .format('D [de] MMMM [de] YYYY')
                      .toUpperCase(),
            }) || 'phrases.periodAfter'
          }`}
        </Typography>
      </div>
    )
  }

  if (!monthNavigationDateStart && monthNavigationDateEnd) {
    return (
      <div className={classes.display}>
        <Typography
          variant="subtitle1"
          display="block"
          className={classes.titleHeader}
        >
          {`${
            intl.get(`phrases.periodBefore`, {
              date:
                monthNavigationDateEnd?.length === 6
                  ? moment(monthNavigationDateEnd, 'YYYYMM')
                      .locale('pt-BR')
                      .format('MMMM/YYYY')
                      .toUpperCase()
                  : moment(monthNavigationDateEnd)
                      .locale('pt-BR')
                      .format('D [de] MMMM [de] YYYY')
                      .toUpperCase(),
            }) || 'phrases.periodBefore'
          }`}
        </Typography>
      </div>
    )
  }

  if (monthNavigationDateStart && monthNavigationDateEnd) {
    return (
      <div className={classes.display}>
        <Typography
          variant="subtitle1"
          display="block"
          className={classes.titleHeader}
        >
          {`${
            intl.get(`phrases.periodBetween`, {
              dateStart:
                monthNavigationDateStart?.length === 6
                  ? moment(monthNavigationDateStart, 'YYYYMM')
                      .locale('pt-BR')
                      .format('MMMM/YYYY')
                      .toUpperCase()
                  : moment(monthNavigationDateStart)
                      .locale('pt-BR')
                      .format('D [de] MMMM [de] YYYY')
                      .toUpperCase(),
              dateEnd:
                monthNavigationDateEnd?.length === 6
                  ? moment(monthNavigationDateEnd, 'YYYYMM')
                      .format('MMMM/YYYY')
                      .toUpperCase()
                  : moment(monthNavigationDateEnd)
                      .format('D [de] MMMM [de] YYYY')
                      .toUpperCase(),
            }) || 'phrases.periodBetween'
          }`}
        </Typography>
      </div>
    )
  }

  if (!monthNavigationDateStart && !monthNavigationDateEnd) {
    if (monthNavigationMessage) {
      return (
        <div className={classes.display}>
          <Typography
            variant="subtitle1"
            display="block"
            className={classes.titleHeader}
          >
            {monthNavigationMessage}
          </Typography>
        </div>
      )
    } else {
      return (
        <div className={classes.display}>
          <IconButton
            role="button"
            component="span"
            onClick={handleReduceCycle}
            className={classes.iconButton}
            disabled={monthNavigationIsLoading}
            size="small"
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <FormControl role="form">
            <div data-testid="select" title={selectedMonth.label}>
              <Select
                value={selectedMonth}
                onChange={handleChangeMonth}
                placeholder={intl.get(`words.months`) || 'words.months'}
                options={cycles}
                className={classes.select}
                styles={customStyles}
                getOptionLabel={(option) =>
                  `${monthNavigationReferenceText || ''} ${option.label}`
                }
              />
            </div>
          </FormControl>
          <IconButton
            role="button"
            component="span"
            className={classes.iconButton}
            onClick={handleIncreaseCycle}
            disabled={monthNavigationIsLoading}
            size="small"
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </div>
      )
    }
  }
  return <div />
}

MonthNavigation.defaultProps = {
  handleButtonClick: () => {},
}

MonthNavigation.propTypes = {
  handleButtonClick: PropTypes.func.isRequired,
}

export default MonthNavigation
