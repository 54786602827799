import React from 'react'
import PropTypes from 'prop-types'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

const formatCommercialData = (value) => {
  let list = ''
  if (value && Array.isArray(value) && value.length > 1) {
    return value.map((item, index) => {
      return (list = `${item?.name} ${index < value.length - 1 ? '/ ' : ''}`)
    })
  } else if (value && Array.isArray(value) && value.length === 1) {
    list = value[0].name
  }

  return list
}

const ArrayCommercial = (props) => {
  return (
    <DataTypeProvider
      formatterComponent={({ value }) =>
        value ? formatCommercialData(value) : ''
      }
      {...props}
    />
  )
}

ArrayCommercial.defaultProps = {
  attribute: '',
}

ArrayCommercial.propTypes = {
  attribute: PropTypes.string.isRequired,
}

export default ArrayCommercial
