import { IconButton, Tooltip } from '../../../core'
import { makeStyles } from '../../../styles'
import { Add as AddIcon } from '../../../icons'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.tertiary?.main,
  },
}))

const Add = ({ handleButtonClick, addText }) => {
  const classes = useStyles()

  const text = addText || 'addText'

  return (
    <Tooltip data-testid="tooltip" title={text}>
      <IconButton
        role="button"
        onClick={() => handleButtonClick('Add')}
        className={classes.icon}
        aria-label={text}
      >
        <AddIcon data-testid="addIcon" />
      </IconButton>
    </Tooltip>
  )
}

Add.defaultProps = {
  handleButtonClick: () => {},
  addText: '',
}

Add.propTypes = {
  handleButtonClick: PropTypes.func.isRequired,
  addText: PropTypes.string,
}

export default Add
