import React, { useState } from 'react'
import {
  Button,
  ClickAwayListener,
  Collapse,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from '../../../core'
import { makeStyles } from '../../../styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: '0px 5px',
    padding: 5,
  },
  popper: {
    zIndex: 999,
    marginTop: 5,
  },
  paper: {
    maxWidth: 300,
    minWidth: 250,
    maxHeight: 500,
    overflowY: `auto`,
    overflowX: `hidden`,
  },
  menuItem: {
    '$&disabled': {
      color: 'darkslategray',
      textDecoration: 'line-through',
    },
  },
}))

const ListButton = ({
  handleButtonClick,
  listButtonIcon,
  listButtonText,
  listButtonItens,
}) => {
  const classes = useStyles()
  const anchorRef = React.useRef(null)

  const text = listButtonText || 'listButtonText'

  const [open, setOpen] = useState(false)
  const [anchorButton, setAnchorButton] = useState(null)

  const handleClick = (item) => {
    handleButtonClick('ListButton', item)
    setOpen(false)
  }

  const handlePopperOpen = (e) => {
    setOpen(true)
    setAnchorButton(e.currentTarget)
  }

  return (
    <>
      <Tooltip data-testid="tooltip" title={text}>
        <Button
          role="button"
          ref={anchorRef}
          onClick={handlePopperOpen}
          className={classes.button}
          startIcon={listButtonIcon}
          layout="tertiary"
          variant="contained"
          width="100px"
        >
          {text}
        </Button>
      </Tooltip>

      <Popper
        transition
        open={open}
        placement="bottom-start"
        className={classes.popper}
        anchorEl={anchorButton}
      >
        {({ TransitionProps, placement }) => (
          <Collapse
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' || placement === 'bottom-start'
                  ? 'center bottom'
                  : 'center top',
            }}
          >
            <Paper className={classes.paper} elevation={3}>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList role="list" autoFocusItem={open} id="download-list">
                  {listButtonItens?.map((item) => (
                    <MenuItem
                      classes={{
                        root: classes.menuItem,
                      }}
                      role="listitem"
                      key={item?.label}
                      onClick={() => handleClick(item)}
                      disabled={item.isDisabled}
                    >
                      <Tooltip title={item?.label}>
                        <Typography variant="inherit" noWrap>
                          {item?.label}
                        </Typography>
                      </Tooltip>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Collapse>
        )}
      </Popper>
    </>
  )
}

ListButton.defaultProps = {
  handleButtonClick: () => {},
}

ListButton.propTypes = {
  handleButtonClick: PropTypes.func.isRequired,
}

export default ListButton
