import PropTypes from 'prop-types'
import React from 'react'
import { useReportData } from '.../../../src/API/Hooks'
import { useReportBudget } from '../API/Hooks/useReportBudget'

export const FiltersReportsContext = React.createContext()

const Provider = ({ children }) => {
  const reportData = useReportData()
  const reportBudget = useReportBudget()
  const {
    data: reports,
    size: reportsSize,
    total,
    cleanFilters,
    applyFilters,
    updateFilters,
    updateGrouping,
    updateSorting,
    updateCumulative,
    updatePage,
    updateRowsPerPage,
    filters,
    body,
    filteredColumns,
    loading,
    handleRemoveFilter,
    handleGraphicValue,
  } = reportData
  const {
    dataBudget,
    loading: loadingBudget,
    patchBudgetContent,
  } = reportBudget

  return (
    <FiltersReportsContext.Provider
      value={{
        reports,
        dataBudget,
        total,
        reportsSize,
        cleanFilters,
        applyFilters,
        updateFilters,
        updateGrouping,
        updateCumulative,
        updateSorting,
        handleGraphicValue,
        handleRemoveFilter,
        updatePage,
        body,
        updateRowsPerPage,
        filters,
        filteredColumns,
        loading,
        loadingBudget,
        patchBudgetContent,
      }}
    >
      {children}
    </FiltersReportsContext.Provider>
  )
}

Provider.defaultProps = {
  children: <></>,
}

Provider.propTypes = {
  children: PropTypes.node,
}

export default Provider
