import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import {
  List,
  Table,
  TableBody,
  TableHead,
  Typography,
  Tabs,
  Tab,
} from '../../../core'
import { intl } from '../../../translation'
import { formatNumber } from '../../../utils'
import {
  DetailsRowData,
  RowHeader,
  SlicesRowData,
} from './Components/RowComponents'
import { TableDetails } from './Components/TableDetails'
import { useStyles } from './styles'

export const PAYZENSaleDetails = ({ tariffSelected, currencyData }) => {
  const classes = useStyles()
  const [selectedValue, setSelectedValue] = useState()
  const [selectedTab, setSelectedTab] = useState(0)

  const textBillingType = (type) => {
    switch (type) {
      case 0:
        return intl.get('maps.DEFAULT') || 'maps.DEFAULT'
      case 1:
        return intl.get('maps.WHITE_LABEL') || 'maps.WHITE_LABEL'
      case 2:
        return intl.get('maps.PROVIDER_STORE') || 'maps.PROVIDER_STORE'
      case 3:
        return intl.get('maps.PROVIDER') || 'maps.PROVIDER'
      case 4:
        return intl.get('maps.WHATSAPP_OLD') || 'maps.WHATSAPP_OLD'
      case 5:
        return intl.get('maps.PROVIDER_WHATSAPP') || 'maps.PROVIDER_WHATSAPP'
      case 6:
        return intl.get('maps.DEFAULT_STORE_OLD') || 'maps.DEFAULT_STORE_OLD'
      case 7:
        return intl.get('maps.DEFAULT_STORE') || 'maps.DEFAULT_STORE'
      case 8:
        return intl.get('maps.PROVIDER_OLD') || 'maps.PROVIDER_OLD'
      case 9:
        return intl.get('maps.WHATSAPP') || 'maps.WHATSAPP'
      default:
        return (
          intl.get('phrases.UNEXPECTED_ERROR') || 'phrases.UNEXPECTED_ERROR'
        )
    }
  }

  const {
    waterfall,
    waterfallStore,
    billingType,
    minimumTransaction,
    minimumValue,
    storeSetup,
    minimumToSetupStore,
    countByTransactedStores,
    minimumValueByStore,
    minimumTransactionByStore,
  } = tariffSelected?.details || {}

  useEffect(() => {
    tariffSelected?.id && setSelectedValue(tariffSelected.id)
  }, [tariffSelected])

  const DetailsHeader = [
    {
      align: 'center',
      text: intl.get('phrases.billingType') || 'phrases.billingType',
    },
    {
      align: 'center',
      text:
        intl.get('phrases.minimumTransaction') || 'phrases.minimumTransaction',
    },
    {
      align: 'center',
      text: intl.get('phrases.minimumValue') || 'phrases.minimumValue',
    },
    {
      align: 'center',
      text: intl.get('phrases.cumulative') || 'phrases.cumulative',
    },
  ]

  const DetailsHeaderByStore = [
    {
      align: 'center',
      text:
        intl.get('phrases.cumulativeByStore') || 'phrases.cumulativeByStore',
    },
    {
      align: 'center',
      text: intl.get('phrases.storeSetup') || 'phrases.storeSetup',
    },
    {
      align: 'center',
      text:
        intl.get('phrases.minimumToSetupStore') ||
        'phrases.minimumToSetupStore',
    },
    {
      align: 'center',
      text:
        intl.get('phrases.countByTransactedStores') ||
        'phrases.countByTransactedStores',
    },
    {
      align: 'center',
      text:
        intl.get('phrases.minimumValueByStore') ||
        'phrases.minimumValueByStore',
    },
    {
      align: 'center',
      text:
        intl.get('phrases.minimumTransactionByStore') ||
        'phrases.minimumTransactionByStore',
    },
  ]

  const DetailsData = [
    {
      align: 'center',
      value: textBillingType(billingType),
    },
    {
      align: 'center',
      value: formatNumber(minimumTransaction),
    },
    {
      align: 'center',
      isCurrency: true,
      value: minimumValue || 0,
    },
    {
      align: 'center',
      value: waterfall ? intl.get('words.yes') : intl.get('words.no'),
    },
  ]

  const DetailsDataByStore = [
    {
      align: 'center',
      value: waterfallStore ? intl.get('words.yes') : intl.get('words.no'),
    },
    {
      align: 'center',
      value: formatNumber(storeSetup),
    },
    {
      align: 'center',
      value: formatNumber(minimumToSetupStore),
    },
    {
      align: 'center',
      value: countByTransactedStores
        ? intl.get('words.yes')
        : intl.get('words.no'),
    },
    {
      align: 'center',
      value: formatNumber(minimumValueByStore),
    },
    {
      align: 'center',
      value: minimumTransactionByStore,
    },
  ]

  const SlicesHeader = [
    {
      align: 'center',
      text: intl.get('words.start') || 'words.start',
    },
    {
      align: 'center',
      text: intl.get('words.end') || 'words.end',
    },
    {
      align: 'right',
      text: intl.get('words.value') || 'words.value',
    },
  ]

  const SlicesData = (row) => {
    return [
      {
        align: 'center',
        value: row?.minLimit > 0 ? formatNumber(row?.minLimit) : '0',
      },
      {
        align: 'center',
        value:
          row?.maxLimit > 0
            ? formatNumber(row?.maxLimit)
            : intl.get('phrases.noLimit'),
      },
      {
        align: 'right',
        isCurrency: true,
        value: row?.value || 0,
      },
    ]
  }

  const handleChangeTab = (e, newValue) => {
    setSelectedTab(newValue)
  }

  return (
    <List dense className={classes.container}>
      {JSON.stringify(tariffSelected) !== '{}' && (
        <>
          <Typography variant="body1" className={classes.titleDetails}>
            {intl.get('words.details') || 'words.details'}
          </Typography>
          <div className={classes.listItem}>
            <TableDetails tariffSelected={tariffSelected} />

            {tariffSelected?.details && (
              <>
                <Table size="small">
                  <TableHead>
                    <RowHeader itens={DetailsHeader} />
                  </TableHead>
                  <TableBody>
                    <DetailsRowData itens={DetailsData} />
                  </TableBody>
                </Table>
                <Table size="small">
                  <TableHead>
                    <RowHeader itens={DetailsHeaderByStore} />
                  </TableHead>
                  <TableBody>
                    <DetailsRowData itens={DetailsDataByStore} />
                  </TableBody>
                </Table>
              </>
            )}
            <Tabs
              value={selectedTab}
              onChange={handleChangeTab}
              style={{ minHeight: 35, height: 35 }}
            >
              <Tab
                label="Por tarifa"
                className={selectedTab === 0 && classes.tabDetails}
              />
              <Tab
                label="Por Loja"
                className={selectedTab === 1 && classes.tabDetails}
              />
            </Tabs>
            {tariffSelected?.details?.values && (
              <>
                <Typography variant="body1" className={classes.titleSlices}>
                  {intl.get('words.slices') || 'words.slices'}
                </Typography>

                {selectedTab === 0 &&
                  tariffSelected &&
                  selectedValue === tariffSelected?.id && (
                    <div className={classes.listItemStatus}>
                      <Table size="small" stickyHeader>
                        <TableHead>
                          <RowHeader itens={SlicesHeader} />
                        </TableHead>
                        <TableBody>
                          {tariffSelected?.details?.values?.map(
                            (row, index) => (
                              <SlicesRowData
                                key={row?.minLimit || index.toString()}
                                itens={SlicesData(row)}
                                currencyData={currencyData}
                              />
                            )
                          )}
                        </TableBody>
                      </Table>
                    </div>
                  )}
              </>
            )}
            {selectedTab === 1 && tariffSelected?.details?.valuesByStore && (
              <>
                {tariffSelected && selectedValue === tariffSelected?.id && (
                  <div className={classes.listItemStatus}>
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <RowHeader itens={SlicesHeader} />
                      </TableHead>
                      <TableBody>
                        {tariffSelected?.details?.valuesByStore?.map(
                          (row, index) => (
                            <SlicesRowData
                              key={row?.minLimit || index.toString()}
                              itens={SlicesData(row)}
                              currencyData={currencyData}
                            />
                          )
                        )}
                      </TableBody>
                    </Table>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </List>
  )
}

PAYZENSaleDetails.propTypes = { tariffSelected: PropTypes.object }

PAYZENSaleDetails.default = {
  tariffSelected: {},
}
