import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '0.8em',
    fontFamily: theme.typography.fontFamily,
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    border: `1px solid ${theme?.palette?.secondary?.contrastText}`,
    overflow: 'hidden',
    borderRadius: 4,
    color: theme?.palette?.secondary?.contrastText,
    marginBottom: 5,
    marginTop: 5,
  },
  rootSelected: {
    fontSize: '0.8em',
    fontFamily: theme.typography.fontFamily,
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    border: `2px solid ${theme?.palette?.secondary?.contrastText}`,
    overflow: 'hidden',
    borderRadius: 4,
    color: theme?.palette?.secondary?.contrastText,
    marginBottom: 5,
    marginTop: 5,
  },
  focused: {},
}))

const InputField = (props) => {
  const classes = useStyles()
  const { placeholder, min, max, type, inputProps, classNames } = props

  return (
    <TextField
      {...props}
      placeholder={placeholder}
      type={type}
      fullWidth
      inputProps={{
        role: 'textbox',
        min: min,
        max: max,
      }}
      InputProps={{
        ...inputProps,
        classes: {
          root: props?.value?.length > 0 ? classes.rootSelected : classes.root,
        },
        disableUnderline: true,
      }}
      className={classNames}
    />
  )
}

export default InputField
