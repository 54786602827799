import { DataTypeProvider } from '@devexpress/dx-react-grid'
import React from 'react'

const Bytes = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) => (value ? `${value}MB` : '')}
    {...props}
  />
)

export default Bytes
