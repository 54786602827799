import { Lock } from '../../icons'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  Image,
  InputAdornment,
  TextField,
  Typography,
} from '../../core'
import { makeStyles } from '../../styles'
import { intl } from '../../translation'
import {
  confirmPasswordRule,
  lengthRule,
  lowerCaseRule,
  minLengthRule,
  numberRule,
  specialCharacterRule,
  upperCaseRule,
} from '../../utils'
import { AnimatedDialog } from '../AnimatedDialog'
import { logos } from '../../images'

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 160,
    padding: 0,
    margin: '0px auto',
  },
  adornment: {
    opacity: '0.3',
  },
  helperText: {
    width: '100%',
    textAlign: 'right',
    height: '8px',
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    marginBottom: 5,
    marginTop: 0,
  },
  checkbox: {
    margin: 0,
    padding: 0,
  },
  label: {
    display: 'block',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    width: '100%',
    marginRight: 2,
  },
}))

const defaultState = { password: '', confirmPassword: '' }

export const NewPasswordForm = (props) => {
  const { logo, requestNewPassword } = props
  const classes = useStyles()
  const [state, setState] = useState({ ...defaultState })
  const [statusPassword, setStatusPassword] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [errorMessageConfirm, setErrorMessageConfirm] = useState('')
  const [loading, setLoading] = useState(false)

  const { password, confirmPassword } = state

  let companyLogo = logos?.textLogoBlueTransparentPNG

  if (logo) {
    companyLogo = logo
  }

  useEffect(() => {
    validatePasswordValue(state.password)
  }, [state.password])

  useEffect(() => {
    validateConfirmPasswordValue(state.password, state.confirmPassword)
  }, [state.confirmPassword])

  const viewPassword = (e) => {
    if (!e.key) {
      setStatusPassword(!statusPassword)
    } else {
      if (e.key === 'Enter') {
        setStatusPassword(!statusPassword)
      } else {
      }
    }
  }

  const keyPress = (e) => {
    if (e.key === 'Enter') {
      handleRequestNewPassword()
    }
  }

  const handleChange = (item, value) =>
    !loading && setState((prev) => ({ ...prev, [item]: value }))

  const handleRequestNewPassword = () => {
    if (
      !password ||
      !confirmPassword ||
      password !== confirmPassword ||
      errorMessage ||
      loading
    ) {
    } else {
      setLoading(true)
      const response = requestNewPassword(state)
      Promise.resolve(response).finally(() => setLoading(false))
    }
  }

  const validatePasswordValue = (value) => {
    if (!value) {
      setErrorMessage('')
      return
    }
    if (!minLengthRule(value)) {
      setErrorMessage(
        intl.get(`phrases.passwordMinLengthError`) ||
          `phrases.passwordMinLengthError`
      )
      return
    }
    if (!lengthRule(value)) {
      setErrorMessage(
        intl.get(`phrases.passwordLengthError`) || `phrases.passwordLengthError`
      )
      return
    }
    if (!specialCharacterRule(value)) {
      setErrorMessage(
        intl.get(`phrases.passwordSpecialCharacterError`) ||
          `phrases.passwordSpecialCharacterError`
      )
      return
    }
    if (!upperCaseRule(value)) {
      setErrorMessage(
        intl.get(`phrases.passwordUpperCaseError`) ||
          `phrases.passwordUpperCaseError`
      )
      return
    }
    if (!lowerCaseRule(value)) {
      setErrorMessage(
        intl.get(`phrases.passwordLowerCaseError`) ||
          `phrases.passwordLowerCaseError`
      )
      return
    }
    if (!numberRule(value)) {
      setErrorMessage(
        intl.get(`phrases.passwordNumberError`) || `phrases.passwordNumberError`
      )
      return
    }
    setErrorMessage('')
  }

  const validateConfirmPasswordValue = (password, confirmPassword) => {
    if (!confirmPassword) {
      setErrorMessageConfirm('')
      return
    }
    if (!confirmPasswordRule(password, confirmPassword)) {
      setErrorMessageConfirm(
        intl.get(`phrases.passwordsUnMatch`) || `phrases.passwordsUnMatch`
      )
      return
    }
    setErrorMessageConfirm('')
  }

  return (
    <AnimatedDialog
      contentTitle={<Image src={companyLogo} height={40} />}
      content={
        <form onSubmit={(event) => event.preventDefault()}>
          <TextField
            autoFocus
            disabled={loading}
            error={errorMessage.length > 0}
            fullWidth
            layout="primary"
            reverse
            key="new-password-id"
            id="new-password-id"
            inputProps={{ 'data-testid': 'new-password-id' }}
            name="new-password-name"
            margin="dense"
            value={state.password}
            label={
              <span className={classes.label}>
                {intl.get('phrases.newPassword') || `phrases.newPassword`}
              </span>
            }
            type={statusPassword ? 'text' : 'password'}
            onChange={(e) => handleChange('password', e.target.value)}
            onKeyPress={keyPress}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock className={classes.adornment} />
                </InputAdornment>
              ),
            }}
            helperText={errorMessage || ' '}
          />
          <TextField
            disabled={loading}
            error={errorMessageConfirm.length > 0}
            fullWidth
            layout="primary"
            reverse
            key="confirm-password-id"
            id="confirm-password-id"
            inputProps={{ 'data-testid': 'confirm-password-id' }}
            name="confirm-password-name"
            margin="dense"
            value={state.confirmPassword}
            label={
              <span className={classes.label}>
                {intl.get('phrases.confirmNewPassword') ||
                  `phrases.confirmNewPassword`}
              </span>
            }
            type={statusPassword ? 'text' : 'password'}
            onChange={(e) => handleChange('confirmPassword', e.target.value)}
            onKeyPress={keyPress}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock className={classes.adornment} />
                </InputAdornment>
              ),
            }}
            helperText={errorMessageConfirm || ' '}
            FormHelperTextProps={{ 'data-testid': 'helper-text' }}
          />
          {state.password.length > 0 && (
            <Typography
              component="p"
              variant="subtitle1"
              className={classes.helperText}
            >
              <Checkbox
                checked={statusPassword}
                onChange={viewPassword}
                onKeyPress={viewPassword}
                color="primary"
                className={classes.checkbox}
              />
              {intl.get('phrases.viewPassword') || `phrases.viewPassword`}
            </Typography>
          )}
        </form>
      }
      contentActions={
        <Button
          fullWidth
          type="button"
          color="primary"
          variant="contained"
          className={classes.button}
          id="update-password-button-id"
          name="update-password-button-name"
          onClick={handleRequestNewPassword}
          disabled={
            !!(
              errorMessage ||
              !state.password.length ||
              !state.confirmPassword.length ||
              state.password !== state.confirmPassword ||
              loading
            )
          }
        >
          {intl.get('phrases.updatePassword') || `phrases.updatePassword`}
        </Button>
      }
    />
  )
}

NewPasswordForm.propTypes = {
  logo: PropTypes.string,
  requestNewPassword: PropTypes.func.isRequired,
}

NewPasswordForm.defaultProps = {
  logo: '',
  requestNewPassword: () => console.log('Request new password func undefined'),
}
