import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core'
import {
  Check as CheckIcon,
  Cancel as CancelIcon,
  Error as ErrorIcon,
  Send as SendIcon,
} from '../../../icons'
import intl from 'react-intl-universal'
import { CircularProgressWithLabel } from '../../CircularProgressWithLabel'

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.tertiary?.main,
  },
  progressContainer: {
    position: 'relative',
    marginTop: 5,
    marginLeft: 5,
  },
  progressInfo: {
    boxShadow: '1px 2px 6px 1px rgba(0,0,0,0.15)',
    position: 'absolute',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 999,
    borderRadius: '6px',
    padding: '15px',
  },
  fileItem: {
    display: 'flex',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    gap: '15px',
  },
  successIconContainer: {
    border: `3px solid ${theme.palette.success.main}`,
    borderRadius: '100%',
    display: 'flex',
  },
  errorIconContainer: {
    border: `3px solid ${theme.palette.error.main}`,
    borderRadius: '100%',
    display: 'flex',
  },
  successIcon: {
    color: theme?.palette.success.main,
  },
  errorIcon: {
    color: theme?.palette.error.main,
  },
}))

export default function CounterSendingProcess({
  handleButtonClick,
  handleReset,
  defaultTexts,
  isLoading,
  processCounter,
}) {
  const classes = useStyles()

  return (
    <>
      {isLoading ? (
        <>
          <div className={classes.progressContainer}>
            <span className={classes.icon}>
              {processCounter?.status === 'ERROR' ? (
                <Tooltip title={defaultTexts.errorProcess}>
                  <IconButton
                    onClick={handleReset}
                    aria-label="reset process data"
                  >
                    <div className={classes.errorIconContainer}>
                      <ErrorIcon
                        className={classes.errorIcon}
                        data-testid="errorIcon"
                      />
                    </div>
                  </IconButton>
                </Tooltip>
              ) : (
                <>
                  {processCounter?.progress === 100 ? (
                    <IconButton
                      onClick={handleReset}
                      aria-label="reset process data"
                    >
                      <div className={classes.successIconContainer}>
                        <CheckIcon
                          className={classes.successIcon}
                          data-testid="checkIcon"
                        />
                      </div>
                    </IconButton>
                  ) : (
                    <CircularProgressWithLabel
                      value={processCounter?.progress ?? 0}
                    />
                  )}
                </>
              )}
            </span>
            {processCounter?.status !== 'ERROR' && (
              <div className={classes.progressInfo}>
                <div className={classes.statusContainer}>
                  <Tooltip title={defaultTexts.doneProcess}>
                    <div className={classes.fileItem}>
                      <CheckIcon
                        fontSize="small"
                        style={{ marginRight: '5px', color: 'green' }}
                      />
                      <Typography
                        variant="subtitle1"
                        style={{ color: 'green' }}
                      >
                        {processCounter?.successItems}
                      </Typography>
                    </div>
                  </Tooltip>
                  <Tooltip title={defaultTexts.errorProcess}>
                    <div className={classes.fileItem}>
                      <CancelIcon
                        fontSize="small"
                        style={{ marginRight: '5px', color: 'red' }}
                      />
                      <Typography variant="subtitle1" style={{ color: 'red' }}>
                        {processCounter?.errorItems}
                      </Typography>
                    </div>
                  </Tooltip>
                </div>

                <div className={classes.fileItem}>
                  <Typography variant="subtitle1">
                    {intl.get(`titles.total`)}: {processCounter?.totalItems}
                  </Typography>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <Tooltip
            data-testid="tooltip-exporting"
            title={intl.get('phrases.sendToSage')}
          >
            <IconButton
              role="button"
              onClick={() => handleButtonClick('SendSalesOrderToSage')}
              className={classes.icon}
            >
              <SendIcon data-testid="listIcon" />
            </IconButton>
          </Tooltip>
        </>
      )}
    </>
  )
}

CounterSendingProcess.propTypes = {
  screen: PropTypes.string,
  actionName: PropTypes.string,
}
