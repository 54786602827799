import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { CircularProgressWithLabel, MenuNavigation } from '../../components'
import {
  AppBar,
  CssBaseline,
  Drawer,
  IconButton,
  Toolbar,
  Tooltip,
  Hidden,
} from '../../core'
import {
  ExitToApp as ExitToAppIcon,
  Menu as MenuIcon,
  CloudDone as CloudDoneIcon,
} from '../../icons'
import { logos } from '../../images'
import { intl } from '../../translation'
import { Footer } from './Footer'
import { HeaderRouter } from './HeaderRouter'
import { MainRouter } from './MainRouter'
import { useStyles } from './styles'
import { useWebSocketContext } from '../../../../contexts/WebSocket'

export const PrivateAppSkeleton = ({
  logo,
  theme,
  headerCenterContent,
  headerEndContent,
  routes,
  handleLogout,
  notifications,
}) => {
  const { processCounters } = useWebSocketContext()
  const { totalItems } = processCounters.root ?? {}
  const classes = useStyles()
  const [totalItemsProcess, setTotalItemsProcess] = useState(null)

  const [openLeft, setOpenLeft] = useState(false)

  let headerLogo =
    theme && theme === 'dark'
      ? logos?.textLogoWhiteTransparentPNG
      : logos?.textLogoBlueTransparentPNG

  if (logo.length) {
    headerLogo = logo
  }

  useEffect(() => {
    if (totalItems > totalItemsProcess) {
      setTotalItemsProcess(totalItems)
    }
  }, [totalItems])

  const handleDrawerOpenLeft = () => {
    setOpenLeft(!openLeft)
  }

  return (
    <BrowserRouter>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar disableGutters variant="dense" className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawerOpenLeft}
              className={classNames(classes.menuButton, {
                [classes.drawerIconRotate]: openLeft,
                [classes.drawerIcon]: !openLeft,
              })}
              size="small"
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.logo}>
              <img src={headerLogo} alt="Logo" className={classes.imgLogo} />
              {!!totalItems && (
                <Tooltip title={intl.get('phrases.processingProgress')} arrow>
                  <div>
                    <CircularProgressWithLabel
                      value={(100 * totalItems) / totalItemsProcess}
                      currentItem={totalItems}
                    />
                  </div>
                </Tooltip>
              )}
            </div>
            <div className={classNames(classes.header)}>
              <div className={classes.headerStart}>
                <HeaderRouter routes={routes || []} />
              </div>
              <Hidden xsDown>
                <div className={classes.headerCenter}>
                  {headerCenterContent}
                </div>
              </Hidden>
              <div className={classes.headerEnd}>
                {headerEndContent}
                <Tooltip title={intl.get(`words.logout`) || `words.logout`}>
                  <IconButton
                    color="inherit"
                    aria-label="Logout"
                    onClick={handleLogout}
                    size="small"
                  >
                    <ExitToAppIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classNames(classes.drawerLeft, {
            [classes.drawerOpenLeft]: openLeft,
            [classes.drawerCloseLeft]: !openLeft,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpenLeft]: openLeft,
              [classes.drawerCloseLeft]: !openLeft,
            }),
          }}
          variant="permanent"
          anchor="left"
          open={openLeft}
          transitionDuration={400}
        >
          <MenuNavigation
            routes={routes || []}
            isOpen={openLeft}
            notifications={notifications}
          />
          <Footer text={process.env.REACT_APP_VERSION} />
        </Drawer>
        <div
          className={classNames(classes.content, {
            [classes.contentShiftLeft]: openLeft,
          })}
        >
          <div className={classes.mainDiv}>
            <MainRouter routes={routes} />
          </div>
        </div>
      </div>
    </BrowserRouter>
  )
}

PrivateAppSkeleton.propTypes = {
  logo: PropTypes.element,
  theme: PropTypes.string,
  headerEndContent: PropTypes.element,
  leftContent: PropTypes.element,
  mainContent: PropTypes.element,
  notifications: PropTypes.object,
}

PrivateAppSkeleton.defaultProps = {
  logo: <></>,
  theme: 'light',
  headerEndContent: <></>,
  leftContent: <></>,
  mainContent: <></>,
  notifications: {},
}
