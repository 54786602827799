import useCallAPI from './useCallAPI'

const filterBody = ({
  commercialId,
  cycle,
  description,
  expectedDueDate,
  type,
  value,
  paymentMonth,
  dueDate,
  currencyCode,
  commissionInvoiceId,
}) => ({
  commercialId,
  cycle,
  description,
  expectedDueDate,
  type,
  value,
  paymentMonth,
  dueDate,
  currencyCode,
  commissionInvoiceId,
})

export const useCommissionsData = (newContext) => {
  const {
    reset,
    requestSuccess,
    requestLoading,
    requestError,
    errorData,
    fetchPOST,
    fetchPUT,
    fetchDELETE,
  } = useCallAPI({
    initialCall: false,
    context: !newContext ? `commissions` : `commissions_${newContext}`,
  })

  const errors = errorData?.errors

  const handlePOST = (params) => {
    fetchPOST({
      userBody: filterBody(params),
    })
  }

  const handlePUT = (params) => {
    fetchPUT({
      userBody: filterBody(params),
    })
  }

  const handleDELETE = (params) => {
    fetchDELETE({
      userBody: params,
    })
  }

  return {
    reset,
    errors,
    loading: requestLoading,
    success: requestSuccess,
    error: requestError,
    handlePOST,
    handlePUT,
    handleDELETE,
  }
}
