import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useMemo } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AlertBadge,
  Badge,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '../../../core'
import {
  Check as CheckIcon,
  CheckCircle as CheckCircleIcon,
  Delete as DeleteIcon,
  Error as ErrorIcon,
  SaveAlt as SaveAltIcon,
  Info as InfoIcon,
  ExpandMore as ExpandMoreIcon,
} from '../../../icons'

import { makeStyles, useTheme } from '../../../styles'
import { intl } from '../../../translation'
import { DialogModel } from '../../DialogModel'
import { useWebSocketContext } from '../../../../../contexts/WebSocket'
import { convertHexToRGBA } from '../../../utils'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useHistory, useLocation } from 'react-router-dom'
import { useNotification } from '../../../../../contexts/SnackNotifications'
import { useAlertData } from '../../../../../API/Hooks'

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.tertiary?.main,
  },
  inline: {
    color: theme?.palette?.quaternary?.contrastText,
  },
  link: {
    color: theme?.palette?.primary?.main,
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  message: {
    padding: 5,
    marginBottom: 5,
    borderBottom: `1px solid ${theme?.palette?.quaternary?.contrastText}`,
  },
  messageText: {
    textAlign: 'justify',
    color: theme?.palette?.quaternary?.contrastText,
  },
  disabled: {
    padding: '0 5px',
    color: theme?.palette?.primary?.main,
    fontWeight: 'bold',
    textDecoration: `line-through solid ${theme.palette.error.dark}`,
    cursor: 'default',
    pointerEvents: 'all !important',
    '&:hover': {
      textDecoration: `line-through solid ${theme.palette.error.dark}`,
    },
  },
  downloadContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    whiteSpace: 'nowrap',
  },
  snackbarButtonError: {
    marginLeft: theme.spacing(2),
    width: '100%',
    border: `1px solid`,
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette?.error?.light,
    color: theme.palette.background.paper,
    '&:hover': {
      color: theme?.palette?.primary?.main,
      border: `1px solid ${theme.palette.primary?.main}`,
    },
  },
  snackbarButtonSuccess: {
    marginLeft: theme.spacing(2),
    width: '100%',
    border: `1px solid`,
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette?.success?.light,
    color: theme?.palette?.quaternary?.contrastText,
    '&:hover': {
      color: theme?.palette?.primary?.main,
      border: `1px solid ${theme.palette.primary?.main}`,
    },
  },
}))

function compare(b, a) {
  const bandA = a.dateAlert
  const bandB = b.dateAlert

  let comparison = 0
  if (bandA > bandB) {
    comparison = 1
  } else if (bandA < bandB) {
    comparison = -1
  }
  return comparison
}

const NotificationsModal = ({
  open,
  onClose,
  onClickItem,
  items,
  notificationsIsLoading,
  onNotificationDownload,
  onNotificationCleanAll,
  notificationsScreen,
  setDeletedMessageKey,
  deletedMessageKey,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const [enabledList, setEnabledList] = useState([])

  const verifyDate = () => {
    const newEnabledList = [...enabledList]
    items.forEach((item, index) => {
      if (item?.labelAlert === 'billingNfZipFile' && item.dateAlert) {
        const expirationTime = moment(item.dateAlert).add(60, 'minutes')
        const currentTime = moment()
        const timeDifference = expirationTime.diff(currentTime)
        if (timeDifference <= 0) {
          const nonExistentIndex =
            enabledList.findIndex((enableElm) => enableElm === index) === -1

          nonExistentIndex && newEnabledList.push(item)
        }
      }
      if (
        (item?.labelAlert === 'exportDataCompleted' ||
          item?.labelAlert === 'billingInvoiceGenerationCompleted' ||
          item?.labelAlert === 'downloadInvoice') &&
        item.dateAlert
      ) {
        const expirationTime = moment(item.dateAlert).add(10, 'minutes')
        const currentTime = moment()
        const timeDifference = expirationTime.diff(currentTime)
        if (timeDifference <= 0) {
          const nonExistentIndex =
            enabledList.findIndex((enableElm) => enableElm === index) === -1

          nonExistentIndex && newEnabledList.push(item)
        }
      }
    })
    if (enabledList !== newEnabledList) {
      setEnabledList(newEnabledList)
    }
  }

  useEffect(() => {
    if (!open) {
      setEnabledList([])
      return
    }
    verifyDate()
    const timer = setInterval(() => {
      verifyDate()
    }, 1000)
    return () => clearInterval(timer)
  }, [open, items])

  const handleCleanAllClick = () => {
    if (items.length > 0) {
      onNotificationCleanAll(notificationsScreen)
    }
  }

  const handleItemDelete = (item) => {
    onClickItem(item)
    if (item.removedAt != null) {
      setDeletedMessageKey(item.key)
    }
  }

  const renderBodyContent = (item) => {
    const parsedBody = JSON.parse(item?.body)

    if (parsedBody.exceptionList) {
      return (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              Total de erros: {parsedBody?.exceptionList?.length}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {parsedBody?.exceptionList?.map((error, index) => (
                <Typography
                  key={index}
                  style={{
                    marginBottom: '8px',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    borderRadius: '4px',
                    padding: '8px',
                    backgroundColor: '#fff',
                  }}
                >
                  - {error?.detail}
                </Typography>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      )
    } else if (item?.length && item?.screen !== 'billingEmailScreen') {
      return (
        <>
          <Typography
            component="span"
            variant="body2"
            className={classes.inline}
            color="textPrimary"
          >
            {`${intl.get('words.message')}:`}
          </Typography>
          <br />
          <div className={classes.message}>
            <Typography
              component="span"
              variant="subtitle1"
              className={classes.messageText}
              color="textPrimary"
            >
              {`${parsedBody?.error?.detail}`}
            </Typography>
          </div>
        </>
      )
    }
  }

  return (
    <DialogModel
      open={open}
      title={intl.get('words.downloads') || 'words.downloads'}
      onClose={onClose}
    >
      <div
        style={{
          height: '400px',
        }}
      >
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {items?.length && items?.map ? (
            <Button
              variant="outlined"
              layout="primary"
              type="primary"
              onClick={handleCleanAllClick}
            >
              {intl.get(`phrases.cleanAll`) || `phrases.cleanAll`}
            </Button>
          ) : (
            intl.get(`phrases.noNotifications`) || `phrases.noNotifications`
          )}

          <List
            component="nav"
            style={{
              maxHeight: 600,
              overflow: 'auto',
              width: '100%',
            }}
          >
            {items?.sort(compare)?.map((item, index) => {
              const isRead = item.readAt != null

              if (item.key === deletedMessageKey) {
                return null
              }
              const getColorAlert = (status, type) => {
                return status
                  ? convertHexToRGBA(
                      theme?.palette?.secondary?.hoverContrast,
                      0.7
                    )
                  : type === 'error'
                  ? '#c62828'
                  : '#388e3c'
              }
              const parsedBody = JSON.parse(item?.body)
              const nonExpiredIndex =
                enabledList.findIndex((elm) => elm === item) === -1
              return (
                <ListItem
                  key={index.toString()}
                  style={{
                    color: getColorAlert(isRead, item?.type),
                    border: `1px solid ${getColorAlert(isRead, item?.type)}`,
                    borderLeft: `10px solid ${getColorAlert(
                      isRead,
                      item?.type
                    )}`,
                    borderRadius: 5,
                    margin: '10px auto',
                  }}
                >
                  <ListItemText
                    primary={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {item?.type === 'error' ? (
                          <ErrorIcon />
                        ) : (
                          <CheckCircleIcon />
                        )}
                        <Typography
                          style={{ display: 'inline', margin: '8px' }}
                        >
                          {intl.get(`phrases.${item?.labelAlert}`)}
                        </Typography>
                      </div>
                    }
                    secondary={
                      <>
                        {item && renderBodyContent(item)}

                        {parsedBody?.link && (
                          <>
                            <Button
                              className={`${
                                nonExpiredIndex
                                  ? classes.link
                                  : classes.disabled
                              } ${classes.button}`}
                              onClick={() => {
                                if (nonExpiredIndex) {
                                  const key = item.key
                                  const { link, params } = parsedBody
                                  onNotificationDownload(link, params, key)
                                }
                              }}
                              endIcon={
                                <Tooltip
                                  title={
                                    nonExpiredIndex
                                      ? intl.get(`phrases.downloadAlert`)
                                      : intl.get(`phrases.downloadExpired`)
                                  }
                                >
                                  <InfoIcon
                                    style={{
                                      color: `${
                                        nonExpiredIndex ? 'orange' : 'red'
                                      }`,
                                    }}
                                    size="small"
                                  />
                                </Tooltip>
                              }
                              disabled={!nonExpiredIndex}
                            >
                              {intl.get('phrases.downloadFile') ||
                                'phrases.downloadFile'}
                            </Button>

                            <br />
                          </>
                        )}

                        <Typography
                          component="span"
                          variant="body2"
                          style={{
                            color: theme?.palette?.quaternary?.contrastText,
                            opacity: 0.8,
                          }}
                        >
                          {item.sentBy}
                        </Typography>
                        <br />
                        <Typography
                          component="span"
                          variant="body2"
                          style={{
                            color: theme?.palette?.quaternary?.contrastText,
                            opacity: 0.8,
                          }}
                        >
                          {`${intl.get(`phrases.sentIn`)}: ${moment(
                            item?.dateAlert
                          ).format('DD/MM/YYYY HH:mm:ss')}`}
                        </Typography>
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Tooltip
                      title={
                        isRead
                          ? intl.get(`words.delete`) || 'words.delete'
                          : intl.get(`phrases.notificationsStatusChange`) ||
                            'phrases.notificationsStatusChange'
                      }
                    >
                      <IconButton
                        edge="end"
                        aria-label="check"
                        disabled={notificationsIsLoading}
                        onClick={() => handleItemDelete(item)}
                        style={{
                          color: getColorAlert(isRead, item?.type),
                        }}
                      >
                        {isRead ? <DeleteIcon /> : <CheckIcon />}
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}
          </List>
        </div>
      </div>
    </DialogModel>
  )
}

const Notifications = ({
  notificationsIsLoading,
  handleButtonClick,
  notificationsScreen,
}) => {
  const classes = useStyles()
  const {
    webSocketNotifications,
    setDeletedMessageKey,
    setCurrentScreen,
    deletedMessageKey,
  } = useWebSocketContext() ?? {}
  const {
    openModal,
    setOpenModal,
    redirectedFromSnackbar,
    setRedirectedFromSnackbar,
  } = useNotification() ?? {}
  const history = useHistory() ?? {}
  const location = useLocation() ?? {}
  const enqueueSnackbar = useSnackbar()?.enqueueSnackbar
  const alertData = useAlertData()

  const screensPathsMap = {
    dashboardScreen: '/',
    billingScreen: '/billing',
    billingErrorScreen: '/billing-error',
    invoiceScreen: '/invoice',
    salesOrderScreen: '/sales-order',
    billingEmailScreen: '/billing_email',
    reports: '/billingReportScreen',
    products: '/products',
    commercialScreen: '/commercial',
    accountScreen: '/account',
    opportunityScreen: '/opportunity',
    commissionScreen: '/commissions',
    extractScreen: '/extract',
    conciliationGprsScreen: '/conciliation_gprs',
    conciliationGprsPlanScreen: '/plans',
    conciliationGprsReportScreen: '/intern_invoice',
    tariffScreen: '/tariff',
    userScreen: '/users',
    groupScreen: '/groups',
    billingProcessScreen: '/billing-process',
    commercialBonusScreen: '/commercial-bonus',
  }
  const handleMarkAsReceived = alertData.useMarkAsReceivedNotification()

  useEffect(() => {
    if (
      Array.isArray(webSocketNotifications) &&
      webSocketNotifications.length > 0
    ) {
      const notificationKeysListReceived = []

      webSocketNotifications.forEach((notification) => {
        const messageValidation =
          notification?.labelAlert === 'exportDataCompleted' ||
          notification?.labelAlert === 'billingInvoiceGenerationCompleted' ||
          notification?.labelAlert === 'downloadInvoice' ||
          notification?.labelAlert === 'exportDataError' ||
          notification?.labelAlert === 'exportDataError' ||
          notification?.labelAlert === 'billingNfZipFile'
        if (
          !notification?.removedAt &&
          !notification?.receivedAt &&
          messageValidation
        ) {
          const message = intl.get(`phrases.${notification?.labelAlert}`)
          const action = (key) => (
            <Button
              className={
                notification?.type === 'error'
                  ? classes.snackbarButtonError
                  : classes.snackbarButtonSuccess
              }
              onClick={() => handleOpenNotifications(notification?.screen)}
            >
              Mostrar Detalhes
            </Button>
          )

          if (notification?.type === 'error') {
            enqueueSnackbar(message, {
              variant: 'error',
              autoHideDuration: 7000,
              action,
            })
          } else {
            enqueueSnackbar(message, {
              variant: 'success',
              autoHideDuration: 7000,
              action,
            })
          }

          if (!notification?.receivedAt && !notification?.readAt) {
            notificationKeysListReceived.push(notification?.key)
          }
        }

        if (
          notificationKeysListReceived.length > 0 &&
          !notification?.receivedAt &&
          !notification?.readAt
        ) {
          handleMarkAsReceived(notificationKeysListReceived)
        }
      })
    }
  }, [webSocketNotifications, enqueueSnackbar, classes, handleMarkAsReceived])

  useEffect(() => {
    if (redirectedFromSnackbar) {
      const timer = setTimeout(() => {
        setOpenModal(true)
        setRedirectedFromSnackbar(false)
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [location.pathname, redirectedFromSnackbar])

  const notificationList = useMemo(() => {
    let newNotificationList = []

    if (!_.isEmpty(webSocketNotifications)) {
      webSocketNotifications.forEach((notification) => {
        const messageValidation =
          notification?.labelAlert === 'billingNfZipFile' ||
          notification?.labelAlert === 'exportDataCompleted' ||
          notification?.labelAlert === 'billingInvoiceGenerationCompleted' ||
          notification?.labelAlert === 'downloadInvoice' ||
          notification?.labelAlert === 'exportDataError'

        if (
          !notification?.removedAt &&
          notification?.screen === notificationsScreen &&
          messageValidation
        ) {
          newNotificationList.push(notification)
        }
      })
    }

    return newNotificationList
  }, [webSocketNotifications, notificationsScreen])

  const findPathByScreen = (screen) => {
    return screensPathsMap[screen] || null
  }

  const handleOpenNotifications = (screen) => {
    const path = findPathByScreen(screen)
    if (screen === notificationsScreen) {
      setOpenModal(true)
    } else if (path) {
      setRedirectedFromSnackbar(true)
      history.push(path)
    }
  }

  const handleCleanAllNotifications = () => {
    handleClearNotifications('all')
  }

  const handleClearNotifications = (item) => {
    if (webSocketNotifications.length > 0) {
      if (item === 'all') {
        handleButtonClick('Notifications', {
          componentAction: 'cleanAll',
          screen: notificationsScreen,
        })
        setOpenModal(false)
      } else if (!item?.readAt) {
        handleButtonClick('Notifications', {
          componentAction: 'markAsRead',
          key: item.key,
        })
      } else {
        handleButtonClick('Notifications', {
          componentAction: 'clearOne',
          key: item.key,
        })
      }
      setCurrentScreen('')
    }
    if (!webSocketNotifications || webSocketNotifications === 0) {
      setOpenModal(false)
    }
  }
  const hasNotifications = notificationList.length > 0

  return (
    <>
      <Tooltip title={intl.get(`words.downloads`) || 'words.downloads'}>
        <IconButton onClick={() => setOpenModal(true)} className={classes.icon}>
          <Badge variant="dot" color="error" invisible={!hasNotifications}>
            <SaveAltIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <NotificationsModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onClickItem={(item) => {
          if (item.componentAction !== 'download') {
            handleClearNotifications(item)
          }
        }}
        setDeletedMessageKey={setDeletedMessageKey}
        deletedMessageKey={deletedMessageKey}
        notificationsIsLoading={notificationsIsLoading}
        items={notificationList || []}
        onNotificationCleanAll={handleCleanAllNotifications}
        notificationsScreen={notificationsScreen}
        onNotificationDownload={async (url, params, key) => {
          try {
            await handleButtonClick('Notifications', {
              componentAction: 'markAsRead',
              key: key,
            })

            await handleButtonClick('Notifications', {
              componentAction: 'download',
              url: url,
              params: params,
            })
          } catch (error) {
            console.log(error, 'error')
          }
        }}
      />
    </>
  )
}

Notifications.defaultProps = {
  handleButtonClick: () => {},
  notificationsIsLoading: false,
}

Notifications.propTypes = {
  handleButtonClick: PropTypes.func.isRequired,
  notificationsIsLoading: PropTypes.bool,
}

export default Notifications
