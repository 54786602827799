import React from 'react'
import _ from 'lodash'
import { Loading } from '../Loading'
import { makeStyles } from '../../styles'
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableHead,
} from '../../core'
import { BodyRow, HeaderRow } from './Rows'

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.tertiary?.main,
    textAlign: 'center',
  },
}))

export const CardWithTable = ({ title, header, body }) => {
  const classes = useStyles()

  return (
    <Card style={{ height: '100%' }}>
      <CardHeader className={classes.header} title={title || 'title'} />
      <CardContent
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {header && body && !_.isEmpty(body) ? (
          <div style={{ overflowY: 'scroll', height: '100%', width: '100%' }}>
            <Table stickyHeader style={{ height: '100%' }}>
              <TableHead>
                <HeaderRow header={header} />
              </TableHead>
              <TableBody>
                <BodyRow body={body} />
              </TableBody>
            </Table>
          </div>
        ) : (
          <Loading />
        )}
      </CardContent>
    </Card>
  )
}
