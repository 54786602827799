import React from 'react'
import { APICall } from './APICall'
import { useValidateErrorResponse } from './useValidateErrorResponse'
import { useSnackbar } from '../notifications'
import { intl } from '../translation'

const defaultMethods = {
  error: null,
  loading: null,
  data: null,
  success: null,
}

export const useCallAxios = ({
  baseEndpoint,
  headers,
  callOnInit,
  urlParameters,
  disableCompanyIdOnInit,
}) => {
  const [getRequest, setGetRequest] = React.useState({ ...defaultMethods })
  const [postRequest, setPostRequest] = React.useState({ ...defaultMethods })
  const [putRequest, setPutRequest] = React.useState({ ...defaultMethods })
  const [patchRequest, setPatchRequest] = React.useState({ ...defaultMethods })
  const [deleteRequest, setDeleteRequest] = React.useState({
    ...defaultMethods,
  })
  const enqueueSnackbar = useSnackbar()?.enqueueSnackbar
  const { validateError } = useValidateErrorResponse()

  const prepareCall = (verb) => {
    switch (verb) {
      case 'GET':
        setGetRequest({ ...defaultMethods, loading: true })
        break
      case 'POST':
        setPostRequest({ ...defaultMethods, loading: true })
        break
      case 'PUT':
        setPutRequest({ ...defaultMethods, loading: true })
        break
      case 'PATCH':
        setPatchRequest({ ...defaultMethods, loading: true })
        break
      case 'DELETE':
        setDeleteRequest({ ...defaultMethods, loading: true })
        break
    }
  }

  const updateData = ({ verb, data, success, loading }) => {
    const newData = {
      ...defaultMethods,
      success,
      loading,
    }
    if (data) {
      newData.data = data
    }
    switch (verb) {
      case 'GET':
        setGetRequest({
          ...newData,
        })
        break
      case 'POST':
        setPostRequest({
          ...newData,
        })
        break
      case 'PUT':
        setPutRequest({
          ...newData,
        })
        break
      case 'PATCH':
        setPatchRequest({
          ...newData,
        })
        break
      case 'DELETE':
        setDeleteRequest({
          ...newData,
        })
        break
    }
  }

  const updateError = (verb, error) => {
    switch (verb) {
      case 'GET':
        setGetRequest({ ...defaultMethods, error })
        break
      case 'POST':
        setPostRequest({ ...defaultMethods, error })
        break
      case 'PUT':
        setPutRequest({ ...defaultMethods, error })
        break
      case 'PATCH':
        setPatchRequest({ ...defaultMethods, error })
        break
      case 'DELETE':
        setDeleteRequest({
          ...defaultMethods,
          error,
        })
        break
    }
  }

  React.useEffect(() => {
    if (callOnInit && baseEndpoint) {
      fetchAPI({
        verb: 'GET',
        endpoint: baseEndpoint,
        urlParameters,
        disableCompanyId: disableCompanyIdOnInit,
      })
    }

    return () => null
  }, [])

  const fetchAPI = ({
    body = null,
    urlParameters = null,
    endpoint = '',
    id = null,
    responseType = null,
    noRefreshData = false,
    verb = '',
    isFormData,
    disableCompanyId,
  }) => {
    prepareCall(verb)
    return new Promise(() => {
      APICall({
        body,
        urlParameters,
        endpoint,
        headers,
        id,
        responseType,
        verb,
        isFormData,
        disableCompanyId,
      })
        .then((data) => {
          if (!noRefreshData) {
            updateData({
              verb,
              data: data?.data,
              success: true,
              loading: false,
            })
          } else {
            updateData({ verb, success: true, loading: false })
          }
        })
        .catch((err) => {
          validateError(err?.response?.data)
          updateError(verb, err?.response?.data)

          if (err?.response?.status >= 499 && err?.response?.status <= 599) {
            enqueueSnackbar(intl.get('phrases.503'), {
              variant: 'error',
            })
          }
          updateData({ verb, data: null, success: false, loading: false })
        })
    })
  }

  const fetchDELETE = ({
    urlParameters,
    id,
    body,
    responseType,
    forceEndpoint,
    noRefreshData,
    isFormData,
    disableCompanyId,
  }) => {
    fetchAPI({
      verb: 'DELETE',
      endpoint: forceEndpoint
        ? `${process.env.REACT_APP_API_URL}${forceEndpoint}`
        : baseEndpoint,
      urlParameters,
      id,
      body,
      responseType,
      noRefreshData,
      isFormData,
      disableCompanyId,
    })
  }

  const fetchGET = ({
    urlParameters,
    id,
    responseType,
    forceEndpoint,
    isFormData,
    disableCompanyId,
  }) => {
    fetchAPI({
      verb: 'GET',
      endpoint: forceEndpoint
        ? `${process.env.REACT_APP_API_URL}${forceEndpoint}`
        : baseEndpoint,
      urlParameters,
      id,
      responseType,
      isFormData,
      disableCompanyId,
    })
  }

  const fetchPATCH = ({
    urlParameters,
    id,
    body,
    responseType,
    forceEndpoint,
    noRefreshData,
    isFormData,
    disableCompanyId,
  }) => {
    fetchAPI({
      verb: 'PATCH',
      endpoint: forceEndpoint
        ? `${process.env.REACT_APP_API_URL}${forceEndpoint}`
        : baseEndpoint,
      urlParameters,
      id,
      body,
      responseType,
      noRefreshData,
      isFormData,
      disableCompanyId,
    })
  }

  const fetchPOST = ({
    urlParameters,
    id,
    body,
    responseType,
    forceEndpoint,
    noRefreshData,
    isFormData,
    disableCompanyId,
  }) => {
    fetchAPI({
      verb: 'POST',
      endpoint: forceEndpoint
        ? `${process.env.REACT_APP_API_URL}${forceEndpoint}`
        : baseEndpoint,
      urlParameters,
      id,
      body,
      responseType,
      noRefreshData,
      isFormData,
      disableCompanyId,
    })
  }

  const fetchPUT = ({
    urlParameters,
    id,
    body,
    noRefreshData,
    responseType,
    forceEndpoint,
    isFormData,
    disableCompanyId,
  }) => {
    fetchAPI({
      verb: 'PUT',
      endpoint: forceEndpoint
        ? `${process.env.REACT_APP_API_URL}${forceEndpoint}`
        : baseEndpoint,
      urlParameters,
      id,
      body,
      noRefreshData,
      responseType,
      isFormData,
      disableCompanyId,
    })
  }

  const reset = () => {
    setGetRequest({ ...defaultMethods })
    setPostRequest({ ...defaultMethods })
    setPutRequest({ ...defaultMethods })
    setPatchRequest({ ...defaultMethods })
    setDeleteRequest({ ...defaultMethods })
  }

  return {
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    fetchDELETE,
    fetchGET,
    fetchPATCH,
    fetchPOST,
    fetchPUT,
    reset,
  }
}
