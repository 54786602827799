/* eslint-disable consistent-return */
import axios from 'axios'
import React from 'react'
import { APICall } from './APICall'
import { useValidateErrorResponse } from '../../components/Library/api/useValidateErrorResponse'
import { useSnackbar } from '../../components/Library/notifications'
import { intl } from '../../components/Library/translation'

const { CancelToken } = axios

const defaultMethods = {
  GET: null,
  POST: null,
  PUT: null,
  PATCH: null,
  DELETE: null,
}

const useCallAPI = ({
  initialCall,
  context,
  urlParameters,
  disableCompanyIdOnInit,
}) => {
  const [requestLoading, setRequestLoading] = React.useState({
    ...defaultMethods,
  })
  const [requestError, setRequestError] = React.useState({ ...defaultMethods })
  const [requestSuccess, setRequestSuccess] = React.useState({
    ...defaultMethods,
  })
  const [requestData, setRequestData] = React.useState({})

  const [errorData, setErrorData] = React.useState({})

  const enqueueSnackbar = useSnackbar()?.enqueueSnackbar
  const { validateError } = useValidateErrorResponse()

  React.useEffect(() => {
    if (initialCall && context) {
      const verb = 'GET'
      fetchAPI({
        verb,
        callContext: context,
        callUrlParameters: urlParameters,
        disableCompanyId: disableCompanyIdOnInit,
      })
    }

    return () => null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchAPI = ({
    verb,
    callContext,
    callUrlParameters = null,
    id = null,
    body = null,
    noRefreshData,
    isFormData = false,
    isFileDownload = false,
    disableCompanyId = false,
  }) => {
    if (!requestLoading[verb]) {
      setRequestError((prev) => ({
        ...prev,
        [verb]: null,
      }))
      setRequestLoading((prev) => ({
        ...prev,
        [verb]: true,
      }))
      setRequestSuccess((prev) => ({
        ...prev,
        [verb]: null,
      }))

      const axiosToken = CancelToken.source()
      const cancelToken = axiosToken?.token

      return new Promise((resolve, reject) => {
        APICall({
          cancelToken,
          verb,
          callContext,
          callUrlParameters,
          id,
          body,
          isFormData,
          isFileDownload,
          disableCompanyId,
        })
          .then((values) => {
            if (!noRefreshData) {
              setRequestData(values)
              setErrorData({})
            }
            setRequestSuccess((prev) => ({
              ...prev,
              [verb]: true,
            }))
            setRequestError((prev) => ({
              ...prev,
              [verb]: false,
            }))
          })
          .catch((err) => {
            setRequestSuccess((prev) => ({
              ...prev,
              [verb]: false,
            }))
            setRequestError((prev) => ({
              ...prev,
              [verb]: true,
            }))
            setRequestData({})
            setErrorData(err?.response?.data)

            if (err?.response?.status >= 499 && err?.response?.status <= 599) {
              enqueueSnackbar(intl.get('phrases.503'), {
                variant: 'error',
              })
            }
            const errMessage = validateError(err?.response?.data)
            reject(errMessage)
          })
          .finally(() => {
            setRequestLoading((prev) => ({
              ...prev,
              [verb]: false,
            }))
          })
      })
    }
  }

  const fetchDELETE = ({
    userParams = {},
    userId = null,
    userBody = {},
    isFormData,
    isFileDownload,
  }) => {
    const verb = 'DELETE'
    fetchAPI({
      verb,
      callContext: context,
      callUrlParameters: userParams,
      id: userId,
      body: userBody,
      isFormData,
      isFileDownload,
    })
  }

  const fetchGET = ({
    userParams = {},
    userId = null,
    getContext,
    isFormData,
    isFileDownload,
  }) => {
    const verb = 'GET'
    const callContext = getContext || context
    fetchAPI({
      verb,
      callContext,
      callUrlParameters: userParams,
      id: userId,
      isFormData,
      isFileDownload,
    })
  }

  const fetchPATCH = ({
    userParams = {},
    userId = null,
    userBody = {},
    isFormData,
    isFileDownload,
    disableCompanyId,
  }) => {
    const verb = 'PATCH'
    fetchAPI({
      verb,
      callContext: context,
      callUrlParameters: userParams,
      id: userId,
      body: userBody,
      isFormData,
      isFileDownload,
      disableCompanyId,
    })
  }

  const fetchPOST = ({
    userParams = {},
    userId = null,
    userBody = {},
    newContext,
    isFormData,
    isFileDownload,
    disableCompanyId,
  }) => {
    const verb = 'POST'
    fetchAPI({
      verb,
      callContext: newContext || context,
      callUrlParameters: userParams,
      id: userId,
      body: userBody,
      isFormData,
      isFileDownload,
      disableCompanyId,
    })
  }

  const fetchPUT = ({
    userParams = {},
    userId = null,
    userBody = {},
    noRefreshData,
    newContext,
    isFormData,
    isFileDownload,
    disableCompanyId,
  }) => {
    const verb = 'PUT'
    fetchAPI({
      verb,
      callContext: newContext || context,
      callUrlParameters: userParams,
      id: userId,
      body: userBody,
      noRefreshData,
      isFormData,
      isFileDownload,
      disableCompanyId,
    })
  }

  const reset = () => {
    setRequestData({})
    setRequestError(false)
    setRequestLoading(false)
    setRequestSuccess(null)
  }

  return {
    requestLoading,
    requestData,
    errorData,
    requestError,
    requestSuccess,
    fetchDELETE,
    fetchGET,
    fetchPATCH,
    fetchPOST,
    fetchPUT,
    reset,
  }
}

export default useCallAPI
