export const layoutLG = [
  { i: 'a', x: 0, y: 0, w: 3, h: 1, maxH: 1, minW: 3, maxW: 4 },
  { i: 'b', x: 3, y: 0, w: 3, h: 1, maxH: 1, minW: 3, maxW: 4 },
  { i: 'c', x: 6, y: 0, w: 3, h: 1, maxH: 1, minW: 3, maxW: 4 },
  { i: 'd', x: 9, y: 0, w: 3, h: 1, maxH: 1, minW: 3, maxW: 4 },
  { i: 'e', x: 0, y: 1, w: 6, h: 3, minH: 3, minW: 3 },
  { i: 'f', x: 6, y: 1, w: 6, h: 3, minH: 3, minW: 3 },

  { i: 'g', x: 0, y: 4, w: 4, h: 1, maxH: 1, minW: 3, maxW: 4 },
  { i: 'h', x: 4, y: 4, w: 4, h: 1, maxH: 1, minW: 3, maxW: 4 },
  { i: 'i', x: 8, y: 4, w: 4, h: 1, maxH: 1, minW: 3, maxW: 4 },
  { i: 'j', x: 0, y: 5, w: 4, h: 3, minH: 3, minW: 4 },
  { i: 'k', x: 4, y: 5, w: 4, h: 3, minH: 3, minW: 4 },
  { i: 'l', x: 8, y: 5, w: 4, h: 3, minH: 3, minW: 4 },

  { i: 'm', x: 0, y: 8, w: 12, h: 4, minH: 4, minW: 3 },
]

export const layoutMD = [
  { i: 'a', x: 0, y: 0, w: 6, h: 1, minH: 1, maxH: 1, minW: 4 },
  { i: 'b', x: 6, y: 0, w: 6, h: 1, minH: 1, maxH: 1, minW: 4 },
  { i: 'e', x: 0, y: 1, w: 12, h: 3, minH: 3, minW: 6 },

  { i: 'c', x: 0, y: 4, w: 6, h: 1, minH: 1, maxH: 1, minW: 4 },
  { i: 'd', x: 6, y: 4, w: 6, h: 1, minH: 1, maxH: 1, minW: 4 },
  { i: 'f', x: 0, y: 5, w: 12, h: 3, minH: 3, minW: 6 },

  { i: 'g', x: 0, y: 8, w: 12, h: 1, minH: 1, maxH: 1, minW: 4 },
  { i: 'j', x: 0, y: 9, w: 12, h: 3, minH: 3, minW: 6 },

  { i: 'h', x: 0, y: 12, w: 12, h: 1, minH: 1, maxH: 1, minW: 4 },
  { i: 'k', x: 0, y: 13, w: 12, h: 3, minH: 3, minW: 6 },

  { i: 'i', x: 0, y: 16, w: 12, h: 1, minH: 1, maxH: 1, minW: 4 },
  { i: 'l', x: 0, y: 17, w: 12, h: 3, minH: 3, minW: 6 },

  { i: 'm', x: 0, y: 20, w: 12, h: 4, minH: 4, minW: 6 },
]

export const layoutSM = [
  { i: 'a', x: 0, y: 0, w: 6, h: 1, minH: 1, maxH: 1, minW: 6 },
  { i: 'b', x: 6, y: 0, w: 6, h: 1, minH: 1, maxH: 1, minW: 6 },
  { i: 'e', x: 0, y: 1, w: 12, h: 3, minH: 3, minW: 12 },

  { i: 'c', x: 0, y: 4, w: 6, h: 1, minH: 1, maxH: 1, minW: 6 },
  { i: 'd', x: 6, y: 4, w: 6, h: 1, minH: 1, maxH: 1, minW: 6 },
  { i: 'f', x: 0, y: 5, w: 12, h: 3, minH: 3, minW: 12 },

  { i: 'g', x: 0, y: 8, w: 12, h: 1, minH: 1, maxH: 1, minW: 6 },
  { i: 'j', x: 0, y: 9, w: 12, h: 3, minH: 3, minW: 12 },

  { i: 'h', x: 0, y: 12, w: 12, h: 1, minH: 1, maxH: 1, minW: 6 },
  { i: 'k', x: 0, y: 13, w: 12, h: 3, minH: 3, minW: 12 },

  { i: 'i', x: 0, y: 16, w: 12, h: 1, minH: 1, maxH: 1, minW: 6 },
  { i: 'l', x: 0, y: 17, w: 12, h: 3, minH: 3, minW: 12 },

  { i: 'm', x: 0, y: 20, w: 12, h: 4, minH: 4, minW: 6 },
]

export const layoutXS = [
  { i: 'a', x: 0, y: 0, w: 12, h: 1, minH: 1, maxH: 1, minW: 12 },
  { i: 'b', x: 0, y: 1, w: 12, h: 1, minH: 1, maxH: 1, minW: 12 },
  { i: 'e', x: 0, y: 2, w: 12, h: 3, minH: 3, maxH: 3, minW: 12 },

  { i: 'c', x: 0, y: 5, w: 12, h: 1, minH: 1, maxH: 1, minW: 12 },
  { i: 'd', x: 0, y: 6, w: 12, h: 1, minH: 1, maxH: 1, minW: 12 },
  { i: 'f', x: 0, y: 7, w: 12, h: 3, minH: 3, maxH: 3, minW: 12 },

  { i: 'g', x: 0, y: 10, w: 12, h: 1, minH: 1, maxH: 1, minW: 12 },
  { i: 'j', x: 0, y: 11, w: 12, h: 3, minH: 3, maxH: 3, minW: 12 },

  { i: 'h', x: 0, y: 14, w: 12, h: 1, minH: 1, maxH: 1, minW: 12 },
  { i: 'k', x: 0, y: 15, w: 12, h: 3, minH: 3, maxH: 3, minW: 12 },

  { i: 'i', x: 0, y: 18, w: 12, h: 1, minH: 1, maxH: 1, minW: 12 },
  { i: 'l', x: 0, y: 19, w: 12, h: 3, minH: 3, maxH: 3, minW: 12 },

  { i: 'm', x: 0, y: 22, w: 12, h: 4, minH: 4, maxH: 4, minW: 12 },
]
