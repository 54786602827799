import axios from 'axios'
import qs from 'qs'
import React, { useContext } from 'react'
import { AuthContext } from '../../contexts/Auth'
import { useValidateErrorResponse } from '../../components/Library/api/useValidateErrorResponse'
import { headerLogin, loginAuthBodyDefault, qsConfig } from './APIConstants'
import { normalizeAuthData } from './Normalizers'
import { ThemeContext } from '../../contexts/Theme'
import { UserContext } from '../../contexts/User'

const { CancelToken } = axios

const loadItem = (item) => {
  try {
    const serializedItem = localStorage.getItem(item)

    if (serializedItem === null) {
      return undefined
    }

    return JSON.parse(serializedItem)
  } catch (err) {
    return undefined
  }
}

const createHeaders = () => {
  const token = loadItem('token')

  const headers = {
    Authorization: `Bearer ${token}`,
  }
  return headers
}

export const useLogin = () => {
  const [, dispatchAuthContext] = React.useContext(AuthContext)
  const { validateError } = useValidateErrorResponse()
  const [requestLoading, setRequestLoading] = React.useState(false)
  const [requestError, setRequestError] = React.useState(false)
  const [requestData, setRequestData] = React.useState({})

  const { token } = requestData

  React.useEffect(() => {
    if (token) {
      dispatchAuthContext({
        type: 'setToken',
        token,
      })
    }
  }, [token, dispatchAuthContext])

  const fetchLogin = ({ username, password }) => {
    if (!requestLoading && username && password) {
      setRequestError(false)
      setRequestLoading(true)

      const axiosToken = CancelToken.source()
      const cancelToken = axiosToken?.token

      return new Promise((resolve, reject) => {
        const body = {
          ...loginAuthBodyDefault,
          username,
          password,
        }

        const stringifiedBody = qs.stringify(body, qsConfig)

        axios
          .post(
            `${process.env.REACT_APP_API_URL}/auth/login`,
            stringifiedBody,
            {
              headers: headerLogin,
              cancelToken,
            }
          )
          .then(({ data }) => normalizeAuthData(data))
          .then((values) => setRequestData(values))
          .catch((err) => {
            setRequestError(true)
            const errMessage = validateError(err?.response?.data)
            reject(errMessage)
          })
          .finally(() => setRequestLoading(false))
      })
    }
  }

  return { requestError, requestLoading, fetchLogin }
}

export const useRecoveryPassword = () => {
  const { validateError } = useValidateErrorResponse()
  const [requestLoading, setRequestLoading] = React.useState(false)
  const [requestError, setRequestError] = React.useState(false)
  const [requestData, setRequestData] = React.useState({ status: false })

  const fetchRecoveryPassword = ({ username }) => {
    return new Promise((resolve, reject) => {
      if (!requestLoading && username) {
        setRequestError(false)
        setRequestLoading(true)
        const axiosToken = CancelToken.source()
        const cancelToken = axiosToken?.token

        const body = {
          email: username,
        }

        axios
          .put(
            `${process.env.REACT_APP_API_URL}/user/request-new-password`,
            body,
            { cancelToken }
          )
          .then(() => {
            setRequestData({ status: true })
            resolve()
          })
          .catch((err) => {
            setRequestError(true)
            const errMessage = validateError(err?.response?.data)
            reject(errMessage)
          })
          .finally(() => setRequestLoading(false))
      } else {
        reject()
      }
    })
  }

  return { requestData, requestError, requestLoading, fetchRecoveryPassword }
}

export const useChangePassword = () => {
  const { validateError } = useValidateErrorResponse()
  const [requestLoading, setRequestLoading] = React.useState(false)
  const [requestError, setRequestError] = React.useState(false)
  const [requestData, setRequestData] = React.useState({ status: false })

  const fetchChangePassword = ({ password, token }) => {
    return new Promise((resolve, reject) => {
      if (!requestLoading && password && token) {
        setRequestError(false)
        setRequestLoading(true)
        const axiosToken = CancelToken.source()
        const cancelToken = axiosToken?.token

        const body = {
          password,
          token,
        }

        axios
          .put(`${process.env.REACT_APP_API_URL}/user/new-password`, body, {
            cancelToken,
          })
          .then(() => {
            setRequestData({ status: true })
            resolve()
          })
          .catch((err) => {
            setRequestError(true)
            const errMessage = validateError(err?.response?.data)
            reject(errMessage)
          })
          .finally(() => setRequestLoading(false))
      } else {
        reject()
      }
    })
  }

  return { requestData, requestError, requestLoading, fetchChangePassword }
}

export const useLogout = () => {
  const [stateAuthContext, dispatchAuthContext] = useContext(AuthContext)
  const [, dispatchUserContext] = useContext(UserContext)
  const [, dispatchThemeContext] = useContext(ThemeContext)

  const [requestLoading, setRequestLoading] = React.useState(false)

  const fetchLogout = () => {
    if (stateAuthContext.token) {
      return new Promise((resolve, reject) => {
        if (!requestLoading) {
          setRequestLoading(true)
          const token = loadItem('token')
          const headers = createHeaders()

          const body = {
            token,
          }

          axios
            .put(`${process.env.REACT_APP_API_URL}/auth/logout`, body, {
              headers,
            })
            .finally(() => {
              dispatchAuthContext({ type: 'clear' })
              dispatchUserContext({ type: 'clear' })
              dispatchThemeContext({ type: 'clear' })
              resolve()
            })
        } else {
          reject()
        }
      })
    }
  }

  return { fetchLogout }
}
