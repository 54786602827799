/* eslint-disable react/prop-types */
import { Checkbox, TableCell, TableRow, Typography } from '../../../core'
import { makeStyles } from '../../../styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  itemCell: {
    padding: 0,
    margin: 0,
    color: theme?.palette?.secondary?.contrastText,
    paddingLeft: 30,
    width: 'calc(100vw - 350px)',
  },
  row: {
    height: '7px',
    fontFamily: theme.typography.fontFamily,
  },
  checkBox: {
    padding: 6,
    margin: 0,
  },
  userCell: {
    padding: 0,
    paddingRight: 16,
    paddingLeft: 16,
    textAlign: 'center',
    minWidth: 150,
    maxWidth: 150,
    borderLeft: `1px solid ${theme?.palette?.quaternary?.main}`,
  },
}))

export const RowWithCheckboxes = (props) => {
  const classes = useStyles()
  const {
    editStatus,
    titleRow,
    userChecked,
    userDisabled,
    onChange,
    group,
    groupChecked,
  } = props

  return (
    <TableRow
      key={`row_${titleRow}`}
      className={classes.row}
      style={{
        opacity: !editStatus ? '0.5' : '1.0',
      }}
    >
      <TableCell align="left" className={classes.itemCell}>
        <Typography>
          {typeof titleRow === 'string' ? titleRow : titleRow.toString()}
        </Typography>
      </TableCell>

      <TableCell align="center" className={classes.userCell}>
        <Checkbox
          id="userCheckbox"
          inputProps={{
            'data-testid': `userCheckbox_${titleRow}`,
          }}
          color="primary"
          className={classes.checkBox}
          classes={{
            checked: {},
          }}
          checked={userChecked}
          disabled={userDisabled}
          onClick={onChange}
        />
      </TableCell>
      {group && (
        <TableCell align="center" className={classes.userCell}>
          <Checkbox
            id="groupCheckbox"
            inputProps={{
              'data-testid': `groupCheckbox_${titleRow}`,
            }}
            color="primary"
            className={classes.checkBox}
            classes={{
              checked: {},
            }}
            checked={groupChecked}
            disabled
          />
        </TableCell>
      )}
    </TableRow>
  )
}
