import React from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { layoutLG, layoutMD, layoutSM, layoutXS } from './constants'

const ResponsiveGridLayout = WidthProvider(Responsive)

export const InteractiveDashboard = ({
  children,
  layouts,
  handleChangeLayout,
}) => {
  var defaultLayouts = {
    lg: layoutLG,
    md: layoutMD,
    sm: layoutSM,
    xs: layoutXS,
  }

  const originalLayout = layouts || defaultLayouts

  const onLayoutChange = (layout, breakPoint) => {
    if (typeof handleChangeLayout === 'function') {
      handleChangeLayout(layout, breakPoint)
      return
    }
    console.log('handleChangeLayout(layout,breakPoint) is undefined')
  }

  return (
    <ResponsiveGridLayout
      className="layout"
      layouts={originalLayout}
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
      rowHeight={70}
      onLayoutChange={onLayoutChange}
    >
      {children}
    </ResponsiveGridLayout>
  )
}
