import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { intl } from '../../translation'
import { SearchSelect, Typography } from '../../core'
import { makeStyles } from '../../styles'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  headerSelect: {
    display: 'flex',
    padding: 10,
    minWidth: 200,
  },
}))

const FormAddTariff = ({
  products,
  payzenBillingTypes,
  veripagBillingTypes,
  billingTypes,
  tariffTypes,
  operators,
  onSelectedValues,
  hiddenCompanySelect,
  hiddenCurrencySelect,
  companies,
  currencies,
}) => {
  const classes = useStyles()

  const [product, setProduct] = useState(null)
  const [billingPayzenType, setBillingPayzenType] = useState(null)
  const [billingVeripagType, setBillingVeripagType] = useState(null)
  const [billingLSSType, setBillingLSSType] = useState(null)

  const [tariffType, setTariffType] = useState(null)
  const [operator, setOperator] = useState(null)
  const [company, setCompany] = useState(null)
  const [currency, setCurrency] = useState(null)

  const viewPayzenBillingTypes = product?.value === 12
  const viewVeripagBillingTypes = product?.value === 104
  const viewLSSBillingTypes = product?.value === 10

  useEffect(() => {
    if (currencies?.length === 1) {
      setCurrency(currencies[0])
    }
  }, [currencies])

  useEffect(() => {
    if (typeof onSelectedValues === 'function') {
      if (
        !_.isEmpty(product) &&
        !_.isEmpty(tariffType) &&
        !_.isEmpty(operator) &&
        ((viewLSSBillingTypes && !_.isEmpty(billingLSSType)) ||
          !viewLSSBillingTypes) &&
        ((!hiddenCompanySelect && !_.isEmpty(company)) ||
          hiddenCompanySelect) &&
        ((!hiddenCurrencySelect && !_.isEmpty(currency)) ||
          hiddenCurrencySelect)
      ) {
        onSelectedValues({
          product,
          tariffType,
          operator,
          billingVeripagType,
          billingPayzenType,
          billingLSSType,
          company,
          currency,
        })
      } else {
        onSelectedValues({})
      }
    }
  }, [
    product,
    tariffType,
    operator,
    billingVeripagType,
    billingPayzenType,
    billingLSSType,
    company,
    currency,
  ])

  const handleChangeProduct = (selectedItem) => setProduct(selectedItem)

  const handleChangeBillingPayzenType = (selectedItem) =>
    setBillingPayzenType(selectedItem)

  const handleChangeBillingVeripagType = (selectedItem) =>
    setBillingVeripagType(selectedItem)

  const handleChangeBillingLSSType = (selectedItem) =>
    setBillingLSSType(selectedItem)

  const handleChangeType = (selectedItem) => setTariffType(selectedItem)

  const handleChangeOperators = (selectedItem) => setOperator(selectedItem)

  const handleSelectCompany = (selectedItem) => setCompany(selectedItem)

  const handleChangeCurrency = (selectedItem) => setCurrency(selectedItem)

  return (
    <>
      <Typography
        variant="subtitle1"
        display="block"
        style={{ fontWeight: 'bold' }}
      >
        {intl.get('words.details') || 'words.details'}
      </Typography>
      <div className={classes.header}>
        {!hiddenCompanySelect && (
          <div className={classes.headerSelect}>
            <SearchSelect
              id="companies-list"
              listOptions={companies || []}
              onSelectOption={handleSelectCompany}
              selectedOption={company}
              placeholder={intl.get(`words.company`) || 'words.company'}
            />
          </div>
        )}
        <div className={classes.headerSelect}>
          <SearchSelect
            id="tariffTypes-list"
            listOptions={tariffTypes || []}
            onSelectOption={handleChangeType}
            selectedOption={tariffType}
            placeholder={intl.get(`titles.tariffType`) || 'titles.tariffType'}
          />
        </div>
        <div className={classes.headerSelect}>
          <SearchSelect
            id="operators-list"
            listOptions={operators || []}
            onSelectOption={handleChangeOperators}
            selectedOption={operator}
            placeholder={intl.get(`titles.operator`) || 'titles.operator'}
          />
        </div>
        <div className={classes.headerSelect}>
          <SearchSelect
            id="products-list"
            listOptions={products || []}
            onSelectOption={handleChangeProduct}
            selectedOption={product}
            placeholder={intl.get(`titles.productId`) || 'titles.productId'}
          />
        </div>
        {!hiddenCurrencySelect && (
          <div className={classes.headerSelect}>
            <SearchSelect
              id="currencies-list"
              listOptions={currencies || []}
              onSelectOption={handleChangeCurrency}
              selectedOption={currency}
              placeholder={intl.get(`words.currency`) || 'words.currency'}
            />
          </div>
        )}
        {viewPayzenBillingTypes && (
          <div className={classes.headerSelect}>
            <SearchSelect
              id="payzenBillingTypes-list"
              listOptions={payzenBillingTypes || []}
              onSelectOption={handleChangeBillingPayzenType}
              selectedOption={billingPayzenType}
              placeholder={intl.get(`titles.type`) || 'titles.type'}
            />
          </div>
        )}
        {viewVeripagBillingTypes && (
          <div className={classes.headerSelect}>
            <SearchSelect
              id="veripagBillingTypes-list"
              listOptions={veripagBillingTypes || []}
              onSelectOption={handleChangeBillingVeripagType}
              selectedOption={billingVeripagType}
              placeholder={intl.get(`titles.type`) || 'titles.type'}
            />
          </div>
        )}
        {viewLSSBillingTypes && (
          <div className={classes.headerSelect}>
            <SearchSelect
              id="viewLSSBillingTypes-list"
              listOptions={billingTypes || []}
              onSelectOption={handleChangeBillingLSSType}
              selectedOption={billingLSSType}
              placeholder={intl.get(`titles.type`) || 'titles.type'}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default FormAddTariff
