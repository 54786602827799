import { Plugin } from '@devexpress/dx-react-core'
import PropTypes from 'prop-types'
import React from 'react'
import { intl } from '../../../translation'
import { findByType } from '../utils/subComponents'
import {
  AbonnementText,
  Actions,
  ArrayCommercial,
  ArrayRevenue,
  Billet,
  BillingTypeVeripag,
  BooleanText,
  Bytes,
  Carriers,
  CompleteMonth,
  CommercialAcronym,
  Currency,
  CustomObject,
  Date,
  DateShort,
  GenericStringList,
  Invoice,
  Month,
  Number,
  Pdf,
  Percentage,
  PercentageValue,
  Phone,
  QueryString,
  Screen,
  Status,
  StatusReport,
  TextColorful,
  Time,
  Translator,
  TranslatorExtractType,
  TranslatorPayzenBilling,
  TranslatorPayzenCharge,
  TranslatorTariffType,
} from './DataTypeProviders'

const TypeProviders = ({
  settingsProviders,
  carriers,
  children,
  onBilletDownload,
  onInvoiceDownload,
  onPdfDownload,
  currencyData,
  currencyListOptions,
}) => {
  const renderActions = (row) => {
    const actions = findByType(children, Actions)

    if (!actions) {
      return null
    }

    return React.cloneElement(actions, { row })
  }

  return (
    <Plugin name="TypeProviders">
      {/* Formata a data com base no intl `default.date.monthYear` */}
      {settingsProviders.month && <Month for={settingsProviders.month} />}

      {/* Formata a data com base no intl `default.date.monthYear` */}
      {settingsProviders.completeMonth && (
        <CompleteMonth for={settingsProviders.completeMonth} />
      )}

      {/* Formata a data com base no intl `default.date.short` */}
      {settingsProviders.shortDate && (
        <DateShort for={settingsProviders.shortDate} />
      )}

      {/* Traduz a booleana para `words.yes` ou `words.no` */}
      {settingsProviders.bool && settingsProviders.bool.yesAndNo && (
        <BooleanText
          for={settingsProviders.bool.yesAndNo}
          isTrueValue={intl.get('words.yes') || 'yes'}
          isFalseValue={intl.get('words.no') || 'no'}
        />
      )}

      {settingsProviders.bool && settingsProviders.bool.activeAndInactive && (
        <BooleanText
          for={settingsProviders.bool.activeAndInactive}
          isTrueValue={intl.get('words.active') || 'active'}
          isFalseValue={intl.get('words.inactive') || 'inactive'}
        />
      )}

      {/* Converte para os tipos de status do SIM Card */}
      {settingsProviders.simstatus && (
        <Status for={settingsProviders.simstatus} />
      )}

      {/* Converte para as telas existente */}
      {settingsProviders.screenName && (
        <Screen for={settingsProviders.screenName} />
      )}

      {/* Traduz o texto e muda a cor do `dot` de acordo com o status */}
      {settingsProviders.statusReport && (
        <StatusReport for={settingsProviders.statusReport} />
      )}

      {/* Exibe uma lista dos usuários do comercial responsáveis pela empresa,
        adiciona um `avatar` com as iniciais do usuário do comercial e exibe o nome do mesmo */}
      {settingsProviders.commercial && (
        <CommercialAcronym for={settingsProviders.commercial} />
      )}

      {/* Exibe uma lista de strings */}
      {settingsProviders.listString && (
        <GenericStringList for={settingsProviders.listString} />
      )}

      {/* Adiciona o componente `actionsCell` na coluna de ações */}
      {settingsProviders.actions && (
        <Actions
          for={settingsProviders.actions}
          actionsCell={(row) => renderActions(row)}
        />
      )}

      {/* Exibe as imagens das operadoras com base nos valores recebidos */}
      {carriers && settingsProviders.logo && (
        <Carriers carriers={carriers} for={settingsProviders.logo} />
      )}

      {/* Traduz o status do simcard com base no valor recebido */}
      {settingsProviders.simcardStatus && (
        <CustomObject
          options={{
            activated: intl.get('status.activated'),
            cancelled: intl.get('status.cancelled'),
            suspended: intl.get('status.suspended'),
            replacementRequest: intl.get('status.replacementRequest'),
            cancellationRequest: intl.get('status.cancellationRequest'),
            replace: intl.get('status.replace'),
          }}
          for={settingsProviders.simcardStatus}
        />
      )}

      {settingsProviders.abonnementText && (
        <AbonnementText for={settingsProviders.abonnementText} />
      )}

      {settingsProviders.veripagBillingTypeText && (
        <BillingTypeVeripag for={settingsProviders.veripagBillingTypeText} />
      )}

      {/* Formata o valor em megabytes */}
      {settingsProviders.byte && <Bytes for={settingsProviders.byte} />}

      {/* Calcula o valor e mostra % */}
      {settingsProviders.percentage && (
        <Percentage for={settingsProviders.percentage} />
      )}

      {/* Mosta o valor com % */}
      {settingsProviders.percentageValue && (
        <PercentageValue for={settingsProviders.percentageValue} />
      )}

      {/* Exibe os valore da querystring em uma lista */}
      {settingsProviders.queryString && (
        <QueryString for={settingsProviders.queryString} />
      )}

      {/* Traduz o valor com base em regras predefinidas */}
      {settingsProviders.translator && (
        <Translator for={settingsProviders.translator} />
      )}

      {/* Traduz o valor para PAYZEN billing type */}
      {settingsProviders.billingTypePayzenText && (
        <TranslatorPayzenBilling
          for={settingsProviders.billingTypePayzenText}
        />
      )}

      {/* Traduz o valor para PAYZEN charge type */}
      {settingsProviders.chargeTypePayzenText && (
        <TranslatorPayzenCharge for={settingsProviders.chargeTypePayzenText} />
      )}

      {/* Traduz o valor para o tipo correto do extrato */}
      {settingsProviders.extractType && (
        <TranslatorExtractType for={settingsProviders.extractType} />
      )}

      {/* Traduz o valor para o tipo correto de tarifa */}
      {settingsProviders.tariffType && (
        <TranslatorTariffType for={settingsProviders.tariffType} />
      )}

      {/* Formata a data para o formato completo da mesma `default.date.complete` */}
      {settingsProviders.date && <Date for={settingsProviders.date} />}

      {/* Formata o valor para moeda */}
      {settingsProviders.currency && (
        <Currency
          for={settingsProviders.currency}
          currencyData={currencyData}
          currencyListOptions={currencyListOptions}
        />
      )}

      {/* Formata o valor em segundos para HH:mm:ss */}
      {settingsProviders.time && <Time for={settingsProviders.time} />}

      {/* Formata o valor para número com . */}
      {settingsProviders.number && <Number for={settingsProviders.number} />}

      {/* Formata telefones com 11 dígitos */}
      {settingsProviders.phone && <Phone for={settingsProviders.phone} />}

      {/* Botão para download de boleto */}
      {settingsProviders.billet && (
        <Billet
          for={settingsProviders.billet}
          onBilletDownload={onBilletDownload}
        />
      )}

      {/* Botão para download de Nota Fiscal */}
      {settingsProviders.invoice && (
        <Invoice
          for={settingsProviders.invoice}
          onInvoiceDownload={onInvoiceDownload}
        />
      )}

      {/* Botão para download de PDF */}
      {settingsProviders.pdf && (
        <Pdf for={settingsProviders.pdf} onPdfDownload={onPdfDownload} />
      )}

      {/* Botão para download de PDF */}
      {settingsProviders.arrayCommercial && (
        <ArrayCommercial for={settingsProviders.arrayCommercial} />
      )}

      {/* Botão para download de PDF */}
      {settingsProviders.arrayRevenue && (
        <ArrayRevenue for={settingsProviders.arrayRevenue} />
      )}

      {/* Colorir texto conforme valor */}
      {settingsProviders.isColorful && (
        <TextColorful for={settingsProviders.isColorful} />
      )}
    </Plugin>
  )
}

TypeProviders.defaultProps = {
  children: [],
  carriers: [],
  settingsProviders: {},
}

TypeProviders.propTypes = {
  children: PropTypes.node.isRequired,
  carriers: PropTypes.array.isRequired,
  settingsProviders: PropTypes.object.isRequired,
}

export default TypeProviders
