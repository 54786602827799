import PropTypes from 'prop-types'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { InputAdornment, Typography } from '../../../../core'
import { makeStyles, useTheme } from '../../../../styles'
import { intl } from '../../../../translation'
import { useSnackbar } from '../../../../notifications'
import InputField from '../Atoms/InputField'
import NumberFormatCurrency from '../Utils/NumberFormatCurrency'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '0.8em',
    fontFamily: theme?.typography?.fontFamily,
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    border: `1px solid ${theme?.palette?.secondary?.contrastText}`,
    overflow: 'hidden',
    borderRadius: 4,
    color: theme?.palette?.secondary?.contrastText,
    marginBottom: 5,
    marginTop: 5,
  },
  input: {
    marginTop: 3,
  },
  focused: {},
  alignLeft: {
    width: '47.5%',
    marginRight: '5%',
    paddingTop: 0,
    marginTop: 0,
  },
  alignRight: {
    width: '47.5%',
    paddingTop: 0,
    marginTop: 0,
  },
  title: {
    color: theme?.palette?.secondary?.contrastText,
    textAlign: 'left',
    marginBottom: 0,
  },
}))

const FieldRangeCurrency = ({
  filter,
  onUpdate,
  version,
  defaultName,
  currencyData,
}) => {
  currencyData =
    !currencyData || _.isEmpty(currencyData) ? { symbol: 'R$ ' } : currencyData

  const classes = useStyles()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()

  const [state, setState] = useState({})
  const [valueMin, setValueMin] = useState('')
  const [valueMax, setValueMax] = useState('')

  useEffect(() => {
    if (JSON.stringify(filter) !== '{}') {
      setState({ ...filter })
      setValueMin(filter?.valueMin)
      setValueMax(filter?.valueMax)
    }
  }, [version])

  useEffect(() => {
    setState({
      ...filter,
      valueMin: valueMin || valueMin === 0 ? Number(valueMin) : valueMin,
    })
  }, [valueMin])

  useEffect(() => {
    setState({
      ...filter,
      valueMax: valueMax || valueMax === 0 ? Number(valueMax) : valueMax,
    })
  }, [valueMax])

  useEffect(() => {
    if (JSON.stringify(state) !== '{}') {
      validateValue(state)
    }
  }, [state])

  const validateValue = (data) => {
    onUpdate(data)
  }

  const handleBlurValueMin = (e) => {
    if (valueMax) {
      if (Number(valueMin) > Number(valueMax)) {
        setValueMin(valueMax)
        enqueueSnackbar(
          intl.get('phrases.valueMinError') || 'phrases.valueMinError',
          {
            variant: 'warning',
          }
        )
      }
    }
  }

  const handleBlurValueMax = (e) => {
    if (valueMin) {
      if (valueMax && Number(valueMax) < Number(valueMin)) {
        setValueMax(valueMin)
        enqueueSnackbar(
          intl.get('phrases.valueMaxError') || 'phrases.valueMaxError',
          {
            variant: 'warning',
          }
        )
      }
    }
  }

  return (
    <div data-testid="range_currency" key={`${filter?.defaultName}Fragment`}>
      <Typography className={classes.title}>
        {intl.get(`titles.${defaultName}`) || 'title'}
      </Typography>
      <InputField
        key={`${filter?.defaultName}Min`}
        placeholder={intl.get('words.inputMinTitle') || 'words.inputMinTitle'}
        size="small"
        margin="dense"
        inputProps={{
          classes: {
            root: classes.root,
            input: classes.input,
          },
          style: {
            color:
              valueMin?.length > 0 && theme?.palette?.secondary?.contrastText,
          },
          disableUnderline: true,
          inputComponent: NumberFormatCurrency,
          startAdornment: (
            <InputAdornment
              data-testid="minAdornment"
              disableTypography
              style={{ color: 'lightGray' }}
              position="start"
            >
              {currencyData?.symbol}
            </InputAdornment>
          ),
          value: valueMin,
          onChange: (event) =>
            setValueMin(event?.target?.value ? event?.target?.value : ''),
          onBlur: handleBlurValueMin,
        }}
        classNames={classes.alignLeft}
      />
      <InputField
        key={`${filter?.defaultName}Max`}
        placeholder={intl.get('words.inputMaxTitle') || 'words.inputMaxTitle'}
        size="small"
        margin="dense"
        inputProps={{
          classes: {
            root: classes.root,
            input: classes.input,
          },
          style: {
            color:
              valueMax?.length > 0 && theme?.palette?.secondary?.contrastText,
          },
          disableUnderline: true,
          inputComponent: NumberFormatCurrency,
          startAdornment: (
            <InputAdornment
              data-testid="maxAdornment"
              disableTypography
              style={{ color: 'lightGray' }}
              position="start"
            >
              {currencyData?.symbol}
            </InputAdornment>
          ),
          value: valueMax,
          onChange: (event) => {
            setValueMax(event?.target?.value ? event?.target?.value : '')
          },
          onBlur: handleBlurValueMax,
        }}
        classNames={classes.alignRight}
      />
    </div>
  )
}

FieldRangeCurrency.propTypes = {
  filter: PropTypes.any,
  onUpdate: PropTypes.any,
  version: PropTypes.any,
}

export default FieldRangeCurrency
