import React, { useState } from 'react'
import {
  Paper,
  Popper,
  Tooltip,
  Collapse,
  MenuItem,
  MenuList,
  IconButton,
  Typography,
  ClickAwayListener,
} from '../../../core'
import PropTypes from 'prop-types'
import { intl } from '../../../translation'
import { makeStyles } from '../../../styles'
import { Shuffle as ShuffleIcon } from '../../../icons'

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.tertiary?.main,
  },
  button: {
    margin: '0px 5px',
    padding: 5,
  },
  popper: {
    zIndex: 999,
    marginTop: 5,
  },
  paper: {
    maxWidth: 300,
    minWidth: 250,
    maxHeight: 500,
    overflowY: `auto`,
    overflowX: `hidden`,
  },
  menuItem: {
    '$&disabled': {
      color: 'darkslategray',
      textDecoration: 'line-through',
    },
  },
}))

const MultiSelectChange = ({ optionsList, handleButtonClick }) => {
  const classes = useStyles()
  const anchorRef = React.useRef(null)
  const [open, setOpen] = React.useState(false)

  const handleClick = (item) => {
    handleButtonClick('ChangeStatus', item)
    setOpen(false)
  }

  return (
    <>
      <Tooltip data-testid="tooltip" title={intl.get('phrases.changeStatus')}>
        <IconButton
          role="button"
          ref={anchorRef}
          aria-haspopup="true"
          className={classes.icon}
          onClick={() => setOpen(true)}
          aria-label={intl.get('phrases.changeStatus')}
          aria-controls={open ? 'menu-list-grow' : undefined}
        >
          <ShuffleIcon data-testid="changeStatusIcon" />
        </IconButton>
      </Tooltip>
      <Popper
        transition
        open={open}
        placement="bottom-start"
        className={classes.popper}
        anchorEl={anchorRef?.current}
      >
        {({ TransitionProps, placement }) => (
          <Collapse {...TransitionProps}>
            <Paper className={classes.paper} elevation={3}>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList role="list" autoFocusItem={open} id="download-list">
                  {optionsList?.map((item) => (
                    <MenuItem
                      classes={{
                        root: classes.menuItem,
                      }}
                      role="listitem"
                      key={item.label}
                      onClick={() => handleClick(item)}
                      disabled={item.isDisabled}
                    >
                      <Tooltip title={item.label}>
                        <Typography variant="inherit" noWrap>
                          {item.label}
                        </Typography>
                      </Tooltip>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Collapse>
        )}
      </Popper>
    </>
  )
}

MultiSelectChange.defaultProps = {
  handleButtonClick: () => {},
}

MultiSelectChange.propTypes = {
  handleButtonClick: PropTypes.func.isRequired,
}

export default MultiSelectChange
