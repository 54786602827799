import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { loadItem, removeItem, saveItem } from '../utils/localStorage'
import { browserLanguage } from '../utils/i18n'

const userName = loadItem('userName')
const userEmail = loadItem('userEmail')
const userLanguage = loadItem('userLanguage')
const userId = loadItem('userId')
const company = loadItem('company')
const companyId = loadItem('companyId')
const commercialId = loadItem('commercialId')
const userGroup = loadItem('userGroup')
const accessRoles = loadItem('accessRoles')
const defaultScreen = loadItem('defaultScreen')
const isLatamUser = loadItem('isLatamUser')
const userXCompanyList = loadItem('userXCompanyList')
const userXProductList = loadItem('userXProductList')
const companies = loadItem('companies')
const products = loadItem('products')

const defaultState = {
  userEmail: '',
  userName: '',
  userId: null,
  userGroup: 0,
  company: null,
  companyId: 0,
  commercialId: null,
  defaultScreen: '',
  accessRoles: {},
  isLatamUser: false,
  userXCompanyList: [],
  userXProductList: [],
  companies: {},
  products: {},
}

const initialState = {
  userEmail: userEmail || '',
  userName: userName || '',
  userLanguage: userLanguage,
  userId: userId || null,
  userGroup: userGroup || 0,
  company: company || null,
  userXCompanyList: userXCompanyList || [],
  userXProductList: userXProductList || [],
  companyId: companyId || 0,
  commercialId: commercialId || null,
  accessRoles: accessRoles || {},
  defaultScreen: defaultScreen || '',
  isLatamUser: isLatamUser || false,
  companies: companies || {},
  products: products || {},
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'setSettingsUser':
      let company

      const separatorsNormalize = (currencyObject) => {
        return {
          ...currencyObject,
          thousandSeparator: action.userLanguage === 'en-US' ? ',' : '.',
          decimalSeparator: action.userLanguage === 'en-US' ? '.' : ',',
        }
      }

      if (
        action.company?.currencies &&
        !_.isEmpty(action?.company?.currencies)
      ) {
        company = _.cloneDeep(action.company)
        company.currencies = action.company?.currencies.map((currency) =>
          separatorsNormalize(currency)
        )
      }

      saveItem(action.userEmail, 'userEmail')
      saveItem(action.userName, 'userName')
      saveItem(action.userLanguage, 'userLanguage')
      saveItem(action.userId, 'userId')
      saveItem(action.userGroup, 'userGroup')
      saveItem(company, 'company')
      saveItem(action?.userXCompanyList, 'userXCompanyList')
      saveItem(action?.userXProductList, 'userXProductList')
      saveItem(action.companyId, 'companyId')
      saveItem(action.commercialId, 'commercialId')
      saveItem(action.accessRoles, 'accessRoles')
      saveItem(action.defaultScreen, 'defaultScreen')
      saveItem(action.isLatamUser, 'isLatamUser')
      saveItem(action.companies, 'companies')
      saveItem(action.products, 'products')

      return {
        userEmail: action.userEmail,
        userName: action.userName,
        userLanguage: action.userLanguage,
        userId: action.userId,
        userGroup: action.userGroup,
        company,
        userXCompanyList: action.userXCompanyList,
        userXProductList: action.userXProductList,
        companyId: action.companyId,
        commercialId: action.commercialId,
        accessRoles: action.accessRoles,
        defaultScreen: action.defaultScreen,
        isLatamUser: action.isLatamUser,
        companies: action.companies,
        products: action.products,
      }

    case 'setLATAMView':
      const newStatusLATAM = !state.isLatamUser

      saveItem(newStatusLATAM, 'isLatamUser')

      return {
        ...state,
        isLatamUser: newStatusLATAM,
      }

    case 'setLanguage':
      return { ...state, userLanguage: action.defaultLanguage }

    case 'clear':
      removeItem('userEmail')
      removeItem('userName')
      removeItem('userId')
      removeItem('userGroup')
      removeItem('company')
      removeItem('userXCompanyList')
      removeItem('userXProductList')
      removeItem('companyId')
      removeItem('commercialId')
      removeItem('accessRoles')
      removeItem('defaultScreen')
      removeItem('isLatamUser')
      removeItem('companies')
      removeItem('products')
      return {
        ...defaultState,
        userLanguage: userLanguage ?? browserLanguage(),
      }
    default:
      throw new Error('Unexpected action')
  }
}

export const UserContext = React.createContext()

const Provider = ({ children }) => {
  const context = useReducer(reducer, initialState)
  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}

Provider.defaultProps = {
  children: <></>,
}

Provider.propTypes = {
  children: PropTypes.node,
}

export default Provider
