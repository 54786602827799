import { DataTypeProvider } from '@devexpress/dx-react-grid'
import moment from 'moment'
import React from 'react'

const secondsToTime = (value) => {
  const newDuration = moment.duration(value, 'milliseconds')
  const hours = Math.floor(newDuration.asHours())
  const minutes = Math.floor(newDuration.asMinutes()) - hours * 60
  let seconds =
    Math.floor(newDuration.asSeconds()) - hours * 60 * 60 - minutes * 60
  seconds = seconds > 9 ? seconds : `0${seconds}`
  let response = ''

  response = `${hours ? `${hours}h` : ''} ${minutes ? `${minutes}m` : ''} ${
    seconds ? `${seconds}s` : ''
  }`

  return response
}

const Percentage = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) =>
      value ? (
        secondsToTime(value)
      ) : (
        <span style={{ color: 'lightGray' }}>0s</span>
      )
    }
    {...props}
  />
)

export default Percentage
