import { makeStyles } from '@library/styles'
import { convertHexToRGBA } from '../../../components/Library/utils'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    marginBottom: '15vh',
    color: convertHexToRGBA(theme.palette.primary.main, 0.8),
    textAlign: 'center',
  },

  title: {
    fontWeight: 'bold',
    marginBottom: '15px',
  },
  dialogTitleMax: {
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
    paddingTop: 40,
    width: '100%',
    margin: 0,
    height: 70,
    transition: 'width 1s, height 1s, opacity 1s 1s',
  },
  dialogContent: {
    padding: 45,
    textAlign: 'center',
    overflowY: 'hidden',
    transition: 'width 1s, height 1s, opacity 1s 1s',
  },
  dialogActions: {
    padding: '0px 25px',
    marginBottom: 30,
    height: 30,
    textAlign: 'center',
    transition: 'width 1s, height 1s, opacity 1s 1s',
  },
  root: {
    flexGrow: 1,
    fontFamily: theme.typography.fontFamily,
    overflow: 'none',
    transition: 'width 1s, height 1s, opacity 1s 1s',
  },
  backdrop: {
    backgroundColor: convertHexToRGBA(theme.palette.primary.main, 0.5),
  },

  paperPropsMax: {
    borderLeftWidth: 6,
    borderLeftColor: convertHexToRGBA(theme.palette.primary.main, 1),
    borderLeftStyle: 'solid',
    borderRightWidth: 6,
    borderRightColor: convertHexToRGBA(theme.palette.primary.main, 1),
    borderRightStyle: 'solid',
    overflow: 'hidden',
    width: 438,
    transition: 'width 1s, height 1s, border-width 0.6s linear',
    backgroundColor: '#fff',
    color: convertHexToRGBA(theme.palette.primary.main, 0.7),
  },

  borderDetail: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: 6,
    display: 'inline-block',
    margin: 0,
    padding: 0,
  },
  whiteMark: {
    width: '8%',
    backgroundColor: '#fff',
    height: 6,
  },
  blueMark: {
    width: '50%',
    backgroundColor: convertHexToRGBA(theme.palette.primary.main, 0.7),
    height: 6,
  },
  imageContainer: {
    height: '60px',
    width: '60px',
  },
}))
