import React, { useState, useEffect } from 'react'
import MomentUtils from '@date-io/moment'
import {
  Button,
  FormControl,
  FormControlLabel,
  Popover,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { CalendarToday as CalendarTodayIcon } from '@material-ui/icons'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import moment from 'moment'
import 'moment/locale/pt-br'
import { intl } from '../../translation'

const useStyles = makeStyles(() => ({
  buttonBar: {
    backgroundColor: '#fff',
    height: 40,
  },
  containerBar: {},
  containerMenu: {
    width: 300,
    padding: 20,
  },
  gridPeriod: {
    maxWidth: 200,
    margin: '0px auto',
  },
  applyButton: {
    marginTop: 20,
  },
}))

export const PeriodBar = ({ handlePeriod, initialPeriod }) => {
  const classes = useStyles()
  moment.locale('pt-BR')
  const [anchorEl, setAnchorEl] = useState(null)
  const [value, setValue] = useState(null)
  const [selectedInitialDate, setSelectedInitialDate] = useState(null)
  const [selectedFinalDate, setSelectedFinalDate] = useState(null)
  const [selectedPeriod, setSelectedPeriod] = useState('')

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleApplyValues = (valueParameter) => {
    const instantValue = moment()
    const lastDay = moment(instantValue).subtract(1, 'days')
    const lastWeek = moment(instantValue).subtract(7, 'days')
    const last15days = moment(instantValue).subtract(15, 'days')
    const last30days = moment(instantValue).subtract(30, 'days')
    const startMonth = moment(instantValue).startOf('month')
    const lastStartMonth = moment(instantValue)
      .subtract(1, 'month')
      .startOf('month')
    const lastEndMonth = moment(instantValue)
      .subtract(1, 'month')
      .endOf('month')
    const lastStart3Months = moment(instantValue)
      .subtract(2, 'month')
      .startOf('month')
    const lastEnd3Months = moment(instantValue).endOf('month')
    const lastStart6Months = moment(instantValue)
      .subtract(5, 'month')
      .startOf('month')
    const lastEnd6Months = moment(instantValue).endOf('month')
    const lastStart12Months = moment(instantValue)
      .subtract(11, 'month')
      .startOf('month')
    const lastEnd12Months = moment(instantValue).endOf('month')
    const startYear = moment(instantValue).startOf('year')

    switch (valueParameter) {
      case 'today':
        setSelectedInitialDate(instantValue)
        setSelectedFinalDate(instantValue)
        break
      case 'lastDay':
        setSelectedInitialDate(lastDay)
        setSelectedFinalDate(instantValue)
        break
      case 'lastWeek':
        setSelectedInitialDate(lastWeek)
        setSelectedFinalDate(instantValue)
        break
      case 'last15days':
        setSelectedInitialDate(last15days)
        setSelectedFinalDate(instantValue)
        break
      case 'last30days':
        setSelectedInitialDate(last30days)
        setSelectedFinalDate(instantValue)
        break
      case 'thisMonth':
        setSelectedInitialDate(startMonth)
        setSelectedFinalDate(instantValue)
        break
      case 'lastMonth':
        setSelectedInitialDate(lastStartMonth)
        setSelectedFinalDate(lastEndMonth)
        break
      case 'last3Months':
        setSelectedInitialDate(lastStart3Months)
        setSelectedFinalDate(lastEnd3Months)
        break
      case 'last6Months':
        setSelectedInitialDate(lastStart6Months)
        setSelectedFinalDate(lastEnd6Months)
        break
      case 'last12Months':
        setSelectedInitialDate(lastStart12Months)
        setSelectedFinalDate(lastEnd12Months)
        break
      case 'thisYear':
        setSelectedInitialDate(startYear)
        setSelectedFinalDate(instantValue)
        break

      default:
        break
    }
  }

  useEffect(() => {
    if (selectedInitialDate && selectedFinalDate) {
      definePeriod(selectedInitialDate, selectedFinalDate)
    }
  }, [selectedInitialDate, selectedFinalDate])

  useEffect(() => {
    if (initialPeriod) {
      setValue(initialPeriod)
    }
  }, [initialPeriod])

  useEffect(() => {
    if (value && value !== 'definedByUser') {
      handleApplyValues(value)
    }
  }, [value])

  const definePeriod = (initial, final) => {
    if (initial && final) {
      if (initial > final) {
        setSelectedFinalDate(initial)
      }

      if (initial > moment()) {
        setSelectedInitialDate(moment())
      }

      if (final > moment()) {
        setSelectedFinalDate(moment())
      }

      if (initial && moment(initial).isValid() && !final) {
        setSelectedPeriod(initial?.format('ddd DD/MM/YYYY'))
      }

      if (
        initial &&
        moment(initial).isValid() &&
        final &&
        moment(final).isValid() &&
        initial === final
      ) {
        setSelectedPeriod(`${initial?.format('ddd DD/MM/YYYY')} `)
      }

      if (
        initial &&
        moment(initial).isValid() &&
        final &&
        moment(final).isValid() &&
        initial !== final
      ) {
        setSelectedPeriod(
          `${initial?.format('ddd DD/MM/YYYY') || ``} - ${
            final?.format('ddd DD/MM/YYYY') || ``
          }`
        )
      }

      return
    }
    setSelectedPeriod('')
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const handleDateInitialChange = (date) => {
    setSelectedInitialDate(date)
  }

  const handleDateFinalChange = (date) => {
    setSelectedFinalDate(date)
  }

  const handleSendPeriod = () => {
    handlePeriod({
      type: value,
      initialDate: selectedInitialDate,
      finalDate: selectedFinalDate,
    })
    setAnchorEl(null)
    definePeriod(selectedInitialDate, selectedFinalDate)
  }

  const periods = [
    'today',
    'lastDay',
    'lastWeek',
    'last15days',
    'last30days',
    'thisMonth',
    'lastMonth',
    'last3Months',
    'last6Months',
    'last12Months',
    'thisYear',
    'definedByUser',
  ]

  return (
    <div className={classes.containerBar}>
      <Button
        aria-describedby={id}
        variant="outlined"
        color="primary"
        onClick={handleClick}
        fullWidth
        startIcon={<CalendarTodayIcon />}
        className={classes.buttonBar}
      >
        {selectedPeriod}
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.containerMenu}>
          <FormControl role="form" component="fieldset">
            <RadioGroup
              role="radiogroup"
              aria-label="period"
              name="period1"
              value={value}
              onChange={handleChange}
            >
              {periods?.map((p, index) => {
                return (
                  <FormControlLabel
                    key={index.toString()}
                    value={p}
                    control={
                      <Radio inputProps={{ role: 'radio' }} color="default" />
                    }
                    label={intl.get(`periods.${p}`) || `periods.${p}`}
                  />
                )
              })}
            </RadioGroup>
          </FormControl>
          {value === 'definedByUser' && (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Grid
                container
                direction="column"
                justify="center"
                className={classes.gridPeriod}
              >
                <Grid justify="center">
                  <KeyboardDatePicker
                    InputProps={{
                      'data-testid': 'initialDatePicker',
                    }}
                    disableToolbar
                    inputVariant="outlined"
                    margin="dense"
                    value={selectedInitialDate}
                    placeholder={intl.get(`words.start`) || 'words.start'}
                    onChange={(date) => handleDateInitialChange(date)}
                    format="DD/MM/YYYY"
                    maxDate={moment()}
                    fullWidth
                  />
                </Grid>
                <Grid justify="center">
                  <KeyboardDatePicker
                    InputProps={{
                      'data-testid': 'endDatePicker',
                    }}
                    disableToolbar
                    inputVariant="outlined"
                    margin="dense"
                    value={selectedFinalDate}
                    placeholder={intl.get(`words.end`) || 'words.end'}
                    onChange={(date) => handleDateFinalChange(date)}
                    format="DD/MM/YYYY"
                    maxDate={moment()}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          )}
          <Button
            disabled={!selectedInitialDate || !selectedFinalDate}
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleSendPeriod}
            className={classes.applyButton}
          >
            {intl.get(`words.apply`) || 'words.apply'}
          </Button>
        </div>
      </Popover>
    </div>
  )
}

PeriodBar.propTypes = {}
