import React from 'react'
import { Language as LanguageIcon } from '../../icons'
import { MenuItem, Select, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
  },
  iconStyle: {
    color: theme?.palette?.primary?.main,
  },
}))

export const SelectLanguage = ({
  currentLanguage,
  languagesList,
  handleChangeLanguage,
}) => {
  const classes = useStyles()

  const handleChange = (event) => {
    handleChangeLanguage(event.target.value)
  }

  return (
    <div className={classes.container}>
      <LanguageIcon className={classes.iconStyle} />
      <Select
        value={currentLanguage}
        onChange={handleChange}
        displayEmpty
        className={classes.selectEmpty}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {languagesList.map((language) => (
          <MenuItem value={language.value}>{language.label}</MenuItem>
        ))}
      </Select>
    </div>
  )
}
