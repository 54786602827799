import React, { useEffect, useState } from 'react'
import { Button, Grid, Typography } from '../../../core'
import { DialogModel } from '../../DialogModel'
import { makeStyles } from '../../../styles'
import { intl } from '../../../translation'
import { ScreenDetails } from './ScreenDetails'
import { ScreenList } from './ScreenList'

const useStyles = makeStyles((theme) => ({
  divButtons: {
    display: 'block',
    textAlign: 'right',
    borderTop: `1px solid ${theme?.palette?.primary?.main}`,
  },
  displayContent: {
    display: 'block',
    width: '100%',
  },
  divTable: {
    width: '100%',
    display: 'inline-block',
    padding: 0,
    height: 500,
  },
  row: {
    height: '7px',
  },
  label: { color: theme?.palette?.secondary?.contrastText },
  divLoading: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    paddingTop: '15%',
  },
  loading: {
    width: '80px',
    height: '80px',
    color: theme?.palette?.primary?.main,
  },
  grid: {
    overflow: 'hidden',
    display: 'flex',
  },
  gridList: {
    borderRight: `1px solid ${theme?.palette?.primary?.main}`,
    borderTop: `1px solid ${theme?.palette?.primary?.main}`,
    height: '475px',
    overflow: 'auto',
  },
  gridDetails: {
    borderTop: `1px solid ${theme?.palette?.primary?.main}`,
    height: '400px',
  },
  selectScreen: {
    color: theme?.palette?.secondary?.contrastText,
    width: '100%',
    textAlign: 'center',
    marginTop: '25%',
  },
}))

export const DialogGroupPermissionsByScreen = ({
  open,
  isLoading,
  accessRolesGroup,
  row,
  defaultScreenGroup,
  allowedEditPermission,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles()

  const [editState, setEditState] = useState(false)
  const [selectedScreen, setSelectedScreen] = useState('')
  const [groupRoles, setGroupRoles] = useState({})
  const [groupScreen, setGroupScreen] = useState('')

  useEffect(() => {
    if (JSON.stringify(accessRolesGroup) !== '{}') {
      setGroupRoles({ ...accessRolesGroup })
    }
  }, [accessRolesGroup])

  useEffect(() => {
    if (defaultScreenGroup !== '') {
      setGroupScreen(defaultScreenGroup)
    }
  }, [defaultScreenGroup])

  const handleSelectedScreen = (screen) => {
    selectedScreen !== screen
      ? setSelectedScreen(screen)
      : setSelectedScreen('')
  }

  const handleChangeStatusScreen = (newStatus) => {
    const newScreenGroupRoles = { ...groupRoles[selectedScreen] }
    newScreenGroupRoles.visible = newStatus
    setGroupRoles({ ...groupRoles, [selectedScreen]: newScreenGroupRoles })
  }

  const handleChangeDefaultScreen = (screen) => setGroupScreen(screen)

  const handleChangeStatusAction = (action, newStatus) => {
    const newScreenGroupRoles = {
      ...groupRoles[selectedScreen],
      actions: { ...groupRoles[selectedScreen].actions },
    }
    newScreenGroupRoles.actions[action] = newStatus

    setGroupRoles({ ...groupRoles, [selectedScreen]: newScreenGroupRoles })
  }

  const handleChangeStatusField = (field, newStatus) => {
    const newScreenGroupRoles = {
      ...groupRoles[selectedScreen],
      fields: { ...groupRoles[selectedScreen].fields },
    }
    newScreenGroupRoles.fields[field] = newStatus
    setGroupRoles({ ...groupRoles, [selectedScreen]: newScreenGroupRoles })
  }

  const handleCancelDataUpdates = () => {
    setGroupRoles({ ...accessRolesGroup })
    setGroupScreen(defaultScreenGroup)
    setEditState(false)
  }

  const handleUpdateData = () => {
    if (!groupScreen) {
      return console.log(
        'No defaultScreen defined. Update data is not permitted'
      )
    }

    if (!row?.name) {
      return console.log('No group name defined. Update data is not permitted')
    }

    if (typeof onSubmit !== 'function') {
      return console.log(
        'No onSubmit function defined. Update data is not permitted'
      )
    }

    const newAccessRoles = {
      ...groupRoles,
    }

    const defaultScreen = groupScreen

    const newDataGroup = {
      accessTo: row?.name,
      defaultScreen,
      permissions: {
        ...newAccessRoles,
      },
    }

    onSubmit(newDataGroup)
  }

  return (
    <DialogModel
      open={open}
      title={intl.get(`phrases.groupPermissions`) || `phrases.groupPermissions`}
      size="lg"
      onClose={onClose}
      isLoading={isLoading}
    >
      <div className={classes.displayContent}>
        <div className={classes.divTable}>
          <Typography>{`${intl.get(`words.group`) || 'words.group'}: ${
            row?.name || ''
          }`}</Typography>
          <Grid container direction="row">
            <Grid item xs={4} className={classes.gridList}>
              <ScreenList
                screens={accessRolesGroup}
                handleSelectedScreen={handleSelectedScreen}
                selectedScreen={selectedScreen}
              />
            </Grid>
            <Grid item xs={8} className={classes.gridDetails}>
              {!selectedScreen ? (
                <Typography variant="h5" className={classes.selectScreen}>
                  {intl.get(`phrases.selectAScreen`) || `phrases.selectAScreen`}
                </Typography>
              ) : (
                <ScreenDetails
                  editState={editState}
                  selectedScreen={selectedScreen}
                  row={row}
                  selectedScreenDataGroup={groupRoles?.[selectedScreen]}
                  defaultScreenGroup={groupScreen}
                  handleChangeStatusScreen={handleChangeStatusScreen}
                  handleChangeDefaultScreen={handleChangeDefaultScreen}
                  handleChangeStatusAction={handleChangeStatusAction}
                  handleChangeStatusField={handleChangeStatusField}
                />
              )}
            </Grid>
          </Grid>
        </div>
        {!editState && (
          <div className={classes.divButtons}>
            {allowedEditPermission && (
              <Button
                variant="contained"
                layout="primary"
                width="100px"
                fontSize="12px"
                onClick={() => setEditState(true)}
              >
                {intl.get(`buttons.edit`) || 'buttons.edit'}
              </Button>
            )}

            <Button
              variant="contained"
              layout="secondary"
              width="100px"
              fontSize="12px"
              onClick={onClose}
            >
              {intl.get(`buttons.close`) || 'buttons.close'}
            </Button>
          </div>
        )}

        {editState && (
          <div className={classes.divButtons}>
            <Button
              variant="contained"
              layout="secondary"
              width="100px"
              fontSize="12px"
              onClick={handleCancelDataUpdates}
            >
              {intl.get(`buttons.cancel`) || 'buttons.cancel'}
            </Button>
            <Button
              variant="contained"
              layout="primary"
              width="100px"
              fontSize="12px"
              onClick={handleUpdateData}
              disabled={!allowedEditPermission}
            >
              {intl.get(`buttons.update`) || 'buttons.update'}
            </Button>
          </div>
        )}
      </div>
    </DialogModel>
  )
}

DialogGroupPermissionsByScreen.propTypes = {}
