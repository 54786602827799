import { DataTypeProvider } from '@devexpress/dx-react-grid'
import React from 'react'
import { intl } from '../../../../translation'

const Translator = (props) => (
  <DataTypeProvider
    formatterComponent={({ value, column, ...props }) => {
      if (column && column.name && value) {
        let intlPath = ''
        switch (column.name) {
          case 'severity':
            intlPath = 'alerts'
            break
          case 'status':
            intlPath = 'phrases'
            break
          case 'processName':
            intlPath = 'phrases'
            break
          case 'scope':
            intlPath = 'pages'
            break
          default:
            console.error(`Failed to get item: ${column.name}`)
            intlPath = 'words'
            break
        }
        return value ? intl.get(`${intlPath}.${value}`) : ''
      } else {
        return value
      }
    }}
    {...props}
  />
)

export default Translator
