import {
  Backdrop,
  Button,
  Fade,
  Modal,
  Typography,
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemSecondaryAction,
  Divider,
} from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import intl from 'react-intl-universal'
import {
  ExpandMore,
  ExpandLess,
  Error as ErrorIcon,
  CheckCircle as CheckCircleIcon,
  Delete as DeleteIcon,
  Check as CheckIcon,
  SubdirectoryArrowRight,
} from '../../../src/components/Library/icons'
import _ from 'lodash'
import { useWebSocketContext } from '../../contexts/WebSocket'
import { convertHexToRGBA } from '../Library/utils'
import { makeStyles, useTheme } from '../Library/styles'
import moment from 'moment'
import { useAlertData } from '../../API/Hooks'
import ConfirmationDialog from './ConfirmationDialog'
import screenIconsMap from './ScreensIconsMap'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  '@global': {
    '*': {
      boxSizing: 'border-box',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '80vh',
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    maxWidth: '550px',
    minHeight: '200px',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 0,
  },
  headerModal: {
    backgroundColor: theme?.palette?.primary?.main,
    padding: theme.spacing(2),
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  title: {
    flexGrow: 1,
    color: '#D4A977',
    fontFamily: theme?.typography?.fontFamily,
  },
  listContainer: {
    flexGrow: 1,
    overflowY: 'auto',
    maxHeight: 'calc(60vh - 130px)',
    padding: '0 16px 16px 16px',
    marginBottom: '30px',
  },
  buttonsContainer: {
    display: 'flex',
    padding: '0 16px 16px 16px',
    margin: '10px',
    textAlign: 'center',
    gap: '5px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  clearButton: {
    width: '100%',
    border: `1px solid`,
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.error.light,
    '&:hover': {
      color: theme.palette.error.dark,
      border: `1px solid ${theme.palette.error.dark}`,
    },
  },
  clearAllButton: {
    width: '300px',
    border: `1px solid`,
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.error.light,
    '&:hover': {
      color: theme.palette.error.dark,
      border: `1px solid ${theme.palette.error.dark}`,
    },
  },
  redirectButton: {
    width: '100%',
    border: `1px solid`,
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      color: theme.palette.success.primary,
      border: `1px solid ${theme.palette.success.primary}`,
    },
  },
  link: {
    color: theme?.palette?.primary?.main,
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  disabled: {
    padding: '0 5px',
    color: theme?.palette?.primary?.main,
    fontWeight: 'bold',
    textDecoration: `line-through solid ${theme.palette.error.dark}`,
    cursor: 'default',
    pointerEvents: 'all !important',
    '&:hover': {
      textDecoration: `line-through solid ${theme.palette.error.dark}`,
    },
  },
}))

function compare(b, a) {
  const bandA = a.dateAlert
  const bandB = b.dateAlert

  let comparison = 0
  if (bandA > bandB) {
    comparison = 1
  } else if (bandA < bandB) {
    comparison = -1
  }
  return comparison
}

export default function AlertsNotificationModal({ onCancel, open }) {
  const classes = useStyles()
  const theme = useTheme()
  const {
    webSocketNotifications,
    deletedMessageKey,
    setWebSocketNotifications,
  } = useWebSocketContext() ?? {}
  const alertData = useAlertData()
  const history = useHistory()
  const markAsRead = alertData.useMarkAsRead()
  const deleteMessage = alertData.useDeleteMessage()
  const clearAllNotifications = alertData.useClearAllNotifications()
  const clearAllNotificationsForScreen =
    alertData.useClearAllNotificationsForScreen()

  const [openScreens, setOpenScreens] = useState({})
  const [dialogOpen, setDialogOpen] = useState(false)
  const [confirmAction, setConfirmAction] = useState(null)
  const [currentScreenToDelete, setCurrentScreenToDelete] = useState(null)
  const [screenNotificationCounts, setScreenNotificationCounts] = useState({})
  const [groupedNotifications, setGroupedNotifications] = useState({})
  const [screenToRedirect, setScreenToRedirect] = useState('')
  const [isNotificationsLoading, setIsNotificationsLoading] = useState(false)

  const screensPathsMap = {
    dashboardScreen: '/',
    billingScreen: '/billing',
    billingErrorScreen: '/billing-error',
    invoiceScreen: '/invoice',
    salesOrderScreen: '/sales-order',
    billingEmailScreen: '/billing_email',
    reports: '/billingReportScreen',
    products: '/products',
    commercialScreen: '/commercial',
    accountScreen: '/account',
    opportunityScreen: '/opportunity',
    commissionScreen: '/commissions',
    extractScreen: '/extract',
    conciliationGprsScreen: '/conciliation_gprs',
    conciliationGprsPlanScreen: '/plans',
    conciliationGprsReportScreen: '/intern_invoice',
    tariffScreen: '/tariff',
    userScreen: '/users',
    groupScreen: '/groups',
    billingProcessScreen: '/billing-process',
    commercialBonusScreen: '/commercial-bonus',
  }

  useEffect(() => {
    if (
      alertData.putRequest.loading === true ||
      alertData.deleteRequest.loading === true
    ) {
      setIsNotificationsLoading(true)
    } else {
      setIsNotificationsLoading(false)
    }
  }, [alertData.putRequest, alertData.deleteRequest])

  useEffect(() => {
    if (
      Array.isArray(webSocketNotifications) &&
      webSocketNotifications.length
    ) {
      const grouped = groupNotificationsByScreen(webSocketNotifications)
      if (grouped) {
        setGroupedNotifications(grouped)
        const counts = Object.keys(grouped).reduce((acc, screen) => {
          acc[screen] = grouped[screen].length
          return acc
        }, {})

        setScreenNotificationCounts(counts)
      }
    }
  }, [webSocketNotifications])

  const findPathByScreen = (screen) => {
    return screensPathsMap[screen] || null
  }

  const groupNotificationsByScreen = (notifications) => {
    if (!Array.isArray(notifications)) {
      return {}
    }
    return notifications.reduce((acc, notification) => {
      const messageValidation =
        notification?.labelAlert === 'billingNfZipFile' ||
        notification?.labelAlert === 'exportDataCompleted' ||
        notification?.labelAlert === 'billingInvoiceGenerationCompleted' ||
        notification?.labelAlert === 'downloadInvoice' ||
        notification?.labelAlert === 'exportDataError'

      if (notification && notification.screen && !messageValidation) {
        const { screen, body } = notification
        const parsedBody = JSON.parse(body)
        if (!parsedBody?.link) {
          if (!acc[screen]) {
            acc[screen] = []
          }
          acc[screen].push(notification)
        }
      }
      return acc
    }, {})
  }

  const renderBodyContent = (notification) => {
    const parsedBody = JSON.parse(notification?.body)

    if (parsedBody.exceptionList) {
      return (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>
              Total de erros: {parsedBody?.exceptionList?.length}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {parsedBody?.exceptionList?.map((error, index) => (
                <Typography
                  key={index}
                  style={{
                    marginBottom: '8px',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    borderRadius: '4px',
                    padding: '8px',
                    backgroundColor: '#fff',
                  }}
                >
                  - {error?.detail}
                </Typography>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      )
    } else if (notification?.screen !== 'BillingEmailScreen') {
      return (
        <>
          <Typography
            component="span"
            variant="body2"
            className={classes.inline}
            color="textPrimary"
          >
            {`${intl.get('words.message')}:`}
          </Typography>
          <br />
          <div className={classes.message}>
            <Typography
              component="span"
              variant="subtitle1"
              className={classes.messageText}
              color="textPrimary"
            >
              {`${parsedBody?.error?.detail}`}
            </Typography>
          </div>
        </>
      )
    }
  }

  const handleMarkAsRead = async (key) => {
    try {
      await markAsRead(key)
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const handleDeleteMessage = async (key) => {
    try {
      await deleteMessage(key)
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const handleClearAll = async () => {
    setConfirmAction('clearAll')
    setDialogOpen(true)
  }

  const handleClearAllForScreen = (screenName) => {
    setCurrentScreenToDelete(screenName)
    setConfirmAction('clearScreen')
    setDialogOpen(true)
  }

  const handleConfirmRedirect = (screenName) => {
    setScreenToRedirect(screenName)
    setConfirmAction('redirect')
    setDialogOpen(true)
  }

  const handleCancelRedirect = () => {
    setDialogOpen(false)
  }

  const confirmActionHandler = async () => {
    if (confirmAction === 'redirect') {
      const currentPath = history.location.pathname
      const path = findPathByScreen(screenToRedirect)
      if (path && path !== currentPath) {
        history.push(path)
        window.location.reload()
      }
    } else if (confirmAction === 'clearAll') {
      handleClearAll()
      try {
        await clearAllNotifications()

        onCancel()
      } catch (error) {
        console.log(error, 'error')
      }
    } else if (confirmAction === 'clearScreen') {
      handleClearAllForScreen(currentScreenToDelete)
      try {
        await clearAllNotificationsForScreen(currentScreenToDelete)
        setWebSocketNotifications((prevNotifications) => {
          const newNotifications = { ...prevNotifications }
          delete newNotifications[currentScreenToDelete]
          return newNotifications
        })
      } catch (error) {
        console.log(error, 'error')
      }
    }
    setDialogOpen(false)
    if (!webSocketNotifications || webSocketNotifications === 0) {
      onCancel()
    }
  }

  const handleToggle = (screenName) => {
    setOpenScreens((prev) => ({
      ...prev,
      [screenName]: !prev[screenName],
    }))
  }

  const handleCloseModal = () => {
    onCancel()
  }

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div className={classes.modalContainer}>
          <div className={classes.headerModal}>
            <Typography className={classes.title}>Notificações</Typography>
          </div>
          <div>
            <List>
              <div className={classes.buttonsContainer}>
                {Object.keys(screenNotificationCounts).length > 0 ? (
                  <Button
                    className={classes.clearAllButton}
                    variant="outlined"
                    layout="primary"
                    type="primary"
                    startIcon={<DeleteIcon />}
                    onClick={handleClearAll}
                  >
                    {intl.get('phrases.confirmClearAll')}
                  </Button>
                ) : (
                  intl.get('phrases.noNotifications')
                )}
              </div>
              <div>
                {Object.keys(screenNotificationCounts).map(
                  (screenName, index) => {
                    const ScreenIcon = screenIconsMap[screenName] || null

                    return (
                      <div key={screenName}>
                        {index !== 0 && <Divider variant="middle" />}
                        <ListItem
                          button
                          onClick={() => handleToggle(screenName)}
                        >
                          {ScreenIcon && (
                            <ScreenIcon style={{ marginRight: '8' }} />
                          )}
                          <ListItemText
                            primary={`${intl.get(`pages.${screenName}`)} (${
                              screenNotificationCounts[screenName] || 0
                            })`}
                          />
                          {openScreens[screenName] ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={openScreens[screenName]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List className={classes.listContainer}>
                            <div className={classes.buttonsContainer}>
                              <Button
                                className={classes.clearButton}
                                startIcon={<DeleteIcon />}
                                onClick={() =>
                                  handleClearAllForScreen(screenName)
                                }
                              >
                                {intl.get('phrases.confirmClearScreen')}
                              </Button>
                              <Button
                                className={classes.redirectButton}
                                startIcon={<SubdirectoryArrowRight />}
                                onClick={() =>
                                  handleConfirmRedirect(screenName)
                                }
                              >
                                {intl.get('phrases.redirectConfirmation')}
                              </Button>
                            </div>

                            {(groupedNotifications[screenName] || [])
                              .sort(compare)
                              .map((notification, index) => {
                                const isRead = notification.readAt != null

                                if (notification.key === deletedMessageKey) {
                                  return null
                                }

                                const getColorAlert = (status, type) => {
                                  return status
                                    ? convertHexToRGBA(
                                        theme?.palette?.secondary
                                          ?.hoverContrast,
                                        0.7
                                      )
                                    : type === 'error'
                                    ? '#c62828'
                                    : '#388e3c'
                                }

                                return (
                                  <ListItem
                                    key={index.toString()}
                                    style={{
                                      color: getColorAlert(
                                        isRead,
                                        notification?.type
                                      ),
                                      border: `1px solid ${getColorAlert(
                                        isRead,
                                        notification?.type
                                      )}`,
                                      borderLeft: `10px solid ${getColorAlert(
                                        isRead,
                                        notification?.type
                                      )}`,
                                      borderRadius: 5,
                                      margin: '10px auto',
                                    }}
                                  >
                                    <ListItemText
                                      primary={
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                        >
                                          {notification?.type === 'error' ? (
                                            <ErrorIcon />
                                          ) : (
                                            <CheckCircleIcon />
                                          )}
                                          <Typography
                                            style={{
                                              display: 'inline',
                                              margin: '8px',
                                            }}
                                          >
                                            {intl.get(
                                              `phrases.${notification.labelAlert}`
                                            )}
                                          </Typography>
                                        </div>
                                      }
                                      secondary={
                                        <>
                                          {notification &&
                                            renderBodyContent(notification)}

                                          <Typography
                                            component="span"
                                            variant="body2"
                                            style={{
                                              color:
                                                theme?.palette?.quaternary
                                                  ?.contrastText,
                                              opacity: 0.8,
                                            }}
                                          >
                                            {notification.sentBy}
                                          </Typography>
                                          <br />
                                          <Typography
                                            component="span"
                                            variant="body2"
                                            style={{
                                              color:
                                                theme?.palette?.quaternary
                                                  ?.contrastText,
                                              opacity: 0.8,
                                            }}
                                          >
                                            {`${intl.get(
                                              'phrases.sentIn'
                                            )}: ${moment(
                                              notification?.dateAlert
                                            ).format('DD/MM/YYYY HH:mm:ss')}`}
                                          </Typography>
                                        </>
                                      }
                                    />
                                    <ListItemSecondaryAction>
                                      <Tooltip
                                        title={
                                          isRead
                                            ? intl.get('words.delete')
                                            : intl.get(
                                                'phrases.notificationsStatusChange'
                                              )
                                        }
                                      >
                                        <IconButton
                                          edge="end"
                                          aria-label="check"
                                          disabled={isNotificationsLoading}
                                          onClick={() => {
                                            isRead
                                              ? handleDeleteMessage(
                                                  notification.key
                                                )
                                              : handleMarkAsRead(
                                                  notification.key
                                                )
                                          }}
                                          style={{
                                            color: getColorAlert(
                                              isRead,
                                              notification?.type
                                            ),
                                          }}
                                        >
                                          {isRead ? (
                                            <DeleteIcon />
                                          ) : (
                                            <CheckIcon />
                                          )}
                                        </IconButton>
                                      </Tooltip>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                )
                              })}
                          </List>
                        </Collapse>
                      </div>
                    )
                  }
                )}
              </div>
            </List>
          </div>

          <ConfirmationDialog
            open={dialogOpen}
            onClose={handleCancelRedirect}
            onConfirm={confirmActionHandler}
            title={intl.get('phrases.confirmAction')}
            content={
              confirmAction === 'redirect'
                ? intl.get('phrases.redirectConfirmation')
                : confirmAction === 'clearAll'
                ? intl.get('phrases.confirmClearAll')
                : ` ${intl.get('phrases.confirmClearToScreen')}:
               ${intl.get(`pages.${currentScreenToDelete}`)}`
            }
          />
        </div>
      </Fade>
    </Modal>
  )
}
