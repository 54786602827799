import useCallAPI from './useCallAPI'

const filterBody = ({
  cycle,
  allCustomers,
  status,
  idList,
  customerSageIdList,
  productIdList,
}) => ({
  cycle,
  allCustomers,
  status,
  idList,
  customerSageIdList,
  productIdList,
})

export const useSalesData = (sendOrders) => {
  const {
    reset,
    requestData,
    requestSuccess,
    requestLoading,
    requestError,
    fetchPUT,
    fetchPOST,
  } = useCallAPI({
    initialCall: false,
    context: sendOrders ? `sales_order_send` : `sales_order`,
  })

  const state = requestData?.data?.data

  const handlePOST = (params) => {
    fetchPOST({
      userBody: filterBody(params),
    })
  }

  const handlePUT = (params) => {
    fetchPUT({
      userBody: filterBody(params),
    })
  }

  return {
    reset,
    state,
    loading: requestLoading,
    success: requestSuccess,
    error: requestError,
    handlePUT,
    handlePOST,
  }
}
