import React, { useEffect, useState } from 'react'
import { useWebSocketContext } from '../../../../../contexts/WebSocket'
import { Box, LinearProgress, Typography, Button } from '../../../core'
import { makeStyles } from '../../../styles'

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 200,
    minWidth: 100,
    maxHeight: 500,
    color: theme?.palette?.tertiary?.main,
  },
  success: {
    color: 'lime',
  },
  error: {
    color: 'red',
  },
  newBox: {
    display: 'inline-block',
    width: 400,
    maxHeight: 60,
    color: theme?.palette?.tertiary?.main,
  },
  progressLabel: {
    color: theme?.palette?.tertiary?.main,
  },
  progress: {
    color: 'red',
  },
  interval: {
    display: 'flex',
    width: '20px',
  },
  progressBlock: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  block: {
    display: 'flex',
  },
  blockProgress: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
    flexGrow: 2,
  },
  buttonOk: {
    margin: '5px 0px 5px 5px',
    backgroundColor: theme?.palette?.secondary?.main,
    color: theme?.palette?.tertiary?.main,
    fontFamily: theme.typography.fontFamily,
  },
}))

function LinearProgressWithLabel(props) {
  const classes = useStyles()
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          {...props}
          color="secondary"
          style={{ backgroundColor: 'lightBlue' }}
        />
      </Box>
      <Box minWidth={35}>
        <Typography
          data-testid="percentage"
          variant="caption"
          color="textSecondary"
          className={classes.progressLabel}
        >{`${Math.round(props?.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

const ProcessCounter = ({
  processCounterTitle,
  processCounterTotalText,
  processCounterSuccessText,
  processCounterErrorText,
  processCounterButton,
  screen,
}) => {
  const { processCounters } = useWebSocketContext()
  const { progress, totalItems, successItems, errorItems } =
    processCounters[screen] ?? {}
  const classes = useStyles()

  const textTitle = processCounterTitle || 'processCounterTitle'
  const textSuccess = processCounterSuccessText || 'processCounterSuccessText'
  const textError = processCounterErrorText || 'processCounterErrorText'
  const textTotal = processCounterTotalText || 'processCounterTotalText'

  const hasSuccessItems = successItems ? successItems : 0
  const hasErrorItems = errorItems ? errorItems : 0
  const hasTotalItems = totalItems ? totalItems : 0

  return (
    <>
      <div className={classes.newBox}>
        <div className={classes.block}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            className={classes.progressLabel}
          >
            {textTitle}
          </Typography>
          <div className={classes.blockProgress}>
            <LinearProgressWithLabel
              data-testid="linearProgress"
              value={totalItems > 0 ? progress : 0}
            />
            <div className={classes.progressBlock}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                className={classes.success}
              >
                {`${textSuccess}: ${hasSuccessItems}`}
              </Typography>
              <div className={classes.interval} />
              <Typography
                variant="subtitle2"
                color="textSecondary"
                className={classes.error}
              >
                {`${textError}: ${hasErrorItems}`}
              </Typography>
              <div className={classes.interval} />
              <Typography
                variant="subtitle2"
                color="textSecondary"
                className={classes.progressLabel}
              >
                {`${textTotal}: ${hasTotalItems}`}
              </Typography>
            </div>
          </div>
          {processCounterButton && (
            <Button
              variant="contained"
              onClick={processCounterButton}
              className={classes.buttonOk}
              layout="secondary"
            >
              OK
            </Button>
          )}
        </div>
      </div>
    </>
  )
}

export default ProcessCounter
