import { DataTypeProvider } from '@devexpress/dx-react-grid'
import React from 'react'

const BooleanText = ({ isTrueValue, isFalseValue, ...props }) => (
  <DataTypeProvider
    formatterComponent={({ value }) => (value ? isTrueValue : isFalseValue)}
    {...props}
  />
)

export default BooleanText
