import useCallAPI from './useCallAPI'

const filterBody = ({
  name,
  defaultScreen,
  groupOrigin,
  userEmails,
  group,
}) => ({
  name,
  defaultScreen,
  groupOrigin,
  userEmails,
  group,
})

export const useGroupData = () => {
  const { requestLoading, requestSuccess, fetchPOST, fetchPUT, reset } =
    useCallAPI({
      initialCall: false,
      context: `group`,
    })

  const handlePOST = (params) => {
    fetchPOST({
      userBody: filterBody(params),
    })
  }

  const handleClonePOST = (params) => {
    fetchPOST({
      newContext: `group_clone`,
      userBody: filterBody(params),
    })
  }

  const handlePUT = (params) => {
    fetchPUT({
      userBody: filterBody(params),
    })
  }

  return {
    loading: requestLoading,
    success: requestSuccess,
    handlePOST,
    handleClonePOST,
    handlePUT,
    reset,
  }
}
