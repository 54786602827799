import React from 'react'
import { intl } from '../../../../translation'
import { makeStyles } from '../../../../styles'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

const useStyles = makeStyles((theme) => ({
  dot: {
    width: 10,
    height: 10,
    borderRadius: 10,
    marginRight: 10,
    display: 'inline-block',
  },
}))

const StatusReport = ({ ...props }) => {
  const classes = useStyles()

  const returnColor = (value) => {
    switch (value) {
      case 'report_finished':
        return 'green'
      case 'running':
        return 'orange'
      case 'error':
        return 'red'
      default:
        return 'gray'
    }
  }

  return (
    <DataTypeProvider
      formatterComponent={({ value, ...props }) =>
        value !== '' ? (
          <span>
            <div
              className={classes.dot}
              style={{
                backgroundColor: returnColor(value),
              }}
            />
            <span>{intl.get(`status.${value}`)}</span>
          </span>
        ) : (
          <span>{intl.get(`status.scheduled`)}</span>
        )
      }
      {...props}
    />
  )
}

export default StatusReport
