import React from 'react'
import { Check as CheckIcon } from '@material-ui/icons'
import { Box, CircularProgress, Icon, Typography } from '@material-ui/core'
import customStyles from './styles'

export function CircularProgressWithLabel(props) {
  const classes = customStyles()

  return (
    <Box
      style={{ cursor: 'wait', height: '40px' }}
      position="relative"
      display="inline-flex"
    >
      <Box style={{ width: '100%' }} position="relative">
        <CircularProgress
          variant="determinate"
          color="inherit"
          className={classes.bottom}
          size={40}
          thickness={4}
          value={100}
        />
        <CircularProgress
          variant={props.isPenging ? 'indeterminate' : 'determinate'}
          className={classes.top}
          color="inherit"
          classes={{
            circle: classes.circle,
          }}
          size={40}
          value={props.value ?? 0}
          thickness={4}
        />
      </Box>
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {props.currentItem ? (
          <Typography variant="caption" component="span">
            {props.currentItem}
          </Typography>
        ) : (
          <Typography variant="caption" component="span">{`${
            props.isPenging ? '' : `${Math.round(props.value)}%`
          }`}</Typography>
        )}
      </Box>
    </Box>
  )
}
