import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import {
  AlertBadge,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useMediaQuery,
} from '../../../core'
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '../../../icons'
import { makeStyles } from '../../../styles'
import { intl } from '../../../translation'
import { convertHexToRGBA } from '../../../utils'
import { Item } from './Item'

const useStyles = makeStyles((theme) => ({
  drawerIcon: {
    color: theme?.palette?.tertiary?.main,
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: '0.3s',
    }),
  },
  expandIcon: {
    color: theme?.palette?.tertiary?.main,
  },
  drawerText: {
    color: theme?.palette?.tertiary?.main,
    [theme?.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  simple: {
    marginTop: -1,
    paddingTop: 8,
    paddingBottom: 8,
    '&:hover': {
      backgroundColor: `${convertHexToRGBA(theme.palette.secondary.main, 0.3)}`,
    },
  },
  active: {},
}))

export const ItemWithNested = ({
  icon,
  text,
  nestedItems,
  onClose,
  isOpen,
  role,
  path,
  notifications,
}) => {
  const classes = useStyles()

  const minSize = useMediaQuery('(min-width:600px)')

  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Fragment>
      <ListItem
        role="menuitem"
        button
        onClick={() => setIsExpanded(!isExpanded)}
        classes={{
          selected: classes.selected,
        }}
      >
        {(minSize || !isOpen) && (
          <Tooltip
            role="tooltip"
            title={intl.get(`pages.${text}`) || `pages.${text}`}
          >
            <ListItemIcon className={classes.drawerIcon}>
              <AlertBadge
                icon={icon}
                screen={role}
                nestedItems={nestedItems}
                notifications={notifications}
              />
            </ListItemIcon>
          </Tooltip>
        )}
        <ListItemText
          data-testid="menuItemText"
          primaryTypographyProps={{ className: classes.drawerText }}
          primary={intl.get(`pages.${text}`) || `pages.${text}`}
        />
        {isExpanded ? (
          <ExpandLessIcon className={classes.expandIcon} />
        ) : (
          <ExpandMoreIcon className={classes.expandIcon} />
        )}
      </ListItem>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <List id={`${text}_nestedItemsList`} component="div" disablePadding>
          {nestedItems.map((nestedItem, index) => (
            <Item
              key={`${index}`}
              isVisible={nestedItem.isVisible}
              icon={nestedItem.icon}
              text={nestedItem.text}
              path={nestedItem.path}
              role={nestedItem.role}
              isOpen={isOpen}
              isNested
              notifications={notifications}
            />
          ))}
        </List>
      </Collapse>
    </Fragment>
  )
}

ItemWithNested.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.string,
  nestedItems: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func,
}

ItemWithNested.defaultProps = {
  text: '',
  nestedItems: [],
  onClose: () => {},
}
