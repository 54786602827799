export const loginAuthBodyDefault = {
  grant_type: 'password',
  client_id: 'frontapplication',
  client_secret: '9834ba657bb2c60b5bb53de6f4201905',
}

export const qsConfig = {
  addQueryPrefix: true,
}

export const headerLogin = {
  'Content-Type': 'application/x-www-form-urlencoded',
}
