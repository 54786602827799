import React from 'react'
import PropTypes from 'prop-types'
import { intl } from '../../../translation'
import { makeStyles } from '../../../styles'
import { AccountBalance as AccountBalanceIcon } from '../../../icons'

import {
  Paper,
  Popper,
  Tooltip,
  Collapse,
  MenuItem,
  MenuList,
  IconButton,
  Typography,
  ClickAwayListener,
} from '../../../core'

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.tertiary?.main,
  },
  popper: {
    zIndex: 999,
  },
  paper: {
    maxWidth: 300,
    minWidth: 250,
    maxHeight: 500,
    overflowY: `auto`,
    overflowX: `hidden`,
  },
}))

const StatusChange = ({ statusChangeList, handleButtonClick }) => {
  const classes = useStyles()
  const anchorRef = React.useRef(null)
  const [open, setOpen] = React.useState(false)

  const handleClick = (item) => {
    handleButtonClick('StatusChange', item)
    setOpen(false)
  }

  return (
    <>
      <Tooltip
        data-testid="tooltip"
        title={
          intl.get('phrases.changePaymentStatus') ||
          'phrases.changePaymentStatus'
        }
      >
        <IconButton
          role="button"
          ref={anchorRef}
          className={classes.icon}
          onClick={() => setOpen(!open)}
        >
          <AccountBalanceIcon data-testid="statusChangeIcon" />
        </IconButton>
      </Tooltip>
      <Popper
        transition
        open={open}
        placement="bottom-end"
        className={classes.popper}
        anchorEl={anchorRef.current}
      >
        {({ TransitionProps, placement }) => (
          <Collapse
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={classes.paper} elevation={3}>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList role="list" autoFocusItem={open} id="download-list">
                  {statusChangeList?.map((item) => (
                    <MenuItem
                      role="listitem"
                      key={item?.label}
                      onClick={() => handleClick(item)}
                    >
                      <Tooltip title={item.label}>
                        <Typography variant="inherit" noWrap>
                          {item?.label}
                        </Typography>
                      </Tooltip>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Collapse>
        )}
      </Popper>
    </>
  )
}

StatusChange.defaultProps = {
  statusChangeList: [],
  handleButtonClick: () => {},
}

StatusChange.propTypes = {
  statusChangeList: PropTypes.array.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
}

export default StatusChange
