import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
  Button,
  SearchSelect,
  Tab,
  Tabs,
  TextFieldSuggest,
  Typography,
} from '../../../core'
import { intl } from '../../../translation'
import { DateValidity } from '../DataSelects/DateValidity'
import { useStyles } from './styles'

export const M2MPurchaseStepperAssociate = ({
  requestSuggestCustomers,
  onAssociateTariff,
  plans,
  requestPlans,
  tariffs,
  requestTariffs,
  requestSuggestTariffs,
  onClose,
}) => {
  const classes = useStyles()
  const [dateSelected, setDateSelected] = useState(null)
  const [customerSelected, setCustomerSelected] = useState({})
  const [planSelected, setPlanSelected] = useState({})
  const [tariffSelected, setTariffSelected] = useState({})

  const [selectedTab, setSelectedTab] = useState(0)

  useEffect(() => {
    const hasCustomer = !_.isEmpty(customerSelected)

    if (hasCustomer && typeof requestPlans === 'function') {
      requestPlans(customerSelected)
    }
    if (hasCustomer) {
      setPlanSelected({})
    }
  }, [customerSelected])

  const handleSend = () => {
    if (typeof onAssociateTariff === 'function' && validatedData()) {
      const payload = {}

      if (selectedTab === 0) {
        payload.cycleStart = moment(dateSelected)
        payload.customerId = customerSelected?.value
        payload.groupId = [planSelected?.value]
      }

      if (selectedTab === 1) {
        payload.cycleStart = moment(dateSelected)
        payload.tariffOrigin = tariffSelected?.value
      }

      onAssociateTariff(payload)
    }
  }

  const handleSelectCustomer = (item) =>
    setCustomerSelected(customerSelected !== item ? item : {})

  const handleSelectPlan = (item) =>
    setPlanSelected(planSelected !== item ? item : {})

  const handleChange = (_, newValue) => {
    setSelectedTab(newValue)
  }

  const handleSelectTariff = (item) =>
    setTariffSelected(tariffSelected !== item ? item : {})

  const handleDataStart = (date) => {
    if (date) {
      setDateSelected(date)
    } else {
      setDateSelected(null)
    }
  }

  const validatedData = () => {
    if (selectedTab === 0) {
      if (
        !_.isEmpty(customerSelected) &&
        !_.isEmpty(planSelected) &&
        dateSelected &&
        moment(dateSelected).isValid()
      ) {
        return true
      }
    }

    if (selectedTab === 1) {
      if (
        !_.isEmpty(tariffSelected) &&
        dateSelected &&
        moment(dateSelected).isValid()
      ) {
        return true
      }
    }
    return false
  }

  return (
    <div className={classes.root}>
      <div className={classes.stepper}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label={intl.get('phrases.byCompany') || 'phrases.byCompany'} />
          <Tab
            label={
              intl.get('phrases.byOriginTariff') || 'phrases.byOriginTariff'
            }
          />
        </Tabs>
        {selectedTab === 0 && (
          <>
            <div className={classes.listClients}>
              <Typography className={classes.labelPers}>
                {intl.get('phrases.selectACustomerRequired') ||
                  'phrases.selectACustomerRequired'}
              </Typography>
              <TextFieldSuggest
                id="customers-list"
                defaultName="name"
                minToMakeRequest={3}
                suggestionApiMethod={requestSuggestCustomers}
                selectedValue={handleSelectCustomer}
              />
            </div>
            <div className={classes.listClients}>
              <Typography className={classes.labelPers}>
                {intl.get('phrases.selectAPlanRequired') ||
                  'phrases.selectAPlanRequired'}
              </Typography>
              <SearchSelect
                id="plans"
                listOptions={plans}
                onSelectOption={handleSelectPlan}
                selectedOption={planSelected}
              />
            </div>
          </>
        )}
        {selectedTab === 1 && (
          <>
            <div className={classes.listClients}>
              <Typography className={classes.labelPers}>
                {intl.get('phrases.selectOriginTariffRequired') ||
                  'phrases.selectOriginTariffRequired'}
              </Typography>
              <TextFieldSuggest
                id="tariffs-list"
                defaultName="description"
                minToMakeRequest={3}
                suggestionApiMethod={requestSuggestTariffs}
                selectedValue={handleSelectTariff}
              />
            </div>
          </>
        )}
        <div className={classes.listClients}>
          <Typography className={classes.labelPers}>
            {intl.get('phrases.defineInitialValidityRequired') ||
              'phrases.defineInitialValidityRequired'}
          </Typography>
          <DateValidity
            dateSelected={dateSelected}
            handleDataStart={handleDataStart}
          />
        </div>
      </div>
      <div className={classes.actionsContainer}>
        <Button
          variant="contained"
          layout="secondary"
          onClick={onClose}
          className={classes.button}
        >
          {intl.get('words.back') || 'words.back'}
        </Button>
        <Button
          variant="contained"
          layout="primary"
          onClick={handleSend}
          className={classes.button}
          disabled={!validatedData()}
        >
          {intl.get('words.associate') || 'words.associate'}
        </Button>
      </div>
    </div>
  )
}

M2MPurchaseStepperAssociate.propTypes = {}

M2MPurchaseStepperAssociate.default = {}
