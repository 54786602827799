import { SnackbarProvider } from 'notistack'
import React from 'react'

export const WrapperSnackBar = ({ children }) => {
  return (
    <SnackbarProvider
      maxSnack={5}
      autoHideDuration={2000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transitionDuration={{ exit: 380, enter: 400 }}
      preventDuplicate
    >
      {children}
    </SnackbarProvider>
  )
}
