import { Table } from '@devexpress/dx-react-grid-material-ui'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '../../../styles'
import { intl } from '../../../translation'
import { convertHexToRGBA, defaultColors } from '../../../utils'

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 'normal',
    padding: 0,
    paddingLeft: 10,
    paddingRight: 10,
    color: theme?.palette?.secondary?.contrastText,
    height: (props) => {
      return props?.totalRowHasTitle ? 50 : 'auto'
    },
    borderRight: (props) => {
      return props?.totalRowHasTitle
        ? 'none'
        : `1px solid ${convertHexToRGBA(
            theme?.palette?.secondary?.contrastText,
            0.3
          )}`
    },
    borderBottom: `1px solid ${convertHexToRGBA(
      theme?.palette?.secondary?.contrastText,
      0.3
    )}`,
  },
  selected: {
    backgroundColor: convertHexToRGBA(theme?.palette?.primary?.main, 0.3),
    color: theme?.palette?.primary?.contrastText,
  },
  sales: {
    color:
      theme?.palette?.type === 'light'
        ? defaultColors?.sales?.light
        : defaultColors?.sales?.dark,
  },
  purchase: {
    color:
      theme?.palette?.type === 'light'
        ? defaultColors?.purchase?.light
        : defaultColors?.purchase?.dark,
  },
  margin: {
    color:
      theme?.palette?.type === 'light'
        ? defaultColors?.margin?.light
        : defaultColors?.margin?.dark,
  },
  coloured: {
    color: 'green',
  },
  isBolder: {
    fontWeight: 'bold',
  },
  isNegative: {
    color: 'red',
  },
}))

const Cell = (props) => {
  const classes = useStyles(props)
  const {
    row,
    className,
    column,
    onDoubleClick,
    onSingleClick,
    singleRowSelected,
    salesGroupColumns,
    purchaseGroupColumns,
    marginGroupColumns,
    colouredCurrencyGroupColumns,
    value,
  } = props

  const isBolder = column?.isBolder
  const selected = singleRowSelected === row

  const handleClick = (e, row, column) => {
    if (typeof onSingleClick === 'function') {
      onSingleClick(e, row)
    } else {
      console.log('onSingleClick não é uma função')
    }
  }

  const handleDblClick = (e, row, column) => {
    if (typeof onDoubleClick === 'function') {
      onDoubleClick(e, row)
    } else {
      console.log('onDoubleClick não é uma função')
    }
  }

  return (
    <Table.Cell
      {...props}
      row={row}
      column={column}
      style={{
        textAlign: column?.align || 'center',
        paddingRight: 10,
        paddingLeft: column?.align === 'center' ? 20 : 10,
        width: '100%',
        margin: 0,
      }}
      onClick={(e) => handleClick(e, row)}
      onDoubleClick={(e) => handleDblClick(e, row)}
      className={classNames(className, classes.root, classes.tableCell, {
        [classes.sales]:
          !selected &&
          salesGroupColumns &&
          column?.name &&
          salesGroupColumns.indexOf(column?.name) > -1,
        [classes.purchase]:
          !selected &&
          purchaseGroupColumns &&
          column?.name &&
          purchaseGroupColumns.indexOf(column?.name) > -1,
        [classes.margin]:
          !selected &&
          marginGroupColumns &&
          column?.name &&
          marginGroupColumns.indexOf(column?.name) > -1,
        [classes.coloured]:
          colouredCurrencyGroupColumns &&
          column?.name &&
          colouredCurrencyGroupColumns.indexOf(column?.name) > -1,
        [classes.selected]: selected,
        [classes.isBolder]:
          isBolder ||
          row?.commercials === intl.get(`words.total`) ||
          row?.commercials === `words.total`,
        [classes.isNegative]: value < 0 || value === 'D',
      })}
    />
  )
}

Cell.defaultProps = {
  row: {},
  className: '',
  column: {},
  onDoubleClick: () => {},
}

Cell.propTypes = {
  row: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  column: PropTypes.object.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
}

export default Cell
