import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Table } from '@devexpress/dx-react-grid-material-ui'
import { makeStyles } from '../../../styles'
import { convertHexToRGBA } from '../../../utils'

const useStyles = makeStyles((theme) => ({
  emptyHeaderCell: {
    paddingTop: 8,
    paddingBottom: 8,
    borderLeft: `1px solid ${convertHexToRGBA(
      theme?.palette?.secondary?.contrastText,
      0.3
    )}`,
    borderTop: `1px solid ${convertHexToRGBA(
      theme?.palette?.secondary?.contrastText,
      0.3
    )}`,
    borderBottom: `1px solid ${convertHexToRGBA(
      theme?.palette?.secondary?.contrastText,
      0.3
    )}`,
  },
}))

const EmptyHeaderCell = ({ className, ...others }) => {
  const classes = useStyles()

  return (
    <Table.Cell
      {...others}
      className={classNames(className, classes.emptyHeaderCell)}
    />
  )
}

EmptyHeaderCell.defaultProps = {
  className: '',
}

EmptyHeaderCell.propTypes = {
  className: PropTypes.string.isRequired,
}

export default EmptyHeaderCell
