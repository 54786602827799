// Criar as props do state de acordo com o objeto do backend filterPossibilities
const createState = (filter) => {
  const defaultStateObject = {
    behavior: filter?.behavior || '',
    defaultName: filter?.defaultName || '',
    type: filter?.type || '',
    condition: filter?.condition || true,
    enableNotEquals: filter?.enableNotEquals || false,
    hasSuggested: filter?.hasSuggested || false,
    isSuggested: false,
    minToMakeRequest: filter?.minToMakeRequest || 0,
    suggestions: [],
    suggestionUrl: filter?.suggestionUrl || '',
    columnIdName: filter?.columnIdName || filter?.defaultName,
  }

  switch (filter?.type) {
    case 'text':
      return {
        ...defaultStateObject,
        value: '',
        ...filter,
      }
    case 'id':
      return {
        ...defaultStateObject,
        value: '',
        ...filter,
      }
    case 'number':
      return {
        ...defaultStateObject,
        value: '',
        ...filter,
      }
    case 'range':
      return {
        ...defaultStateObject,
        density: 'B',
        valueMin: '',
        valueMinBytes: 0,
        valueMax: '',
        valueMaxBytes: 0,
        ...filter,
      }
    case 'select':
      return {
        ...defaultStateObject,
        value: {},
        ...filter,
      }
    case 'date':
      return {
        ...defaultStateObject,
        hasAutoFillButton: filter?.hasAutoFillButton || false,
        behavior: filter?.behavior,
        columnIdName: filter?.columnIdName
          ? filter?.columnIdName
          : filter?.defaultName,
        defaultName: filter?.defaultName,
        hasSuggested: filter?.hasSuggested || false,
        isSuggested: false,
        minToMakeRequest: filter?.minToMakeRequest || 0,
        type: filter?.type,
        timeStart: null,
        timeEnd: null,
        dateStart: null,
        dateEnd: null,
        ...filter,
      }
    case 'shortDatePeriod':
      return {
        ...defaultStateObject,
        dateStart: null,
        dateEnd: null,
        ...filter,
      }
    case 'shortDate':
      return {
        ...defaultStateObject,
        date: null,
        ...filter,
      }
    case 'monthPeriod':
      return {
        ...defaultStateObject,
        dateStart: null,
        dateEnd: null,
        ...filter,
      }
    case 'month':
      return {
        ...defaultStateObject,
        date: null,
        ...filter,
      }
    case 'fullYear':
      return {
        ...defaultStateObject,
        date: null,
        ...filter,
      }
    case 'radio':
      return {
        ...defaultStateObject,
        value: filter?.value ? filter?.value : '',
        ...filter,
      }
    case 'checkbox':
      return {
        ...defaultStateObject,
        value: filter?.value ? filter?.value : false,
        ...filter,
      }
    case 'multi-select':
      return {
        ...defaultStateObject,
        value: [],
        ...filter,
      }
    case 'multi-creatable-select':
      return {
        ...defaultStateObject,
        value: [],
        ...filter,
      }
    default:
      return null
  }
}

export { createState }
