import { DataTypeProvider } from '@devexpress/dx-react-grid'
import React from 'react'
import { intl } from '../../../../translation'

const AbonnementText = ({ ...props }) => {
  const translateText = (value) => {
    switch (value) {
      case 1:
        return intl.get('words.production') || 'Produção'
      case 2:
        return intl.get('words.pilot') || 'Piloto'
      case 3:
        return intl.get('words.test') || 'Teste'
      case 4:
        return intl.get('phrases.carrierLoan') || 'Empréstimo operadora'
      case 5:
        return intl.get('phrases.onStock') || 'Em estoque'
      default:
        return intl.get('words.unknown') || 'Desconhecido'
    }
  }

  return (
    <DataTypeProvider
      formatterComponent={({ value, ...props }) =>
        value ? translateText(value) : value
      }
      {...props}
    />
  )
}

export default AbonnementText
