import { TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { makeStyles, useTheme } from '../../../styles'
import { intl } from '../../../translation'
import { convertHexToRGBA } from '../../../utils'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  scrollbar: {
    cursor: 'default',
  },
}))

export const SearchSelect = (props) => {
  const {
    listOptions,
    onSelectOption,
    selectedOption,
    placeholder,
    isDisabled,
    selectMenuPosition,
    margin,
    fullWidth,
    width,
    displayClearIcon,
    className,
    isClearable,
    emptyPlaceholder,
  } = props

  const classes = useStyles()
  const theme = useTheme()

  const [selectedData, setSelectedData] = useState({})

  const customStyles = {
    container: (provided, providedState) => ({
      ...provided,
      width: fullWidth === false && width ? width : '100%',
      marginTop: margin || 0,
      marginBottom: margin || 0,
      backgroundColor: theme?.palette?.secondary?.main,
    }),
    option: (provided, providedState) => ({
      ...provided,
      cursor: 'pointer',
      textAlign: 'left',
      fontWeight: providedState?.isSelected ? 'bold' : '',
      backgroundColor: theme?.palette?.secondary?.main,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
      '&:hover': {
        backgroundColor: convertHexToRGBA(theme?.palette?.primary?.main, 0.3),
        fontWeight: 'bold',
      },
    }),
    control: (provided, providedState) => ({
      ...provided,
      cursor: 'text',
      border: !providedState?.selectProps?.value
        ? `1px solid ${theme?.palette?.secondary?.contrastText}`
        : `2px solid ${theme?.palette?.secondary?.contrastText}`,
      '&:hover': {
        border: !providedState?.selectProps?.value
          ? `1px solid ${theme?.palette?.secondary?.contrastText}`
          : `2px solid ${theme?.palette?.secondary?.contrastText}`,
        '&:active': {
          border: !providedState?.selectProps?.value
            ? `1px solid ${theme?.palette?.secondary?.contrastText}`
            : `2px solid ${theme?.palette?.secondary?.contrastText}`,
        },
      },
      backgroundColor: theme?.palette?.secondary,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
    }),
    placeholder: (provided, providedState) => ({
      ...provided,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
      opacity: '0.4',
      fontSize: '0.8em',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
      cursor: 'pointer',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: displayClearIcon || 'inline',
      color: theme?.palette?.secondary?.contrastText,
      cursor: 'pointer',
    }),
    singleValue: (provided, providedState) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
      marginLeft: 0,
    }),
    valueContainer: (provided, providedState) => ({
      ...provided,
      paddingLeft: 7,
    }),
    input: (provided) => ({
      ...provided,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
    }),
    menu: (provided) => ({
      ...provided,
      overflowY: 'auto',
      maxHeight: '400px',
      backgroundColor: theme?.palette?.secondary?.main,
      border: `1px solid ${theme?.palette?.secondary?.contrastText}`,
      borderRadius: '5px',
      fontSize: '1em',
      position: selectMenuPosition || 'absolute',
      zIndex: 99999,
      paddingLeft: 5,
    }),
  }

  useEffect(() => {
    if (selectedOption && selectedOption !== selectedData) {
      setSelectedData(selectedOption)
    } else if (_.isEmpty(selectedOption)) {
      setSelectedData({})
    }
  }, [selectedOption])

  const handleSelectCustomer = (item) => {
    setSelectedData(item || {})
    onSelectOption(item || {})
  }

  const SearchSelectComponent = ({
    isDisabled,
    isClearable = true,
    emptyPlaceholder,
  }) => (
    <Select
      id="searchSelect"
      placeholder={
        emptyPlaceholder
          ? ''
          : placeholder ||
            intl.get('words.inputSearchTitle') ||
            'words.inputSearchTitle'
      }
      isSearchable
      options={listOptions}
      onChange={handleSelectCustomer}
      styles={customStyles}
      backspaceRemoves
      isClearable={isClearable}
      value={
        selectedData?.value === ''
          ? ''
          : listOptions?.find((x) => x.value === selectedData?.value)
      }
      isDisabled={isDisabled}
    />
  )

  return (
    <TextField
      className={className}
      fullWidth={fullWidth !== false}
      inputProps={{
        role: 'textbox',
      }}
      InputProps={{
        classes: { root: classes.scrollbar },
        disableUnderline: true,
        inputComponent: () =>
          SearchSelectComponent({ isDisabled, isClearable, emptyPlaceholder }),
      }}
    />
  )
}
