import intl from 'react-intl-universal'
import esJson from '../locales/es.json'
import frJson from '../locales/fr.json'
import ptBrJson from '../locales/pt-BR.json'
import { loadItem } from './localStorage'

const user = loadItem('user')

const suportedLocales = ['ES_ES', 'FR_FR', 'PT_BR']

const languagesList = [
  { value: 'PT_BR', label: 'Português', disabled: false },
  { value: 'ES_ES', label: 'Español', disabled: false },
]

const locales = {
  ES_ES: esJson,
  FR_FR: frJson,
  PT_BR: ptBrJson,
}

const loadLocales = () => {
  let currentLocale

  if (user && user.language) {
    currentLocale = user.language
  } else {
    currentLocale = intl.determineLocale({
      urlLocaleKey: 'lang',
      cookieLocaleKey: 'lang',
    })

    if (!suportedLocales.find((language) => language === currentLocale)) {
      currentLocale = 'pt-BR'
    }
  }

  return currentLocale
}

const browserLanguage = () => {
  let defaultLanguage = 'PT_BR'
  const regexFR = new RegExp('fr')
  const regexES = new RegExp('es')

  if (regexES.test(navigator.language)) {
    defaultLanguage = 'ES_ES'
  }
  if (regexFR.test(navigator.language)) {
    defaultLanguage = 'FR_FR'
  }

  return defaultLanguage
}

const updateLocales = (language) => {
  let currentLocale = intl.getInitOptions()
  currentLocale = currentLocale.currentLocale

  if (!language) {
    currentLocale = intl.determineLocale({
      urlLocaleKey: 'lang',
      cookieLocaleKey: 'lang',
    })
  }

  if (suportedLocales.find((value) => value === language)) {
    currentLocale = language
  }

  return currentLocale
}

export {
  locales,
  loadLocales,
  updateLocales,
  suportedLocales,
  languagesList,
  browserLanguage,
}
