import React from 'react'
import Img from 'react-image'
import PropTypes from 'prop-types'
import { makeStyles } from '../../../../styles'
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '../../../../icons'
import {
  List,
  Tooltip,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '../../../../core'

const useStyles = makeStyles((theme) => ({
  isNested: {
    paddingLeft: theme.spacing(4),
  },
}))

const SubMenu = ({ item, onClick }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <ListItem button onClick={() => setOpen(!open)}>
        <Tooltip title={item.label}>
          <ListItemText primary={item.label} />
        </Tooltip>
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.subMenuItems.map((subItem) => (
            <ListItem
              button
              key={subItem.alias}
              className={classes.isNested}
              onClick={(item) => onClick(item)}
            >
              <ListItemIcon>
                <Img
                  src={subItem.url}
                  height={subItem.height}
                  loader={
                    <img
                      alt={subItem.alias}
                      height={subItem.height}
                      src={`${process.env.PUBLIC_URL}/img/ReactImageFallback/loadingLyra.svg`}
                    />
                  }
                  unloader={
                    <img
                      height={subItem.height}
                      src={`${process.env.PUBLIC_URL}img/ReactImageFallback/logo_Blue.svg`}
                    />
                  }
                />
              </ListItemIcon>
              <Tooltip title={subItem.alias}>
                <ListItemText primary={subItem.alias} />
              </Tooltip>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  )
}

SubMenu.defaultProps = {
  list: [],
  onClick: () => {},
}

SubMenu.propTypes = {
  list: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default SubMenu
