/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import React from 'react'
import { IconButton } from '@material-ui/core'
import { useSnackbar } from '../notifications'
import { intl } from '../translation'
import CloseIcon from '@material-ui/icons/Close'

export const useValidateErrorResponse = () => {
  const event401 = new Event('error-401')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const logoutErrors = [
    'NOT_AUTHENTICATED',
    'MISSING_AUTHENTICATION_TOKEN',
    'INVALID_AUTHENTICATION_TOKEN',
    'EXPIRED_AUTHENTICATION_TOKEN',
  ]

  const validateError = (arrayMsgs) => {
    const { errors } = arrayMsgs || {}

    const action = (key) => (
      <IconButton
        aria-label="close"
        color="inherit"
        onClick={() => closeSnackbar(key)}
      >
        <CloseIcon />
      </IconButton>
    )

    if (errors?.length) {
      errors?.map((objError) => {
        if (logoutErrors.indexOf(objError?.code) !== -1) {
          window.dispatchEvent(event401)
        }
        if (objError?.detail) {
          enqueueSnackbar(`${objError?.detail}`, {
            variant: 'error',
            autoHideDuration: 10000,
            action,
          })
        } else if (objError?.title) {
          enqueueSnackbar(`${objError?.title}`, {
            variant: 'error',
            autoHideDuration: 10000,
            action,
          })
        } else
          enqueueSnackbar(
            intl.get(`errors.${objError?.code}`) || `${objError?.code}`,
            {
              variant: 'error',
              autoHideDuration: 10000,
              action,
            }
          )
      })
    }
  }

  return { validateError }
}
