import { normalizeConciliationData } from './Normalizers'
import ENDPOINTS from './ENDPOINTS'
import { useFetchAxiosWrapper } from '../useFetchAxiosWrapper'
import { useState, useEffect } from 'react'
import _ from 'lodash'
import { useConciliationGPRSReportSize } from './useConciliationGPRSReportSize'
import { createHeaders } from '../../utils/utilsAPI'
import axios from 'axios'

const initialState = {
  page: 0,
  rowsPerPage: 50,
}

const filterQuery = (values) => {
  return {
    ...values,
  }
}

export const useConciliationGPRSReport = (params) => {
  const { callOnInit = false } = params || {}
  const conciliationReportSize = useConciliationGPRSReportSize({})
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState(initialState)
  const {
    patchRequest,
    getRequest,
    fetchPOST,
    fetchPATCH,
    postRequest,
    fetchGET,
  } = useFetchAxiosWrapper({
    baseEndpoint: ENDPOINTS.PATCH.conciliation_GPRS_report,
    callOnInit,
  })

  useEffect(() => {
    if (!_.isEqual(getRequest.data, data)) {
      setData(getRequest.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequest])

  useEffect(() => {
    if (!_.isEqual(patchRequest.data, data)) {
      setData(patchRequest.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchRequest])

  const getReports = (_) => {
    const body = {
      page: pagination.page + 1,
      limit: pagination.rowsPerPage,
    }

    fetchPATCH({ body: body })
    conciliationReportSize.handlePATCHSize(body)
  }

  const updatePage = (p) => {
    setPagination({
      ...pagination,
      page: p,
    })
  }

  const updateRowsPerPage = (value) => {
    setPagination({
      ...pagination,
      rowsPerPage: value,
      page: initialState.page,
    })
  }

  /** @function handlePOSTReport */
  //Começa a processar as faturas para gerar um relatório excel
  const handlePOSTReport = (data = {}) => {
    fetchPOST({
      forceEndpoint: ENDPOINTS.POST.conciliation_GPRS_report,
      body: data,
      noRefreshData: true,
    })
  }

  /** @function handleGETDownload */
  //Download do relatório excel
  const handleGETDownload = async () => {
    const url = `${process.env.REACT_APP_API_URL}${ENDPOINTS.GET.conciliation_GPRS_report_download}`

    try {
      const response = await axios.get(url, {
        responseType: 'blob',
        headers: {
          ...createHeaders(),
          'Content-Disposition': 'attachment',
          'Content-Type': 'application/zip',
        },
      })

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
      return downloadUrl
    } catch (error) {
      console.error('Erro ao fazer o download', error)
      throw error
    }
  }

  /** @function handleGETReportStatus */
  //Monitora o status do processamento de faturas
  const handleGETReportStatus = async (params = {}) => {
    const newQuery = filterQuery(params)
    await fetchGET({
      forceEndpoint: ENDPOINTS.GET.conciliation_GPRS_report_status,
      urlParameters: { ...newQuery },
    })
  }

  return {
    data: normalizeConciliationData({ data: data }),
    patchRequest,
    getRequest,
    postRequest,
    pagination,
    size: conciliationReportSize.data,
    handleGETReportStatus,
    getReports,
    updatePage,
    updateRowsPerPage,
    handlePOSTReport,
    handleGETDownload,
  }
}
