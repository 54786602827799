import { useCallback, useEffect, useState } from 'react'
import fileDownload from 'js-file-download'
import { useSnackbar } from '../../components/Library/notifications'
import { intl } from '../../components/Library/translation'
import { useFetchAxiosWrapper } from '../useFetchAxiosWrapper'
import ENDPOINTS from './ENDPOINTS'
import _, { debounce } from 'lodash'

const filterBody = (values) => {
  return {
    key: values?.key,
    screen: values?.screen,
    removeAll: values?.removeAll,
    notificationEvent: values?.notificationEvent,
    keyList: values?.keyList,
  }
}

export const useAlertData = (params) => {
  const { callOnInit = false } = params || {}
  const {
    reset,
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    fetchGET,
    fetchPUT,
    fetchDELETE,
  } = useFetchAxiosWrapper({
    baseEndpoint: ENDPOINTS.DELETE.alerts,
    callOnInit,
  })

  const enqueueSnackbar = useSnackbar()?.enqueueSnackbar

  const [fileName, setFileName] = useState('')
  const [fileExtension, setFileExtension] = useState('')

  const handleGET = (params) => {
    fetchGET({
      responseType: 'arraybuffer',
      forceEndpoint: params.url,
    })
  }

  const handlePUT = (params = {}) => {
    const newBody = filterBody(params)
    fetchPUT({
      body: { ...newBody },
      forceEndpoint: ENDPOINTS.PUT.alerts,
    })
  }

  const handleDELETE = (params = {}) => {
    const newBody = filterBody(params)
    fetchDELETE({
      body: { ...newBody },
    })
  }

  const useMarkAsRead = () => {
    return useCallback(async (key) => {
      key
        ? handlePUT({ key: key, notificationEvent: 'READ' })
        : console.log('ERROR')
    }, [])
  }

  const useDeleteMessage = () => {
    return useCallback(async (key) => {
      key ? handleDELETE({ key: key }) : console.log('ERROR')
    }, [])
  }

  const useClearAllNotifications = () => {
    return useCallback(async () => {
      handleDELETE({
        removeAll: true,
      })
    }, [])
  }

  const useMarkAsReceivedNotification = () => {
    return useCallback(
      debounce((keys) => {
        if (
          !keys ||
          (Array.isArray(keys) && keys.some((key) => key === undefined))
        ) {
          console.log('ERROR: Invalid keys provided')
          return
        }

        if (keys) {
          const isKeysArray = Array.isArray(keys) && keys.length > 1
          const isValid = isKeysArray ? keys.length > 0 : !!keys
          if (isValid) {
            try {
              const payload = isKeysArray
                ? { keyList: keys, notificationEvent: 'RECEIVED' }
                : { key: keys[0], notificationEvent: 'RECEIVED' }
              handlePUT(payload)
            } catch (error) {
              console.error('Error marking as received', error)
            }
          } else {
            console.log('ERROR: Invalid keys provided')
          }
        }
      }, 100),
      []
    )
  }

  const useClearAllNotificationsForScreen = () => {
    return useCallback(async (screen) => {
      screen
        ? handleDELETE({
            screen: screen,
          })
        : console.log('ERROR')
    }, [])
  }

  const handleCall = (values, productsList) => {
    if (!values?.componentAction) {
      values?.status === false
        ? handlePUT({ key: values?.key })
        : values?.status === true
        ? handleDELETE({ key: values?.key })
        : enqueueSnackbar(intl.get('phrases.anErrorHappened'), {
            variant: 'error',
          })
    } else {
      if (values?.componentAction === 'clearOne') {
        values?.key
          ? handleDELETE({ key: values?.key })
          : enqueueSnackbar(intl.get('phrases.cleanAllError'), {
              variant: 'error',
            })
      } else if (values?.componentAction === 'markAsRead') {
        values?.key
          ? handlePUT({ key: values?.key, notificationEvent: 'READ' })
          : console.log('ERROR')
      } else if (values?.componentAction === 'cleanAll') {
        values?.screen
          ? handleDELETE({
              screen: values?.screen,
            })
          : enqueueSnackbar(intl.get('phrases.cleanAllError'), {
              variant: 'error',
            })
      } else if (values?.componentAction === 'download') {
        if (values?.url) {
          if (values?.params?.componentDetails && productsList) {
            const product = productsList.filter(
              (product) => product.value === values.params.componentDetails
            )
            const productName = product[0]?.label
            setFileName(
              `${intl.get(
                `pages.${values?.params?.exportScreen}`
              )}_${productName}_${values?.params?.cycle}`
            )
            setFileExtension('zip')
          } else {
            const exportCodeStr = '/export?code='
            const fileNameParamStr = '/nfe_processor?fileName='
            const fileNameInvoiceParamStr = '/invoice?fileName='
            const indexOfExportCode = values.url.indexOf(exportCodeStr)
            const indexOfFileNameParam = values.url.indexOf(fileNameParamStr)
            const indexOfFileNameInvoiceParam = values.url.indexOf(
              fileNameInvoiceParamStr
            )

            if (indexOfExportCode !== -1) {
              let _fileName = values.url.substring(
                indexOfExportCode + exportCodeStr.length
              )
              if (_fileName.indexOf('&') !== -1) {
                _fileName = _fileName.split('&')[0] // remove other parameters from _fileName
              }
              // Separate file name from its extension
              const _split = _fileName.split('.')
              _fileName = _split[0]
              const _fileExtension = _split[1]

              setFileName(_fileName)
              setFileExtension(_fileExtension)
            } else if (indexOfFileNameParam !== -1) {
              let _fileName = values.url.substring(
                indexOfFileNameParam + fileNameParamStr.length
              )
              if (_fileName.indexOf('&') !== -1) {
                _fileName = _fileName.split('&')[0] // remove other parameters from _fileName
              }
              // Separate file name from its extension
              const _split = _fileName.split('.')
              _fileName = _split[0]
              const _fileExtension = _split[1]

              setFileName(_fileName)
              setFileExtension(_fileExtension)
            } else if (indexOfFileNameInvoiceParam !== -1) {
              const _fileName = values.url.split('=')[1]
              const fileNameType = _fileName.split('.')

              setFileName(fileNameType[0])
              setFileExtension(fileNameType[1])
            } else {
              setFileName(
                `${intl.get(`pages.${values?.params?.exportScreen}`)}_${
                  values?.params?.cycle || values?.params?.paymentMonth
                }`
              )
              setFileExtension('zip')
            }
          }
          enqueueSnackbar(intl.get('phrases.downloadInProcess'), {
            variant: 'info',
          })
          handleGET({ url: values?.url })
        } else {
          enqueueSnackbar(intl.get('phrases.failedToDownload'), {
            variant: 'error',
          })
        }
      } else {
        console.log(
          values?.componentAction,
          'componentAction not recognized in handleHeaderButtonClick'
        )
      }
    }
  }

  useEffect(() => {
    if (getRequest.success) {
      if (getRequest.data) {
        enqueueSnackbar(intl.get('phrases.startingDownload'), {
          variant: 'success',
        })
        if (fileExtension === 'zip') {
          fileDownload(getRequest.data, fileName, 'application/zip')
        } else if (fileExtension === 'txt') {
          fileDownload(getRequest.data, fileName, 'text/plain')
        } else if (fileExtension === '7z') {
          fileDownload(getRequest.data, fileName, 'application/x-7z-compressed')
        } else if (fileExtension === 'bz') {
          fileDownload(getRequest.data, fileName, 'application/x-bzip')
        } else if (fileExtension === 'bz2') {
          fileDownload(getRequest.data, fileName, 'application/x-bzip2')
        } else if (fileExtension === 'gz') {
          fileDownload(getRequest.data, fileName, 'application/gzip')
        } else if (fileExtension === 'rar') {
          fileDownload(getRequest.data, fileName, 'application/vnd.rar')
        } else if (fileExtension === 'tar') {
          fileDownload(getRequest.data, fileName, 'application/x-tar')
        }
      } else {
        enqueueSnackbar(intl.get('phrases.errorDownloadFile'), {
          variant: 'error',
        })
      }
    } else if (getRequest.error || getRequest.success === false) {
      enqueueSnackbar(intl.get('phrases.errorDownloadFile'), {
        variant: 'error',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequest])

  useEffect(() => {
    if (deleteRequest.loading === true) {
      enqueueSnackbar(intl.get('words.wait'), {
        variant: 'info',
      })
    }
    if (deleteRequest.error || deleteRequest.success === false) {
      enqueueSnackbar(intl.get('phrases.deleteError'), {
        variant: 'error',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRequest])

  useEffect(() => {
    if (putRequest.error || putRequest.success === false) {
      enqueueSnackbar(intl.get('phrases.statusChangeError'), {
        variant: 'error',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [putRequest])

  return {
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    reset,
    handleCall,
    useMarkAsRead,
    useDeleteMessage,
    useClearAllNotifications,
    useClearAllNotificationsForScreen,
    useMarkAsReceivedNotification,
  }
}
