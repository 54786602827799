import { normalizeConciliationData } from './Normalizers'
import ENDPOINTS from './ENDPOINTS'
import { useFetchAxiosWrapper } from '../useFetchAxiosWrapper'
import { useState, useEffect } from 'react'
import _ from 'lodash'

const filterQuery = (values) => {
  return {
    ...values,
  }
}

export const useConciliationGPRSExportReport = (params) => {
  const { callOnInit = false } = params || {}
  const [data, setData] = useState([])
  const { getRequest, fetchGET, fetchDELETE, deleteRequest } =
    useFetchAxiosWrapper({
      baseEndpoint: ENDPOINTS.GET.conciliation_GPRS_report_export_status,
      callOnInit,
    })

  useEffect(() => {
    if (!_.isEqual(getRequest.data, data)) {
      setData(getRequest.data)
    }
  }, [getRequest])

  /** @function handleGETReportExportStatus */
  //Monitora o status do processamento de relatório para download
  const handleGETReportExportStatus = async (params = {}) => {
    const newQuery = filterQuery(params)
    await fetchGET({
      forceEndpoint: ENDPOINTS.GET.conciliation_GPRS_report_export_status,
      urlParameters: { ...newQuery },
    })
  }

  const handleDELETEReport = (data = {}) => {
    fetchDELETE({
      forceEndpoint: ENDPOINTS.DELETE.conciliation_delete_lyra_invoice,
      body: data,
      noRefreshData: true,
    })
  }

  return {
    data: normalizeConciliationData({ data: data }),
    getRequest,
    deleteRequest,
    handleDELETEReport,
    handleGETReportExportStatus,
  }
}
