import { useFetchAxiosWrapper } from '../useFetchAxiosWrapper'
import ENDPOINTS from './ENDPOINTS'
import { normalizeDashboardData } from './Normalizers'

const filterQuery = (values) => {
  return {
    cycleStart: values?.cycleStart,
    values: values?.values,
    categoryId: values?.categoryId,
    productId: values?.productId,
    commercialId: values?.commercialId,
    customerId: values?.customerId,
    currencyCode: values?.currencyCode,
  }
}

export const useLibraryDashboard = (params) => {
  const { callOnInit = true } = params || {}
  const {
    reset,
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    fetchGET,
  } = useFetchAxiosWrapper({
    baseEndpoint: ENDPOINTS.GET.dashboard_billing,
    callOnInit,
  })

  const handleGET = (params = {}) => {
    const newQuery = filterQuery(params)
    fetchGET({
      urlParameters: { ...newQuery },
    })
  }

  return {
    getRequest: {
      ...getRequest,
      data: normalizeDashboardData(getRequest),
    },
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    reset,
    handleGET,
  }
}
