import { Table } from '@devexpress/dx-react-grid-material-ui'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '../../../styles'
import { convertHexToRGBA } from '../../../utils'

const useStyles = makeStyles((theme) => ({
  rowHover: {
    backgroundColor: theme?.palette?.primary?.contrastText,
    '&:hover': {
      backgroundColor: convertHexToRGBA(theme?.palette?.primary?.main, 0.3),
      '& > td': {
        color: theme?.palette?.primary?.contrastText,
      },
    },
  },
  selected: {
    backgroundColor: convertHexToRGBA(theme?.palette?.primary?.main, 0.3),
    color: theme?.palette?.primary?.contrastText,
  },
}))

const Row = ({ className, row, singleRowSelected, height, ...restProps }) => {
  const classes = useStyles()

  return (
    <Table.Row
      {...restProps}
      height={height || 25}
      className={classNames(className, classes.rowHover, {
        [classes.selected]:
          row &&
          singleRowSelected &&
          JSON.stringify(row) === JSON.stringify(singleRowSelected),
      })}
    />
  )
}

Row.defaultProps = {
  className: '',
}

Row.propTypes = {
  className: PropTypes.string.isRequired,
}

export default Row
