import { makeStyles } from '../../styles'
import { convertHexToRGBA } from '../../utils'

const drawerWidthLeft = 240

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: 0,
    overflowX: 'hidden',
    overflowY: 'hidden',
    height: '100vh',
    width: '100vw',
  },
  appBar: {
    height: 50,
    boxShadow: 'none',
    zIndex: theme?.zIndex.drawer + 1,
    color: theme?.palette?.secondary?.contrastText,
    marginBottom: 0,
    border: 'none',
    backgroundColor: theme?.palette?.secondary?.main,
  },
  toolbar: {
    height: 50,
    backgroundColor: theme?.palette?.defaultBackground?.topbarBackground,
  },
  menuButton: {
    marginLeft: 14,
    marginRight: 18,
  },
  drawerIconRotate: {
    color: theme?.palette?.tertiary?.main,
    transform: 'rotate(90deg)',
    transition: theme?.transitions.create('transform', {
      easing: theme?.transitions.easing.sharp,
      duration: '0.3s',
    }),
  },
  drawerIcon: {
    color: theme?.palette?.tertiary?.main,
    transition: theme?.transitions?.create('transform', {
      easing: theme?.transitions?.easing?.sharp,
      duration: '0.3s',
    }),
  },
  logo: {
    width: `calc(${drawerWidthLeft}px - 30px)`,
    [theme?.breakpoints.down('xs')]: {
      width: `calc(${drawerWidthLeft / 3}px - 30px)`,
    },
    minWidth: `calc(${drawerWidthLeft / 3}px - 30px)`,
    display: 'flex',
    color: theme.palette.tertiary.main,
    alignItems: 'center',
    gap: '5px',
  },
  imgLogo: {
    width: 60,
    [theme?.breakpoints.down('xs')]: {
      width: 30,
    },
  },
  updateCheck: {
    color: theme.palette.success.main,
  },
  header: {
    color: theme?.palette?.tertiary?.main,
    width: '100%',
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerStart: {
    borderLeft: `1px solid ${theme?.palette?.primary?.main}`,
    padding: '0px 10px',
  },
  headerCenter: {
    padding: '0px 10px',
    textAlign: 'center',
  },
  headerEnd: {
    padding: '0px 10px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  drawerLeft: {
    width: drawerWidthLeft,
    [theme?.breakpoints.down('xs')]: {
      width: `calc(${drawerWidthLeft / 3}px + 30px)`,
    },
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    overflowY: 'hidden',
    borderRight: 'none',
  },
  drawerOpenLeft: {
    width: drawerWidthLeft,
    [theme?.breakpoints.down('xs')]: {
      width: `calc(${drawerWidthLeft / 3}px + 30px)`,
    },
    overflowX: 'hidden',
    overflowY: 'hidden',
    borderRight: 'none',
    transition: theme?.transitions.create('width', {
      easing: theme?.transitions.easing.sharp,
      duration: theme?.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme?.palette?.secondary?.main,
  },
  drawerCloseLeft: {
    overflowX: 'hidden',
    overflowY: 'hidden !important',
    borderRight: 'none',
    transition: theme?.transitions.create('width', {
      easing: theme?.transitions.easing.sharp,
      duration: theme?.transitions.duration.leavingScreen,
    }),
    width: theme?.spacing(7),
    [theme?.breakpoints.down('xs')]: {
      width: theme?.spacing(6),
    },
    backgroundColor: theme?.palette?.secondary?.main,
  },
  content: {
    width: 'calc(100% - 55px)',
    flexGrow: 1,
    transition: theme?.transitions?.create('margin', {
      easing: theme?.transitions?.easing?.sharp,
      duration: theme?.transitions?.duration?.leavingScreen,
    }),
    marginLeft: 0,
    marginTop: 50,
    overflowY: 'hidden',
  },
  contentShiftLeft: {
    transition: theme?.transitions?.create('margin', {
      easing: theme?.transitions?.easing?.easeOut,
      duration: theme?.transitions?.duration?.enteringScreen,
    }),
  },

  mainDiv: {
    height: '100%',
    borderLeft: 'none',
    backgroundColor: theme?.palette?.defaultBackground?.contentBGColor,
    marginLeft: 0,
    borderTopLeftRadius: '5px',
  },
  headerFlex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  pageTitle: {
    color: theme?.palette?.secondary?.contrastText,
    width: 300,
    paddingTop: 8,
  },
  icon: {
    color: theme?.palette?.tertiary?.main,
  },
}))
