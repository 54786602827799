import axios from 'axios'
import qs from 'qs'
import ENDPOINTS from './ENDPOINTS'
import axiosRetry from 'axios-retry'
import _ from 'lodash'

const loadItem = (item) => {
  try {
    const serializedItem = localStorage.getItem(item)

    if (serializedItem === null) {
      return undefined
    }

    return JSON.parse(serializedItem)
  } catch (err) {
    return undefined
  }
}

const createHeaders = () => {
  const token = loadItem('token')

  const headers = {
    Authorization: `Bearer ${token}`,
  }
  return headers
}

axiosRetry(axios, {
  retries: 3,
  retryCondition(error) {
    switch (error.response.status) {
      case 502:
        return true
      default:
        return false
    }
  },
  retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 3000),
})

export const APICall = ({
  verb,
  callContext,
  callUrlParameters = null,
  id = null,
  body = null,
  cancelToken,
  isFormData,
  isFileDownload,
  disableCompanyId,
}) => {
  return new Promise((resolve, reject) => {
    const headers = createHeaders()
    const companyId = loadItem('companyId')

    let result = null
    let valueId = ''
    let query = ''
    let bodyValues = {}

    if (
      id &&
      (typeof id === 'string' ||
        typeof id === 'number' ||
        typeof id === 'boolean')
    ) {
      valueId = `/${id.toString()}`
    }
    if (!!!disableCompanyId) {
      if (typeof callUrlParameters === 'object') {
        if (verb === 'GET') {
          callUrlParameters.companyId = companyId
        }
        query = qs.stringify(callUrlParameters, {
          addQueryPrefix: true,
        })
      }

      if (typeof body === 'object') {
        if (isFormData) {
          body.append('companyId', companyId)
        } else {
          body = {
            ...body,
            companyId: body?.companyId ? body?.companyId : companyId,
          }
        }
      }
    }
    bodyValues = body

    const url = `${process.env.REACT_APP_API_URL}${ENDPOINTS[verb][callContext]}${valueId}${query}`

    switch (verb) {
      case 'GET':
        result = axios.get(url, {
          headers,
          responseType: isFileDownload ? 'arraybuffer' : '',
          cancelToken,
        })
        break
      case 'PUT':
        result = axios.put(url, bodyValues, {
          headers,
          cancelToken,
        })
        break
      case 'POST':
        result = axios.post(url, bodyValues, {
          headers,
          cancelToken,
        })
        break
      case 'DELETE':
        result = axios.delete(url, {
          headers,
          data: {
            ...bodyValues,
          },
          cancelToken,
        })
        break
      case 'PATCH':
        result = axios.patch(url, bodyValues, {
          headers,
          responseType: isFileDownload ? 'arraybuffer' : '',
          cancelToken,
        })
        break
      default:
        break
    }

    result
      .then((data) => resolve(data))
      .catch((err) => {
        reject(err)
      })
  })
}
