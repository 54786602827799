import { DataTypeProvider } from '@devexpress/dx-react-grid'
import React from 'react'
import { intl } from '../../../../translation'

const returnTranslatedValue = (value) => {
  switch (value) {
    case 'C':
      return intl.get(`words.credit`)
    case 'D':
      return intl.get(`words.debit`)
    default:
      return value
  }
}

const TranslatorExtractType = (props) => (
  <DataTypeProvider
    formatterComponent={({ value, ...props }) => {
      const translatedValue =
        value || Number(value) === 0
          ? returnTranslatedValue(value?.toString())
          : '-'

      return translatedValue
    }}
    {...props}
  />
)

export default TranslatorExtractType
