import React, { useContext, useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { useLogout, useSettingsUser } from './API/Hooks'
import { AsyncLogin, AsyncNewPassword } from './Constants/AppRoutesConfig'
import { AuthContext } from './contexts/Auth'
import { ThemeContext } from './contexts/Theme'
import { UserContext } from './contexts/User'
import useEventListener from './hooks/useEventListener'
import PrivateApp from './PrivateApp'
import { WebSocketProvider } from './contexts/WebSocket'
import { NotificationProvider } from './contexts/SnackNotifications'

const RouterAuth = () => {
  const [stateAuthContext] = useContext(AuthContext)
  const [stateUserContext, dispatchUserContext] = useContext(UserContext)
  const [, dispatchThemeContext] = useContext(ThemeContext)
  const { fetchSettingsUser } = useSettingsUser()
  const { fetchLogout } = useLogout()

  // Detect error event
  useEventListener('error-401', fetchLogout)

  useEffect(() => {
    document.title = `LYRA${
      process.env.REACT_APP_ENV_NAME
        ? ` (${process.env.REACT_APP_ENV_NAME})`
        : ''
    }`
  }, [])

  useEffect(() => {
    if (!stateAuthContext.token) {
      fetchLogout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateAuthContext.token])

  useEffect(() => {
    if (stateAuthContext.token && !stateUserContext?.userId) {
      fetchSettingsUser()
    }
  }, [
    stateAuthContext.token,
    stateUserContext.userId,
    dispatchThemeContext,
    dispatchUserContext,
    fetchSettingsUser,
  ])

  return (
    <BrowserRouter>
      <Switch>
        {stateAuthContext?.token ? (
          <WebSocketProvider>
            <NotificationProvider>
              <PrivateApp />
            </NotificationProvider>
          </WebSocketProvider>
        ) : (
          <Switch>
            <Route
              key="reset-password"
              exact
              path="/reset-password"
              component={AsyncNewPassword}
            />
            <Route key="all" path="*" component={AsyncLogin} />
          </Switch>
        )}
      </Switch>
    </BrowserRouter>
  )
}

export default RouterAuth
