import { intl } from '../../components/Library/translation'
import { useFetchAxiosWrapper } from '../useFetchAxiosWrapper'
import ENDPOINTS from './ENDPOINTS'
import { normalizeMapData } from './Normalizers'
import _ from 'lodash'

const translateValuesData = (data) => {
  let newData = []
  if (data?.length > 0) {
    newData = data.map((item) => {
      return { ...item, label: intl.get(`maps.${item?.label}`) }
    })
  }
  return newData
}

const filterQuery = (values) => {
  return {
    ...values,
  }
}

export const useLibraryMap = (params) => {
  const { callOnInit = true, context, translate, urlParameters } = params || {}

  const {
    reset,
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    fetchGET,
  } = useFetchAxiosWrapper({
    baseEndpoint: ENDPOINTS.GET[`${context}_map`],
    callOnInit,
    urlParameters: !_.isEmpty(urlParameters) ? filterQuery(urlParameters) : {},
  })

  let stateMapData = normalizeMapData(getRequest)

  if (translate) {
    stateMapData = translateValuesData(stateMapData) || []
  }

  const handleGET = (params = {}) => {
    const newQuery = filterQuery(params)
    fetchGET({
      urlParameters: { ...newQuery },
    })
  }

  return {
    getRequest: {
      ...getRequest,
      data: stateMapData,
    },
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    reset,
    handleGET,
  }
}
