import { TextField as MaterialTextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '0.8em',
    fontFamily: theme.typography.fontFamily,
    height: (props) => (props.height ? props.height : 40),
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    marginBottom: 5,
    marginTop: (props) =>
      props.marginTop !== null && props.marginTop !== undefined
        ? props.marginTop
        : 10,
    width: (props) => {
      if (props.width && !props.size) {
        return props.width
      } else {
        let fieldWidth

        if (props.size && !props.fullWidth) {
          switch (props.size) {
            case 'small':
              fieldWidth = '120px'
              break
            case 'medium':
              fieldWidth = '230px'
              break
            case 'large':
              fieldWidth = '350px'
              break
            default:
              fieldWidth = '230px'
          }
        }
        return fieldWidth
      }
    },
  },
  rootSelected: {
    borderWidth: '2px',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
  },
  custom: {
    backgroundColor: (props) =>
      props.backgroundcolor
        ? theme?.palette[props.backgroundcolor]?.main || props.backgroundcolor
        : theme?.palette?.secondary?.main,
    color: (props) =>
      props.textcolor
        ? theme?.palette[props.textcolor]?.main || props.textcolor
        : theme?.palette?.secondary?.contrastText,
    '&$focused': {
      color: theme?.palette?.secondary?.contrastText,
    },
  },
  notchedCustom: {
    borderColor: (props) => {
      let bordercolor
      if (props.textcolor) {
        bordercolor = theme?.palette[props.textcolor]?.main || props.textcolor
      } else {
        bordercolor = theme?.palette?.secondary?.contrastText
      }
      return `${bordercolor} !important`
    },
  },
  label: {
    padding: theme.spacing(0.5),
    '&$focused': {
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(0.5),
    },
  },
  filledPrimary: {
    color: theme?.palette?.primary?.contrastText,
    backgroundColor: theme?.palette?.primary?.main,
    '&$focused': {
      color: theme?.palette?.secondary?.hoverContrast,
    },
  },
  notchedFilledPrimary: {
    borderColor: `${theme?.palette?.primary?.contrastText} !important`,
  },
  unfilledPrimary: {
    color: theme?.palette?.primary?.main,
    backgroundColor: theme?.palette?.primary?.contrastText,
    '&$focused': {
      color: theme?.palette?.secondary?.contrastText,
    },
  },
  notchedUnfilledPrimary: {
    borderColor: `${theme?.palette?.primary?.main} !important`,
  },
  secondary: {
    color: theme?.palette?.secondary?.contrastText,
    backgroundColor: theme?.palette?.secondary?.main,
    '&$focused': {
      color: theme?.palette?.secondary?.contrastText,
    },
  },
  notchedSecondary: {
    borderColor: `${theme?.palette?.secondary?.contrastText} !important`,
  },
  filledTertiary: {
    color: theme?.palette?.quaternary?.main,
    backgroundColor: theme?.palette?.tertiary?.main,
    '&$focused': {
      color: theme?.palette?.secondary?.contrastText,
    },
  },
  notchedFilledTertiary: {
    borderColor: `${theme?.palette?.quaternary?.main} !important`,
  },
  unfilledTertiary: {
    color: theme?.palette?.tertiary?.main,
    backgroundColor: theme?.palette?.tertiary?.contrastText,
    '&$focused': {
      color: theme?.palette?.secondary?.contrastText,
    },
  },
  notchedUnfilledTertiary: {
    borderColor: `${theme?.palette?.tertiary?.main} !important`,
  },
  focused: {},
}))

export const TextField = (props) => {
  const {
    layout,
    reverse,
    fullWidth,
    placeholder,
    InputProps,
    InputLabelProps,
    classNames,
    type,
  } = props

  let hasStyle = true

  const unstyledTypes = [
    'button',
    'radio',
    'color',
    'file',
    'image',
    'checkbox',
    'range',
  ]

  if (unstyledTypes.includes(type) || props.select) {
    hasStyle = false
  }

  const classes = useStyles(props)

  return (
    <MaterialTextField
      {...props}
      variant="outlined"
      placeholder={placeholder}
      type={type}
      fullWidth={fullWidth}
      InputLabelProps={{
        ...InputLabelProps,
        classes: {
          focused: classes.focused,
          formControl: classes.formControl,
          root: classnames(
            classes.label,
            (props?.value?.length > 0 ||
              InputProps?.startAdornment ||
              InputProps?.endAdornment ||
              InputProps?.defaultValue) &&
              classes.rootSelected,
            {
              [classes.filledPrimary]:
                hasStyle && layout === 'primary' && !reverse,
              [classes.unfilledPrimary]:
                hasStyle && layout === 'primary' && reverse,
              [classes.secondary]: hasStyle && layout === 'secondary',
              [classes.filledTertiary]:
                hasStyle && layout === 'tertiary' && !reverse,
              [classes.unfilledTertiary]:
                hasStyle && layout === 'tertiary' && reverse,
              [classes.custom]: hasStyle && !layout,
            }
          ),
          ...InputLabelProps?.classes,
        },
      }}
      InputProps={{
        ...InputProps,
        classes: {
          root: classnames(classes.root, {
            [classes.filledPrimary]:
              hasStyle && layout === 'primary' && !reverse,
            [classes.unfilledPrimary]:
              hasStyle && layout === 'primary' && reverse,
            [classes.secondary]: hasStyle && layout === 'secondary',
            [classes.filledTertiary]:
              hasStyle && layout === 'tertiary' && !reverse,
            [classes.unfilledTertiary]:
              hasStyle && layout === 'tertiary' && reverse,
            [classes.custom]: hasStyle && !layout,
          }),
          notchedOutline: classnames(
            props?.value?.length > 0 && classes.rootSelected,
            {
              [classes.notchedFilledPrimary]:
                hasStyle && layout === 'primary' && !reverse,
              [classes.notchedUnfilledPrimary]:
                hasStyle && layout === 'primary' && reverse,
              [classes.notchedSecondary]: hasStyle && layout === 'secondary',
              [classes.notchedFilledTertiary]:
                hasStyle && layout === 'tertiary' && !reverse,
              [classes.notchedUnfilledTertiary]:
                hasStyle && layout === 'tertiary' && reverse,
              [classes.notchedCustom]: hasStyle && !layout,
            }
          ),
          focused: classes.focused,
          ...InputProps?.classes,
        },
      }}
      className={classNames}
    />
  )
}
