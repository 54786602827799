import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '../../core'
import { makeStyles } from '../../styles'
import { ExpandMore as ExpandMoreIcon } from '../../icons'
import PropTypes from 'prop-types'
import React from 'react'
import { intl } from '../../translation'
import createInputComponent from './CreateInputComponent'

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 0,
    padding: 0,
    marginTop: '3%',
    fontFamily: theme.typography.fontFamily,
    fontSize: '0.9em',
    textAlign: 'right',
  },
  expansionPanel: {
    borderRadius: 5,
    marginTop: 5,
    backgroundColor: theme?.palette?.secondary?.main,
  },
  expansionPanelSummary: {
    paddingLeft: '10px',
    color: theme?.palette?.secondary?.contrastText,
    fontWeight: 'bold',
  },
  expansionPanelDetails: {
    display: 'block',
    paddingRight: '3%',
    paddingLeft: '3%',
    paddingTop: '0px',
    paddingBottom: '10px',
  },
}))

// Cria os cards de grupo
const CreateGroup = (props) => {
  const {
    group,
    groupTitle,
    possibilities,
    version,
    updateState,
    state,
    verifyValue,
    preDefinedListOptions,
    validateDateShortcut,
    callAPISuggestion,
    currencyData,
  } = props
  const classes = useStyles()

  return (
    <ExpansionPanel
      className={classes.expansionPanel}
      defaultExpanded
      key={group}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.expansionPanelSummary}
      >
        <p data-testid={groupTitle} className={classes.title}>
          {intl.get(`words.${groupTitle}`) || 'group'}
        </p>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expansionPanelDetails}>
        <div className={classes.listFilters}>
          {possibilities?.map((filter) => {
            if (filter?.group === group) {
              return createInputComponent({
                filter,
                version,
                updateState,
                state,
                verifyValue,
                preDefinedListOptions,
                validateDateShortcut,
                callAPISuggestion,
                currencyData,
                ...props,
              })
            }
            return null
          })}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

CreateGroup.propTypes = {
  group: PropTypes.string,
  groupTitle: PropTypes.string,
  possibilities: PropTypes.array,
  version: PropTypes.number,
  updateState: PropTypes.func,
  state: PropTypes.object,
  verifyValue: PropTypes.func,
  preDefinedListOptions: PropTypes.array,
}

CreateGroup.defaultProps = {
  group: '',
  groupTitle: '',
  possibilities: [],
  version: 0,
  updateState: () => {},
  state: {},
  verifyValue: () => {},
  preDefinedListOptions: [],
}

export default CreateGroup
