import React, { useEffect } from 'react'
import { Loading } from '../Library/components/Loading'
import {
  Backdrop,
  Fade,
  IconButton,
  Modal,
  Typography,
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { useStyles } from './styles'
import PropTypes from 'prop-types'

export default function CustomModal({
  children,
  modalCancel,
  isLoading,
  isBlocked,
  modalTitle,
  disableOutsideClose,
  size,
}) {
  const classes = useStyles()
  const handleSize = () => {
    switch (size) {
      case 'small':
        return classes.modalSmall
      case 'large':
        return classes.modalLarge
      default:
        return ''
    }
  }

  return (
    <Modal
      open={true}
      onClose={(reason) => {
        if (isBlocked || isLoading) return
        if (disableOutsideClose && reason) return
        modalCancel()
      }}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <div className={`${classes.modalContainer} ${handleSize()}`}>
          {isLoading && (
            <div className={classes.loadingContainer}>
              <Loading />
            </div>
          )}
          <div className={classes.headerModal}>
            <Typography className={classes.title}>{modalTitle}</Typography>
            <IconButton
              disabled={isBlocked || isLoading}
              className={classes.closeIconBtn}
              onClick={() => {
                if (isBlocked || isLoading) return

                modalCancel()
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.paper}>{children}</div>
        </div>
      </Fade>
    </Modal>
  )
}

CustomModal.propTypes = {
  children: PropTypes.any,
  modalCancel: PropTypes.any,
  disableOutsideClose: PropTypes.bool,
  isBlocked: PropTypes.bool,
  isLoading: PropTypes.bool,
  modalTitle: PropTypes.string,
  size: PropTypes.string,
}
