import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui'
import { makeStyles } from '../../../styles'
import { convertHexToRGBA, defaultColors } from '../../../utils'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme?.palette?.secondary?.main,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'center',
    borderRight: (props) => {
      return props.totalRowHasTitle
        ? 'none'
        : `1px solid ${convertHexToRGBA(
            theme?.palette?.secondary?.contrastText,
            0.3
          )}`
    },
    borderTop: `1px solid ${convertHexToRGBA(
      theme?.palette?.secondary?.contrastText,
      0.3
    )}`,
    borderBottom: `1px solid ${convertHexToRGBA(
      theme?.palette?.secondary?.contrastText,
      0.3
    )}`,
    color: theme?.palette?.secondary?.contrastText,
  },
  sales: {
    backgroundColor:
      theme?.palette?.type === 'light'
        ? convertHexToRGBA(defaultColors?.sales?.light, 0.3)
        : convertHexToRGBA(defaultColors?.sales?.dark, 0.3),
    color: '#000',
  },
  purchase: {
    backgroundColor:
      theme?.palette?.type === 'light'
        ? convertHexToRGBA(defaultColors?.purchase?.light, 0.3)
        : convertHexToRGBA(defaultColors?.purchase?.dark, 0.3),
    color: '#000',
  },
  margin: {
    backgroundColor:
      theme?.palette?.type === 'light'
        ? convertHexToRGBA(defaultColors?.margin?.light, 0.3)
        : convertHexToRGBA(defaultColors?.margin?.dark, 0.3),
    color: '#000',
  },
}))

const HeaderCell = ({
  className,
  salesGroupColumns,
  purchaseGroupColumns,
  marginGroupColumns,
  ...restProps
}) => {
  const classes = useStyles(restProps)

  const isDraggable = restProps?.column ? restProps?.column?.isDraggable : false

  return (
    <TableHeaderRow.Cell
      {...restProps}
      draggingEnabled={isDraggable}
      className={classNames(className, classes.root, {
        [classes.sales]:
          salesGroupColumns &&
          restProps?.column?.name &&
          salesGroupColumns.indexOf(restProps?.column?.name) > -1,
        [classes.purchase]:
          purchaseGroupColumns &&
          restProps?.column?.name &&
          purchaseGroupColumns.indexOf(restProps?.column?.name) > -1,
        [classes.margin]:
          marginGroupColumns &&
          restProps?.column?.name &&
          marginGroupColumns.indexOf(restProps?.column?.name) > -1,
      })}
    />
  )
}

HeaderCell.defaultProps = {
  className: '',
}

HeaderCell.propTypes = {
  className: PropTypes.string.isRequired,
}

export default HeaderCell
