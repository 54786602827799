import { normalizeCommercialData } from './Normalizers'
import ENDPOINTS from './ENDPOINTS'
import { useFetchAxiosWrapper } from '../useFetchAxiosWrapper'
import { useState, useEffect } from 'react'
import _ from 'lodash'

const initialState = {
  id: {
    id: 'id',
    filterBy: undefined,
  },
  name: {
    id: 'name',
    filterBy: undefined,
  },
  email: {
    id: 'email',
    filterBy: undefined,
  },
  active: {
    id: 'active',
    filterBy: true,
  },
  company: {
    id: 'companyId',
    filterBy: undefined,
  },
}

export const useCommercialData = (params) => {
  const [data, setData] = useState([])
  const { callOnInit = false } = params || {}
  const {
    patchRequest,
    fetchPOST,
    fetchPATCH,
    fetchPUT,
    putRequest,
    postRequest,
  } = useFetchAxiosWrapper({
    baseEndpoint: ENDPOINTS.GET.commercial,
    callOnInit,
  })
  const [filters, setFilters] = useState(initialState)

  useEffect(() => {
    if (!_.isEqual(patchRequest.data, data)) {
      setData(patchRequest.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchRequest])

  const applyFilters = () => {
    const { id, name, email, company, active } = filters
    const body = {
      id: id?.filterBy,
      companyId: company?.filterBy,
      nameStart: name?.filterBy,
      emailStart: email?.filterBy,
      active: active?.filterBy === 'null' ? null : active?.filterBy,
    }

    fetchPATCH({ body: body })
  }

  const updateFilters = (newFilter, toUpdate) => {
    let tableToBeUpdated = JSON.parse(JSON.stringify(filters))
    tableToBeUpdated[toUpdate].filterBy = newFilter
    setFilters({
      ...filters,
      [toUpdate]: {
        ...filters[toUpdate],
        filterBy: newFilter,
      },
    })
  }

  const cleanFilters = () => {
    setFilters(initialState)
  }

  const handlePOST = (data = {}) => {
    fetchPOST({
      forceEndpoint: ENDPOINTS.POST.commercial,
      body: data,
      noRefreshData: true,
    })
  }

  const handlePUT = (data = {}) => {
    fetchPUT({
      forceEndpoint: ENDPOINTS.PUT.commercial,
      body: data,
      noRefreshData: true,
    })
  }

  return {
    data: normalizeCommercialData({ data: data }),
    filters,
    cleanFilters,
    updateFilters,
    applyFilters,
    patchRequest,
    putRequest,
    postRequest,
    handlePOST,
    handlePUT,
  }
}
