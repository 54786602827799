import React from 'react'
import Img from 'react-image'
import PropTypes from 'prop-types'

const ReactImage = ({
  src,
  alt,
  className,
  height,
  title,
  onBlur,
  onFocus,
  onMouseLeave,
  onMouseOver,
  onTouchEnd,
  onTouchStart,
}) => {
  return (
    <Img
      src={src}
      alt={alt}
      className={className}
      height={height}
      title={title}
      onBlur={onBlur}
      onFocus={onFocus}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      onTouchEnd={onTouchEnd}
      onTouchStart={onTouchStart}
      loader={
        <img
          height={height}
          alt={alt}
          src={`${process.env.PUBLIC_URL}/img/ReactImageFallback/loadingLyra.svg`}
        />
      }
      unloader={
        <img
          height={height}
          alt={alt}
          src={`${process.env.PUBLIC_URL}img/ReactImageFallback/logo_Blue.svg`}
        />
      }
    />
  )
}

ReactImage.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.number.isRequired,
  title: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseOver: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onTouchStart: PropTypes.func,
}

ReactImage.defaultProps = {
  alt: '',
  className: '',
  title: '',
  onBlur: null,
  onFocus: null,
  onMouseLeave: null,
  onMouseOver: null,
  onTouchEnd: null,
  onTouchStart: null,
}

export default ReactImage
