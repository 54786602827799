import { useEffect, useState } from 'react'
import useCallAPI from './useCallAPI'
import _ from 'lodash'

const filterBody = ({
  cycle,
  cycleStart,
  cycleEnd,
  dateFormat,
  productId,
  customerId,
  customerIdList,
  file,
  data,
}) => ({
  cycle,
  cycleStart,
  cycleEnd,
  dateFormat,
  productId,
  customerId,
  customerIdList,
  file,
  data,
})

export const useBillingProcess = () => {
  const {
    reset,
    requestSuccess,
    requestLoading,
    requestData,
    requestError,
    errorData,
    fetchPOST,
    fetchPUT,
  } = useCallAPI({
    initialCall: false,
    context: `billing_process`,
  })
  const [data, setData] = useState({})

  useEffect(() => {
    if (!_.isEqual(requestData, data)) {
      setData(requestData)
    }
  }, [requestData])

  const errors = errorData?.errors

  const handlePOST = (params, isFormData = false) => {
    fetchPOST({
      userBody: isFormData ? params : filterBody(params),
      isFormData: isFormData,
    })
  }

  const handlePUT = (params) => {
    fetchPUT({
      userBody: params,
      isFormData: true,
    })
  }

  return {
    reset,
    requestData: data,
    errors,
    loading: requestLoading,
    success: requestSuccess,
    error: requestError,
    handlePOST,
    handlePUT,
  }
}
