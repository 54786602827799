import PropTypes from 'prop-types'
import React, { useReducer } from 'react'
import { loadItem, removeItem, saveItem } from '../utils/localStorage'

const token = loadItem('token')

const initialState = {
  token: token || null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'setToken':
      saveItem(action.token, 'token')
      return { ...state, token: action.token }
    case 'clear':
      removeItem('token')
      return { token: null }
    default:
      throw new Error('Unexpected action')
  }
}

export const AuthContext = React.createContext()

const Provider = ({ children }) => {
  const context = useReducer(reducer, initialState)
  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
}

Provider.defaultProps = {
  children: <></>,
}

Provider.propTypes = {
  children: PropTypes.node,
}

export default Provider
