// Function from https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export const formatBytes = (bytes, decimals) => {
  if (bytes === 0) {
    return '0 Bytes'
  }

  const k = 1024
  const dm = decimals || 2
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

export const formatMB = (bytes, decimal = 2) => {
  if (bytes === 0) {
    return '0 MB'
  }

  let value = bytes / (1024 * 1024)

  const numberOfDecimals = value.toString().split('.')

  if (numberOfDecimals.length > 1) {
    value = value.toFixed(decimal)
  }

  return `${value} MB`
}

export const msToTime = (duration) => {
  let seconds = parseInt((duration / 1000) % 60, 10)
  let minutes = parseInt((duration / (1000 * 60)) % 60, 10)
  let hours = parseInt((duration / (1000 * 60 * 60)) % 24, 10)
  const days = parseInt(duration / (1000 * 60 * 60 * 24), 10)

  hours = hours < 10 ? `0${hours}` : hours
  minutes = minutes < 10 ? `0${minutes}` : minutes
  seconds = seconds < 10 ? `0${seconds}` : seconds

  let text = ''
  const concat = (period) => {
    switch (period) {
      case 'dhms':
        text = `${days}d ${hours}${
          hours === '01' ? 'h ' : 'hs '
        }${minutes}min ${seconds}s`
        break
      case 'hms':
        text = `${
          hours + (hours === '01' ? 'h ' : 'hs ') + minutes
        }min ${seconds}s`
        break
      case 'ms':
        text = `${minutes}min ${seconds}s`
        break
      case 's':
        text = `${seconds}s`
        break
      default:
        text = null
    }
  }

  if (seconds > 0) {
    concat('s')
  }
  if (minutes > 0) {
    concat('ms')
  }
  if (hours > 0) {
    concat('hms')
  }
  if (days > 0) {
    concat('dhms')
  }

  return text
}

export default {
  msToTime,
  formatBytes,
}
