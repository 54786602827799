import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { intl } from '../../../../translation'
import { useSnackbar } from '../../../../notifications'

const formatValue = (value) => {
  switch (value.length) {
    case 0:
      return <span style={{ color: 'lightGray' }}>0</span>
    case 8:
      return `${value.toString().substring(0, 4)}-${value
        .toString()
        .substring(4, value.length)}`
    case 11:
      return `${value.toString().substring(0, 4)}-${value
        .toString()
        .substring(4, 7)}-${value.toString().substring(7, value.length)}`
    case 13:
      return `${value.toString().substring(0, 2)} ${value
        .toString()
        .substring(2, 4)} ${value.toString().substring(4, 5)} ${value
        .toString()
        .substring(5, 9)}-${value.toString().substring(9, value.length)}`
    default:
      return value
  }
}

const Phone = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  return (
    <DataTypeProvider
      formatterComponent={({ value }) => {
        if (value) {
          return (
            <CopyToClipboard
              text={value}
              onCopy={() =>
                enqueueSnackbar(intl.get('phrases.copiedToClipboard'), {
                  variant: 'success',
                })
              }
            >
              <span>
                {value && Number.isInteger(Number(value)) && value.length >= 8
                  ? formatValue(value)
                  : value}
              </span>
            </CopyToClipboard>
          )
        } else {
          return ''
        }
      }}
      {...props}
    />
  )
}

export default Phone
