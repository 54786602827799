import React from 'react'
import { CircularProgress } from '../../core'
import { logos } from '../../images'
import { makeStyles, useTheme } from '../../styles'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  fabProgress: {
    color: theme?.palette?.primary?.main,
    position: 'absolute',
    top: (top) => (JSON.stringify(top) === '{}' ? -25 : top),
    left: -14,
    zIndex: 1,
  },
  imgLogo: {
    width: 40,
  },
}))

export const Loading = ({ logo, top }) => {
  const classes = useStyles(top)
  const theme = useTheme()

  let headerLogo =
    theme && theme === 'dark'
      ? logos?.textLogoWhiteTransparentPNG
      : logos?.textLogoBlueTransparentPNG

  if (logo?.length) {
    headerLogo = logo
  }

  return (
    <div className={classes.wrapper}>
      <img src={headerLogo} alt="Logo" className={classes.imgLogo} />
      <CircularProgress size={68} className={classes.fabProgress} />
    </div>
  )
}

Loading.propTypes = {}
