import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import ptLocale from 'date-fns/locale/pt'
import moment from 'moment'
import React, { useState } from 'react'
import { makeStyles } from '../../../styles'
import { intl } from '../../../translation'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}))

export const DateValidity = (props) => {
  const classes = useStyles()
  const { handleDataStart, dateSelected } = props
  const [isOpenStart, setIsOpenStart] = useState(false)

  const selectDate = (e) => {
    setIsOpenStart(false)
    handleDataStart(e)
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
      <KeyboardDatePicker
        variant="inline"
        onOpen={() => setIsOpenStart(true)}
        onClose={() => setIsOpenStart(false)}
        open={isOpenStart}
        key="DateStart"
        placeholder={
          intl.get('phrases.startValidity') || 'phrases.startValidity'
        }
        onChange={selectDate}
        value={dateSelected}
        cancelLabel={intl.get('words.cancel') || 'words.cancel'}
        okLabel={intl.get('words.select') || 'words.select'}
        clearLabel={intl.get('words.clean') || 'words.clean'}
        InputProps={{
          classes: {
            root: classes.root,
          },
        }}
        animateYearScrolling
        allowKeyboardControl
        clearable
        keyboard
        format="MM/yyyy"
        views={['month', 'year']}
        openTo="month"
        margin="dense"
        invalidDateMessage=""
        minDate={moment().subtract(1, 'month').startOf('month')}
      />
    </MuiPickersUtilsProvider>
  )
}
