import React, { useState } from 'react'
import {
  Button,
  ClickAwayListener,
  Collapse,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from '../../../core'
import { makeStyles } from '../../../styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: '0px 5px',
    padding: 5,
  },
  popper: {
    zIndex: 999,
    marginTop: 5,
  },
  paper: {
    maxWidth: 300,
    minWidth: 250,
    maxHeight: 500,
    overflowY: `auto`,
    overflowX: `hidden`,
  },
  menuItem: {
    '$&disabled': {
      color: 'darkslategray',
      textDecoration: 'line-through',
    },
  },
}))

const DoubleButtons = ({
  doubleButtonsHasLeftButton,
  doubleButtonsHasRightButton,
  handleButtonClick,
  doubleButtonsLeftIcon,
  doubleButtonsLeftText,
  doubleButtonsLeftIsMenu,
  doubleButtonsLeftMenu,
  doubleButtonsRightIcon,
  doubleButtonsRightText,
  doubleButtonsRightIsMenu,
  doubleButtonsRightMenu,
}) => {
  const classes = useStyles()
  const anchorRefLeft = React.useRef(null)
  const anchorRefRight = React.useRef(null)
  const leftText = doubleButtonsLeftText || 'doubleButtonsLeftText'
  const rightText = doubleButtonsRightText || 'doubleButtonsRightText'

  const [leftOpen, setLeftOpen] = useState(false)
  const [rightOpen, setRightOpen] = useState(false)

  const [anchorLeftButton, setAnchorLeftButton] = useState(null)
  const [anchorRightButton, setAnchorRightButton] = useState(null)

  const handleLeftClick = (item) => {
    handleButtonClick('DoubleButtons', {
      ...item,
      componentButton: 'left',
    })
    setLeftOpen(false)
  }

  const handleRightClick = (item) => {
    handleButtonClick('DoubleButtons', {
      ...item,
      componentButton: 'right',
    })
    setRightOpen(false)
  }

  const handleLeftPopper = (e) => {
    setAnchorLeftButton(e.currentTarget)
    setLeftOpen(true)
  }

  const handleRightPopper = (e) => {
    setAnchorRightButton(e.currentTarget)
    setRightOpen(true)
  }

  return (
    <>
      {doubleButtonsHasLeftButton && (
        <Tooltip title={leftText}>
          <Button
            data-testid="leftButton"
            ref={anchorRefLeft}
            onClick={
              doubleButtonsLeftIsMenu
                ? handleLeftPopper
                : () =>
                    handleButtonClick('DoubleButtons', {
                      componentButton: 'left',
                    })
            }
            className={classes.button}
            startIcon={doubleButtonsLeftIcon}
            layout="tertiary"
            variant="contained"
            width="100px"
          >
            {leftText}
          </Button>
        </Tooltip>
      )}
      {doubleButtonsHasLeftButton && doubleButtonsLeftIsMenu && (
        <Popper
          transition
          open={leftOpen}
          placement="bottom-start"
          className={classes.popper}
          anchorEl={anchorLeftButton}
        >
          {({ TransitionProps, placement }) => (
            <Collapse
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper className={classes.paper} elevation={3}>
                <ClickAwayListener onClickAway={() => setLeftOpen(false)}>
                  <MenuList
                    role="list"
                    autoFocusItem={leftOpen}
                    id="download-list"
                  >
                    {doubleButtonsLeftMenu?.map((item) => (
                      <MenuItem
                        classes={{
                          root: classes.menuItem,
                        }}
                        role="listitem"
                        key={item.label}
                        onClick={() => handleLeftClick(item)}
                        disabled={item.isDisabled}
                      >
                        <Tooltip title={item.label}>
                          <Typography variant="inherit" noWrap>
                            {item.label}
                          </Typography>
                        </Tooltip>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Collapse>
          )}
        </Popper>
      )}
      {doubleButtonsHasRightButton && (
        <Tooltip title={rightText}>
          <Button
            data-testid="rightButton"
            ref={anchorRefRight}
            onClick={
              doubleButtonsRightIsMenu
                ? handleRightPopper
                : () =>
                    handleButtonClick('DoubleButtons', {
                      componentButton: 'right',
                    })
            }
            className={classes.button}
            startIcon={doubleButtonsRightIcon}
            layout="tertiary"
            variant="contained"
            width="100px"
          >
            {rightText}
          </Button>
        </Tooltip>
      )}
      {doubleButtonsHasRightButton && doubleButtonsRightIsMenu && (
        <Popper
          transition
          open={rightOpen}
          placement="bottom-start"
          className={classes.popper}
          anchorEl={anchorRightButton}
        >
          {({ TransitionProps, placement }) => (
            <Collapse
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper className={classes.paper} elevation={3}>
                <ClickAwayListener onClickAway={() => setRightOpen(false)}>
                  <MenuList
                    role="list"
                    autoFocusItem={rightOpen}
                    id="download-list"
                  >
                    {doubleButtonsRightMenu?.map((item) => (
                      <MenuItem
                        classes={{
                          root: classes.menuItem,
                        }}
                        role="listitem"
                        key={item.label}
                        onClick={() => handleRightClick(item)}
                        disabled={item.isDisabled}
                      >
                        <Tooltip title={item.label}>
                          <Typography variant="inherit" noWrap>
                            {item.label}
                          </Typography>
                        </Tooltip>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Collapse>
          )}
        </Popper>
      )}
    </>
  )
}

DoubleButtons.defaultProps = {
  handleButtonClick: () => {},
}

DoubleButtons.propTypes = {
  handleButtonClick: PropTypes.func.isRequired,
}

export default DoubleButtons
