import { DataTypeProvider } from '@devexpress/dx-react-grid'
import React from 'react'
import { IconButton } from '../../../../core'
import { Receipt as ReceiptIcon } from '../../../../icons'

export const Billet = (props) => {
  const { onBilletDownload } = props

  const handleDownloadBillet = (value) => {
    if (onBilletDownload && typeof onBilletDownload === 'function') {
      onBilletDownload(value)
    } else {
      console.log(value, 'onBilletDownload is not defined or not a function')
    }
  }

  return (
    <DataTypeProvider
      formatterComponent={({ value }) => (
        <IconButton
          disabled={!value}
          color="primary"
          size="small"
          onClick={() => handleDownloadBillet(value)}
        >
          <ReceiptIcon />
        </IconButton>
      )}
      {...props}
    />
  )
}

export default Billet
