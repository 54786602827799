import { makeStyles } from '../../styles'

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    width: '100%',
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    color: '#182447',
    height: '100%',
    padding: 8,
  },
  iconSpace: {
    display: 'flex',
    width: 80,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    color: '#182447',
    '& svg': {
      width: '1.5em',
      height: '1.5em',
    },
  },
  rootGraph: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  headerTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 40,
  },
  title: {
    display: 'flex',
    padding: '0px 10px',
    textAlign: 'left',
    fontWeight: 'bolder',
    flexGrow: 1,
    color: '#182447',
  },
  buttons: {
    display: 'flex',
    minWidth: 200,
    justifyContent: 'flex-end',
  },
  margin: {
    margin: '0px 2px',
  },
  detailsGraph: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  detailsGraphLoading: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentGraph: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    color: '#182447',
    height: '100%',

    '& > canvas': {
      height: '95% !important',
      width: '95% !important',
    },
  },
  graphDoubleContainer: {
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    display: 'flex',
  },
  graphDoubleLeft: {
    width: '80%',
    height: '100%',
    margin: 0,
    padding: '0px 20px 0px 10px',
    '& > canvas': {
      height: '95% !important',
      width: '95% !important',
    },
  },
  graphDoubleRight: {
    width: '20%',
    height: '100%',
    margin: 0,
    padding: '0px 0px 0px 20px',
    borderLeft: '1px solid lightGray',
    '& > canvas': {
      height: '95% !important',
      width: '95% !important',
    },
  },
}))
