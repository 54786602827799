import useCallAPI from './useCallAPI'
import { normalizeSizeData } from './Normalizers'

const filterQuery = (values) => {
  let newStatusList = ''

  for (let i = 0; i < values?.statusList?.length; i++) {
    if (i !== values?.statusList?.length - 1) {
      newStatusList = newStatusList + values?.statusList?.[i].toString() + ','
    } else {
      newStatusList = newStatusList + values?.statusList?.[i].toString()
    }
  }

  return {
    page: values?.page,
    limit: values?.limit,
    groupBy: values?.groupBy,
    order: values?.order,
    orderBy: values?.orderBy,
    currencyCode: values?.currencyCode,
    productDetails: values?.productDetails,
    detailed: values?.detailed,
    cycle: values?.cycle,
    cycleStart: values?.cycleStart,
    cycleEnd: values?.cycleEnd,
    period: values?.period,
    periodStart: values?.periodStart,
    periodEnd: values?.periodEnd,
    date: values?.date,
    dateStart: values?.dateStart,
    dateEnd: values?.dateEnd,
    productId: values?.productId,
    paymentMonth: values?.paymentMonth,
    year: values?.year,
    statusList: newStatusList ? newStatusList : undefined,
    includeBillingDetails: values?.includeBillingDetails,
    expectedDueDate: values?.expectedDueDate,
    commercialId: values?.commercialId,
  }
}

const filterBody = (values) => {
  const newBody = { ...values }
  newBody.limit = values?.limit && values?.limit
  newBody.sorting && delete newBody.sorting
  newBody.limit && delete newBody.limit
  return newBody
}

export const useSizeData = (context) => {
  const {
    reset,
    requestSuccess,
    requestData,
    fetchGET,
    fetchPATCH,
    requestLoading,
  } = useCallAPI({
    initialCall: false,
    context: `${context}_size`,
  })

  const stateSizeData = normalizeSizeData(requestData)

  const handleGET = ({ urlParameters }) => {
    fetchGET({ userParams: filterQuery(urlParameters) })
  }

  const handlePATCH = (params) => {
    fetchPATCH({
      userBody: filterBody(params),
    })
  }

  return {
    reset,
    state: stateSizeData,
    success: requestSuccess,
    loading: requestLoading,
    handleGET,
    handlePATCH,
  }
}
