import DateFnsUtils from '@date-io/date-fns'
import { IconButton, Typography } from '../../../../core'
import { makeStyles, useTheme } from '../../../../styles'
import { Clear as ClearIcon } from '../../../../icons'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import ptLocale from 'date-fns/locale/pt'
import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/pt-br'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { intl } from '../../../../translation'
import Select from 'react-select'
import MaskedInput from 'react-text-mask'
import InputField from '../Atoms/InputField'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '0.8em',
    fontFamily: theme?.typography?.fontFamily,
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    border: `1px solid ${theme?.palette?.secondary?.contrastText}`,
    overflow: 'hidden',
    borderRadius: 4,
    color: theme?.palette?.secondary?.contrastText,
  },
  adornment: {
    color: theme?.palette?.secondary?.contrastText,
    padding: '5px',
  },
  input: {
    fontSize: '1.0em',
    fontFamily: theme?.typography?.fontFamily,
    height: 40,
    paddingTop: 5,
    paddingBottom: 0,
    paddingLeft: 10,
    overflow: 'hidden',
    borderRadius: 4,
    color: theme?.palette?.secondary?.contrastText,
  },
  alignLeft: {
    width: '100%',
    paddingRight: 0,
    marginRight: '5%',
    paddingTop: 0,
    marginTop: 0,
  },
  alignRight: {
    fontSize: '0.8em',
    width: '33.5%',
    marginTop: 0,
    marginRight: 0,
    marginLeft: 0,
    borderRadius: 4,
    fontFamily: theme?.typography?.fontFamily,
    height: 37,
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: 'center',
    color: theme.palette.text.tertiary,
    '&:placeholder': {
      color: theme.palette.text.tertiary,
    },
    backgroundColor: theme.palette.tertiary,
  },
  title: {
    color: theme?.palette?.secondary?.contrastText,
    textAlign: 'left',
    marginBottom: 0,
  },
  group: {
    fontSize: '0.3em',
    margin: 0,
    padding: 0,
  },
  block: {
    width: '80%',
    backgroundColor: theme?.palette?.secondary?.contrastText,
    height: 1,
    margin: '10px auto',
  },
}))

const usePrevious = (value) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/]}
      guide={false}
      showMask
      style={{ border: 'none' }}
    />
  )
}

const FieldDateTime = (props) => {
  const {
    filter,
    onUpdate,
    language,
    languages,
    preDefinedListOptions,
    validateDateShortcut,
    defaultName,
  } = props
  const classes = useStyles()
  const theme = useTheme()
  const firstLoad = useRef(true)
  const prevFilter = usePrevious(filter)
  const [selectedShortCut, setSelectedShortCut] = useState('')
  const [isOpenStart, setIsOpenStart] = useState(false)
  const [isOpenEnd, setIsOpenEnd] = useState(false)
  const [data, setData] = useState({
    ...filter,
    dateStart: null,
    timeStart: null,
    dateEnd: null,
    timeEnd: null,
  })

  const customStyles = {
    option: (provided, providedState) => ({
      ...provided,
      textAlign: 'left',
      fontWeight: providedState.isSelected ? 'bold' : '',
      backgroundColor: theme.palette.secondary,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
    }),
    control: (provided, providedState) => ({
      ...provided,
      border: !providedState.selectProps.value
        ? `1px solid ${theme?.palette?.secondary?.contrastText}`
        : `2px solid ${theme?.palette?.secondary?.contrastText}`,
      '&:hover': {
        border: !providedState.selectProps.value
          ? `1px solid ${theme?.palette?.secondary?.contrastText}`
          : `2px solid ${theme?.palette?.secondary?.contrastText}`,
        '&:active': {
          border: !providedState.selectProps.value
            ? `1px solid ${theme?.palette?.secondary?.contrastText}`
            : `2px solid ${theme?.palette?.secondary?.contrastText}`,
        },
      },
      backgroundColor: theme.palette.secondary,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
    }),
    placeholder: (provided, providedState) => ({
      ...provided,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
      opacity: '0.4',
      fontSize: '0.8em',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
    }),
    singleValue: (provided, providedState) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
      marginLeft: 0,
    }),
    input: (provided) => ({
      ...provided,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
    }),
    menu: (provided) => ({
      ...provided,
      overflowY: 'auto',
      maxHeight: '400px',
      backgroundColor: theme?.palette?.secondary?.main,
      border: `1px solid ${theme?.palette?.secondary?.contrastText}`,
      borderRadius: '5px',
      fontSize: '1em',
      zIndex: 9999,
      paddingLeft: 5,
    }),
  }

  useEffect(() => {
    if (selectedShortCut) {
      applyDateShorCut(selectedShortCut)
    }
  }, [selectedShortCut])

  useEffect(() => {
    if (!_.isEqual(prevFilter, filter)) {
      setData(filter)
    }
  }, [prevFilter, filter])

  useEffect(() => {
    if (firstLoad.current === true) {
      firstLoad.current = false
      return
    }

    const newData = {
      ...data,
    }

    if (
      moment(data?.dateStart).isValid() &&
      moment(data?.dateEnd).isValid() &&
      moment(data?.dateStart).isAfter(data?.dateEnd)
    ) {
      newData.dateEnd = data?.dateStart
    }

    if (
      !moment(data?.dateEnd).isValid() &&
      !moment(data?.dateStart).isValid()
    ) {
      setSelectedShortCut('')
    }

    if (
      moment(data?.dateStart).isValid() ||
      moment(data?.timeStart, 'HH:mm:ss', true).isValid() ||
      moment(data?.dateEnd).isValid() ||
      moment(data?.timeEnd, 'HH:mm:ss', true).isValid()
    ) {
      onUpdate(newData)
    }
  }, [data])

  const applyDateShorCut = (value) => {
    setData({ ...data, ...validateDateShortcut(value) })
  }

  const handleDataStart = (date) => {
    if (date !== null) {
      const newData = {
        ...data,
        dateStart: moment(date),
        timeStart: !moment(data?.timeStart, 'HH:mm:ss', true).isValid()
          ? '00:00:00'
          : data?.timeStart,
      }
      setData(newData)
    } else {
      const newData = {
        ...data,
        dateStart: null,
        timeStart: null,
      }
      setData(newData)
    }
  }

  const handleDataEnd = (date) => {
    if (date !== null) {
      const newData = {
        ...data,
        dateEnd: moment(date),
        timeEnd: !moment(data?.timeEnd, 'HH:mm:ss', true).isValid()
          ? '23:59:59'
          : data?.timeEnd,
      }
      setData(newData)
    } else {
      const newData = {
        ...data,
        dateEnd: null,
        timeEnd: null,
      }
      setData(newData)
    }
  }

  const handleTimeStart = (event) => {
    if (event?.target?.value) {
      const newData = {
        ...data,
        timeStart: event?.target?.value,
      }
      setData(newData)
    } else {
      const newData = {
        ...data,
        timeStart: null,
      }
      setData(newData)
    }
  }

  const handleTimeEnd = (event) => {
    if (event?.target?.value) {
      const newData = {
        ...data,
        timeEnd: event?.target?.value,
      }
      setData(newData)
    } else {
      const newData = {
        ...data,
        timeEnd: null,
      }
      setData(newData)
    }
  }

  const handleChange = (selectedItem) => {
    if (selectedItem?.value) {
      setSelectedShortCut(selectedItem.value)
    } else {
      setSelectedShortCut('')
      setData({
        ...data,
        dateStart: null,
        dateEnd: null,
      })
    }
  }

  return (
    <div data-testid="date">
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={
          JSON.stringify(languages) !== '{}' && language
            ? languages[language]
            : ptLocale
        }
      >
        <Typography className={classes.title}>
          {intl.get(`titles.${defaultName}`) || 'title'}
        </Typography>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="dense"
          id={`date-picker-inlineStart-${filter?.defaultName}`}
          value={data?.dateStart}
          onChange={handleDataStart}
          KeyboardButtonProps={{
            'aria-label': 'change date',
            classes: { root: classes.adornment },
          }}
          views={['date']}
          autoOk
          invalidDateMessage=""
          onOpen={() => setIsOpenStart(true)}
          onClose={() => setIsOpenStart(false)}
          open={isOpenStart}
          key={`${filter?.defaultName}DateStart`}
          placeholder={
            intl.get('words.inputInitialTitle') || 'words.inputInitialTitle'
          }
          InputAdornmentProps={{ position: 'start', variant: 'standard' }}
          InputProps={{
            classes: {
              root: classes.root,
              adornedEnd: classes.adornment,
              adornedStart: classes.adornment,
            },
            style: {
              border:
                data?.dateStart &&
                moment(data?.dateStart).isValid() &&
                `2px solid ${theme?.palette?.secondary?.contrastText}`,
            },
            endAdornment: data?.dateStart && (
              <IconButton
                data-testid="dateStartClearButton"
                onClick={() =>
                  setData((prev) => ({ ...prev, dateStart: null }))
                }
                classes={{ root: classes.adornment }}
              >
                <ClearIcon />
              </IconButton>
            ),
            disableUnderline: true,
          }}
          className={classes.alignLeft}
        />
        <InputField
          key={`${filter?.defaultName}TimeStart`}
          className={classes.alignRight}
          margin="dense"
          placeholder="00:00:00"
          inputProps={{
            'data-testid': 'startTimeField',
            inputComponent: TextMaskCustom,
            value: data?.timeStart,
            onChange: handleTimeStart,
            classes: {
              root: classes.input,
            },
            style: {
              color:
                data?.timeStart &&
                !moment(data?.timeStart, 'HH:mm:ss', true).isValid() &&
                'red',
              border:
                data?.timeStart &&
                moment(data?.timeStart, 'HH:mm:ss', true).isValid()
                  ? `2px solid ${theme?.palette?.secondary?.contrastText}`
                  : `1px solid ${theme?.palette?.secondary?.contrastText}`,
            },
          }}
        />

        <KeyboardDatePicker
          minDate={moment(data?.dateStart).isValid() && data?.dateStart}
          minDateMessage=""
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="dense"
          id={`date-picker-inlineEnd-${filter?.defaultName}`}
          value={data?.dateEnd}
          onChange={handleDataEnd}
          KeyboardButtonProps={{
            'aria-label': 'change date',
            classes: { root: classes.adornment },
          }}
          views={['date']}
          autoOk
          invalidDateMessage=""
          onOpen={() => setIsOpenEnd(true)}
          onClose={() => setIsOpenEnd(false)}
          open={isOpenEnd}
          key={`${filter?.defaultName}DateEnd`}
          placeholder={
            intl.get('words.inputFinalTitle') || 'words.inputFinalTitle'
          }
          InputAdornmentProps={{ position: 'start', variant: 'standard' }}
          InputProps={{
            classes: {
              root: classes.root,
              adornedEnd: classes.adornment,
              adornedStart: classes.adornment,
            },
            style: {
              border:
                data?.dateEnd &&
                moment(data?.dateEnd).isValid() &&
                `2px solid ${theme?.palette?.secondary?.contrastText}`,
            },
            endAdornment: data?.dateEnd && (
              <IconButton
                data-testid="dateEndClearButton"
                onClick={() => setData((prev) => ({ ...prev, dateEnd: null }))}
                classes={{ root: classes.adornment }}
              >
                <ClearIcon />
              </IconButton>
            ),
            disableUnderline: true,
          }}
          className={classes.alignLeft}
        />
        <InputField
          key={`${filter?.defaultName}TimeEnd`}
          className={classes.alignRight}
          margin="dense"
          placeholder="23:59:59"
          inputProps={{
            'data-testid': 'endTimeField',
            inputComponent: TextMaskCustom,
            value: data?.timeEnd,
            onChange: handleTimeEnd,
            classes: {
              root: classes.input,
            },
            style: {
              color:
                data?.timeEnd &&
                !moment(data?.timeEnd, 'HH:mm:ss', true).isValid() &&
                'red',
              border:
                data?.timeEnd &&
                moment(data?.timeEnd, 'HH:mm:ss', true).isValid()
                  ? `2px solid ${theme?.palette?.secondary?.contrastText}`
                  : `1px solid ${theme?.palette?.secondary?.contrastText}`,
            },
          }}
        />
      </MuiPickersUtilsProvider>

      <div
        data-testid="selectDiv"
        title={selectedShortCut === '' ? '' : selectedShortCut}
        className={classes.div}
      >
        <Select
          inputId="predefinedSelect"
          placeholder={intl.get('words.inputPredefined')}
          value={
            selectedShortCut === ''
              ? ''
              : preDefinedListOptions.find((x) => x.value === selectedShortCut)
          }
          isSearchable
          name={intl.get(`titles.${defaultName}`) || 'title'}
          options={preDefinedListOptions}
          onChange={handleChange}
          backspaceRemoves
          isClearable
          inputProps={{ className: classes.input }}
          styles={customStyles}
        />
      </div>
    </div>
  )
}

FieldDateTime.propTypes = {
  filter: PropTypes.any,
  onUpdate: PropTypes.any,
}

export default FieldDateTime
