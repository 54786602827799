import { Tooltip, Typography } from '../../../../core'
import { makeStyles, useTheme } from '../../../../styles'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { intl } from '../../../../translation'
import CreatableSelect from 'react-select/creatable'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  input: {
    color: theme?.palette?.secondary?.contrastText,
  },
  div: {
    marginTop: 10,
    marginBottom: 10,
  },
  equal: {
    color: theme?.palette?.secondary?.contrastText,
    fontFamily: theme?.typography?.fontFamily,
    fontSize: '21px',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  notEqual: {
    color: 'red',
    fontFamily: theme?.typography?.fontFamily,
    fontSize: '21px',
    textAlign: 'left',
    fontWeight: 'bold',
  },
}))

const FieldSelectCreatableMulti = ({
  filter,
  enableNotEquals,
  onUpdate,
  minHeight,
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const customStyles = {
    option: (provided, providedState) => ({
      ...provided,
      fontWeight: providedState?.isSelected ? 'bold' : '',
      backgroundColor: theme?.palette?.secondary?.contrastText,
      color: theme?.palette?.secondary?.contrastText,
      fontSize: '1.0em',
    }),
    control: (provided, providedState) => ({
      ...provided,
      border: !providedState?.selectProps?.value?.value
        ? `1px solid ${theme?.palette?.secondary?.contrastText}`
        : `2px solid ${theme?.palette?.secondary?.contrastText}`,
      '&:hover': {
        border: !providedState?.selectProps?.value?.value
          ? `1px solid ${theme?.palette?.secondary?.contrastText}`
          : `2px solid ${theme?.palette?.secondary?.contrastText}`,
        '&:active': {
          border: !providedState?.selectProps?.value?.value
            ? `1px solid ${theme?.palette?.secondary?.contrastText}`
            : `2px solid ${theme?.palette?.secondary?.contrastText}`,
        },
      },
      fontSize: '0.8em',
      backgroundColor: theme?.palette?.tertiary?.contrastText,
      color: theme?.palette?.secondary?.contrastText,
      minHeight: minHeight || 38,
    }),
    placeholder: (provided, providedState) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
      opacity: !providedState?.selectProps?.value?.value ? '0.4' : 1,
      marginLeft:
        providedState?.selectProps?.value?.value && enableNotEquals ? 10 : 0,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),

    valueContainer: (provided, providedState) => ({
      ...provided,
      color: theme?.palette?.secondary?.contrastText,
      marginLeft:
        providedState?.selectProps?.value?.value && enableNotEquals ? 15 : 0,
      maxHeight: 300,
      overflowY: 'auto',
    }),
    input: (provided) => ({
      ...provided,
      fontFamily: theme?.typography?.fontFamily,
      color: theme?.palette?.secondary?.contrastText,
    }),
    menu: (provided) => ({
      ...provided,
      overflowY: 'auto',
      maxHeight: '400px',
      backgroundColor: theme?.palette?.secondary?.contrastText,
      color: theme?.palette?.secondary?.contrastText,
      border: `1px solid ${theme?.palette?.secondary?.contrastText}`,
      borderRadius: '5px',
      fontSize: '1em',
      zIndex: 999999,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      minWidth: 20,
    }),
    multiValue: (provided) => ({
      ...provided,
      fontSize: '1.1em',
      color: theme?.palette?.quaternary?.contrastText,
    }),
  }

  const filterState = {
    behavior: filter?.behavior,
    defaultName: filter?.defaultName,
    hasSuggested: filter?.hasSuggested,
    isSuggested: filter?.isSuggested,
    minToMakeRequest: filter?.minToMakeRequest,
    type: filter?.type,
    value: filter?.value,
    condition: filter?.condition,
    enableNotEquals: filter?.enableNotEquals,
  }
  const [data, setData] = useState(true)
  const [listOptions, setListOptions] = useState([])

  const firstLoad = useRef(true)

  useEffect(() => {
    if (filter?.value?.length === 0 && listOptions.length > 0) {
      setListOptions([])
    }
  }, [filter])

  useEffect(() => {
    if (firstLoad.current === true) {
      firstLoad.current = false
    } else {
      onUpdate({ ...filterState, condition: data })
    }
  }, [data])

  const handleChangeCreatable = (value) => {
    if (value && value?.length < listOptions.length) {
      setListOptions(value)
      onUpdate({ ...filterState, condition: data, value })
    }

    if (!value) {
      setListOptions([])
      onUpdate({ ...filterState, condition: data, value: [] })
    }
  }

  const handleChangeCreatableOption = (value) => {
    const valuesSplitted = value?.split(/[ ;,]+/)
    const newValues = listOptions || []
    if (valuesSplitted && valuesSplitted.length > 0) {
      valuesSplitted.map((item) => {
        const index = newValues.findIndex((val) => val.value === item)
        if (item.length && index === -1) {
          return newValues.push({ value: item, label: item })
        }
      })
      setListOptions(newValues)
      onUpdate({ ...filterState, condition: data, value: newValues })
    }
  }

  const handleClickCondition = () => {
    setData(!data)
  }

  return (
    <div data-testid="multi-creatable-select" className={classes.div}>
      {filter?.value && enableNotEquals && (
        <section
          style={{ display: 'inline-block', width: '20px', padding: '0px' }}
        >
          {!data ? (
            <Tooltip
              title={intl.get(`words.notEqual`) || `words.notEqual`}
              placement="right-start"
            >
              <Typography
                className={classes.notEqual}
                onClick={handleClickCondition}
                component="h2"
              >
                &#8800;
              </Typography>
            </Tooltip>
          ) : (
            <Tooltip
              title={intl.get(`words.equal`) || `words.equal`}
              placement="right-start"
            >
              <Typography
                className={classes.equal}
                component="h2"
                onClick={handleClickCondition}
              >
                &#61;
              </Typography>
            </Tooltip>
          )}
        </section>
      )}
      <section
        data-testid="selectSection"
        style={{
          display: 'inline-block',
          width: `${enableNotEquals ? 'calc(100% - 20px)' : '100%'}`,
          padding: 0,
        }}
      >
        <CreatableSelect
          inputId="creatableSelect"
          classNamePrefix={intl.get(`titles.${filter?.defaultName}`)}
          placeholder={intl.get(`titles.${filter?.defaultName}`)}
          name={intl.get(`titles.${filter?.defaultName}`)}
          isClearable
          formatCreateLabel={() => (
            <label style={{ fontWeight: 'bolder', color: 'white' }}>
              {intl.get(`phrases.clickToValidateValues`) ||
                `phrases.clickToValidateValues`}
            </label>
          )}
          isValidNewOption={() => {
            return true
          }}
          isMulti
          onChange={handleChangeCreatable}
          onCreateOption={handleChangeCreatableOption}
          options={listOptions}
          value={listOptions}
          styles={customStyles}
          noOptionsMessage={() => (
            <label style={{ fontWeight: 'bolder', color: 'red' }}>
              {intl.get(`phrases.informValuesWithSemiColonOrWhiteSpace`) ||
                `phrases.informValuesWithSemiColonOrWhiteSpace`}
            </label>
          )}
        />
      </section>
    </div>
  )
}

FieldSelectCreatableMulti.propTypes = {
  enableNotEquals: PropTypes.any,
  filter: PropTypes.any,
  listOptions: PropTypes.any,
  onUpdate: PropTypes.any,
}

export default FieldSelectCreatableMulti
