import React from 'react'
import { Button as MaterialButton } from '@material-ui/core'
import { makeStyles } from '../../../styles'
import { convertHexToRGBA } from '../../../utils/Colors'
import classnames from 'classnames'

const useStyles = makeStyles((theme) => ({
  materialButton: {
    margin: (props) => {
      const margin = props.margin ? props.margin : theme.spacing(1)
      return margin
    },
    fontFamily: theme?.typography?.fontFamily,
    backgroundColor: (props) => {
      let bgcolor
      if (props.layout) {
        switch (props.variant) {
          case 'contained':
            bgcolor =
              theme?.palette[props.layout]?.main ||
              theme?.palette?.secondary?.main
            break
          case 'outlined':
            bgcolor = theme?.palette?.secondary?.main
            break
          case 'text':
            bgcolor = 'transparent'
            break
          default:
            bgcolor = 'transparent'
        }
      } else {
        switch (props.variant) {
          case 'contained':
            bgcolor = props.backgroundcolor
              ? theme?.palette[props.backgroundcolor]?.main ||
                props.backgroundcolor ||
                theme?.palette?.secondary?.main
              : theme?.palette?.secondary?.main
            break
          case 'outlined':
            bgcolor = props.backgroundcolor
              ? theme?.palette[props.backgroundcolor]?.main ||
                props.backgroundcolor ||
                theme?.palette?.secondary?.main
              : theme?.palette?.secondary?.main
            break
          case 'text':
            bgcolor = 'transparent'
            break
          default:
            bgcolor = 'transparent'
        }
      }
      return bgcolor
    },
    color: (props) => {
      let txcolor

      if (props.layout && theme.palette) {
        switch (props.variant) {
          case 'contained':
            txcolor =
              props.layout === 'secondary'
                ? theme?.palette[props.layout]?.contrastText
                : '#ffffff'
            break
          case 'outlined':
            txcolor =
              props.layout === 'secondary'
                ? theme?.palette?.secondary?.contrastText
                : theme?.palette[props.layout]?.main ||
                  theme?.palette?.secondary?.contrastText
            break
          case 'text':
            txcolor =
              props.layout === 'secondary'
                ? theme?.palette?.secondary?.contrastText
                : theme?.palette[props.layout]?.main ||
                  theme?.palette?.secondary?.contrastText
            break
          default:
            txcolor = theme?.palette?.secondary?.contrastText
        }
      } else {
        if (props.textcolor) {
          txcolor =
            theme?.palette[props.textcolor]?.main ||
            props.textcolor ||
            theme?.palette?.secondary?.contrastText
        } else {
          txcolor = '#000000'
        }
      }
      return txcolor
    },
    fontWeight: (props) => (props.fontWeight ? props.fontWeight : 'normal'),
    width: (props) => {
      let buttonWidth
      if (props.fullwidth) {
        buttonWidth = 'calc(100% - 16px)'
      } else if (props.customsize) {
        switch (props.customsize) {
          case 'small':
            buttonWidth = '120px'
            break
          case 'medium':
            buttonWidth = '180px'
            break
          case 'large':
            buttonWidth = '220px'
            break
          default:
            buttonWidth = '120px'
            break
        }
      } else {
        if (props.width) {
          buttonWidth = props.width || '120px'
        } else {
          buttonWidth = '120px'
        }
      }
      return buttonWidth
    },
    fontSize: (props) => {
      let letterSize
      if (props.customsize) {
        switch (props.customsize) {
          case 'small':
            letterSize = '12px'
            break
          case 'medium':
            letterSize = '16px'
            break
          case 'large':
            letterSize = '18px'
            break
          default:
            letterSize = '12px'
            break
        }
      } else {
        if (props.fontSize) {
          letterSize = props.fontSize || '12px'
        } else {
          letterSize = '12px'
        }
      }

      return letterSize
    },
    borderColor: (props) => {
      let bordercolor
      if (props.layout) {
        switch (props.variant) {
          case 'contained':
            bordercolor = 'transparent'
            break
          case 'outlined':
            bordercolor =
              props.layout === 'secondary'
                ? theme?.palette?.secondary?.contrastText
                : theme?.palette[props.layout]?.main ||
                  theme?.palette?.secondary?.contrastText
            break
          case 'text':
            bordercolor = 'transparent'
            break
          default:
            bordercolor = 'transparent'
        }
      } else {
        switch (props.variant) {
          case 'contained':
            bordercolor = 'transparent'
            break
          case 'outlined':
            bordercolor = props.textcolor
              ? theme?.palette[props.textcolor]?.main ||
                props.textcolor ||
                theme?.palette?.secondary?.contrastText
              : theme?.palette?.secondary?.contrastText
            break
          case 'text':
            bordercolor = 'transparent'
            break
          default:
            bordercolor = 'transparent'
        }
      }
      return bordercolor
    },
    '&:hover': {
      backgroundColor: (props) => {
        let bgcolor
        if (props.layout) {
          switch (props.variant) {
            case 'contained':
              bgcolor =
                theme?.palette[props.layout]?.hover ||
                theme?.palette?.secondary?.hover
              break
            case 'outlined':
              bgcolor =
                props.layout === 'secondary'
                  ? theme?.palette?.secondary?.hover
                  : theme?.palette[props.layout]?.hoverContrast ||
                    theme?.palette?.secondary?.hover
              break
            case 'text':
              bgcolor =
                props.layout === 'secondary'
                  ? theme?.palette?.secondary?.hover
                  : theme?.palette[props.layout]?.hoverContrast ||
                    theme?.palette?.secondary?.hover
              break
            default:
              bgcolor = 'transparent'
          }
        } else {
          switch (props.variant) {
            case 'contained':
              bgcolor = props.backgroundcolor
                ? theme?.palette[props.backgroundcolor]?.hover ||
                  convertHexToRGBA(props.backgroundcolor, 0.85) ||
                  theme?.palette?.secondary?.hover
                : theme?.palette?.secondary?.hover
              break
            case 'outlined':
              bgcolor =
                !props.textcolor && !props.backgroundcolor
                  ? theme?.palette?.secondary?.hover
                  : props.textcolor && !props.backgroundcolor
                  ? theme?.palette[props.textcolor]?.hoverContrast ||
                    convertHexToRGBA(props.textcolor, 0.1) ||
                    theme?.palette?.secondary?.hover
                  : !props.textcolor && props.backgroundcolor === 'secondary'
                  ? theme?.palette?.secondary?.hover
                  : !props.textcolor && props.backgroundcolor !== 'secondary'
                  ? theme?.palette[props.backgroundcolor]?.hoverContrast ||
                    convertHexToRGBA(props.backgroundcolor, 0.85) ||
                    theme?.palette?.secondary?.hover
                  : props.textcolor && props.backgroundcolor === 'secondary'
                  ? theme?.palette[props.textcolor]?.hoverContrast ||
                    convertHexToRGBA(props.textcolor, 0.1) ||
                    theme?.palette?.secondary?.hover
                  : theme?.palette[props.background]?.hover ||
                    convertHexToRGBA(props.backgroundcolor, 0.85) ||
                    theme?.palette?.secondary?.hover
              break
            case 'text':
              bgcolor =
                !props.textcolor && !props.backgroundcolor
                  ? theme?.palette?.secondary?.hover
                  : props.textcolor && !props.backgroundcolor
                  ? theme?.palette[props.textcolor]?.hoverContrast ||
                    convertHexToRGBA(props.textcolor, 0.1) ||
                    theme?.palette?.secondary?.hover
                  : !props.textcolor && props.backgroundcolor === 'secondary'
                  ? theme?.palette?.secondary?.hover
                  : !props.textcolor && props.backgroundcolor !== 'secondary'
                  ? theme?.palette[props.backgroundcolor]?.hoverContrast ||
                    convertHexToRGBA(props.backgroundcolor, 0.85) ||
                    theme?.palette?.secondary?.hover
                  : props.textcolor && props.backgroundcolor === 'secondary'
                  ? theme?.palette[props.textcolor]?.hoverContrast ||
                    convertHexToRGBA(props.textcolor, 0.1) ||
                    theme?.palette?.secondary?.hover
                  : theme?.palette[props.background]?.hover ||
                    convertHexToRGBA(props.backgroundcolor, 0.85) ||
                    theme?.palette?.secondary?.hover
              break
            default:
              bgcolor = 'transparent'
          }
        }
        return bgcolor
      },
    },
  },
}))

export const Button = (props) => {
  const { variant, children, className } = props

  const classes = useStyles(props)

  return (
    <MaterialButton
      className={classnames(classes.materialButton, className)}
      classes={{
        contained: classes.materialButton,
        text: classes.materialButton,
        outlined: classes.materialButton,
      }}
      variant={variant}
      style={{ height: 40 }}
      {...props}
    >
      {children}
    </MaterialButton>
  )
}

Button.propTypes = {}
