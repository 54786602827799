import {
  Button,
  ClickAwayListener,
  Collapse,
  MenuItem,
  MenuList,
  IconButton,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from '../../../core'
import { makeStyles } from '../../../styles'
import PropTypes from 'prop-types'
import React, { useState, useRef } from 'react'
import { intl } from '../../../translation'

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.tertiary?.main,
    borderColor: theme?.palette?.tertiary?.main,
    padding: 5,
  },
  paper: {
    maxWidth: 300,
    minWidth: 150,
    maxHeight: 500,
    overflowY: `auto`,
    overflowX: `hidden`,
  },
  popper: {
    zIndex: 999,
    marginTop: 5,
  },
}))

const MultifunctionalRight = ({
  handleButtonClick,
  multifunctionalRightIcon,
  multifunctionalRightText,
  multifunctionalRightIsIconButton,
  multifunctionalRightIsMenu,
  multifunctionalRightItems,
  multifunctionalRightWidth,
}) => {
  const classes = useStyles()
  const anchorRef = useRef(null)

  const text = multifunctionalRightText || 'multifunctionalRightText'

  const [open, setOpen] = useState(false)
  const [anchorButton, setAnchorButton] = useState(null)

  const handlePopperOpening = (e) => {
    setAnchorButton(e.currentTarget)
    setOpen(true)
  }

  const handleOptionClick = (item) => {
    handleButtonClick('MultifunctionalRight', item)
    setOpen(false)
  }

  return (
    <>
      <Tooltip data-testid="tooltip" title={text}>
        {multifunctionalRightIsIconButton && multifunctionalRightIcon ? (
          <IconButton
            role="button"
            onClick={() => handleButtonClick('MultifunctionalRight', {})}
            className={classes.icon}
            aria-label={text}
          >
            {multifunctionalRightIcon || 'multifunctionalRightIcon'}
          </IconButton>
        ) : (
          <Button
            width={multifunctionalRightWidth}
            role="button"
            ref={anchorRef}
            variant="contained"
            layout="tertiary"
            onClick={
              multifunctionalRightIsMenu
                ? handlePopperOpening
                : () => handleButtonClick('MultifunctionalRight', {})
            }
            startIcon={multifunctionalRightIcon}
          >
            {text}
          </Button>
        )}
      </Tooltip>
      {multifunctionalRightIsMenu && (
        <Popper
          transition
          open={open}
          placement="bottom-start"
          className={classes.popper}
          anchorEl={anchorButton}
        >
          {({ TransitionProps, placement }) => (
            <Collapse
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper className={classes.paper} elevation={3}>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <MenuList role="list" autoFocusItem={open}>
                    {multifunctionalRightItems?.map((item) => (
                      <MenuItem
                        role="listitem"
                        key={item.code || 'all'}
                        onClick={() => handleOptionClick(item)}
                      >
                        <Tooltip
                          title={
                            item.code || intl.get(`words.all`) || 'words.all'
                          }
                        >
                          <Typography variant="inherit" noWrap>
                            {item.code
                              ? `${item.code} - ${item.symbol}`
                              : intl.get(`words.all`) || 'words.all'}
                          </Typography>
                        </Tooltip>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Collapse>
          )}
        </Popper>
      )}
    </>
  )
}

MultifunctionalRight.defaultProps = {
  handleButtonClick: () => {},
}

MultifunctionalRight.propTypes = {
  handleButtonClick: PropTypes.func.isRequired,
}

export default MultifunctionalRight
