import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui'
import { Checkbox } from '../../../core'
import { makeStyles } from '../../../styles'
import { convertHexToRGBA } from '../../../utils'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 2,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: `11px !important`,
    borderRight: `1px solid ${convertHexToRGBA(
      theme?.palette?.secondary?.contrastText,
      0.3
    )}`,
    borderBottom: `1px solid ${convertHexToRGBA(
      theme?.palette?.secondary?.contrastText,
      0.3
    )}`,
    color: theme?.palette?.secondary?.contrastText,
    backgroundColor: theme?.palette?.secondary?.main,
  },
  rootCheckbox: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme?.palette?.secondary?.main,
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme?.palette?.secondary?.main,
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: theme?.palette?.primary?.main,
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
}))

function StyledCheckbox(props) {
  const classes = useStyles()

  return (
    <Checkbox
      className={classes.rootCheckbox}
      disableRipple
      color="default"
      checkedIcon={
        <span className={classNames(classes.icon, classes.checkedIcon)} />
      }
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  )
}

const SelectCell = ({ className, selected, onToggle, ...restProps }) => {
  const classes = useStyles()

  return (
    <TableHeaderRow.Cell
      {...restProps}
      draggingEnabled={false}
      className={classNames(className, classes.root)}
    >
      <StyledCheckbox
        checked={selected}
        onClick={(e) => {
          e.stopPropagation()
          onToggle()
        }}
      />
    </TableHeaderRow.Cell>
  )
}

SelectCell.defaultProps = {
  className: '',
  selected: false,
  onToggle: () => {},
}

SelectCell.propTypes = {
  onToggle: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
}

export default SelectCell
