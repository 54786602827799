import React from 'react'
import { CircularProgressWithLabel } from '../CircularProgressWithLabel'
import {
  Check as CheckIcon,
  PeopleAlt as PeopleAltIcon,
  Error as ErrorIcon,
} from '../../icons'
import { IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core'
import intl from 'react-intl-universal'

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.tertiary?.main,
  },
  successIcon: {
    color: theme?.palette.success.main,
  },
  errorIcon: {
    color: theme?.palette.error.main,
  },
  successIconContainer: {
    border: `3px solid ${theme.palette.success.main}`,
    borderRadius: '100%',
    padding: '2px',
    display: 'flex',
  },
  errorIconContainer: {
    border: `3px solid ${theme.palette.error.main}`,
    borderRadius: '100%',
    padding: '2px',
    display: 'flex',
  },

  exportQueueContainer: {
    boxShadow: '1px 2px 6px 1px rgba(0,0,0,0.15)',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    display: 'flex',
    zIndex: '1000',
    padding: '5px',
    borderRadius: '6px',
    left: '0',
    gap: '5px',
  },
  exportQueueText: {
    width: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    lineHeight: '2px',
  },
  countPeopleText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    padding: '10px',
    fontSize: '18px',
  },
  text: {
    color: theme.palette.grey[700],
    fontSize: '12px',
    margin: '5px',
    padding: '2px 0',
  },
  peopleText: {
    fontWeight: 'bold',
  },
  exportQueueWrapper: {
    position: 'relative',
  },
}))
const ProgressWithPosition = ({
  processCounter,
  handleReset,
  defaultTexts,
}) => {
  const classes = useStyles()
  return (
    <>
      {processCounter?.status && (
        <>
          {processCounter?.status === 'PENDING' && (
            <div className={classes.exportQueueWrapper}>
              <div className={classes.icon}>
                <CircularProgressWithLabel
                  isPenging={true}
                  value={processCounter?.progress ?? 0}
                />
              </div>

              <div className={classes.exportQueueContainer}>
                {processCounter.position ? (
                  <>
                    <PeopleAltIcon color="primary" fontSize="large" />
                    <div className={classes.exportQueueText}>
                      <span className={classes.countPeopleText} variant="h6">
                        {processCounter.position ?? '-'}
                      </span>
                      <span
                        className={`${classes.peopleText} ${classes.text}`}
                        variant="caption"
                      >
                        {intl.get(
                          `words.${
                            processCounter.position === 1 ? 'person' : 'people'
                          }`
                        )}
                      </span>
                      <span className={classes.text} variant="caption">
                        {intl.get('words.onRow')}
                      </span>
                    </div>
                  </>
                ) : (
                  <div>
                    <Typography variant="caption">
                      {intl.get('words.preparing')} <br />
                      {defaultTexts.preparingProcess}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          )}
          {processCounter?.status === 'STARTED' && (
            <Tooltip
              data-testid="tooltip-exporting"
              title={defaultTexts.startedProcess}
            >
              <span className={classes.icon}>
                <CircularProgressWithLabel
                  value={processCounter?.progress ?? 0}
                />
              </span>
            </Tooltip>
          )}
          {processCounter?.status === 'DONE' && (
            <Tooltip
              data-testid="tooltip-done"
              title={defaultTexts.doneProcess}
            >
              <IconButton
                role="button"
                onClick={handleReset}
                className={classes.icon}
              >
                <div className={classes.successIconContainer}>
                  <CheckIcon
                    className={classes.successIcon}
                    data-testid="checkIcon"
                  />
                </div>
              </IconButton>
            </Tooltip>
          )}
          {processCounter?.status === 'ERROR' && (
            <Tooltip
              data-testid="tooltip-done"
              title={defaultTexts.errorProcess}
            >
              <IconButton
                role="button"
                onClick={handleReset}
                className={classes.icon}
              >
                <div className={classes.errorIconContainer}>
                  <ErrorIcon
                    className={classes.errorIcon}
                    data-testid="errorIcon"
                  />
                </div>
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
    </>
  )
}

export default ProgressWithPosition
