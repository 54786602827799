import _ from 'lodash'
import React, { useEffect, useState, useRef } from 'react'
import { intl } from '../../translation'
import { DialogModel } from '../DialogModel'
import Actions from './Actions'
import FormAddTariff from './FormAddTariff'
import { useStyles } from './styles'
import DialDetails from './Tariffs/DialDetails'
import GPRSDetails from './Tariffs/GPRSDetails'
import IPDetails from './Tariffs/IPDetails'
import LSSDetails from './Tariffs/LSSDetails'
import M2MDetails from './Tariffs/M2MDetails'
import PayzenDetails from './Tariffs/PayzenDetails'
import VeripagDetails from './Tariffs/VeripagDetails'
import X25Details from './Tariffs/X25Details'

export const TariffCreateDialog = (props) => {
  const classes = useStyles()
  const {
    open,
    onClose,
    billingTypes,
    codeDetails,
    codes,
    customerDefault,
    customers,
    size,
    numberDetails,
    numbers,
    onAssociateTariff,
    planDetails,
    plans,
    purchaseTariffs,
    requestCodeDetails,
    requestCodes,
    requestCustomers,
    requestNumberDetails,
    requestNumbers,
    requestPlanDetails,
    requestPlans,
    requestPurchaseTariffs,
    tariffSelected,
    products,
    payzenBillingTypes,
    veripagBillingTypes,
    tariffTypes,
    operators,
    sendData,
    hiddenCompanySelect,
    hiddenCurrencySelect,
    companies,
    currencies,
  } = props

  const previousProduct = useRef('')
  const [selectedData, setSelectedData] = useState({})
  const [selectedCurrency, setSelectedCurrency] = useState({})
  const [tariffData, setTariffData] = useState({})
  const [statusCreateReady, setStatusCreateReady] = useState(false)

  useEffect(() => {
    if (_.isEmpty(selectedData) && statusCreateReady) {
      setStatusCreateReady(false)
    }

    if (previousProduct.current !== selectedData?.product) {
      setTariffData({})
      previousProduct.current = selectedData?.product
    }

    if (selectedData?.currency) {
      const currencyOption = currencies
        ? currencies?.find(
            (currency) => currency?.code === selectedData?.currency?.value
          )
        : {}
      currencyOption !== selectedCurrency && setSelectedCurrency(currencyOption)
    }
  }, [selectedData])

  useEffect(() => {
    if (!_.isEmpty(tariffData)) {
      setStatusCreateReady(true)
    } else {
      setStatusCreateReady(false)
    }
  }, [tariffData])

  const renderTariffDetails = (product) => {
    switch (product) {
      case 2:
      case 3:
        return (
          <DialDetails
            changeDetails={(values) => setTariffData(values)}
            currencyData={selectedCurrency}
          />
        )
      case 5:
        return (
          <GPRSDetails
            changeDetails={(values) => setTariffData(values)}
            currencyData={selectedCurrency}
          />
        )
      case 6:
        return (
          <IPDetails
            changeDetails={(values) => setTariffData(values)}
            currencyData={selectedCurrency}
          />
        )
      case 9:
        return (
          <X25Details
            changeDetails={(values) => setTariffData(values)}
            currencyData={selectedCurrency}
          />
        )
      case 10:
        return (
          <LSSDetails
            changeDetails={(values) => setTariffData(values)}
            currencyData={selectedCurrency}
          />
        )
      case 12:
        return (
          <PayzenDetails
            changeDetails={(values) => setTariffData(values)}
            billingPayzenType={selectedData?.billingPayzenType?.value}
            currencyData={selectedCurrency}
          />
        )
      case 104:
        return (
          <VeripagDetails
            changeDetails={(values) => setTariffData(values)}
            billingVeripagType={selectedData?.billingVeripagType?.value}
            currencyData={selectedCurrency}
          />
        )
      case 105:
        return (
          <M2MDetails
            changeDetails={(values) => setTariffData(values)}
            currencyData={selectedCurrency}
          />
        )
      default:
        return (
          <h1 className={classes.error}>
            {intl.get('phrases.inexistentProduct') ||
              'phrases.inexistentProduct'}
          </h1>
        )
    }
  }

  const commonProps = {
    billingTypes,
    codeDetails,
    codes,
    customerDefault,
    customers,
    numberDetails,
    numbers,
    onAssociateTariff,
    planDetails,
    plans,
    products,
    purchaseTariffs,
    requestCodeDetails,
    requestCodes,
    requestCustomers,
    requestNumberDetails,
    requestNumbers,
    requestPlanDetails,
    requestPlans,
    requestPurchaseTariffs,
    tariffSelected,
    payzenBillingTypes,
    veripagBillingTypes,
    tariffTypes,
    operators,
    hiddenCompanySelect,
    hiddenCurrencySelect,
    companies,
    currencies,
  }

  const handleSendData = () => {
    sendData({
      ...selectedData,
      ...tariffData,
    })
  }

  const handleClose = () => {
    onClose()
    setSelectedData({})
  }

  return (
    <DialogModel
      open={open}
      title={intl.get('phrases.tariffCreate') || 'phrases.tariffCreate'}
      size={size || 'lg'}
      onClose={handleClose}
    >
      <div className={classes.container}>
        <main className={classes.main}>
          <FormAddTariff
            {...commonProps}
            onSelectedValues={(values) => setSelectedData({ ...values })}
          />
          <div className={classes.details}>
            {selectedData?.product?.value &&
              selectedData?.tariffType?.value &&
              renderTariffDetails(
                selectedData.product.value,
                selectedData.tariffType.value
              )}
          </div>
          <Actions
            disabled={!statusCreateReady}
            onClose={handleClose}
            sendData={handleSendData}
          />
        </main>
      </div>
    </DialogModel>
  )
}
