const loadItem = (item) => {
  try {
    const serializedItem = localStorage.getItem(item)

    if (serializedItem === null) {
      return undefined
    }

    return JSON.parse(serializedItem)
  } catch (err) {
    return undefined
  }
}

const saveItem = (state, item) => {
  try {
    const serializedItem = JSON.stringify(state)

    localStorage.setItem(item, serializedItem)
  } catch (err) {
    console.error(err)
    // Ignore write errors
  }
}

const removeItem = (item) => {
  try {
    localStorage.removeItem(item)
  } catch (err) {
    // Ignore write errors
  }
}

const removeAll = () => {
  try {
    localStorage.clear()
  } catch (err) {
    // Ignore write errors
  }
}

const hasItem = (item) => {
  try {
    const serializedItem = localStorage.getItem(item)
    return !!serializedItem
  } catch (err) {
    return false
  }
}

export { loadItem, saveItem, removeItem, hasItem, removeAll }
