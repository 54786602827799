import { Table } from '@devexpress/dx-react-grid-material-ui'
import { Button, IconButton, Tooltip } from '@material-ui/core'
import { DeleteForever } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCommissionsData } from '../../../../../API/Hooks'
import CustomModal from '../../../../CustomModal'
import { makeStyles } from '../../../styles'
import { intl } from '../../../translation'

const useStyles = makeStyles((theme) => ({
  buttonRemove: {
    color: theme.palette.error.light,
    '&:hover': {
      color: theme.palette.error.dark,
    },
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
  },
  buttonOkRemove: {
    color: theme.palette.error.light,
    cursor: 'pointer',
  },
  buttonFalseRemove: {
    color: 'rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
  },
}))

const CustomCell = (props) => {
  const classes = useStyles(props)
  const { enqueueSnackbar } = useSnackbar()
  const { column, row, onNewReleaseInsert, canDeleteAdjust } = props
  const [open, setOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const commissionsData = useCommissionsData('new_release')

  useEffect(() => {
    if (commissionsData.success.DELETE === true) {
      enqueueSnackbar(intl.get('phrases.deleteCommissionInvoiceSuccess'), {
        variant: 'success',
      })
      commissionsData.success.DELETE = false

      onNewReleaseInsert()
    }

    if (commissionsData.error.DELETE) {
      enqueueSnackbar(intl.get('phrases.deleteCommissionInvoiceError'), {
        variant: 'error',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commissionsData.success])

  const handleDeleteComission = () => {
    const payload = {
      commissionInvoiceId: selectedRow?.id,
    }
    if (canDeleteAdjust) {
      commissionsData.handleDELETE(payload)
    }

    handleClose()
  }

  const handleClickOpen = (row) => {
    setSelectedRow(row)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const verifyRowIsFalse = row?.auto === false

  if (column.name === 'delete') {
    return (
      <>
        <Table.Cell
          {...props}
          style={{
            borderBottom: '1px solid rgba(59, 58, 57, 0.3)',
          }}
        >
          <Tooltip title={intl.get(`words.delete`)}>
            <IconButton
              aria-label="delete"
              onClick={() => handleClickOpen(row)}
              size="small"
              role="button"
              disabled={row?.auto === true}
            >
              <DeleteForever
                className={`${
                  verifyRowIsFalse
                    ? classes.buttonRemove
                    : classes.buttonFalseRemove
                } `}
                size="small"
                role="button"
              />
            </IconButton>
          </Tooltip>
        </Table.Cell>

        {open && (
          <CustomModal
            modalTitle={intl.get('buttons.confirm')}
            modalCancel={handleClose}
            size={'small'}
          >
            <Alert severity="warning">Deseja realmente deletar?</Alert>
            <div className={classes.buttonGroup}>
              <Button
                variant="contained"
                color="secondary"
                customsize="small"
                onClick={handleClose}
              >
                {intl.get(`buttons.cancel`)}
              </Button>
              <Button
                onClick={handleDeleteComission}
                color="primary"
                variant="contained"
                autoFocus
                style={{ backgroundColor: '#df0f0f' }}
              >
                {intl.get('buttons.deleteResume')}
              </Button>
            </div>
          </CustomModal>
        )}
      </>
    )
  }
  return <Table.Cell {...props} />
}

export default CustomCell
