import { useFetchAxiosWrapper } from '../useFetchAxiosWrapper'
import ENDPOINTS from './ENDPOINTS'
import { normalizeAccountsData } from './Normalizers'
import { useState, useEffect } from 'react'
import _ from 'lodash'

const filterQuery = (values) => {
  return {
    ...values,
  }
}

export const useLibraryAccounts = (params) => {
  const [data, setData] = useState([])
  const { callOnInit = true } = params || {}
  const {
    reset,
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    fetchGET,
    fetchPATCH,
    fetchPOST,
    fetchPUT,
  } = useFetchAxiosWrapper({
    baseEndpoint: ENDPOINTS.GET.account_results,
    callOnInit,
  })

  useEffect(() => {
    if (!_.isEqual(getRequest.data, data)) {
      setData(getRequest.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequest])

  useEffect(() => {
    if (!_.isEqual(patchRequest.data, data)) {
      setData(patchRequest.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchRequest])

  const handleGET = ({ urlParameters }) => {
    const newQuery = filterQuery(urlParameters)
    fetchGET({
      urlParameters: { ...newQuery },
    })
  }

  const handlePATCH = (params = {}) => {
    fetchPATCH({
      body: { ...params },
    })
  }

  const handlePOST = (data = {}) => {
    fetchPOST({
      body: data,
      noRefreshData: true,
    })
  }

  const handlePUT = (data = {}) => {
    fetchPUT({
      body: data,
      noRefreshData: true,
    })
  }

  return {
    data: normalizeAccountsData({ data: data }),
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    reset,
    handleGET,
    handlePATCH,
    handlePOST,
    handlePUT,
  }
}
