import PropTypes from 'prop-types'
import React from 'react'
import {
  ClickAwayListener,
  Collapse,
  IconButton,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from '../../../../core'
import { ViewColumn as ViewColumnIcon } from '../../../../icons'
import { makeStyles } from '../../../../styles'
import { intl } from '../../../../translation'
import Option from './Option'

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.tertiary?.main,
  },
  popper: {
    zIndex: 999,
  },
  paper: {
    maxWidth: 300,
    minWidth: 250,
    maxHeight: 500,
    overflowY: `auto`,
    overflowX: `hidden`,
  },
}))

const Visibility = ({ visibilitySettings, handleButtonClick }) => {
  const classes = useStyles()
  const anchorRef = React.useRef(null)
  const [open, setOpen] = React.useState(false)

  const toggleAll = () => {
    const newSettings = { ...visibilitySettings }
    if (
      visibilitySettings?.hiddenColumns?.length === 0 &&
      visibilitySettings?.columns
    ) {
      visibilitySettings.columns.forEach((element) =>
        newSettings.hiddenColumns.push(element?.name)
      )
    } else {
      newSettings.hiddenColumns = []
    }

    handleButtonClick('Visibility', newSettings)
  }

  const toggle = (name) => {
    const newSettings = { ...visibilitySettings }

    const elementIndex = visibilitySettings?.hiddenColumns.findIndex(
      (element) => element === name
    )

    if (elementIndex >= 0 && newSettings?.hiddenColumns) {
      newSettings.hiddenColumns.splice(elementIndex, 1)
    } else {
      newSettings.hiddenColumns.push(name)
    }

    handleButtonClick('Visibility', newSettings)
  }

  return (
    <>
      <Tooltip
        data-testid="tooltip"
        title={intl.get(`words.visibility`) || 'words.visibility'}
      >
        <IconButton
          role="button"
          ref={anchorRef}
          className={classes.icon}
          onClick={() => setOpen(!open)}
        >
          <ViewColumnIcon data-testid="viewIcon" />
        </IconButton>
      </Tooltip>
      <Popper
        transition
        open={open}
        role={undefined}
        className={classes.popper}
        anchorEl={anchorRef?.current}
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Collapse {...TransitionProps} timeout={10}>
            <Paper className={classes.paper} elevation={3}>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList role="list" autoFocusItem={open} id="menu-list-grow">
                  <Option
                    label={intl.get('phrases.selectAll') || 'phrases.selectAll'}
                    checked={visibilitySettings?.hiddenColumns?.length === 0}
                    onClick={toggleAll}
                  />
                  {visibilitySettings?.columns?.map((column) => {
                    const element = visibilitySettings.columns.find(
                      (e) => e?.columnName === column?.name
                    )

                    if (!element) {
                      return console.error(
                        intl.get('phrases.columnNotFound', {
                          name: column?.name,
                        }) || 'phrases.columnNotFound'
                      )
                    }

                    const checked =
                      !visibilitySettings?.hiddenColumns?.includes(
                        element?.name
                      )

                    return (
                      <Option
                        checked={checked}
                        key={element?.name}
                        label={
                          intl.get(`titles.${element?.name}`) ||
                          `titles.${element?.name}`
                        }
                        onClick={() => toggle(element?.name)}
                      />
                    )
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Collapse>
        )}
      </Popper>
    </>
  )
}

Visibility.defaultProps = {
  handleButtonClick: () => {},
  visibilitySettings: {
    order: [],
    columns: [],
    hiddenColumns: [],
  },
}

Visibility.propTypes = {
  handleButtonClick: PropTypes.func.isRequired,
  visibilitySettings: PropTypes.shape({
    order: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    hiddenColumns: PropTypes.array.isRequired,
  }),
}

export default Visibility
