import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

const Actions = (props) => {
  return (
    <DataTypeProvider
      formatterComponent={({ row }) => {
        return props.actionsCell(row)
      }}
      {...props}
    />
  )
}

Actions.displayName = 'Actions'

export default Actions
