import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableHead, TableContainer } from '../../../../core'
import { intl } from '../../../../translation'
import { RowTitle, RowWithCheckboxes, RowTitleHead } from '../../Components'
import { makeStyles } from '../../../../styles'

/* import PropTypes from 'prop-types' */

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: '475px',
  },
  table: {
    width: '100%',
  },
}))

export const ScreenDetails = ({
  editState,
  selectedScreen,
  selectedScreenDataGroup,
  defaultScreenGroup,
  handleChangeStatusScreen,
  handleChangeDefaultScreen,
  handleChangeStatusAction,
  handleChangeStatusField,
}) => {
  const classes = useStyles()
  const [screenActions, setScreenActions] = useState([])
  const [screenFields, setScreenFields] = useState([])

  useEffect(() => {
    if (selectedScreenDataGroup?.actions) {
      const orderedActions =
        orderByTranslatedValue('phrases', selectedScreenDataGroup.actions) || []

      setScreenActions(orderedActions)
    }

    if (selectedScreenDataGroup?.fields) {
      const orderedFields =
        orderByTranslatedValue('titles', selectedScreenDataGroup.fields) || []

      setScreenFields(orderedFields)
    }
  }, [selectedScreenDataGroup])

  const orderByTranslatedValue = (section, actions) => {
    return Object.keys(actions).sort((a, b) =>
      intl.get(`${section}.${a.toString()}`).toString()?.toLowerCase() >
      intl.get(`${section}.${b.toString()}`).toString()?.toLowerCase()
        ? 1
        : -1
    )
  }

  return (
    <TableContainer className={classes.tableContainer}>
      {selectedScreen && (
        <Table
          role="table"
          key={`table_${selectedScreen}`}
          className={classes.table}
          size="small"
          stickyHeader
        >
          <TableHead>
            <RowTitleHead
              editStatus={editState}
              titleRow={
                intl.get(`pages.${selectedScreen}`) || `pages.${selectedScreen}`
              }
              userTitleRow={intl.get(`words.group`) || `words.group`}
            />
          </TableHead>
          <TableBody>
            <RowTitle
              editStatus={editState}
              titleRow={intl.get('words.screen') || 'words.screen'}
            />
            <RowWithCheckboxes
              editStatus={editState}
              titleRow={intl.get(`words.active`) || `words.active`}
              userChecked={selectedScreenDataGroup?.visible}
              userDisabled={
                !selectedScreen ||
                !editState ||
                selectedScreen === defaultScreenGroup
              }
              onChange={() => {
                handleChangeStatusScreen(!selectedScreenDataGroup?.visible)
              }}
            />
            <RowWithCheckboxes
              editStatus={editState}
              titleRow={
                intl.get(`words.defaultScreen`) || `words.defaultScreen`
              }
              userChecked={selectedScreen === defaultScreenGroup}
              userDisabled={
                !selectedScreen ||
                !editState ||
                !selectedScreenDataGroup?.visible
              }
              onChange={() => {
                handleChangeDefaultScreen(selectedScreen)
              }}
            />
            {screenActions?.length > 0 && (
              <>
                <RowTitle
                  editStatus={editState}
                  titleRow={intl.get(`words.actions`) || `words.actions`}
                />

                {screenActions.map((action) => {
                  if (
                    typeof selectedScreenDataGroup?.actions?.[action] ===
                    'boolean'
                  ) {
                    const actionGroupValue =
                      selectedScreenDataGroup?.actions?.[action]

                    return (
                      <RowWithCheckboxes
                        editStatus={editState}
                        titleRow={
                          intl.get(`phrases.${action}`) || `phrases.${action}`
                        }
                        userChecked={actionGroupValue}
                        userDisabled={
                          !selectedScreen ||
                          !editState ||
                          !selectedScreenDataGroup?.visible
                        }
                        onChange={() => {
                          handleChangeStatusAction(action, !actionGroupValue)
                        }}
                      />
                    )
                  }
                })}
              </>
            )}
            {screenFields?.length > 0 && (
              <>
                <RowTitle
                  editStatus={editState}
                  titleRow={intl.get(`words.fields`) || `words.fields`}
                />

                {screenFields.map((field) => {
                  if (
                    typeof selectedScreenDataGroup?.fields?.[field] ===
                    'boolean'
                  ) {
                    const fieldGroupValue =
                      selectedScreenDataGroup?.fields?.[field]

                    return (
                      <RowWithCheckboxes
                        editStatus={editState}
                        titleRow={
                          intl.get(`titles.${field}`) || `titles.${field}`
                        }
                        userChecked={fieldGroupValue}
                        userDisabled={
                          !selectedScreen ||
                          !editState ||
                          !selectedScreenDataGroup?.visible
                        }
                        onChange={() => {
                          handleChangeStatusField(field, !fieldGroupValue)
                        }}
                      />
                    )
                  }
                })}
              </>
            )}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  )
}

ScreenDetails.propTypes = {}
