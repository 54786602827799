import { useFetchAxiosWrapper } from '../useFetchAxiosWrapper'
import ENDPOINTS from './ENDPOINTS'
import { normalizeBillingData } from './Normalizers'

const filterQuery = (values) => {
  return {
    groupBy: values?.groupBy,
    order: values?.order,
    orderBy: values?.orderBy,
    productDetails: values?.productDetails,
    detailed: values?.detailed,
    cycleStart: values?.cycleStart,
    cycleEnd: values?.cycleEnd,
    productId: values?.productId,
    customerId: values?.customerId,
    categoryId: values?.categoryId,
    commercialId: values?.commercialId,
    cards: values?.cards,
    paymentMonth: values?.paymentMonth,
    year: values?.year,
    componentDetails: values?.componentDetails,
    limit: values?.limit,
    values: values?.values,
    currencyCode: values?.currencyCode,
  }
}

export const useLibraryBilling = (params) => {
  const { context, callOnInit = true } = params || {}
  const {
    reset,
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    fetchGET,
  } = useFetchAxiosWrapper({
    baseEndpoint: context
      ? ENDPOINTS.GET[context]
      : ENDPOINTS.GET.billing_results,
    callOnInit,
  })

  const handleGET = (params = {}) => {
    const newQuery = filterQuery(params)
    fetchGET({
      urlParameters: { ...newQuery },
    })
  }

  return {
    getRequest: {
      ...getRequest,
      data: normalizeBillingData(getRequest),
    },
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    reset,
    handleGET,
  }
}
