import { DataTypeProvider } from '@devexpress/dx-react-grid'
import classNames from 'classnames'
import React from 'react'
import { Tooltip } from '../../../../core'
import { CheckCircleOutline as CheckCircleOutlineIcon } from '../../../../icons'
import { makeStyles } from '../../../../styles'
import { intl } from '../../../../translation'

const useStyles = makeStyles((theme) => ({
  onlineIcon: {
    borderRadius: 10,
    fontSize: 'initial',
    boxShadow:
      'inset 1px 0 0 rgba(255,255,255,0.3), inset -1px 0 0 rgba(255,255,255,0.3), 0 0 4px 0 rgba(95,99,104,0.3), 0 0 6px 2px rgba(95,99,104,.3)',
  },
  red: {
    color: 'orangered',
  },
  green: {
    color: 'limegreen',
  },
}))

const OnlineStatus = (props) => {
  const classes = useStyles()

  return (
    <DataTypeProvider
      formatterComponent={({ value }) => (
        <Tooltip
          title={value ? intl.get('words.online') : intl.get('words.offline')}
        >
          <CheckCircleOutlineIcon
            className={classNames(
              classes.onlineIcon,
              value ? classes.green : classes.red
            )}
          />
        </Tooltip>
      )}
      {...props}
    />
  )
}

export default OnlineStatus
