import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import intl from 'react-intl-universal'

const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  screen,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Alert severity="warning">{content}</Alert>
      </DialogContent>
      <DialogActions
      // style={{
      //   width: '100%',
      //   justifyContent: 'flex-end',
      //   display: 'flex',
      //   marginTop: '10px',
      // }}
      >
        <Button
          onClick={onClose}
          variant="contained"
          layout="secondary"
          customsize="small"
        >
          {intl.get(`buttons.cancel`)}
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          variant="contained"
          autoFocus
          style={{ backgroundColor: '#df0f0f' }}
        >
          {intl.get(`buttons.confirm`)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
