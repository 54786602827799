import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { intl } from '../../translation'
import { DialogModel } from '../DialogModel'
import { useStyles } from './styles'
import {
  DialSaleDetails,
  DialSaleStepperAssociate,
  GPRSPurchaseDetails,
  GPRSPurchaseStepperAssociate,
  GPRSSaleDetails,
  GPRSSaleStepperAssociate,
  IPSaleDetails,
  IPSaleStepperAssociate,
  LSSSaleDetails,
  LSSSaleStepperAssociate,
  M2MPurchaseDetails,
  M2MPurchaseStepperAssociate,
  M2MSaleDetails,
  M2MSaleStepperAssociate,
  PAYZENSaleDetails,
  PAYZENSaleStepperAssociate,
  VERIPAGSaleDetails,
  VERIPAGSaleStepperAssociate,
  X25SaleDetails,
  X25SaleStepperAssociate,
} from './Tariffs'

export const TariffAssociateDialog = (props) => {
  const classes = useStyles()
  const {
    open,
    onClose,
    codeDetails,
    codes,
    customerDefault,
    customers,
    size,
    numberDetails,
    numbers,
    onAssociateTariff,
    planDetails,
    plans,
    purchaseTariffs,
    requestCodeDetails,
    requestCodes,
    requestCustomers,
    requestNumberDetails,
    requestNumbers,
    requestPlanDetails,
    requestPlans,
    requestPurchaseTariffs,
    tariffSelected,
    requestTariffs,
    tariffs,
    requestSuggestTariffs,
    requestSuggestCustomers,
    isReadyOnly,
    userLanguage,
  } = props

  const [currencyData, setCurrencyData] = useState({})

  useEffect(() => {
    const fullCurrencyData = tariffSelected?.currency
      ? {
          ...tariffSelected?.currency,
          symbol: tariffSelected?.currency?.symbol + ' ',
          thousandSeparator: userLanguage === 'en-US' ? ',' : '.',
          decimalSeparator: userLanguage === 'en-US' ? '.' : ',',
        }
      : undefined

    fullCurrencyData && setCurrencyData(fullCurrencyData)
  }, [tariffSelected])

  const renderTariffDetails = (product, type) => {
    switch (product) {
      case 2:
        return (
          <DialSaleDetails
            tariffSelected={tariffSelected}
            currencyData={currencyData}
          />
        )
      case 3:
        return (
          <DialSaleDetails
            tariffSelected={tariffSelected}
            currencyData={currencyData}
          />
        )
      case 5:
        if (type === 'Compra') {
          return (
            <GPRSPurchaseDetails
              tariffSelected={tariffSelected}
              currencyData={currencyData}
            />
          )
        }
        return (
          <GPRSSaleDetails
            tariffSelected={tariffSelected}
            currencyData={currencyData}
          />
        )
      case 6:
        return (
          <IPSaleDetails
            tariffSelected={tariffSelected}
            currencyData={currencyData}
          />
        )
      case 9:
        return (
          <X25SaleDetails
            tariffSelected={tariffSelected}
            currencyData={currencyData}
          />
        )
      case 10:
        return (
          <LSSSaleDetails
            tariffSelected={tariffSelected}
            currencyData={currencyData}
          />
        )
      case 12:
        return (
          <PAYZENSaleDetails
            tariffSelected={tariffSelected}
            currencyData={currencyData}
          />
        )
      case 104:
        return (
          <VERIPAGSaleDetails
            tariffSelected={tariffSelected}
            currencyData={currencyData}
          />
        )
      case 105:
        if (type === 'Compra') {
          return (
            <M2MPurchaseDetails
              tariffSelected={tariffSelected}
              currencyData={currencyData}
            />
          )
        }
        return (
          <M2MSaleDetails
            tariffSelected={tariffSelected}
            currencyData={currencyData}
          />
        )
      default:
        return (
          <h1 className={classes.error}>
            {intl.get('phrases.inexistentProduct') ||
              'phrases.inexistentProduct'}
          </h1>
        )
    }
  }

  const commonProps = {
    codeDetails,
    codes,
    customerDefault,
    customers,
    numberDetails,
    numbers,
    onAssociateTariff,
    planDetails,
    plans,
    purchaseTariffs,
    requestCodeDetails,
    requestCodes,
    requestCustomers,
    requestNumberDetails,
    requestNumbers,
    requestPlanDetails,
    requestPlans,
    requestPurchaseTariffs,
    tariffSelected,
    onClose,
    requestTariffs,
    tariffs,
    requestSuggestTariffs,
    requestSuggestCustomers,
  }

  const renderTariffStepper = (product, type) => {
    switch (product) {
      case 2:
        return <DialSaleStepperAssociate {...commonProps} />
      case 3:
        return <DialSaleStepperAssociate {...commonProps} />
      case 5:
        if (type === 'Compra') {
          return <GPRSPurchaseStepperAssociate {...commonProps} />
        }
        return <GPRSSaleStepperAssociate {...commonProps} />
      case 6:
        return <IPSaleStepperAssociate {...commonProps} />
      case 9:
        return <X25SaleStepperAssociate {...commonProps} />
      case 10:
        return <LSSSaleStepperAssociate {...commonProps} />
      case 12:
        return <PAYZENSaleStepperAssociate {...commonProps} />
      case 104:
        return <VERIPAGSaleStepperAssociate {...commonProps} />

      case 105:
        if (type === 'Compra') {
          return <M2MPurchaseStepperAssociate {...commonProps} />
        }
        return <M2MSaleStepperAssociate {...commonProps} />
      default:
        return (
          <h1 className={classes.error}>
            {intl.get('phrases.inexistentProduct') ||
              'phrases.inexistentProduct'}
          </h1>
        )
    }
  }

  return (
    <DialogModel
      open={open}
      title={
        intl.get('phrases.tariffInfo', {
          product: tariffSelected?.description,
        }) || 'phrases.tariffInfo'
      }
      size={size || 'lg'}
      onClose={onClose}
    >
      <div className={classes.container}>
        <main className={classes.main}>
          <aside
            className={classes.details}
            style={{ width: isReadyOnly && '100%' }}
          >
            {tariffSelected?.productId &&
              tariffSelected?.type &&
              renderTariffDetails(
                tariffSelected.productId,
                tariffSelected.type
              )}
          </aside>
          {!isReadyOnly && (
            <article className={classes.stepper}>
              {tariffSelected?.productId &&
                tariffSelected?.type &&
                renderTariffStepper(
                  tariffSelected.productId,
                  tariffSelected.type
                )}
            </article>
          )}
        </main>
      </div>
    </DialogModel>
  )
}

TariffAssociateDialog.propTypes = {
  codeDetails: PropTypes.arrayOf(PropTypes.object),
  codes: PropTypes.arrayOf(PropTypes.object),
  customerDefault: PropTypes.object,
  customers: PropTypes.arrayOf(PropTypes.object),
  size: PropTypes.string,
  numberDetails: PropTypes.object,
  numbers: PropTypes.arrayOf(PropTypes.object),
  onAssociateTariff: PropTypes.func,
  planDetails: PropTypes.object,
  plans: PropTypes.arrayOf(PropTypes.object),
  purchaseTariffs: PropTypes.arrayOf(PropTypes.object),
  requestCodeDetails: PropTypes.func,
  requestCodes: PropTypes.func,
  requestCustomers: PropTypes.func,
  requestNumberDetails: PropTypes.func,
  requestNumbers: PropTypes.func,
  requestPlanDetails: PropTypes.func,
  requestPlans: PropTypes.func,
  requestPurchaseTariffs: PropTypes.func,
  tariffSelected: PropTypes.object,
}

TariffAssociateDialog.default = {
  codeDetails: [{}],
  codes: [{}],
  customerDefault: {},
  customers: [{}],
  size: 'lg',
  numberDetails: {},
  numbers: [{}],
  onAssociateTariff: () => console.log('Function onAssociateTariff undefined'),
  planDetails: {},
  plans: [{}],
  purchaseTariffs: [{}],
  requestCodeDetails: () =>
    console.log('Function requestCodeDetails undefined'),
  requestCodes: () => console.log('Function requestCodes undefined'),
  requestCustomers: () => console.log('Function requestCustomers undefined'),
  requestNumberDetails: () =>
    console.log('Function requestNumberDetails undefined'),
  requestNumbers: () => console.log('Function requestNumbers undefined'),
  requestPlanDetails: () =>
    console.log('Function requestPlanDetails undefined'),
  requestPlans: () => console.log('Function requestPlans undefined'),
  requestPurchaseTariffs: () =>
    console.log('Function requestPurchaseTariffs undefined'),
  tariffSelected: {},
}
