import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Table } from '@devexpress/dx-react-grid-material-ui'

import { makeStyles } from '../../../styles'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    margin: 0,
    border: 'none',
    color: theme?.palette?.secondary?.contrastText,
    width: '100vw',
    textAlign: (props) => {
      const alignment = props.type === 'string' ? 'left' : 'right'
      return alignment
    },
  },
  span: {
    fontWeight: 'bold',
  },
  negativeValue: {
    color: 'red',
  },
}))

const CellTotal = ({
  row,
  className,
  column,
  onDoubleClick,
  value,
  ...others
}) => {
  const classes = useStyles(others)

  return (
    <Table.Cell
      {...others}
      row={row}
      column={column}
      onDoubleClick={(e) => onDoubleClick(e, row, column)}
      className={classNames(className, classes.root)}
    >
      <span
        className={classNames(classes.span, {
          [classes.negativeValue]: value?.[0] === '-',
        })}
      >
        {value}
      </span>
    </Table.Cell>
  )
}

CellTotal.defaultProps = {
  row: {},
  className: '',
  column: {},
  onDoubleClick: () => {},
}

CellTotal.propTypes = {
  row: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  column: PropTypes.object.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
}

export default CellTotal
