import React from 'react'
import ReactDOM from 'react-dom'
import AuthProvider from './contexts/Auth'
import ThemeProvider from './contexts/Theme'
import UserProvider from './contexts/User'
import FiltersProvider from './contexts/Filters'
import RouterAuth from './RouterAuth'
import { unregister } from './registerServiceWorker'
import WrapperLocales from './WrapperLocales'
import WrapperSnackBar from './WrapperSnackBar'
import WrapperTheme from './WrapperTheme'
import './index.css'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorBoundaryScreen from './screens/public/ErrorBoundaryScreen'

ReactDOM.render(
  <AuthProvider>
    <UserProvider>
      <WrapperLocales>
        <ThemeProvider>
          <WrapperTheme>
            <WrapperSnackBar>
              <ErrorBoundary fallback={<ErrorBoundaryScreen />}>
                <FiltersProvider>
                  <RouterAuth />
                </FiltersProvider>
              </ErrorBoundary>
            </WrapperSnackBar>
          </WrapperTheme>
        </ThemeProvider>
      </WrapperLocales>
    </UserProvider>
  </AuthProvider>,
  document.getElementById('root')
)
// registerServiceWorker()
unregister()
