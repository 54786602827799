import React, { useState, useEffect, Fragment } from 'react'
import { TextField } from '../TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { intl } from '../../../translation'
import _ from 'lodash'

export const SuggestSelect = (props) => {
  const {
    requestNewOptions,
    listOptions,
    placeholder,
    onSelectOption,
    selectedOption,
    emptyPlaceholder,
    disabled,
    loading,
  } = props
  const [open, setOpen] = useState(false)
  const [valueTyped, setValueTyped] = useState('')
  const [selectedData, setSelectedData] = useState(null)

  useEffect(() => {
    if (selectedOption?.value && selectedOption?.label) {
      const newObjectDataReceived = {}
      newObjectDataReceived.value = selectedOption?.value
      newObjectDataReceived.name = selectedOption?.label

      setSelectedData(newObjectDataReceived)
    }
  }, [])

  useEffect(() => {
    if (listOptions && typeof requestNewOptions === 'function') {
      requestNewOptions(valueTyped)
      return
    }
    if (valueTyped && typeof requestNewOptions === 'function') {
      requestNewOptions(valueTyped)
    }
  }, [valueTyped])

  useEffect(() => {
    const newObjectData = {}
    if (onSelectOption && !_.isEmpty(selectedData)) {
      newObjectData.value = selectedData?.value
      newObjectData.label = selectedData?.name
    }
    if (
      typeof onSelectOption === 'function' &&
      (!selectedOption || selectedOption !== selectedData)
    ) {
      onSelectOption(newObjectData)
    }
  }, [selectedData])

  const handleTypeData = (text) => setValueTyped(text)
  const handleSelectOption = (item) => setSelectedData(item)

  return (
    <Autocomplete
      id="SuggestSelect"
      style={{ width: '100%' }}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      getOptionSelected={(option, value) => option?.name === value?.name}
      getOptionLabel={(option) => option?.name}
      options={listOptions}
      noOptionsText={intl.get('phrases.noOptions')}
      loading={loading}
      onInputChange={(event, newInputValue) => handleTypeData(newInputValue)}
      onChange={(event, newValue) => handleSelectOption(newValue)}
      value={selectedData}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          marginTop={5}
          {...params}
          label={
            emptyPlaceholder
              ? ''
              : placeholder ||
                intl.get('words.inputSearchTitle') ||
                'words.inputSearchTitle'
          }
          margin="dense"
          InputProps={{
            ...params?.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params?.InputProps?.endAdornment}
              </Fragment>
            ),
          }}
          disabled={disabled}
        />
      )}
    />
  )
}
