import PropTypes from 'prop-types'
import React from 'react'
import { Route, withRouter, Switch } from 'react-router-dom'
import { Typography } from '../../core'
import { intl } from '../../translation'
import { makeStyles } from '../../styles'

const useStyles = makeStyles((theme) => ({
  headerStart: {
    [theme?.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
}))
export const HeaderRouter = withRouter((props) => {
  const classes = useStyles()

  const { routes } = props
  return (
    <Switch>
      {routes?.map((route, index) => {
        if (route?.hasNestedItems) {
          const { nestedItems } = route
          return nestedItems?.map((nestedRoute, nestedIndex) => (
            <Route
              key={`${nestedIndex}`}
              path={nestedRoute?.path}
              exact
              render={() => (
                <Typography className={classes.headerStart} variant="h6" noWrap>
                  {intl.get(`pages.${nestedRoute?.text}`) ||
                    `pages.${nestedRoute?.text}`}
                </Typography>
              )}
              {...props}
            />
          ))
        }

        return (
          <Route
            key={`${index}`}
            path={route?.path}
            exact
            render={() => (
              <Typography className={classes.headerStart} variant="h6" noWrap>
                {intl.get(`pages.${route?.text}`) || `pages.${route?.text}`}
              </Typography>
            )}
            {...props}
          />
        )
      })}
    </Switch>
  )
})

HeaderRouter.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  restProps: PropTypes.object,
}
