import {
  AccountBalance as AccountBalanceIcon,
  AccountBox as AccountBoxIcon,
  AccountTree as AccountTreeIcon,
  AttachMoney as AttachMoneyIcon,
  Business as BusinessIcon,
  Dashboard as DashboardIcon,
  Description as DescriptionIcon,
  ErrorOutline as ErrorOutlineIcon,
  Group as GroupWorkIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  HowToReg as HowToRegIcon,
  LocalOffer as LocalOfferIcon,
  People as PeopleIcon,
  Receipt as ReceiptIcon,
  Settings as SettingsIcon,
  ShowChart as ShowChartIcon,
  Star as StarIcon,
  TouchApp as TouchAppIcon,
  TrendingUp as TrendingUpIcon,
  PieChart as PieChartIcon,
  Email as EmailIcon,
  LibraryAddCheck as LibraryAddCheckIcon,
  Face as FaceIcon,
  FormatListNumbered as FormatListNumberedIcon,
  BarChart as BarChartIcon,
  Person as PersonIcon,
  BusinessCenter as BusinessCenterIcon,
  MonetizationOn as MonetizationOnIcon,
} from '../components/Library/icons'

import React from 'react'
import Loadable from 'react-loadable'
import LoadingComponent from '../utils/LoadingComponent'
import FiltersReportsProvider from '../contexts/Reports'
import FiltersCommercialProvider from '../contexts/Commercial'

const defaultConfigCharge = {
  loading: LoadingComponent,
  delay: 500,
  timeout: 3000,
}

export const AsyncLogin = Loadable({
  loader: () => import('../screens/public/Login'),
  ...defaultConfigCharge,
})

export const AsyncNewPassword = Loadable({
  loader: () => import('../screens/public/NewPassword'),
  ...defaultConfigCharge,
})

const AsyncUsers = Loadable({
  loader: () => import('../screens/private/Users'),
  ...defaultConfigCharge,
})

const AsyncDashboard = Loadable({
  loader: () => import('../screens/private/Dashboard'),
  ...defaultConfigCharge,
})

const AsyncLATAMDashboard = Loadable({
  loader: () => import('../screens/private/LATAMDashboard'),
  ...defaultConfigCharge,
})

const AsyncTariffs = Loadable({
  loader: () => import('../screens/private/Tariffs'),
  ...defaultConfigCharge,
})

const AsyncLATAMTariffs = Loadable({
  loader: () => import('../screens/private/LATAMTariffs'),
  ...defaultConfigCharge,
})

const AsyncGroups = Loadable({
  loader: () => import('../screens/private/Groups'),
  ...defaultConfigCharge,
})

const AsyncExtract = Loadable({
  loader: () => import('../screens/private/Extract'),
  ...defaultConfigCharge,
})

const AsyncCommissions = Loadable({
  loader: () => import('../screens/private/Commissions'),
  ...defaultConfigCharge,
})

const AsyncBillingProcess = Loadable({
  loader: () => import('../screens/private/BillingProcess'),
  ...defaultConfigCharge,
})

const AsyncBillingErrors = Loadable({
  loader: () => import('../screens/private/BillingErrors'),
  ...defaultConfigCharge,
})

const AsyncCommercialBonus = Loadable({
  loader: () => import('../screens/private/CommercialBonus'),
  ...defaultConfigCharge,
})

const AsyncInvoices = Loadable({
  loader: () => import('../screens/private/Invoices'),
  ...defaultConfigCharge,
})

const AsyncSalesOrders = Loadable({
  loader: () => import('../screens/private/SalesOrders'),
  ...defaultConfigCharge,
})

const AsyncProducts = Loadable({
  loader: () => import('../screens/private/Products'),
  ...defaultConfigCharge,
})

// const AsyncCustomers = Loadable({
//   loader: () => import('../screens/private/Customers'),
//   ...defaultConfigCharge,
// })

const AsyncLATAMCustomers = Loadable({
  loader: () => import('../screens/private/LATAMCustomers'),
  ...defaultConfigCharge,
})

const AsyncLATAMShop = Loadable({
  loader: () => import('../screens/private/LATAMShop'),
  ...defaultConfigCharge,
})

const AsyncOpportunities = Loadable({
  loader: () => import('../screens/private/Opportunities'),
  ...defaultConfigCharge,
})

const AsyncBilling = Loadable({
  loader: () => import('../screens/private/Billing'),
  ...defaultConfigCharge,
})

const AsyncReports = Loadable({
  loader: () => import('../screens/private/Reports'),
  ...defaultConfigCharge,
})
const AsyncLATAMBilling = Loadable({
  loader: () => import('../screens/private/LATAMBilling'),
  ...defaultConfigCharge,
})

const AsyncAccounts = Loadable({
  loader: () => import('../screens/private/Accounts'),
  ...defaultConfigCharge,
})

const AsyncSendEmails = Loadable({
  loader: () => import('../screens/private/SendEmails'),
  ...defaultConfigCharge,
})

const AsyncConciliationGPRS = Loadable({
  loader: () => import('../screens/private/ConciliationGPRS'),
  ...defaultConfigCharge,
})

const AsyncConciliationGPRSPlans = Loadable({
  loader: () => import('../screens/private/Plans'),
  ...defaultConfigCharge,
})

const AsyncConciliationGPRSInternInvoice = Loadable({
  loader: () => import('../screens/private/InternInvoice'),
  ...defaultConfigCharge,
})

const AsyncCommercial = Loadable({
  loader: () => import('../screens/private/Commercial'),
  ...defaultConfigCharge,
})

export const privateRoutesBR = [
  {
    text: `dashboard`,
    icon: DashboardIcon,
    path: '/',
    role: ['dashboardScreen'],
    main: <AsyncDashboard />,
  },
  {
    text: `financial`,
    icon: AccountBalanceIcon,
    hasNestedItems: true,
    role: [
      'billingScreen',
      'billingErrorScreen',
      'invoiceScreen',
      'salesOrderScreen',
      'billingEmailScreen',
    ],
    nestedItems: [
      {
        text: `billing`,
        icon: TrendingUpIcon,
        path: '/billing',
        role: ['billingScreen'],
        main: <AsyncBilling />,
      },
      {
        text: `billingError`,
        icon: ErrorOutlineIcon,
        path: '/billing-error',
        role: ['billingErrorScreen'],
        main: <AsyncBillingErrors />,
      },
      {
        text: `invoice`,
        icon: DescriptionIcon,
        path: '/invoice',
        role: ['invoiceScreen'],
        main: <AsyncInvoices />,
      },
      {
        text: `salesOrder`,
        icon: LocalOfferIcon,
        path: '/sales-order',
        role: ['salesOrderScreen'],
        main: <AsyncSalesOrders />,
      },
      {
        text: `billingEmailScreen`,
        icon: EmailIcon,
        path: '/billing_email',
        role: ['billingEmailScreen'],
        main: <AsyncSendEmails />,
      },
    ],
  },
  {
    text: `reports`,
    icon: PieChartIcon,
    path: '/reports',
    role: ['billingReportScreen'],
    main: (
      <FiltersReportsProvider>
        <AsyncReports />
      </FiltersReportsProvider>
    ),
  },
  {
    text: `products`,
    icon: AccountTreeIcon,
    path: '/products',
    role: ['productScreen'],
    main: <AsyncProducts />,
  },
  // {
  //   text: `customer`,
  //   icon: BusinessIcon,
  //   path: '/customer',
  //   role: ['customerScreen'],
  //   main: <AsyncCustomers />,
  // },
  {
    text: `crm`,
    icon: AccountBoxIcon,
    hasNestedItems: true,
    role: [
      'commercialScreen',
      'accountScreen',
      'opportunityScreen',
      'commissionScreen',
      'extractScreen',
    ],
    nestedItems: [
      {
        text: `commercialScreen`,
        icon: BusinessCenterIcon,
        path: '/commercial',
        role: ['commercialScreen'],
        main: (
          <FiltersCommercialProvider>
            <AsyncCommercial />
          </FiltersCommercialProvider>
        ),
      },
      {
        text: `account`,
        icon: AccountBoxIcon,
        path: '/account',
        role: ['accountScreen'],
        main: <AsyncAccounts />,
      },
      {
        text: `opportunity`,
        icon: ShowChartIcon,
        path: '/opportunity',
        role: ['opportunityScreen'],
        main: <AsyncOpportunities />,
      },
      {
        text: `commission`,
        icon: MonetizationOnIcon,
        path: '/commissions',
        role: ['commissionScreen'],
        main: <AsyncCommissions />,
      },
      {
        text: `extract`,
        icon: ReceiptIcon,
        path: '/extract',
        role: ['extractScreen'],
        main: <AsyncExtract />,
      },
    ],
  },
  {
    text: `conciliation_gprs`,
    icon: LibraryAddCheckIcon,
    hasNestedItems: true,
    role: [
      'conciliationGprsScreen',
      'conciliationGprsPlanScreen',
      'conciliationGprsReportScreen',
    ],
    nestedItems: [
      {
        text: `conciliationGPRS`,
        icon: LibraryAddCheckIcon,
        path: '/conciliation_gprs',
        role: ['conciliationGprsReadScreen'],
        main: <AsyncConciliationGPRS />,
      },
      {
        text: `plansScreen`,
        icon: FormatListNumberedIcon,
        path: '/plans',
        role: ['conciliationGprsPlanScreen'],
        main: <AsyncConciliationGPRSPlans />,
      },
      {
        text: `reportScreen`,
        icon: BarChartIcon,
        path: '/intern_invoice',
        role: ['conciliationGprsReportScreen'],
        main: <AsyncConciliationGPRSInternInvoice />,
      },
    ],
  },
  {
    text: `admin`,
    icon: SettingsIcon,
    hasNestedItems: true,
    role: [
      'tariffScreen',
      'userScreen',
      'groupScreen',
      'billingProcessScreen',
      'commercialBonusScreen',
    ],
    nestedItems: [
      {
        text: `tariff`,
        icon: AttachMoneyIcon,
        path: '/tariff',
        role: ['tariffScreen'],
        main: <AsyncTariffs />,
      },
      {
        text: `users`,
        icon: PersonIcon,
        path: '/users',
        role: ['userScreen'],
        main: <AsyncUsers />,
      },
      {
        text: `groups`,
        icon: GroupWorkIcon,
        path: '/groups',
        role: ['groupScreen'],
        main: <AsyncGroups />,
      },

      {
        text: `billingProcess`,
        icon: TouchAppIcon,
        path: '/billing-process',
        role: ['billingProcessScreen'],
        main: <AsyncBillingProcess />,
      },
      {
        text: `commercialBonus`,
        icon: StarIcon,
        path: '/commercial-bonus',
        role: ['commercialBonusScreen'],
        main: <AsyncCommercialBonus />,
      },
    ],
  },
]

export const privateRoutesLATAM = [
  {
    text: `dashboard`,
    icon: DashboardIcon,
    path: '/',
    role: ['dashboardScreen'],
    main: <AsyncLATAMDashboard />,
  },
  {
    text: `financial`,
    icon: AccountBalanceIcon,
    hasNestedItems: true,
    role: ['billingScreen'],
    nestedItems: [
      {
        text: `billing`,
        icon: TrendingUpIcon,
        path: '/billing',
        role: ['billingScreen'],
        main: <AsyncLATAMBilling />,
      },
    ],
  },
  {
    text: `customer`,
    icon: BusinessIcon,
    path: '/customer',
    role: ['customerScreen'],
    main: <AsyncLATAMCustomers />,
  },
  {
    text: `payzenShop`,
    icon: BusinessIcon,
    path: '/payzenShop',
    role: ['customerScreen'],
    main: <AsyncLATAMShop />,
  },
  {
    text: `admin`,
    icon: SettingsIcon,
    hasNestedItems: true,
    role: ['tariffScreen'],
    nestedItems: [
      {
        text: `tariff`,
        icon: AttachMoneyIcon,
        path: '/tariff',
        role: ['tariffScreen'],
        main: <AsyncLATAMTariffs />,
      },
      {
        text: `billingProcess`,
        icon: TouchAppIcon,
        path: '/billing-process',
        role: ['billingProcessScreen'],
        main: <AsyncBillingProcess />,
      },
    ],
  },
]
