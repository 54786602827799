import { DataTypeProvider } from '@devexpress/dx-react-grid'
import React, { Fragment } from 'react'
import { Error as ErrorIcon } from '../../../../icons'
import { Chip } from '../../../../core'
import { makeStyles } from '../../../../styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    overflowX: 'auto',
  },
  chip: {
    margin: 5,
    padding: 1,
  },
  chipError: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
  },
}))

const GenericStringList = (props) => {
  const classes = useStyles()

  return (
    <DataTypeProvider
      formatterComponent={({ value }) => {
        if (!value) return ''

        return (
          <div className={classes.container}>
            {value?.map((value, index) => {
              const hasError = value.toString()?.includes('#ERROR')
              const newValue = hasError ? value.replace('#ERROR', '') : value
              return (
                <Fragment key={`${value}-${index}`}>
                  <Chip
                    icon={hasError && <ErrorIcon style={{ color: '#fff' }} />}
                    className={`${classes.chip} ${
                      hasError ? classes.chipError : ''
                    }`}
                    label={newValue}
                    size="small"
                  />
                  <br />
                </Fragment>
              )
            })}
          </div>
        )
      }}
      {...props}
    />
  )
}

export default GenericStringList
