import axios from 'axios'
import { intl } from '../components/Library/translation'

const event401 = new Event('error-401')
const event501 = new Event('error-501')

const validateError = (err) => {
  if (axios.isCancel(err)) {
    return `cancelled`
  }

  const errMessage = { listMessages: [], listExceptions: [] }

  const responseErrors = err && err.response && err.response.data

  if (
    responseErrors &&
    responseErrors.errors &&
    responseErrors.errors.length > 0
  ) {
    const error = responseErrors.errors[0]

    if (error.status === 503 && error.code === 'SERVER_UNAVAILABLE') {
      errMessage.listMessages.push(error.title)
      window.dispatchEvent(event501)

      return errMessage
    }
  }

  if (responseErrors && responseErrors === 'Authentication Error') {
    errMessage.listMessages.push(intl.get('phrases.401'))
    window.dispatchEvent(event401)

    return errMessage
  }

  if (responseErrors && responseErrors.listExceptions) {
    const { listExceptions } = responseErrors
    const exceptionsMessages = []
    listExceptions.map((lineError) => {
      const errorBody = { ...lineError }
      delete errorBody.errorMessage
      return exceptionsMessages.push(
        intl.get(`phrases.${lineError.errorMessage}`, { ...errorBody })
      )
    })
    errMessage.listExceptions = exceptionsMessages
  }

  if (responseErrors && responseErrors.errorDescription) {
    const { errorDescription, errorBody } = responseErrors

    if (
      errorDescription === 'INVALID_TOKEN' ||
      errorDescription === 'token_required' ||
      errorDescription === 'decoded_token_error' ||
      errorDescription === 'login_expired' ||
      errorDescription === 'inactive_user' ||
      errorDescription === 'error_at_new_login_expiration' ||
      errorDescription === 'invalidToken'
    ) {
      errMessage.listMessages.push(intl.get('phrases.401'))
      window.dispatchEvent(event401)

      return errMessage
    }

    errMessage.listMessages.push(
      intl.get(`phrases.${responseErrors.errorDescription}`, { ...errorBody })
    )
    return errMessage
  }

  errMessage.listMessages.push(intl.get(`phrases.errorNotFound`))
  return errMessage
}

export default validateError
