import React, { useState, useEffect } from 'react'
import {
  InsertDriveFile as InsertDriveFileIcon,
  Backup as BackupIcon,
} from '../../../icons'
import { makeStyles } from '../../../styles'
import { intl } from '../../../translation'
import { Button } from '../Button'
import { useSnackbar } from '../../../notifications'

const useStyles = makeStyles(() => ({
  inputBox: {
    width: '100%',
    heigth: 200,
  },
  input: {
    display: 'none',
  },
  textBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: '#8a8a8a',
  },
}))

export const UploadInput = ({
  withIcon,
  handleSelectFile,
  cleanFileValidation,
  hasOldFile,
}) => {
  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()

  const [fileName, setFileName] = useState(null)

  useEffect(() => {
    if (hasOldFile) {
      handleClean()
    }
  }, [hasOldFile])

  const handleFileChange = (e) => {
    const file = e?.target?.files?.[0]
    if (file) {
      setFileName(file?.name)
    } else {
      enqueueSnackbar(intl.get('phrases.uploadFilePayzenError'), {
        variant: 'error',
      })
    }

    if (typeof handleSelectFile === 'function') {
      handleSelectFile(file)
    }
  }

  const handleClean = () => {
    setFileName(null)
    if (typeof cleanFileValidation === 'function') {
      cleanFileValidation()
    }
  }

  return (
    <div className={classes.inputBox}>
      {!fileName && (
        <Button
          variant="contained"
          backgroundcolor="#e0e0e0"
          component="label"
          startIcon={withIcon ? <BackupIcon /> : null}
          fullwidth
        >
          {intl.get(`phrases.selectFile`) || `phrases.selectFile`}
          <input
            className={classes.input}
            type="file"
            onChange={handleFileChange}
          />
        </Button>
      )}

      {fileName && (
        <>
          <Button
            variant="contained"
            backgroundcolor="#e0e0e0"
            component="label"
            onClick={handleClean}
            fullwidth
          >
            {intl.get(`phrases.removeFile`) || `phrases.removeFile`}
          </Button>
          <div className={classes.textBox}>
            <p className={classes.text}>
              <InsertDriveFileIcon />
            </p>
            <p className={classes.text}>{fileName}</p>
          </div>
        </>
      )}
    </div>
  )
}

UploadInput.propTypes = {}
